import { PLAN_TYPES } from "../../constants/mail.constant";
import { TBillingCycle } from "../../types/billing-cycle";
import { TPlan } from "../../types/plan.type";
import { BILLING_CYCLE_KEYS } from "../constants";

const { MONTHLY, QUARTERLY, YEARLY, TWO_YEARLY, FOUR_YEARLY } =
  BILLING_CYCLE_KEYS;

// will be used to get the supported billing cycle for all plans and used in the plan page
export const getSupportedBillingCycleForAllPlans = (
  haveLitePlanSupport: boolean
): TBillingCycle[] => {
  if (haveLitePlanSupport) {
    return [QUARTERLY, YEARLY, TWO_YEARLY];
  }
  return [MONTHLY, YEARLY, TWO_YEARLY, FOUR_YEARLY];
};

export const getSupportedBillingCycleForPlan = (
  plan: TPlan,
  haveLitePlanSupport: boolean
): TBillingCycle[] => {
  const planType = plan.neoPlanType;

  switch (planType) {
    case PLAN_TYPES.LITE:
      return haveLitePlanSupport ? [YEARLY, TWO_YEARLY, FOUR_YEARLY] : [];
    case PLAN_TYPES.STARTER:
    case PLAN_TYPES.STANDARD:
    case PLAN_TYPES.MAX:
      return haveLitePlanSupport
        ? [MONTHLY, QUARTERLY, YEARLY, TWO_YEARLY, FOUR_YEARLY]
        : [MONTHLY, YEARLY, TWO_YEARLY, FOUR_YEARLY];
    default:
      return [];
  }
};
