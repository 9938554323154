import { useSelector } from "react-redux";
import {
  ANALYTICS_EVENTS,
  SUCCESS_REDIRECTION_TIME,
} from "../../helpers/constants";
import { isMobile } from "../../helpers/utils";
import { useRedirection } from "../../hooks/useRedirection";
import { createBulkEmail } from "../../services/apiFunctions";
import { userSelector } from "../../store/selectors";
import { logAdditionalMailboxesCreated } from "../../telemetry/medusaEventsFunctions";
import {
  selectMailAdditionalMailbox,
  selectMailPurchaseDomain,
} from "../../store/slices/mail.slice";

/**
 * This hook create mailbox in bulk using a lambda function createBulkEmail
 */
const { ADDITIONAL_MAILBOXES_CREATED } = ANALYTICS_EVENTS;
export const useBulkMailboxPurchase = ({
  setCountOfMailboxPurchaseFailed,
  setMailboxPurchaseSuccess,
}) => {
  const { alternateEmail, token } = useSelector(userSelector);
  // TODO: for site
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);

  // TODO: for site
  const domain = useSelector(selectMailPurchaseDomain);
  const { handleCheckoutRedirection } = useRedirection();

  const handleBulkMailboxPurchase = () => {
    createBulkEmail({
      token,
      domainName: domain,
      startupEmails: additionalMailboxes.map((item) => item.email),
      ...(isMobile() && {
        alternateEmail: alternateEmail,
      }),
    })
      .then((response) => {
        if (response?.data?.mailboxCreationFailedCount > 0) {
          setCountOfMailboxPurchaseFailed(
            response.data.mailboxCreationFailedCount
          );
        } else {
          logAdditionalMailboxesCreated(ADDITIONAL_MAILBOXES_CREATED);
          setMailboxPurchaseSuccess(true);
          setTimeout(() => {
            handleCheckoutRedirection();
          }, SUCCESS_REDIRECTION_TIME * 1000);
        }
      })
      .catch((error) => {
        setCountOfMailboxPurchaseFailed(additionalMailboxes?.length);
        console.error(error);
      });
  };

  return { handleBulkMailboxPurchase };
};
