import AppLoaderIcon from '../../assets/images/loading-spinner.svg';
import s from './Loader.module.scss';

const Loader = ({ loadingText }) => {
  return (
    <div className={s.loader}>
      <img src={AppLoaderIcon} alt='app-loader' />
      { loadingText ? <span>{loadingText}</span> : null }
    </div>
  )
}

export default Loader;
