import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router";
import classNames from "classnames";
import AddMailbox from "../index";
import { appSelector, userSelector } from "../../../store/selectors";
import { userActions } from "../../../store/actions";
import {
  ANALYTICS_EVENTS,
  NEO_E5_DOMAINS,
  OFFERING,
  PAGE_PATHS,
} from "../../../helpers/constants";
import AdminMailboxInfo from "./AdminMailboxInfo";
import styles from "../styles.module.scss";
import { TOnOkButtonClickArgs } from "../Common/types";
import { TLogEventProps } from "../../../types/global";
import { logMailboxEvent } from "../../../telemetry/medusaEventsFunctions";
import { showTeamMailboxPostDomainPurchase } from "../../../helpers/featureVWOExperimentsHelper";
import {
  isMailDomainCustom,
  selectMailAdditionalMailbox,
  selectMailAdminMailbox,
  selectMailPurchaseDomain,
  setMailAdminMailbox,
  setMailDomain,
} from "../../../store/slices/mail.slice";

const { MAILBOX_ADD_VIEWED, MAILBOX_ADDED, MAILBOX_ADD_FAILED } =
  ANALYTICS_EVENTS;
const { STAGING, PREPROD, PROD } = NEO_E5_DOMAINS;

const MailAddMailbox: React.FC = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { updateUser } = bindActionCreators(userActions, dispatch);
  const { hasActiveOrders } = useSelector(userSelector);
  const domain = useSelector(selectMailPurchaseDomain);
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);
  const adminMailbox = useSelector(selectMailAdminMailbox);
  const { search_params } = useSelector(appSelector);
  const isCustomDomainFlow = useSelector(isMailDomainCustom);

  const redirectToDomainSelection = () => {
    /**
     * firstOrder will show stale data, so update here once
     */
    updateUser({ firstOrder: !hasActiveOrders });
    navigateTo({
      pathname: PAGE_PATHS.GET_STARTED,
      search: search_params,
    });
  };

  const title = (
    <>
      Let's create your mailbox{" "}
      <div className={classNames("subTitle", styles.greenDomainName)}>
        @{domain}
        <div onClick={redirectToDomainSelection}>&#9998;</div>
      </div>
    </>
  );

  const onOkButtonClick = async ({
    mailboxCreds,
    email,
  }: TOnOkButtonClickArgs) => {
    dispatch(setMailAdminMailbox({ ...mailboxCreds, email }));

    await redirectUserBasedOnCampaign();
  };

  const redirectUserBasedOnCampaign = async () => {
    const skipTeamAndGenericMailbox = await showTeamMailboxPostDomainPurchase();
    updateUser({
      showTeamMailboxPostDomainPurchase: skipTeamAndGenericMailbox,
    });
    const pagePath = skipTeamAndGenericMailbox
      ? isCustomDomainFlow
        ? PAGE_PATHS.PLANS
        : PAGE_PATHS.PREVIEW_SITE
      : PAGE_PATHS.ADD_TEAM_MAILBOX;

    navigateTo({
      pathname: pagePath,
      search: search_params,
    });
  };

  const logEvent = ({ eventName, data }: TLogEventProps) => {
    switch (eventName) {
      case MAILBOX_ADD_VIEWED:
      case MAILBOX_ADDED:
      case MAILBOX_ADD_FAILED:
        logMailboxEvent(eventName, data);
        break;

      default:
        console.log("Invalid event name");
    }
  };

  const setOffering = () => {
    if (
      !domain?.includes(PROD) &&
      !domain?.includes(STAGING) &&
      !domain?.includes(PREPROD)
    ) {
      dispatch(setMailDomain({ offering: OFFERING.CUSTOM_DOMAIN }));
    } else {
      dispatch(setMailDomain({ offering: OFFERING.COSITE }));
    }
  };

  return (
    <AddMailbox
      domain={domain}
      additionalMailboxes={additionalMailboxes}
      adminMailbox={adminMailbox}
      title={title}
      AdminMailboxInfo={<AdminMailboxInfo />}
      okButtonProps={{
        label: "Create mailbox",
        handler: onOkButtonClick,
      }}
      logEvent={logEvent}
      setOffering={setOffering}
    />
  );
};

export default MailAddMailbox;
