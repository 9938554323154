import {
  MAIL_PLAN_FEATURE_MAP,
  PLAN_TYPES,
  PLAN_TYPES_TO_BE_FILTERED,
} from "../../constants/mail.constant";
import {
  TFeature,
  TFeatureTextMap,
  TPlan,
  TPlanFromAPIResponse,
} from "../../types/plan.type";
import { toGB } from "../../utils/size.utils";
import {
  addDiscountPercentageToPlans,
  addPricingOfPaidPlanIntoTrialPlan,
  // filterOutFreePlans,
  formatPlanDisplayName,
  getPlanFeaturesComparison,
} from "../plans.helper";

const plansMiddlewares = [
  addPricingOfPaidPlanIntoTrialPlan,
  // filterOutFreePlans,
  filterOutUnwantedPlans,
];

const _sanitizeNeoFeatures = (
  planList: TPlan[],
  isCustomDomain: boolean
): TPlan[] => {
  return planList.map((plan) => {
    const features = plan.features.filter((feature) => {
      return isCustomDomain
        ? feature.type !== MAIL_PLAN_FEATURE_MAP.NEO_DOMAIN
        : feature.type !== MAIL_PLAN_FEATURE_MAP.CUSTOM_DOMAIN;
    });
    return {
      ...plan,
      features,
    };
  });
};

const _getFeatureValueForPlan = (feature: TFeature) => {
  const { attrs, type } = feature;
  switch (type) {
    case MAIL_PLAN_FEATURE_MAP.READ_RECEIPTS:
      return attrs.limit ? { val: `${attrs.limit} Read Receipts` } : null;
    case MAIL_PLAN_FEATURE_MAP.CONTACT_GROUPS:
      return attrs.limit ? { val: `${attrs.limit} Contact Group` } : null;
    case MAIL_PLAN_FEATURE_MAP.EMAIL_TEMPLATES:
      return attrs.limit ? { val: `${attrs.limit} Email Template` } : null;
    case MAIL_PLAN_FEATURE_MAP.TITAN_AI:
      return attrs.limit ? { val: `${attrs.limit} AI responses` } : null;
    case MAIL_PLAN_FEATURE_MAP.MULTIPLE_EXTERNAL_FORWARDER:
      return attrs.limit ? { val: `${attrs.limit} Forwarders` } : null;
    case MAIL_PLAN_FEATURE_MAP.STORAGE:
      return attrs.quota_in_mb
        ? { val: `${toGB(attrs.quota_in_mb as unknown as number)} GB` }
        : null;
    case MAIL_PLAN_FEATURE_MAP.CUSTOM_DOMAIN:
      return attrs ? { val: `${attrs.trialInDays} days free` } : null;
    case MAIL_PLAN_FEATURE_MAP.NEO_SITE:
      return attrs ? { val: "Free Beta" } : null;
    case MAIL_PLAN_FEATURE_MAP.MAIL_SEND:
      return attrs ? { val: `${attrs.dailyLimit} per day` } : null;
    default:
      return null;
  }
};

const getFeatTitle = (
  feature: TFeature,
  featuresTextMap: TFeatureTextMap,
  { domain }: { domain?: string } = {}
) => {
  if (domain && feature.type === MAIL_PLAN_FEATURE_MAP.NEO_DOMAIN) {
    return `<strong>${domain} </strong>${
      featuresTextMap?.[feature.type]?.text
    }`;
  }
  return featuresTextMap?.[feature.type]?.text;
};

export const getMailPlanFeaturesComparison = (
  plans: TPlan[],
  {
    isCustomDomain,
    domain,
    featuresTextMap,
    getFeatureValueForPlan,
  }: {
    isCustomDomain: boolean;
    domain: string;
    featuresTextMap: TFeatureTextMap;
    getFeatureValueForPlan: Parameters<
      typeof getPlanFeaturesComparison
    >[1]["getFeatureValueForPlan"];
  }
) => {
  const planList = _sanitizeNeoFeatures(plans, isCustomDomain);

  return getPlanFeaturesComparison(planList, {
    getFeatTitle: (feature) =>
      getFeatTitle(feature, featuresTextMap, {
        domain,
      }),
    getFeatureValueForPlan: (feature, plan) => {
      return (
        getFeatureValueForPlan(feature, plan) ||
        _getFeatureValueForPlan(feature)
      );
    },
  });
};

export const convertToNeoPlanType = (planType: string): string => {
  const planTypeLowerCase = planType?.toLowerCase();
  if (!planTypeLowerCase) return PLAN_TYPES.STARTER;
  if (planTypeLowerCase.startsWith("neo_domain")) return PLAN_TYPES.DOMAIN;
  if (planTypeLowerCase.startsWith("lite")) return PLAN_TYPES.LITE;
  if (planTypeLowerCase.startsWith("pro")) return PLAN_TYPES.STARTER;
  if (planTypeLowerCase.startsWith("premium")) return PLAN_TYPES.STANDARD;
  if (planTypeLowerCase.startsWith("ultra")) return PLAN_TYPES.MAX;
  return PLAN_TYPES.STARTER;
};

export const formatAndSortMailPlans = (
  plans: TPlanFromAPIResponse,
  featureProps?: any
): TPlan[] => {
  const formattedAndSortedPlans = plans
    .map((plan: TPlanFromAPIResponse) => ({
      ...plan,
      pricing: addDiscountPercentageToPlans(plan.planPricing),
      features: plan.features.map((pf: any) => ({
        ...(pf || {}),
        metaInfo: featureProps?.[pf.type] || null,
      })),
      neoPlanType: convertToNeoPlanType(plan.type),
      name: formatPlanDisplayName(plan.displayName)
    }))
    .sort(
      (a: TPlanFromAPIResponse, b: TPlanFromAPIResponse) => a.zIndex - b.zIndex
    );
  return plansMiddlewares.reduce(
    (plans: TPlan[], middleware) => middleware(plans),
    formattedAndSortedPlans
  );
};

export function filterOutUnwantedPlans(plans: TPlan[]): TPlan[] {
  return plans.filter(
    (plan) => !PLAN_TYPES_TO_BE_FILTERED.includes(plan.neoPlanType)
  );
}

export const getMailPlanToUpSell = (plans: TPlan[], useTrial = true): TPlan => {
  const filteredPlans = plans.filter(({ isTrial }) =>
    useTrial ? isTrial : !isTrial
  );
  return (
    filteredPlans.find((plan) => plan.neoPlanType === PLAN_TYPES.STARTER) ||
    filteredPlans[0]
  );
};
