export const EXIT_POPUP_MAX_AGE = 8 * 60 * 60 * 1000;

export const PITCH_TYPES = {
  EMAIL: "email",
  DOMAIN: "domain",
} as const;

export const EXIT_INTENT_TYPES = {
  MOUSE_EXIT: "Outside viewport",
  BACK_BUTTON_EXIT: "Browser back",
} as const;
