import BackoffScheduler from './backoff-scheduler';

class ExponentialBackoffScheduler extends BackoffScheduler {
  constructor(opts: { baseDelay?: number; maxDelay?: number; jitter?: boolean } = {}) {
    super({ ...opts });
  }

  /**
   * Calculates back off delay using exponential concept
   * @param {Number} baseDelay in seconds
   * @param {Number} numTries
   * @returns nextDelay in milliseconds
   */
  getNextBackoffDelay(base: number, exponent: number): number {
    const nextDelay: number = base ** exponent * 1000;
    return nextDelay;
  }
}

export default ExponentialBackoffScheduler;
