import ENBridge from "../../bridge";
import { sendMedusaEvent } from "../../utils/medusa";

export const entriSetupAborted = (
  entriCloseEventPayload,
  entriState,
  neoState
) => {
  if (!entriCloseEventPayload || !entriState) return null;

  const { getDnsSetupFor } = ENBridge.handlers;

  const eventName = `${getDnsSetupFor().toLowerCase()}_entri_setup_aborted`;

  return sendMedusaEvent(eventName, neoState, {
    domain_provider: entriState.provider,
    setup_type: entriState.setupType,
    setup_success: !!entriCloseEventPayload.success,
    reason: entriCloseEventPayload.lastStatus,
  });
};
