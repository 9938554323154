import { UPDATE_APP_PARAMS } from "./actionsConstants";

export const updateAppParams = (appParams) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_APP_PARAMS,
      payload: appParams,
    });
  };
};
