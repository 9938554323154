import { VWOLoaded } from "./vwoHelper";

export const onVWOLoaded = async () => {
  try {
    await VWOLoaded;
    return true;
  } catch {
    console.log("VWO loading did not complete successfully, continuing");
    return true;
  }
};
