import { getTotalNoOfMailboxesAdded } from "../../helpers/mailbox.helper";
import {
  selectSiteAdminMailbox,
  selectSiteNoOfAdditionalMailboxes,
} from "../../store/slices/site.slice";
import { store } from "../../store/store";
import { RootState } from "../../types/store.type";

export const getSiteMailboxesDataForMedusa = (storeState?: RootState) => {
  const storeData = storeState || store.getState();
  const noOfAdditionalMailboxes = selectSiteNoOfAdditionalMailboxes(storeData);
  const adminMailbox = selectSiteAdminMailbox(storeData);

  const countOfMailboxAdded = getTotalNoOfMailboxesAdded(
    adminMailbox,
    noOfAdditionalMailboxes
  );

  return {
    email: adminMailbox?.email,
    total_mailbox_count: countOfMailboxAdded,
  };
};
