import classNames from "classnames";
import { HEADER_TYPES } from "../../helpers/constants";
import { isMobile } from "../../helpers/utils";
import Price from "../Common/Price";
import styles from "./styles.module.scss";

const headerContent = {
  [HEADER_TYPES.SIGN_IN]: "Sign in to your customer account",
  [HEADER_TYPES.SIGN_UP]: "Let’s create your customer account",
  [HEADER_TYPES.ADD_MAILBOX]: "Let's create your mailbox",
  [HEADER_TYPES.ADD_TEAM_MAILBOX]: "Next, add mailboxes for your team",
  [HEADER_TYPES.ADD_GENERIC_MAILBOX]: "Add general mailboxes for your business",
  [HEADER_TYPES.PLANS]: isMobile()
    ? "Select the right Neo plan"
    : "Select the right Neo plan for you",
  [HEADER_TYPES.SITE_PLANS]: isMobile()
    ? "Select the right Site plan"
    : "Select the right Neo Site plan for you",
  [HEADER_TYPES.BILLING]: "Checkout",
  [HEADER_TYPES.BILLING_CUSTOM_DOMAIN]: (
    <>
      <Price value={0} /> Payment today. Checkout.
    </>
  ),
  [HEADER_TYPES.BILLING_CO_SITE_TRIAL]: (
    <>
      Pay <Price value={0} /> today.
    </>
  ),
  [HEADER_TYPES.PREVIEW_SITE]: "This is how your Neo Site would look",
  [HEADER_TYPES.GET_STARTED]: "Does your business already have a domain?",
  [HEADER_TYPES.HAVE_DOMAIN]: "Enter your domain name",
  [HEADER_TYPES.GET_DOMAIN]: "Let’s find a domain for you",
  [HEADER_TYPES.YOUR_IDEA]: "Tell us about your business idea",
  [HEADER_TYPES.YOUR_BUSINESS_NAME]: "Your business name",
  [HEADER_TYPES.YOUR_EMAIL]: "Your email address",
};

const Header = (props) => {
  const { type, className, children } = props;
  return (
    <div id={type} className={classNames(styles.header, className)}>
      {children || headerContent[type]}
    </div>
  );
};

export default Header;
