import React from "react";
import classNames from "classnames";
import SummaryItem from "../SummaryItem";
import { TBillingCycle } from "../../../../types/billing-cycle";
import { BILLING_CYCLE_DISPLAY_LABELS } from "../../../../helpers/constants";
import { isMobile } from "../../../../helpers/utils";
import styles from "./style.module.scss";

interface HeaderProps {
  BillingCycleDropdown: React.FC;
  getNextSavingBillingCycle: () => TBillingCycle | null;
}

const Header: React.FC<HeaderProps> = ({
  BillingCycleDropdown,
  getNextSavingBillingCycle,
}) => {
  const getSavingsTag = () => {
    const nextSavingBillingCycle = getNextSavingBillingCycle();
    return nextSavingBillingCycle ? (
      <div className={styles.savingsTag}>
        Save more with {BILLING_CYCLE_DISPLAY_LABELS[nextSavingBillingCycle]}
      </div>
    ) : null;
  };
  return (
    <SummaryItem
      className={classNames(styles.header, {
        [styles.hasSavingsTag]: true,
      })}
      columns={isMobile() ? 1 : 2}
    >
      {!isMobile() && <div>Product</div>}
      <div className={styles.billingCycleWrapper}>
        {getSavingsTag()}
        Billing cycle
        <BillingCycleDropdown />
      </div>
    </SummaryItem>
  );
};

export default React.memo(Header);
