import React from "react";
import { getDomainCharges } from "../../../../helpers/domainPricingHelper";
import { TBillingCycle } from "../../../../types/billing-cycle";
import { TDomainPricing } from "../../../../types/domain.type";
import Price from "../../../Common/Price";

interface DomainChargesComponentProps {
  duration: TBillingCycle;
  domainPricing: TDomainPricing;
}

export const DomainCharges: React.FC<DomainChargesComponentProps> = ({
  duration,
  domainPricing,
}) => {
  const domainCharges = getDomainCharges(duration, domainPricing);

  return (
    <>
      <Price value={domainCharges.renewalCharge} />/{domainCharges.period}
      {domainCharges.additionalCharge && (
        <>
          (<Price value={domainCharges.additionalCharge} />/
          {domainCharges.additionalPeriod})
        </>
      )}
    </>
  );
};
