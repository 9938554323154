import React from "react";
import Popup from "../../../Common/Popup/Popup";
import { TAdminMailbox } from "../../../../types/mailbox.type";
import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";
import { ReactComponent as BulbOutlineIcon } from "../../../../assets/bulb-outline.svg";
import Button from "../../../Common/Button/Button";
import MailBenefits from "./MailBenefits";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  adminMailbox: TAdminMailbox;
  noOfAdditionalMailboxes: number;
}

const MailboxRemoveConfirmation: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  adminMailbox,
  noOfAdditionalMailboxes,
}) => {
  return (
    <Popup isOpen={isOpen} overlayTransparent={false} onClose={onClose}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>Remove Business Mail</div>
        <div className={styles.modalBody}>
          <InfoIcon />
          <div>
            You'll lose <strong>{adminMailbox.email}</strong>
            {noOfAdditionalMailboxes ? " and other mailboxes" : ""}. Do you want
            to continue?
          </div>
        </div>
        <div className={styles.benefitContainer}>
          <BulbOutlineIcon />
          <MailBenefits />
        </div>
        <div className={styles.modalActions}>
          <Button
            onClick={onConfirm}
            className={styles.modalButtonRemove}
            width="100%"
            type="default"
          >
            Remove Business Mail
          </Button>
          <Button onClick={onClose} type="primary" width="100%">
            Keep Business Mail
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default MailboxRemoveConfirmation;
