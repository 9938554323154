import { bll } from "../../../utils/bll"
import { errorLogger } from "../../../utils/error";
import { parseLookupParam } from "../../../utils/helpers";

export const dnsBannerConfig = async (cpToken, domainName, dnsRecordsToParse) => {
  try {
    const dnsBannerResponse = await bll()
      .get(`/internal/domain/dns-banner-config?${parseLookupParam(dnsRecordsToParse)}&domainName=${domainName}`, {
        'Authorization': cpToken,
        'X-Auth': 'controlPanel'
      });

    return dnsBannerResponse;
  } catch(e) {
    return errorLogger.FAILED_TO_FETCH_DNS_INFO(e);
  }
}

export const getLatestUnverifiedDkimKey = async (cpToken, domainName) => {
  try {
    // check for unverified key
    const response = await bll().get(`/panel/domain/dkim/get-all?domainName=${domainName}`, {
      'Authorization': cpToken,
      'X-Auth': 'controlPanel'
    });

    // generate new key if it not their
    if (!response?.latestUnverifiedDkimKey) {
      const generatedKey = await bll().post(`/panel/domain/dkim/generate?domainName=${domainName}`, {}, {
        'Authorization': cpToken,
        'X-Auth': 'controlPanel'
      });

      return generatedKey;
    }

    return response.latestUnverifiedDkimKey;
  } catch(e) {
    // `true`: do not return error in this case
    return errorLogger.FAILED_TO_GET_DKIM_KEY(e, true);
  }
}

export const verifyDkimKey = async (dkimId, cpToken, domainName) => {
  try {
    await bll().post(`/panel/domain/dkim/enable?domainName=${domainName}`, {
      dkimId, domainName
    }, {
      'Authorization': cpToken,
      'X-Auth': 'controlPanel'
    });
  } catch(e) {
    // `true`: do not return error in this case
    return errorLogger.FAILED_TO_VERIFY_DKIM_KEY(e, true);
  }
}