import { UPDATE_ERRORS } from "./actionsConstants";

export const updateErrorCounts = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERRORS,
      payload,
    });
  };
};
