import { lambda } from "../../../utils/lambda";
import { errorLogger } from "../../../utils/error";
import { entri } from "../../../utils/entri";

export const getEntriToken = async () => {
  try {
    const response = await lambda().post('&functionName=getEntriToken');
    if (!response || !response.auth_token) throw Error("token_missing");
    return response;
  } catch(e) {
    return errorLogger.ENTRI_FAILED_TO_GET_TOKEN(e);
  }
}

export const getEntriCheckDomain = async (token, domain, applicationId) => {
  try {
    const response = await entri.checkDomain(domain, {
      token,
      applicationId
    });
    return response;
  } catch(e) {
    throw errorLogger.ENTRI_DOMAIN_CHECK_FAILED(e);
  }
}