import ENBridge from "../../bridge";
import { useWebmailButton } from "./useWebmailButton";

const WebmailButton = ({ text, ...restProps }) => {
  const { Button } = ENBridge.sharedComponents;
  const { onWebmailButtonClick } = useWebmailButton(restProps);

  return <Button type="primary" onClick={onWebmailButtonClick}>{text}</Button>
}

export default WebmailButton;
