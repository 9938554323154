import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BackIcon from "../../../assets/back.svg";
import { appSelector } from "../../../store/selectors";
import styles from "./GoBack.module.scss";

function GoBack({ pagePath }) {
  const navigateTo = useNavigate();

  const { search_params } = useSelector(appSelector);

  const handleGoBack = () => {
    if (pagePath) {
      navigateTo({
        pathname: pagePath,
        search: search_params,
      });
    } else {
      navigateTo(-1);
    }
  };

  return (
    <div className={styles.backBtn} onClick={() => handleGoBack()}>
      <img src={BackIcon} alt={"Back Icon"} />
      Back
    </div>
  );
}

export default GoBack;
