import React, { useState } from "react";
import classNames from "classnames";
import styles from "./style.module.scss";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { isEmailValid } from "../../../helpers/utils";

type InputProps = React.ComponentProps<typeof Input>;
type ButtonProps = React.ComponentProps<typeof Button>;

type Props = {
  onSubmit: (email: string) => void;
  placeholder?: string;
  errorMessage?: string;
  buttonText?: string;
  inputProps?: Partial<InputProps>;
  buttonProps?: Partial<ButtonProps>;
};

export default function EmailSubmitter({
  onSubmit,
  placeholder = "Enter your email",
  errorMessage = "Please enter a valid email",
  buttonText = "Submit",
  inputProps = {},
  buttonProps = {},
}: Props) {
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsValid(true);
  };

  const handleSubmit = () => {
    if (isEmailValid(email) && email) {
      onSubmit(email);
    } else {
      setIsValid(false);
    }
  };

  return (
    <div>
      <Input
        className={classNames(styles.input, inputProps.className)}
        wrapperClassName={classNames(
          styles.inputWrapper,
          inputProps.wrapperClassName
        )}
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder={placeholder}
        hasError={!isValid}
        errorMessage={errorMessage}
        {...inputProps}
      />
      <Button
        className={classNames(styles.submitButton, buttonProps.className)}
        onClick={handleSubmit}
        type="primary"
        disabled={!isValid || buttonProps.disabled}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </div>
  );
}
