import { LOGOUT, UPDATE_PLAN_PRICING } from "../actions/actionsConstants";

export const initialPricingState = {};

const pricing = (state = initialPricingState, action) => {
  switch (action.type) {
    case UPDATE_PLAN_PRICING:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return initialPricingState;  

    default:
      return state;
  }
};

export default pricing;
