import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { appSelector } from "../../../store/selectors";
import {
  ANALYTICS_EVENTS,
  OFFERING,
  PAGE_PATHS,
} from "../../../helpers/constants";
import GetDomain from "../index";
import { TLogEventProps } from "../../../types/global";
import {
  logCustomerEvent,
  logDomainEvent,
} from "../../../telemetry/medusaEventsFunctions";
import { TDomainOwnershipDetails, TDomainPricing } from "../Common/types";
import {
  resetSiteMailboxState,
  selectSiteDomain,
  selectSiteDomainAttrs,
  selectSiteDomainPricing,
  setSiteDomain,
  setSiteDomainPricing,
} from "../../../store/slices/site.slice";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import { businessNameSelector } from "../../../store/slices/visitor.slice";
import {
  getSiteBuilderEventData,
  getVisitorProfileForMedusa,
} from "../../../telemetry/site/siteMedusaData";

const {
  CUSTOMER_ACCOUNT_LINKED,
  DOMAIN_AVAILABILITY_CHECKED,
  DOMAIN_SELECTED,
} = ANALYTICS_EVENTS;

const SiteGetDomain: React.FC = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { search_params } = useSelector(appSelector);
  const { coSiteDomain } = useSelector(selectSiteDomain);
  const businessName = useSelector(businessNameSelector);
  const { isDomainChargeable } = useSelector(selectSiteDomainAttrs);
  const domainPricing = useSelector(selectSiteDomainPricing);
  const isLoggedIn = useIsLoggedIn();

  const title = "Here’s a domain name suggestion for you";

  const onGetDomain = (): void => {
    if (isLoggedIn) {
      navigateTo({
        pathname: PAGE_PATHS.SITE_PLANS,
        search: search_params,
      });
      return;
    }
    navigateTo({
      pathname: PAGE_PATHS.SITE_SIGN_UP,
      search: search_params,
    });
  };

  const logEvent = ({ eventName, data, flag }: TLogEventProps) => {
    switch (eventName) {
      case CUSTOMER_ACCOUNT_LINKED:
        logCustomerEvent(eventName, {
          ...data,
          ...getSiteBuilderEventData(),
          ...getVisitorProfileForMedusa(),
        });
        break;

      case DOMAIN_AVAILABILITY_CHECKED:
      case DOMAIN_SELECTED:
        logDomainEvent(
          eventName,
          {
            ...data,
            ...getSiteBuilderEventData(),
            ...getVisitorProfileForMedusa(),
          },
          flag
        );
        break;

      default:
        console.log("Invalid event name");
    }
  };

  const updateDomainPricing = ({ domainPricing }: TDomainPricing) => {
    dispatch(setSiteDomainPricing({ ...domainPricing }));
  };

  const initializeOffering = () => {
    dispatch(setSiteDomain({ offering: OFFERING.COSITE }));
  };

  const onGetFreeDomain = (payload: TDomainOwnershipDetails) => {
    const { coSiteDomain, offering } = payload;
    dispatch(
      setSiteDomain({
        coSiteDomain,
        offering,
      })
    );
    dispatch(resetSiteMailboxState());
  };

  return (
    <GetDomain
      title={title}
      coSiteDomain={coSiteDomain}
      businessName={businessName.replace(/\s/g, "").toLowerCase()}
      domainPricing={domainPricing}
      isDomainChargeable={isDomainChargeable}
      onGetDomain={onGetDomain}
      logEvent={logEvent}
      initializeOffering={initializeOffering}
      updateDomainPricing={updateDomainPricing}
      onGetFreeDomain={onGetFreeDomain}
      domainPricingFor="site"
    />
  );
};

export default SiteGetDomain;
