import React from "react";
import { useSelector } from "react-redux";
import CoSiteDomain from "./CoSiteDomain";
import CustomDomain from "./CustomDomain";
import { isMailDomainCustom } from "../../store/slices/mail.slice";

const AllDone = () => {
  // TODO: for site
  const customDomain = useSelector(isMailDomainCustom);

  // All done page has different UI for custom and cosite domain
  return <>{customDomain ? <CustomDomain /> : <CoSiteDomain />}</>;
};

export default AllDone;
