import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ANALYTICS_EVENTS,
  COOKIE_KEYS,
  GA_EVENTS,
  INPUT_ERROR_MESSAGE,
} from "../../helpers/constants";
import { logGoogleAnalyticsEvent } from "../../helpers/gaEventHelper";
import { showErrorToast } from "../../helpers/toastHelper";
import {
  getCJCookieValue,
  getThreeHoursHenceTime,
  getUrlParams,
  getUtmParamsFromCookie,
  isEmailValid,
  isInputLeadingSpace,
  isMobile,
  isPasswordValid,
} from "../../helpers/utils";
import { setCookie } from "../../helpers/cookie.helper";
import {
  createMailCustomer,
  logEntityChange,
  verifyEmail,
} from "../../services/apiFunctions";
import { errorSelector, userSelector } from "../../store/selectors";
import {
  clearVisitorEmailFromCookieIfSameAs,
  getVisitorEmailFromCookie,
} from "../../helpers/exitIntent.helper";
import CaptchaVerification from "../CaptchaVerification/CaptchaVerification";
import Button from "../Common/Button/Button";
import Input from "../Common/Input/Input";
import Header from "../Header";
import { errorActions, userActions } from "./../../store/actions";
import styles from "./styles.module.scss";

const EMAIL_ALREADY_REGISTERED = "This email address is already registered.";
const SOMETHING_WENT_WRONG = "Something went wrong while creating your account";

const EMAIL_ERROR_CODE = {
  INVALID_RECOVERY_EMAIL_DOMAIN: "INVALID_RECOVERY_EMAIL_DOMAIN",
  RECOVERY_EMAIL_CANNOT_BE_CUSTOMER_EMAIL:
    "RECOVERY_EMAIL_CANNOT_BE_CUSTOMER_EMAIL",
};

const {
  CUSTOMER_ACCOUNT_CREATED,
  CUSTOMER_ACCOUNT_LINKED,
  CUSTOMER_PASSWORD_CHECKED_FAILED,
  CUSTOMER_EMAIL_CHECKED_FAILED,
  TRAFFIC_VERIFICATION_FAILED,
  TRAFFIC_VERIFIED,
} = ANALYTICS_EVENTS;

const formErrorValues = {
  name: false,
  password: false,
  alternateEmail: false,
  turnstile: false,
};

const SignUp = (props) => {
  const {
    title,
    subTitle,
    fullNameLabel,
    alternateEmailLabel,
    setPasswordLabel,
    ctaText,
    navigateToSignIn,
    navigateAwayFromSignUp,
    onSuccess,
    onFailure,
    domain,
    logEvent,
  } = props;
  const user = useSelector(userSelector);
  const { name, alternateEmail, token, source_hook } = user;
  const {
    emailCheckFailCount: { count },
    passwordCheckFailCount: { count: passwordErrorCount } = {},
  } = useSelector(errorSelector);
  const { updateUser } = bindActionCreators(userActions, useDispatch());
  const turnstileRef = useRef(null); // Create a ref for CaptchaVerification
  const [cfTurnstileToken, setCfTurnstileToken] = useState(null);
  const [formErrors, setFormErrors] = useState(formErrorValues);
  const [processing, setProcessing] = useState(false);

  const emailErrors = {
    count: count || 0,
    reason: "",
  };

  const passwordErrors = {
    count: passwordErrorCount || 0,
    reason: "",
  };

  const [emailCheckFailCount, setEmailCheckFailCount] = useState(emailErrors);
  const [passwordCheckFailCount, setPasswordCheckFailCount] =
    useState(passwordErrors);

  useEffect(() => {
    if (!!token) {
      navigateAwayFromSignUp();
    }
  }, []);

  const [userDetails, setUserDetails] = useState(() => ({
    name: name || "",
    password: "",
    alternateEmail:
      getUrlParams()?.email ||
      alternateEmail ||
      getVisitorEmailFromCookie() ||
      "",
  }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const isFieldEmpty = isInputLeadingSpace(e) || e.target.value === "";
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
    setUserDetails({
      ...userDetails,
      [name]: isFieldEmpty ? "" : value,
    });
  };

  useEffect(() => {
    const { name } = userDetails;
    updateUser({ name });
  }, [userDetails.name]);

  const validateAllInputs = () => {
    const { name, password, alternateEmail } = userDetails;

    let updatedFormErrors = { ...formErrors },
      error = false;

    if (name === "") {
      error = true;
      updatedFormErrors.name = INPUT_ERROR_MESSAGE.PLEASE_ENTER_YOUR_FULL_NAME;
    }

    // handle alternate email validation
    if (alternateEmail === "") {
      error = true;
      updatedFormErrors.alternateEmail =
        INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_EMAIL;
    } else if (!isEmailValid(alternateEmail)) {
      error = true;
      updatedFormErrors.alternateEmail =
        INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_EMAIL;
      setEmailCheckFailCount({
        count: emailCheckFailCount.count + 1,
        reason: "Invalid email format",
      });
    }

    // handle password validation
    if (password === "") {
      error = true;
      updatedFormErrors.password =
        INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_PASSWORD;
    } else if (!isPasswordValid(password)) {
      error = true;
      updatedFormErrors.password = INPUT_ERROR_MESSAGE.PASSWORD_ERROR;
      setPasswordCheckFailCount({
        count: passwordCheckFailCount.count + 1,
        reason: "Invalid Password Format",
      });
    }

    if (!cfTurnstileToken) {
      error = true;
      updatedFormErrors.turnstile = "Please verify that you are not a robot";
    }

    setFormErrors(updatedFormErrors);

    return !error;
  };

  const { updateErrorCounts } = bindActionCreators(errorActions, useDispatch());

  const handleFailCountChange = (count, reason, type) => {
    if (!token && count > 0) {
      updateErrorCounts({ [`${type}CheckFailCount`]: { count, reason } });

      const payload = {
        [`${type}_fail_count`]: count,
        error: reason,
        source_hook,
      };

      logEvent({
        eventName:
          type === "password"
            ? CUSTOMER_PASSWORD_CHECKED_FAILED
            : CUSTOMER_EMAIL_CHECKED_FAILED,
        data: payload,
        flag: true,
      });
    }
  };

  useEffect(() => {
    handleFailCountChange(
      emailCheckFailCount.count,
      emailCheckFailCount.reason,
      "email"
    );
  }, [emailCheckFailCount.count]);

  useEffect(() => {
    handleFailCountChange(
      passwordCheckFailCount.count,
      passwordCheckFailCount.reason,
      "password"
    );
  }, [passwordCheckFailCount.count]);

  const verifyEmailApi = () => {
    const { alternateEmail } = userDetails;

    return verifyEmail({ alternateEmail, selectedDomainName: domain }).then(
      (response) => {
        /**
         * Sometimes(rarely) Third party API used in lambda funtion of verify email returns undefined response
         * To handle that added below check
         */
        if (!response) {
          setProcessing(false);
          console.error("error verify email", response);
          showErrorToast(
            "Something went wrong, Please try again after sometime"
          );
          return { ok: false };
        }

        const { error, code, desc, statusCode } = response.data;
        if (statusCode === 200) {
          return { ok: true };
        }
        const {
          INVALID_RECOVERY_EMAIL_DOMAIN,
          RECOVERY_EMAIL_CANNOT_BE_CUSTOMER_EMAIL,
        } = EMAIL_ERROR_CODE;
        if (error) {
          // handled lambda error here
          switch (code) {
            case INVALID_RECOVERY_EMAIL_DOMAIN:
              setEmailCheckFailCount({
                count: emailCheckFailCount.count + 1,
                reason: desc,
              });
              // set email error
              setFormErrors({
                ...formErrors,
                alternateEmail:
                  "You seem to have entered an invalid customer account email",
              });
              break;

            case RECOVERY_EMAIL_CANNOT_BE_CUSTOMER_EMAIL:
              setEmailCheckFailCount({
                count: emailCheckFailCount.count + 1,
                reason: desc,
              });
              // set email error
              setFormErrors({
                ...formErrors,
                alternateEmail:
                  "Recovery email cannot be a Customer email address",
              });
              break;

            default:
              break;
          }
        } else if (response.data?.data?.errors?.[0]) {
          // handled server error in this block
          setEmailCheckFailCount({
            count: emailCheckFailCount.count + 1,
            reason: response.data?.data?.errors?.[0],
          });
          // set email error
          setFormErrors({
            ...formErrors,
            alternateEmail: response.data.data.errors[0],
          });
        }
        setProcessing(false);
        console.error("error verify email", response);
        return { ok: false };
      }
    );
  };

  const handleEntityChangeOnServer = (token, customerId) => {
    const payload = {
      type: "titan_customer",
      id: customerId.toString(),
      attrs: {
        ...getUtmParamsFromCookie(),
        signup_device: isMobile() ? "mobile" : "desktop",
      },
    };
    return logEntityChange(
      {
        ...payload,
      },
      {},
      { "X-CP-Token": token }
    );
  };

  const createMailCustomerApi = () => {
    const { name, password, alternateEmail } = userDetails;
    const { CUSTOMER_ID_COOKIE } = COOKIE_KEYS;
    const cjCookieValue = getCJCookieValue();

    const payload = {
      name,
      password,
      email: alternateEmail,
      cfToken: cfTurnstileToken,
    };

    createMailCustomer(payload).then((response) => {
      const res = response.data;
      const {
        statusCode,
        code,
        desc,
        token,
        userId,
        expiryEpochTimestamp = getThreeHoursHenceTime(),
      } = res;
      if (statusCode === 200) {
        updateUser({
          token: token,
          tokenExpiry: expiryEpochTimestamp,
          customer_id: userId,
          billingInformation: {
            ...user.billingInformation,
            yourName: payload.name,
          },
        });
        setCookie(CUSTOMER_ID_COOKIE, userId);
        clearVisitorEmailFromCookieIfSameAs(alternateEmail);

        logEvent({
          eventName: CUSTOMER_ACCOUNT_LINKED,
          data: {
            user_type: "Customer",
            customer_account_linked_via: "Sign Up",
            dummy_entity_Id: true,
          },
          flag: true,
        });
        logEvent({
          eventName: CUSTOMER_ACCOUNT_CREATED,
          data: {
            ...(cjCookieValue ? { cjevent: cjCookieValue } : {}),
            source_hook,
            user_type: "Customer",
          },
          flag: true,
        });
        logEvent({
          eventName: TRAFFIC_VERIFIED,
        });
        logGoogleAnalyticsEvent(GA_EVENTS.CUSTOMER_ACCOUNT_CREATED);
        handleEntityChangeOnServer(token, userId)
          .then((res) => {
            if (res.status !== 200) {
              console.error(
                `There was an error sending the entity change request. Error Code: ${res.status}`
              );
            }
          })
          .finally(() => {
            navigateAwayFromSignUp();
          });
        if (onSuccess) {
          onSuccess(res);
        }
      } else {
        if (code === "USER_ALREADY_EXISTS") {
          setFormErrors({
            ...formErrors,
            alternateEmail: USER_ALREADY_EXISTS,
          });
          setEmailCheckFailCount({
            count: emailCheckFailCount.count + 1,
            reason: desc,
          });
        }
        if (code === "CAPTCHA_VERIFICATION_FAILED") {
          setFormErrors({
            ...formErrors,
            turnstile: desc,
          });
          logEvent({
            eventName: TRAFFIC_VERIFICATION_FAILED,
          });
        }
        // Since every cloudflare captcha token can only be verified once,
        // Reset the Turnstile captcha on any failure
        turnstileRef.current?.reset();
        console.error(res.desc || SOMETHING_WENT_WRONG);
        setProcessing(false);
        if (onFailure) {
          onFailure(res);
        }
      }
    });
  };

  const handleSubmit = () => {
    const { name, alternateEmail } = userDetails;
    updateUser({ name, alternateEmail });

    const isFormValid = validateAllInputs();
    if (isFormValid) {
      setProcessing(true);
      // Is alternate email valid verify with api
      verifyEmailApi().then((response) => {
        if (response.ok) {
          createMailCustomerApi();
        } else {
          setProcessing(false);
        }
      });
    }
  };

  const setCaptchaError = (errorMessage) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      turnstile: errorMessage,
    }));
  };

  const USER_ALREADY_EXISTS = (
    <>
      {EMAIL_ALREADY_REGISTERED}{" "}
      <span className={styles.redirectLink} onClick={navigateToSignIn}>
        Sign in instead
      </span>
    </>
  );

  return (
    <>
      <Header>{title}</Header>
      <div className={"max-width-600"}>
        <div className="subTitle">
          {subTitle && <p className={styles.customerAccInfo}>{subTitle}</p>}
          <div>
            <span>Existing user?</span>
            <div className={styles.createAccount} onClick={navigateToSignIn}>
              Sign in
            </div>
          </div>
        </div>
        <Input
          label={fullNameLabel}
          placeholder="Enter your name"
          type={"text"}
          defaultValue={userDetails.name}
          name={"name"}
          hasError={formErrors["name"]}
          errorMessage={formErrors["name"]}
          onChange={handleInputChange}
          maxLength={30}
        />
        <Input
          label={alternateEmailLabel}
          placeholder="Enter your existing email address"
          type={"text"}
          defaultValue={userDetails.alternateEmail}
          name={"alternateEmail"}
          hasError={formErrors["alternateEmail"]}
          errorMessage={formErrors["alternateEmail"]}
          onChange={handleInputChange}
          autoCapitalize={"none"}
        />
        <Input
          label={setPasswordLabel}
          name={"password"}
          type={"password"}
          placeholder="Enter your password"
          defaultValue={userDetails.password}
          hasError={formErrors["password"]}
          errorMessage={formErrors["password"]}
          onChange={handleInputChange}
          isPasswordInput={true}
        />
        <CaptchaVerification
          ref={turnstileRef}
          onTokenChange={setCfTurnstileToken}
          error={formErrors.turnstile}
          setError={setCaptchaError}
        />
        <Button
          type={"primary"}
          onClick={handleSubmit}
          isProcessing={processing}
          width={"auto"}
        >
          {ctaText}
        </Button>
      </div>
    </>
  );
};

export default SignUp;
