import React from "react";
import SummaryItem from "../../shared/SummaryItem";
import { TPlan } from "../../../../types/plan.type";
import Price from "../../../Common/Price";
import { getRoundedDecimal } from "../../../../helpers/utils";
import {
  NUMBER_OF_MONTHS,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../../../helpers/constants";
import { TBillingCycle } from "../../../../types/billing-cycle";
import styles from "./style.module.scss";

type Props = {
  plan: TPlan;
  totalPayable: number;
  isCoSiteTrial?: boolean;
  isCustomDomain?: boolean;
  perSiteCharge: number;
  duration: TBillingCycle;
};

export default function SiteSummary({
  plan,
  totalPayable,
  isCoSiteTrial,
  isCustomDomain,
  duration,
  perSiteCharge,
}: Props) {
  const trialDays = isCustomDomain
    ? TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN
    : isCoSiteTrial
    ? TRIAL_DURATIONS_IN_DAYS.CO_SITE
    : 0;
  const noOfMonths =
    NUMBER_OF_MONTHS[duration as keyof typeof NUMBER_OF_MONTHS];
  return (
    <SummaryItem columns={2}>
      <div>
        <div>
          <strong>AI Site <span className={styles.planName}>({plan.name} plan)</span></strong>{" "}
        </div>
      </div>
      <div>
        <strong>
          <Price value={0} />
        </strong>
        <div className="colorGreen">Free for {trialDays} days</div>
        <div>
          <Price value={getRoundedDecimal(totalPayable, 2, "string")} />{" "}
           (<Price value={getRoundedDecimal(perSiteCharge, 2, "string")} /> x {" "}
          {noOfMonths} {noOfMonths > 1 ? "months" : "month"}) after trial
        </div>
      </div>
    </SummaryItem>
  );
}
