type MouseExitIntentOptions = {
  threshold?: number;
  onChange: (exited: boolean) => void;
};

export const createMouseExitIntent = ({
  threshold = 200,
  onChange,
}: MouseExitIntentOptions) => {
  let timer: NodeJS.Timeout | null = null;

  const handleMouseLeave = (e: MouseEvent) => {
    if (e.clientY <= 0) {
      timer = setTimeout(() => {
        onChange(true);
      }, threshold);
    }
  };

  const handleMouseEnter = () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    onChange(false);
  };

  const startMonitoring = () => {
    document.addEventListener("mouseleave", handleMouseLeave);
    document.addEventListener("mouseenter", handleMouseEnter);
  };

  const stopMonitoring = () => {
    document.removeEventListener("mouseleave", handleMouseLeave);
    document.removeEventListener("mouseenter", handleMouseEnter);
    if (timer) {
      clearTimeout(timer);
    }
  };

  return {
    startMonitoring,
    stopMonitoring,
  };
};
