import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TProductTypes } from "../../types/products.type";
import { TPlan } from "../../types/plan.type";

type Plans = TPlan[];

const initialPlans: Plans = [];

export const createPlansSlice = (scope: TProductTypes) => {
  return createSlice({
    name: `${scope}/plans`,
    initialState: initialPlans,
    reducers: {
      setPlans(state: Plans, action: PayloadAction<TPlan[]>) {
        return action.payload;
      }
    },
  });
};

export type PlansReducer = ReturnType<typeof createPlansSlice>;
