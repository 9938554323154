import React from "react";
import NeoLogo from "../index";
import { redirectToNeoHome } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { userSelector } from "../../../store/selectors";
import {
  selectSiteDomain,
  selectSitePurchaseDomain,
} from "../../../store/slices/site.slice";
import { NEO_OFFERING_EVENT_VALUE, OFFERING } from "../../../helpers/constants";

const { USER_CUSTOM_DOMAIN, CO_DOT_SITE } = NEO_OFFERING_EVENT_VALUE;

const SiteNeoLogo: React.FC = () => {
  const { name, alternateEmail } = useSelector(userSelector);
  const domain = useSelector(selectSitePurchaseDomain);
  const { offering } = useSelector(selectSiteDomain);

  const redirectToHomepage = () => {
    redirectToNeoHome({
      name,
      alternateEmail: alternateEmail,
      domain_name: domain,
      neo_offering:
        offering === OFFERING.COSITE ? CO_DOT_SITE : USER_CUSTOM_DOMAIN,
    });
  };
  return <NeoLogo redirectToHomepage={redirectToHomepage} />;
};

export default SiteNeoLogo;
