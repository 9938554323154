export const parseUTCDate = (date: string) => {
  const dateObj = new Date(date);
  return Date.UTC(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate(),
    dateObj.getHours(),
    dateObj.getMinutes(),
    dateObj.getSeconds(),
    dateObj.getMilliseconds()
  );
};

export const getDaysLeft = (expiryDate: string | EpochTimeStamp) => {
  const expiryEpoch = new Date(expiryDate).getTime();
  const currentEpoch = new Date().getTime();
  const diff = expiryEpoch - currentEpoch;
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};

/**
 * Return UTC dateTime in DDHHmmss format
 * @returns {string}
 */
export const getUTCDDHHmmss = () => {
  const nowUtc = new Date().toISOString().replace(/\.\d+Z$/, 'Z');
  const day = nowUtc.slice(8, 10).padStart(2, '0');
  const hours = nowUtc.slice(11, 13);
  const minutes = nowUtc.slice(14, 16);
  const seconds = nowUtc.slice(17, 19);
  return `${day}${hours}${minutes}${seconds}`;
};