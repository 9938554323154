import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { PlansProvider } from "../context";
import useStickyElement from "../../../../hooks/dom/useStickyElement";
import useHorizontalOverflowIndicator from "../../../../hooks/dom/useHorizontalOverflowIndicator";
import useMergedRef from "../../../../hooks/useMergedRefs";
import OverflowIndicator from "../../../OverflowIndicator";
import { PlansContextProps } from "../types";
import { useFeatureCard } from "../hooks/useFeatureCard";
import PlansHeader from "../PlanHeader";
import FeatureGroup from "../FeatureGroup";
import FeatureRow from "../FeatureRow";
import PlanHeaderItem from "../PlanHeaderItem";
import PlanPrice from "../PlanPrice";
import BillingInfo from "../billingInfo";
import PlanButton from "../PlanButton";
import PlanDiscountTag from "../PlanDiscountTag";
import PlanTag from "../PlanTag";
import styles from "./style.module.scss";

type PlansTableProps = Omit<
  PlansContextProps,
  "hideFeatureCard" | "showFeatureCard" | "activeFeature" | "components"
> & {
  components?: Partial<PlansContextProps["components"]>;
};

const defaultComponents: PlansContextProps['components'] = {
  PlansHeader,
  Promo: () => null,
  FeatureGroup,
  FeatureRow,
  PlanHeaderItem,
  PlanPrice,
  BillingInfo: (props: any) => <BillingInfo {...props} billingFor="plan" />,
  PlanButton,
  PlanDiscountTag,
  PlanTag,
  EmptyPlanItem: () => null,
};

const PlansTable: FC<PlansTableProps> = (props) => {
  const { stickyElementRef, horizontalScrollableContainerRef } =
    useStickyElement<HTMLDivElement, HTMLDivElement>();
  const {
    showIndicator,
    horizontalScrollableContainerRef: overflowContainer,
    scrollToEnd,
  } = useHorizontalOverflowIndicator({ once: true });
  const plansTableRef = useMergedRef(
    horizontalScrollableContainerRef,
    overflowContainer
  );
  const { activeFeature, showFeatureCard, hideFeatureCard } = useFeatureCard();
  const _components = useMemo(
    () => ({ ...defaultComponents, ...props.components }),
    [props.components]
  );
  const { visiblePlans, groupedFeatures } = props;
  const { PlansHeader, Promo } = _components;

  const contextValue = useMemo(() => {
    return {
      ...props,
      components: _components,
      activeFeature,
      showFeatureCard,
      hideFeatureCard,
    };
  }, [activeFeature, showFeatureCard, hideFeatureCard, props, _components]);

  return (
    <PlansProvider value={contextValue}>
      <OverflowIndicator
        showIndicator={showIndicator}
        onClick={scrollToEnd}
        showOnlyOnMobile={false}
      />
      <div
        className={classNames(styles.plansTable, {
          [styles.fourPlans]: visiblePlans.length >= 4,
        })}
        ref={plansTableRef}
      >
        <PlansHeader ref={stickyElementRef} className={styles.plansHeader}/>
        <Promo view="mobile" className={styles.promoMobile} />
        {groupedFeatures.map((featureGroup) => (
          <FeatureGroup
            key={featureGroup.categoryName}
            featureGroup={featureGroup}
          />
        ))}
      </div>
    </PlansProvider>
  );
};

export default PlansTable;
