import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import { appSelector } from "../../../store/selectors";
import SignIn from "../index";
import { logCustomerEvent } from "../../../telemetry/medusaEventsFunctions";
import { selectSitePurchaseDomain } from "../../../store/slices/site.slice";
import {
  getSiteBuilderEventData,
  getVisitorProfileForMedusa,
} from "../../../telemetry/site/siteMedusaData";
import { transferSiteVisitorDataPostAuthAction } from "../../../store/slices/visitor.slice";

const {
  CUSTOMER_ACCOUNT_SIGNED_IN,
  CUSTOMER_ACCOUNT_SIGNED_IN_FAILED,
  CUSTOMER_ACCOUNT_LINKED,
  CUSTOMER_ACCOUNT_FORGOT_PASSWORD_CLICKED,
} = ANALYTICS_EVENTS;

const SiteSignIn = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { search_params } = useSelector(appSelector);
  const domain = useSelector(selectSitePurchaseDomain);

  const navigateToSignup = () => {
    navigateTo(
      {
        pathname: PAGE_PATHS.SITE_SIGN_UP,
        search: search_params,
      },
      { replace: true }
    );
  };

  const navigateAwayFromSignIn = () => {
    if (!!domain) {
      navigateTo(
        {
          pathname: PAGE_PATHS.SITE_PLANS,
          search: search_params,
        },
        { replace: true }
      );
    } else {
      navigateTo(
        {
          pathname: PAGE_PATHS.SITE_GET_STARTED,
          search: search_params,
        },
        { replace: true }
      );
    }
  };

  const navigateAfterLogin = () => {
    if (!domain) {
      navigateTo(
        {
          pathname: PAGE_PATHS.SITE_GET_STARTED,
          search: search_params,
        },
        { replace: true }
      );
    } else {
      navigateTo(
        {
          pathname: PAGE_PATHS.SITE_PLANS,
          search: search_params,
        },
        { replace: true }
      );
    }
  };

  const logEvent = ({ eventName, data, flag }) => {
    switch (eventName) {
      case CUSTOMER_ACCOUNT_SIGNED_IN_FAILED:
      case CUSTOMER_ACCOUNT_SIGNED_IN:
      case CUSTOMER_ACCOUNT_FORGOT_PASSWORD_CLICKED:
        logCustomerEvent(
          eventName,
          {
            ...data,
            ...getSiteBuilderEventData(),
            ...getVisitorProfileForMedusa(),
          },
          flag
        );
        break;

      case CUSTOMER_ACCOUNT_LINKED:
        logCustomerEvent(
          eventName,
          {
            ...data,
            ...getSiteBuilderEventData(),
            ...getVisitorProfileForMedusa(true),
          },
          flag
        );
        break;
      default:
        console.log("Invalid event name");
    }
  };

  const onSuccess = (response) => {
    if (response?.userEmail) {
      dispatch(
        transferSiteVisitorDataPostAuthAction({
          visitorEmail: response.userEmail,
        })
      );
    }
  };

  return (
    <SignIn
      navigateToSignup={navigateToSignup}
      navigateAwayFromSignIn={navigateAwayFromSignIn}
      navigateAfterLogin={navigateAfterLogin}
      logEvent={logEvent}
      onSuccess={onSuccess}
    />
  );
};

export default SiteSignIn;
