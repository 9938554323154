import { useEffect, useState, useRef } from "react";

type Props = {
  disabled?: boolean;
};

const useHasHorizontalScroll = <T extends HTMLElement>({
  disabled = false,
}: Props = {}) => {
  const horizontalScrollableContainerRef = useRef<T>(null);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);

  useEffect(() => {
    const container = horizontalScrollableContainerRef.current;
    if (!container || disabled) return;

    const checkIfElementHasHorizontalScroll = (element: T) => {
      return element.scrollWidth > element.clientWidth;
    };

    // Initial check
    setHasHorizontalScroll(checkIfElementHasHorizontalScroll(container));

    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        const target = entry.target as T;
        setHasHorizontalScroll(checkIfElementHasHorizontalScroll(target));
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
      resizeObserver.disconnect();
    };
  }, [horizontalScrollableContainerRef.current, disabled]);

  return { horizontalScrollableContainerRef, hasHorizontalScroll };
};

export default useHasHorizontalScroll;
