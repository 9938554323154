import { useEffect } from "react";
import { dnsBannerConfigProxy } from "../services/bll/dnsBannerConfig/proxy";
import { getEntriDetailsProxy } from "../services/lambda/getEntriToken/proxy";
import ENBridge from "../bridge";
import { useEntriContext } from "../store/entri/useEntriContext";
import { useNeoContext } from "../store/neo/useNeoContext";

/**
 * hook to manage neo specific props which are necessary for useEntri hook
 * here we fetch dnsRecords, entri token and return as config
 * also it set the EntriContext so that it can be use elsewhere
 */
export const useNeoPropsForEntri = () => {
  const { state: entriState, dispatch: entriDispatch } = useEntriContext();
  const { state, dispatch } = useNeoContext();
  const { redirectToCP, getAdminPanelUrl, getEnvProps, isSiteSetupMode } = ENBridge.handlers;
  const { dnsRecords, domain, cpToken } = state;
  const { entriToken, errorFetchingEntriDetails } = entriState;
  const { ENTRI_APP_ID } = getEnvProps();
  const applicationId = ENTRI_APP_ID;
  const manualSetupDocumentation = `${getAdminPanelUrl(cpToken, domain)}&section=${isSiteSetupMode() ? "connect-site-domain" : "domain-verification"}`;

  useEffect(() => {
    // fetch entri token
    // incase of failure redirect to CP
    const fetchEntriDetails = async () => {
      try {
        await getEntriDetailsProxy(entriDispatch, applicationId, state);
      } catch (e) {
        redirectToCP(cpToken, domain);
      }
    }

    // we need to wait for cpToken validation
    // else it is possible that token was invalid and we make unnecessary call to entri
    if (!entriToken && !errorFetchingEntriDetails && domain && cpToken) {
      fetchEntriDetails();
    }
  }, [entriToken, errorFetchingEntriDetails, domain, cpToken]);

  useEffect(() => {
    // this is to avoid refetch
    const hasNoDnsRecords = !dnsRecords || !dnsRecords.length;

    // fetch DNS records
    // incase of failure redirect to CP
    const getDnsHeaders = async () => {
      try {
        await dnsBannerConfigProxy(dispatch, state);
      } catch(e) {
        redirectToCP(cpToken, domain);
      }
    };

    if (hasNoDnsRecords && domain && cpToken) getDnsHeaders();
  }, [dnsRecords, domain, cpToken]);

  return {
    errorFetchingEntriDetails,
    config: {
      dnsRecords,
      applicationId,
      token: entriToken,
      prefilledDomain: domain,
      manualSetupDocumentation
    }
  }
}