import { PRODUCT_TYPES } from "../../constants/products.constants";
import { getProductFromUrlPath } from "../../helpers/products.helper";
import { getUrlParams } from "../../helpers/utils";
import {
  businessIdeaSelector,
  businessNameSelector,
  // visitorEmailSelector,
} from "../../store/slices/visitor.slice";
import { store } from "../../store/store";

export const getSiteBuilderEventData = () => {
  const { siteCategory, industryKey, templateName } = getUrlParams();

  return {
    site_category: siteCategory,
    industry: industryKey,
    template_name: templateName,
  };
};

export const getVisitorProfileForMedusa = () => {
  const state = store.getState();
  return {
    business_name: businessNameSelector(state),
    business_description: businessIdeaSelector(state),
  };
};

export const getCommonSiteProductData = () => {
  const productType = getProductFromUrlPath(window.location.pathname);
  if (productType === PRODUCT_TYPES.SITE) {
    return {
      ...getSiteBuilderEventData(),
      ...getVisitorProfileForMedusa(),
    };
  }
  return {}; // Return an empty object if product type is not SITE
};
