import { TUTMParams } from "../types/utm.type";
import { COOKIE_KEYS } from "./constants";
import { getCookie, setCookie } from "./cookie.helper";

const { UTM_PARAMS } = COOKIE_KEYS;

export const updateUTMParamsInCookie = ({
  utm_term,
  utm_campaign,
  utm_content,
  utm_medium,
  utm_source,
}: TUTMParams) => {
  const UTM_PARAMS_COOKIES = JSON.parse(getCookie(UTM_PARAMS) || "{}");

  if (
    !!(utm_term && utm_term !== UTM_PARAMS_COOKIES?.["utm_term"]) ||
    !!(utm_medium && utm_medium !== UTM_PARAMS_COOKIES?.["utm_medium"]) ||
    !!(utm_campaign && utm_campaign !== UTM_PARAMS_COOKIES?.["utm_campaign"]) ||
    !!(utm_source && utm_source !== UTM_PARAMS_COOKIES?.["utm_source"]) ||
    !!(utm_content && utm_content !== UTM_PARAMS_COOKIES?.["utm_content"])
  ) {
    setCookie(
      UTM_PARAMS,
      JSON.stringify({
        utm_term: utm_term || UTM_PARAMS_COOKIES?.["utm_term"],
        utm_medium: utm_medium || UTM_PARAMS_COOKIES?.["utm_medium"],
        utm_campaign: utm_campaign || UTM_PARAMS_COOKIES?.["utm_campaign"],
        utm_source: utm_source || UTM_PARAMS_COOKIES?.["utm_source"],
        utm_content: utm_content || UTM_PARAMS_COOKIES?.["utm_content"],
      })
    );
  }
};
