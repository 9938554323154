import { useEffect, useMemo, useState } from "react";
import { createBackButtonExitIntentManager } from "../../../dom/backButtonExitIntent";
import { getRootDomain } from "../../../helpers/domainHelpers";
import { getHostNameFromUrl } from "../../../utils/urlUtils";

type Props = {
  disabled?: boolean;
};

// Can only go back if the referrer is from the same domain
const canGoBack = () => {
  if(!document.referrer) return false;
  if (
    getRootDomain(getHostNameFromUrl(document.referrer)) ===
    getRootDomain(window.location.hostname)
  ) {
    return true;
  }
  return false;
};

export const useBackButtonExitIntent = ({ disabled }: Props) => {
  const [backButtonExit, setBackButtonExit] = useState(false);
  const backButtonExitIntentManager = useMemo(
    () => createBackButtonExitIntentManager(),
    []
  );

  useEffect(() => {
    backButtonExitIntentManager.init();
  }, []);

  useEffect(() => {
    return backButtonExitIntentManager.addListener(
      () => {
        setBackButtonExit(true);
      },
      disabled,
      canGoBack
    );
  }, [disabled]);

  useEffect(() => {
    // make it work like trigger.
    if (backButtonExit) setBackButtonExit(false);
  }, [backButtonExit]);

  return backButtonExit;
};
