import ENBridge from "../../bridge";
import { sendMedusaEvent } from "../../utils/medusa";

export const entriSetupInitiated = (entriState, neoState) => {
  if (!entriState.provider) return null;

  const { getDnsSetupFor } = ENBridge.handlers;

  const eventName = `${getDnsSetupFor().toLowerCase()}_entri_setup_initiated`;

  return sendMedusaEvent(eventName, neoState, {
    domain_provider: entriState.provider,
  });
};
