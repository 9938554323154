import React from "react";
import { Collapse } from "antd";
import { ReactComponent as ArrowDown } from "../../../assets/right-arrow.svg";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface CustomAccordionProps {
  title: string;
  children: React.ReactNode;
  expanded: boolean;
  onChange: (key: string | string[]) => void;
  disabled?: boolean;
  className?: string;
}

const { Panel } = Collapse;

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  title,
  children,
  expanded,
  onChange,
  disabled = false,
  className,
}) => (
  <Collapse
    activeKey={expanded ? "1" : ""}
    onChange={onChange}
    className={classNames(styles.accordion, className, { [styles.disabled]: disabled })}
    expandIcon={({ isActive }) => (
      <ArrowDown className={classNames(styles.arrowIcon, { [styles.rotated]: isActive })} />
    )}
    expandIconPosition="end"
  >
    <Panel
      header={<div className={styles.accordionTitle}>{title}</div>}
      key="1"
      disabled={disabled}
    >
      {children}
    </Panel>
  </Collapse>
);

export default CustomAccordion;
