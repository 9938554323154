import React from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/selectors";
import CustomMobileSetupRenderer from "./CustomMobileSetupRenderer";

function CustomDomainMobileSetup() {
  const user = useSelector(userSelector);
  const { controlPanelAutoLoginToken, alternateEmail } = user;

  return <CustomMobileSetupRenderer controlPanelAutoLoginToken={controlPanelAutoLoginToken} alternateEmail={alternateEmail} />
}

export default CustomDomainMobileSetup;
