import { FLOW_TYPE, STATE_ACTIONS, USER_TYPE } from "../../utils/const";

export const neoReducer = (state, action) => {
  switch (action.type) {
    case STATE_ACTIONS.SET_DNS_RECORDS:
      return {
        ...state,
        dnsRecords: action.payload.dnsRecords,
        rawDNSRecords: action.payload.rawDNSRecords,
      };

    case STATE_ACTIONS.INIT_STATE_FROM_AUTO_LOGIN_FLOW:
      return {
        ...state,
        domain: action.payload.domain,
        customerId: action.payload.userId,
        accountId: action.payload.accountId,
        customerEmail: action.payload.userEmail,
        orderId: action.payload.domainId,
        name: action.payload.name,
        email: action.payload.email,
        billingInformation: null,
        partnerAlphaNumId: action.payload.partnerAlphaNumId,
        partnerId: action.payload.partnerId,
        neoOffering: action.payload.neo_offering,
        cpToken: action.payload.cpToken,
        tokenExpiry: action.payload.tokenExpiry,
        flowType: FLOW_TYPE.AUTO_LOGIN,
        userType: action.payload.accountId
          ? USER_TYPE.ADMIN
          : USER_TYPE.CUSTOMER,
        soid: action.payload.soid,
        bid: action.payload.bid,
      };

    case STATE_ACTIONS.INIT_STATE_FROM_PURCHASE_FLOW:
      return {
        ...state,
        domain: action.payload.domain,
        customerId: action.payload.customerId,
        accountId: action.payload.accountId,
        orderId: action.payload.orderId,
        customerEmail: action.payload.customerEmail,
        email: action.payload.email,
        name: action.payload.name,
        billingInformation: action.payload.billingInformation,
        partnerAlphaNumId: action.payload.partnerAlphaNumId,
        partnerId: action.payload.partnerId,
        neoOffering: action.payload.neoOffering,
        plan: action.payload.plan,
        cpToken: action.payload.cpToken,
        tokenExpiry: action.payload.tokenExpiry,
        controlPanelAutoLoginToken: action.payload.controlPanelAutoLoginToken,
        webmailAutoLoginToken: action.payload.webmailAutoLoginToken,
        hasActiveOrders: action.payload.hasActiveOrders,
        showTeamMailboxPostDomainPurchase:
          action.payload.showTeamMailboxPostDomainPurchase,
        flowType: FLOW_TYPE.PURCHASE,
        userType: USER_TYPE.CUSTOMER,
      };

    case STATE_ACTIONS.SET_CP_TOKEN_VALIDATING:
      return {
        ...state,
        cpTokenValidating: action.payload,
      };

    case STATE_ACTIONS.SET_DNS_RECORDS_FETCHING:
      return {
        ...state,
        dnsRecordsFetching: action.payload,
      };

    default:
      return state;
  }
};
