import React, { FC } from "react";
import classNames from "classnames";
import { sanitize } from "dompurify";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import { TGroupedFeature } from "../../../../types/plan.type";
import { usePlansContext } from "../context";
import styles from "./style.module.scss";

interface FeatureRowProps {
  feature: TGroupedFeature;
}

const FeatureRow: FC<FeatureRowProps> = ({ feature }) => {
  const {
    activeFeature,
    featuresTextMap,
    showFeatureCard,
    hideFeatureCard,
    allFeatures,
    visiblePlans,
    components: {
      FeatureCard,
    }
  } = usePlansContext();
  const isFeatureCardActive = activeFeature === feature.type;
  const featTitle = feature.title;

  if (!featuresTextMap[feature.type]) return null;

  const tooltip = featuresTextMap[feature.type]?.tooltip;

  return (
    <div className={styles.planSection}>
      <div className={classNames(styles.planRow, "planRow")}>
        <div
          onClick={() => showFeatureCard(feature.type)}
          className={classNames(
            styles.planItem,
            styles.firstCol,
            isFeatureCardActive && styles.active
          )}
        >
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitize(featTitle),
              }}
            />
            {feature.metaInfo && FeatureCard ? (
              <FeatureCard
                hideFeatureCard={hideFeatureCard}
                isFeatureCardActive={isFeatureCardActive}
                activeFeature={activeFeature}
                allFeatures={allFeatures}
                visiblePlans={visiblePlans}
              />
            ) : tooltip ? (
              <Tooltip id={feature.type} tooltipText={tooltip} />
            ) : null}
            {feature.metaInfo && <i />}
          </div>
        </div>

        {visiblePlans.map((_, index) => {
          const featureStatus = feature.plans[index] || false;
          return (
            <div className={styles.planItem} key={`${feature.type}-${index}`}>
              {typeof featureStatus === "boolean" ? (
                <div
                  className={classNames(
                    styles.checkOrCross,
                    featureStatus ? styles.check : styles.cross
                  )}
                />
              ) : (
                <div className={styles.featureValue}>
                  <span>{featureStatus.val}</span>
                  {featureStatus.val_tooltip && (
                    <Tooltip
                      id={`${feature.type}-${index}`}
                      tooltipText={featureStatus.val_tooltip}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeatureRow;
