import classNames from "classnames";
import React, { ReactNode, useEffect, useRef } from "react";
import buttonStyles from "./Button.module.scss";

interface ButtonProps {
  children: ReactNode;
  type?: string;
  onClick?: () => void;
  className?: string;
  isProcessing?: boolean;
  width?: string;
  maxWidth?: string;
  style?: React.CSSProperties;
  disabled?: boolean;

  [key: string]: any;
}

const Button: React.FC<ButtonProps> = ({
  children,
  type = "",
  onClick,
  className,
  isProcessing,
  width,
  maxWidth,
  disabled,
  ...rest
}) => {
  const onButtonClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (btnRef.current) {
      btnRef.current.style.setProperty("--maxWidth", maxWidth || "");
      btnRef.current.style.setProperty("--width", width || "auto");
    }
  }, [maxWidth, width]);

  return (
    <button
      className={classNames(className, buttonStyles.btn, buttonStyles[type], {
        [buttonStyles.processing]: isProcessing,
        [buttonStyles.disabled]: disabled,
      })}
      onClick={onButtonClick}
      disabled={disabled || isProcessing}
      ref={btnRef}
      tabIndex={0}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
