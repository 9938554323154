import React from "react";
import { getWebmailUrl } from "../../../helpers/utils";
import styles from "../styles.module.scss";

const LoginNudge: React.FC = () => {
  return (
    <div className={styles.webmailLoginNudge}>
      Want to access your inbox?{" "}
      <a href={getWebmailUrl()} target="_blank" rel="noreferrer">
        Sign in to webmail
      </a>
    </div>
  );
};

export default LoginNudge;
