import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  resetAmplitudeUserId,
  updateAmplitudeOrderUser,
} from "../../../helpers/amplitudeHelper";
import { showErrorToast } from "../../../helpers/toastHelper";
import { getActiveVWOCampaignIDs } from "../../../helpers/vwoHelper";
import { userSelector } from "../../../store/selectors";
import { ANALYTICS_ATTRIBUTES } from "../../../telemetry/medusaHelper";
import CardElementsWrapper from "../../CardElementsWrapper/CardElementsWrapper";
import Price from "../../Common/Price";
import { userActions } from "../../../store/actions";

type BillingCardProps = {
  isVisible: boolean;
  onContinue: () => void;
  cancelOrPauseNote?: React.ReactNode;
} & Pick<
  React.ComponentProps<typeof CardElementsWrapper>,
  | "isTrial"
  | "initPaymentAPI"
  | "initPaymentPayload"
  | "completePurchaseAPI"
  | "completePurchasePayload"
  | "onApiFailure"
  | "onFailure"
  | "onPaymentSuccess"
  | "domain"
  | "canCheckEntriFlow"
  | "loadingSubTitle"
  | "logEvent"
>;

// TODO: Optimize this component to avoid re-rendering
const BillingCard: React.FC<BillingCardProps> = ({
  isVisible,
  onContinue,
  isTrial,
  initPaymentAPI,
  initPaymentPayload,
  completePurchaseAPI,
  completePurchasePayload,
  onApiFailure,
  onFailure,
  onPaymentSuccess,
  domain,
  logEvent,
  cancelOrPauseNote,
  ...rest
}) => {
  const cardRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();
  const { updateUser } = bindActionCreators(userActions, dispatch);

  useEffect(() => {
    return () => {
      resetAmplitudeUserId();
    };
  }, []);

  const { isBillingInfoSaved, experiments } = useSelector(userSelector);

  const shouldStartPayment = (): Promise<void> => {
    if (!isBillingInfoSaved) {
      showErrorToast("Please save billing info details");
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  };

  const updateOrderUserPropertiesOnAmplitude = async (orderId: string) => {
    const act_exp = await getActiveVWOCampaignIDs();
    const { ACTIVE_EXPERIMENTS, EXPERIMENTS } = ANALYTICS_ATTRIBUTES;
    updateAmplitudeOrderUser(orderId, {
      [ACTIVE_EXPERIMENTS]: act_exp,
      [EXPERIMENTS]: experiments,
    });
  };

  const _onPaymentSuccess = ({ purchaseResponse, ...rest }: any) => {
    const { domainId, controlPanelAutoLoginToken } = purchaseResponse;
    updateUser({
      controlPanelAutoLoginToken,
      hasActiveOrders: true,
      isBillingInfoSaved: false,
    });
    onPaymentSuccess({ purchaseResponse, ...rest });
    updateOrderUserPropertiesOnAmplitude(domainId);
  };

  return (
    <div>
      <CardElementsWrapper
        cardRef={cardRef}
        domain={domain}
        isTrial={isTrial}
        shouldStartPayment={shouldStartPayment}
        initPaymentAPI={initPaymentAPI}
        onApiFailure={onApiFailure}
        onFailure={onFailure}
        initPaymentPayload={initPaymentPayload}
        completePurchaseAPI={completePurchaseAPI}
        completePurchasePayload={completePurchasePayload}
        onPaymentSuccess={_onPaymentSuccess}
        logEvent={logEvent}
        {...rest}
        buttonText={
          <>
            Pay{" "}
            {isTrial ? (
              <>
                <Price value={0} />{" "}
              </>
            ) : (
              ``
            )}
            and continue
          </>
        }
        cancelOrPauseNote={cancelOrPauseNote}
      />
    </div>
  );
};

export default BillingCard;
