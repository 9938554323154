import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  businessIdeaSelector,
  businessNameSelector,
} from "../../../store/slices/visitor.slice";
import { PAGE_PATHS } from "../../../helpers/constants";

const SiteRouteProtection = ({ children }) => {
  const businessName = useSelector(businessNameSelector);
  const businessIdea = useSelector(businessIdeaSelector);

  if (!businessName || !businessIdea) {
    return (
      <Navigate
        to={{
          pathname: PAGE_PATHS.YOUR_IDEA,
        }}
        replace
      />
    );
  }

  return children;
};

export default SiteRouteProtection;
