import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../Common/Button/Button";
import styles from "./EmailRow.module.scss";
import { selectMailPurchaseDomain } from "../../store/slices/mail.slice";

const LIMIT_FOR_VIEW_MORE = 3;

const EmailRow = ({
  emailList,
  handleDeleteMailbox,
  handleAddGenericMailbox,
  isOrderSummaryPage,
  collapsible = false,
  fromCheckoutModal = false,
}) => {
  // TODO: for site
  const domain = useSelector(selectMailPurchaseDomain);

  const [isCollapsed, setIsCollapsed] = useState(
    collapsible && emailList.length > 3
  );

  return (
    <>
      <div
        className={classNames({
          [styles.emailListWrapper]: true,
          [styles.collapsed]: isCollapsed,
          [styles.fromCheckoutModal]: fromCheckoutModal,
        })}
      >
        {emailList?.map((item) => {
          const { username, isAdded = true, isPopular } = item;
          return (
            <React.Fragment key={username}>
              <div
                className={classNames(styles.rowItem, {
                  [styles.orderSummaryRow]: !!isOrderSummaryPage,
                  [styles.genericEmailRow]:
                    typeof handleAddGenericMailbox === "function",
                  [styles.removeBottomBorderLastChild]:
                    !!isOrderSummaryPage &&
                    emailList.length <= LIMIT_FOR_VIEW_MORE,
                })}
              >
                <div>
                  <div className={styles.email}>
                    <span className={styles.username}>{username}</span>
                    <span className={styles.domainName}>@{domain}</span>
                  </div>
                  {Boolean(isPopular) && (
                    <div className={styles.rowPopularBtn}>POPULAR</div>
                  )}
                </div>
                {isAdded ? (
                  <div className={styles.addedWrapper}>
                    {!isOrderSummaryPage && (
                      <span>
                        <span className={styles.tick}></span>Added
                      </span>
                    )}
                    <button
                      onClick={() => handleDeleteMailbox(item)}
                      className={classNames({
                        [styles.deleteIcon]: true,
                        [styles.small]: !!isOrderSummaryPage,
                      })}
                      title="Delete"
                    />
                  </div>
                ) : (
                  <Button
                    type={"secondary"}
                    onClick={() => handleAddGenericMailbox(item)}
                    className={styles.suggestionAddBtn}
                  >
                    Add
                  </Button>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {collapsible && emailList.length > LIMIT_FOR_VIEW_MORE && (
        <button
          className={styles.collapseToggle}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          View{" "}
          {isCollapsed
            ? `${emailList.length - LIMIT_FOR_VIEW_MORE} more`
            : "less"}
        </button>
      )}
    </>
  );
};

export default EmailRow;
