import { TRIAL_DURATIONS_IN_DAYS } from "../../../helpers/constants";

export const ENTRI_FLOW_ROUTES = {
  ROOT: "/setup-dns",
};

export const ENTRI_SETUP_MODE = {
  SITE: "site",
  EMAIL: "email",
};

export const ENV_PROPS = {
  STAGING: {
    BLL_BASE: "https://flockmail-bll.flock-staging.com",
    ENTRI_APP_ID: "neo_staging",
  },
  PREPROD: {
    BLL_BASE: "https://bll.titan.email",
    ENTRI_APP_ID: "neo",
  },
  PROD: {
    BLL_BASE: "https://bll.titan.email",
    ENTRI_APP_ID: "neo",
  },
};

export const STATE_ACTIONS = {
  SET_DNS_RECORDS: "SET_DNS_RECORDS",
  SET_CP_TOKEN_VALIDATING: "SET_CP_TOKEN_VALIDATING",
  SET_DNS_RECORDS_FETCHING: "SET_DNS_RECORDS_FETCHING",
  INIT_STATE_FROM_AUTO_LOGIN_FLOW: "INIT_STATE_FROM_AUTO_LOGIN_FLOW",
  INIT_STATE_FROM_PURCHASE_FLOW: "INIT_STATE_FROM_PURCHASE_FLOW",
  SET_ENTRI_CHECK_DOMAIN_PAYLOAD: "SET_ENTRI_CHECK_DOMAIN_PAYLOAD",
  SET_GETTING_ENTRI_DETAILS: "SET_GETTING_ENTRI_DETAILS",
  SET_ERROR_FETCHING_ENTRI_DETAILS: "SET_ERROR_FETCHING_ENTRI_DETAILS",
};

export const SUPPORTED_QUERY_PARAMS = {
  domain: "*",
  token: "*",
  tokenType: "*",
  jwt: "*",
  partnerId: "*",
  domainId: "*",
  accountId: "*",
  soid: "*",
  bid: "*",
  setupFor: ["site", "email"],
  sourceHook: [
    "New order purchase flow",
    "Control panel",
    "Webmail",
    "Mobile app",
    "Order confirmation email",
    "Domain verification reminder email",
  ],
};

export const SCREEN_TYPE_MAP = {
  SETUP_DNS: "SETUP_DNS",
  SETUP_COMPLETE: "SETUP_COMPLETE",
  CUSTOM_MOBILE_SETUP: "CUSTOM_MOBILE_SETUP",
};

// @link https://developers.entri.com/docs/api-reference#laststatus-possible-values
export const ENTRI_MODAL_LAST_STATUS = {
  FINISHED_SUCCESSFULLY: "FINISHED_SUCCESSFULLY",
};

export const STORAGE_KEYS = {
  TOKEN: "cp_token",
};

export const FLOW_TYPE = {
  AUTO_LOGIN: "auto_login",
  PURCHASE: "purchase",
};

export const ENTRI_SETUP_TYPE = {
  MANUAL: "Manual",
  AUTOMATIC: "Automatic",
};

export const USER_TYPE = {
  ADMIN: "admin",
  CUSTOMER: "customer",
};

export const REDIRECTION_OPTIONS = {
  ENTRI_SETUP: "Setup with Entri",
  NEO_SETUP: "Setup with Neo",
};

export const SETUP_COMPLETE_REDIRECTION_OPTIONS = {
  WEBMAIL: "Webmail",
  NEO_ADMIN_PANEL: "Neo Admin Panel",
  GOOGLE_PLAY_STORE: "Google Play Store",
  APPLE_APP_STORE: "Apple App Store",
  SITE_BUILDER: "Site builder",
};

export const DUMMY_DNS_RECORDS = [
  {
    type: "MX",
    host: "@",
    value: "mx0001.neo.space",
    priority: 20,
    ttl: 3600,
  },
  {
    type: "MX",
    host: "@",
    value: "mx0002.neo.space",
    priority: 20,
    ttl: 3600,
  },
  {
    type: "TXT",
    host: "@",
    value: "v=spf1 include:spf0001.neo.space ~all",
    ttl: 3600,
  },
];

export const PURCHASE_FLOW_MEDUSA_ATTRB = [
  "FREE_TRIAL_DAYS",
  "PRICE_ANNUAL",
  "PRICE_MONTHLY",
  "PRICE_FIRST_YEAR",
  "CURRENCY",
  "BILLING_CYCLE",
  "COUNTRY",
  "DOMAIN",
  "FIRST_ORDER",
  "CUSTOMER_ID",
  "CUSTOMER_EMAIL",
  "FIRST_NAME",
  "LAST_NAME",
  "NEO_OFFERING",
  "EMAIL",
  "USER_TYPE",
  "ORDER_ID",
  "ACCOUNT_ID",
  "TOTAL_MAILBOX_COUNT",
  "NAME_MAILBOX_COUNT",
  "GENERIC_MAILBOX_COUNT",
  "SUGGESTED_MAILBOX_COUNT",
  "SOURCE_HOOK",
];

export const PURCHASE_FLOW_CUSTOMER_SIGN_OUT_ATTRB = [
  "DOMAIN_PLAN",
  "DOMAIN_PRICE_MONTHLY",
  "CUSTOMER_ID",
  "FIRST_NAME",
  "LAST_NAME",
];

export const CUSTOM_DOMAIN_TRIAL_DURATION_IN_DAYS =
  TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN;
