import React from "react";
import { ReactComponent as PromoIcon } from "../../../../assets/promo.svg";
import { getVerboseDiscount } from "../../../../helpers/discount.helper";
import { PromoState } from "../../../../types/promo.type";
import { Discount } from "../../../../types/global";
import { getRoundedDecimal } from "../../../../helpers/utils";
import Price from "../../../Common/Price";
import SummaryItem from "../SummaryItem";
import promoStyles from "./style.module.scss";

type Props = {
  discountAmount: number;
  promoCodeDetails: PromoState["promoCodeDetails"];
  emailAccountDiscount?: Discount;
};

export default function PromoSummary({
  discountAmount,
  promoCodeDetails,
  emailAccountDiscount,
}: Props) {
  if (!discountAmount || !promoCodeDetails || !emailAccountDiscount)
    return null;

  const verboseDiscount = getVerboseDiscount(emailAccountDiscount);
  const discountDescription = verboseDiscount
    ? `${verboseDiscount} off on Neo Mail`
    : "";

  return (
    <SummaryItem columns={2}>
      <div className={promoStyles.titleWrapper}>
        {" "}
        <strong>{promoCodeDetails?.displayCode} Discount applied</strong>
        <PromoIcon className={promoStyles.promoIcon} />
      </div>
      <div>
        <div>
          <strong>
            -<Price value={getRoundedDecimal(discountAmount, 2, "string")} />
          </strong>
        </div>
        {discountDescription && (
          <div className={"colorGreen"}>{discountDescription}</div>
        )}
      </div>
    </SummaryItem>
  );
}
