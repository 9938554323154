import { DEFAULT_CURRENCY } from "../../constants/currency";
import { LOGOUT, UPDATE_DOMAIN } from "../actions/actionsConstants";

export const initialDomainState = {
  domainPricing: {
    monthly: 0,
    yearly: 0,
  },
  isDomainChargeable: true, // By default marking as domain is chargeable
  currency: DEFAULT_CURRENCY,
};

const domain = (state = initialDomainState, action) => {
  switch (action.type) {
    case UPDATE_DOMAIN:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return initialDomainState;  

    default:
      return state;
  }
};

export default domain;
