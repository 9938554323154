import {
  BILLING_CYCLE_DURATIONS,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../helpers/constants";
import { selectMailPlanToUpSell } from "../../store/slices/mail.slice";
import {
  isSiteDomainCustom,
  selectSiteDomainAttrs,
  selectSitePlan,
  selectSitePlanDetails,
} from "../../store/slices/site.slice";
import { store } from "../../store/store";
import { RootState } from "../../types/store.type";
import { getPlanDataForMedusa } from "../shared/pricing.medusa.helper";

export const getSitePlanDataFromStore = (storeState?: RootState) => {
  const storeData = storeState || store.getState();
  const { duration } = selectSitePlan(storeData);
  const mailPlan = selectMailPlanToUpSell(storeData);
  const sitePlan = selectSitePlanDetails(storeData);
  const { isCoSiteTrial } = selectSiteDomainAttrs(storeData);
  const isUserCustomDomain = isSiteDomainCustom(storeData);

  return {
    billing_cycle: BILLING_CYCLE_DURATIONS[duration] || duration,
    free_trial_days: isUserCustomDomain
      ? TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN
      : isCoSiteTrial
      ? TRIAL_DURATIONS_IN_DAYS.CO_SITE
      : 0,
    payment_for: "New Order",
    ...getPlanDataForMedusa("mail", duration, mailPlan),
    ...getPlanDataForMedusa("site", duration, sitePlan),
  };
};
