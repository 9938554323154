import { useCallback } from "react";
import { TLogEventProps } from "../../../types/global";
import { TComponents } from "../shared/types";
import FeatureCard from "../shared/FeatureCard";

type FeatureCardProps = React.ComponentProps<
  NonNullable<TComponents["FeatureCard"]>
>;

const SiteFeatureCard: React.FC<FeatureCardProps> = ({ ...props }) => {
  const logEvent = useCallback((event: TLogEventProps) => {
    console.log(event);
  }, []);

  return <FeatureCard {...props} logEvent={logEvent} />;
};

export default SiteFeatureCard;
