import { useEffect, useState } from "react";
import { createMouseExitIntent } from "../../../dom/mouseExitIntent";

type Props = {
  threshold?: number;
  disabled?: boolean;
};

export const useMouseExitIntent = ({
  threshold = 200,
  disabled = false,
}: Props = {}) => {
  const [mouseExited, setMouseExited] = useState(false);

  useEffect(() => {
    if (disabled) return;
    const { startMonitoring, stopMonitoring } = createMouseExitIntent({
      threshold,
      onChange: setMouseExited,
    });
    startMonitoring();
    return () => {
      stopMonitoring();
    };
  }, [disabled, threshold]);

  return mouseExited;
};
