import React from "react";
import { useSelector } from "react-redux";
import BillingInformation from "../shared/BillingInformation";
import {
  isMailDomainCustom,
  selectMailDomainAttrs,
} from "../../../store/slices/mail.slice";
import { TLogEventProps } from "../../../types/global";
import { logBillingPageEvent } from "../../../telemetry/medusaEventsFunctions";

type Props = {
  isVisible: boolean;
  onContinue: () => void;
};

// TODO: Optimize this component to avoid re-rendering
export default function MailBillingInformation({ ...props }: Props) {
  const { isCoSiteTrial } = useSelector(selectMailDomainAttrs);
  const isCustomDomain = useSelector(isMailDomainCustom);

  const logEvent = ({ eventName, data = {} }: TLogEventProps) => {
    logBillingPageEvent(eventName, {
      ...data,
    });
  };

  return (
    <BillingInformation
      {...props}
      showWhyTrialTooltip={isCoSiteTrial || isCustomDomain}
      logEvent={logEvent}
    />
  );
}
