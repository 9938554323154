import React, { ComponentType, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { cleanUrl, getTokenFromURL } from "../helpers/utils";
import Loader from "../components/Common/Loader/Loader";
import { appActions, userActions } from "../store/actions";
import { AppDispatch } from "../store/store";

interface WithHOCProps {}
  

function AutoLogin<T extends WithHOCProps>(
  WrappedComponent: ComponentType<T>
): React.FC<Omit<T, keyof WithHOCProps>> {
  const WithHOC: React.FC<Omit<T, keyof WithHOCProps>> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState<boolean>(true);
    const { updateAppParams } = bindActionCreators(appActions, useDispatch());

   useEffect(() => {
      const token = getTokenFromURL();
      const updatedSearchParams = cleanUrl(["token"]);    
      updateAppParams({ search_params: updatedSearchParams });
      autoLogin(token);
    }, []);

    const autoLogin = async (token: string) => {
        if(token) {
            try {
                await dispatch(userActions.autoLoginUser(token));
            } catch (error) {
                console.error('failed to auto login', error);
            }
        } 
        setLoading(false);
    }

    return (loading ? <Loader />  : <WrappedComponent {...(props as T)} /> );
  };

  return WithHOC;
}

export default AutoLogin;
