import ENBridge from "../../bridge";
import { useCustomMobileSetup } from "./useCustomMobileSetup";

import s from './CustomMobileSetup.module.scss';

const CustomMobileSetup = (props) => {
  const {
    controlPanelAutoLoginToken,
    customerEmail,
    onBackClicked
  } = useCustomMobileSetup(props);
  const { BACK_ICON } = ENBridge.images;
  const { CustomMobileSetupRenderer } = ENBridge.sharedComponents;

  return (
    <div>
      <div className={s.backBtn} onClick={onBackClicked}>
        <img src={BACK_ICON} alt="back icon" />
        Back
      </div>
      <CustomMobileSetupRenderer
        // TODO: make necessary changes here once we replace latest cp auto login
        // in neo signup
        controlPanelAutoLoginToken={controlPanelAutoLoginToken}
        alternateEmail={customerEmail}
      />
    </div>
  )
}

export default CustomMobileSetup;