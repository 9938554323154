import lodashSet from "lodash/set";
import { STATUS_CODES } from "../constants/api.constants";
import { PROMO_ERROR_CODES } from "../constants/promo.constants";
import { appActions } from "../store/actions";
import { resetPromo } from "../store/slices/promo.slice";
import { store } from "../store/store";
import { PromoCodeErrorDetails } from "../types/promo.type";
import { logBillingPageEvent } from "../telemetry/medusaEventsFunctions";
import { cleanUrl } from "./utils";
import { ANALYTICS_EVENTS } from "./constants";

export const resetPromoState = () => {
  store.dispatch(resetPromo());
  removePromoFromUrl();
};

export const removePromoFromUrl = () => {
  const updatedSearchParams = cleanUrl(["promo_code_id"]);
  store.dispatch(
    appActions.updateAppParams({ search_params: updatedSearchParams })
  );
};

export const parsePromoErrorResponse = (
  errorResponse: any,
  promoCodeId: string
): PromoCodeErrorDetails | undefined => {
  if (
    !errorResponse ||
    !promoCodeId ||
    errorResponse.status === STATUS_CODES.OK ||
    ![STATUS_CODES.INVALID_REQUEST, STATUS_CODES.NOT_FOUND].includes(
      errorResponse.status
    )
  )
    return;
  lodashSet(errorResponse, "data.attrs.id", promoCodeId);
  if (errorResponse?.status === STATUS_CODES.NOT_FOUND) {
    errorResponse.data.code = PROMO_ERROR_CODES.PROMO_CODE_INVALID;
  }
  return {
    ...(errorResponse?.data.attrs || {}),
    errorCode: errorResponse?.data.code,
  };
};

export const logPromoFailedErrorFromErrorResponse = (
  errorResponse: any,
  promoCodeId: string,
  payload: object,
) => {
  const promoErrorDetails = parsePromoErrorResponse(errorResponse, promoCodeId);
  if (promoErrorDetails) {
    logBillingPageEvent(ANALYTICS_EVENTS.CUSTOMER_PAYMENT_FAILED, {
      ...payload,
      promo_code_id: promoCodeId,
      promo_code_error: promoErrorDetails.errorCode,
      promo_code: promoErrorDetails.displayCode,
    })
  }
};
