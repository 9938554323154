import { getDomainStateBitSet } from "../../helpers/bitSetHelper";
import { processDomainPricing } from "../../helpers/domainPricingHelper";
import { checkMailOrderAvailability } from "../../services/apiFunctions";
import { userSelector } from "../selectors";
import {
  selectMailPurchaseDomain,
  setMailDomainPricing,
} from "../slices/mail.slice";
import { setSiteDomainPricing } from "../slices/site.slice";
// import { updateUser } from "./userActions";

// TODO: This is temporary hack for now till we get separate api to fetch domain pricing. Once that is received from backend , we will replace this function with actual api call. DomainName and domainState will not be required after that.
export function fetchDomainPricing() {
  return async (dispatch, getState) => {
    const { customer_id } = userSelector(getState());
    // TODO: for site
    const domain = selectMailPurchaseDomain(getState());
    if (!domain) return;
    const payload = {
      domainName: domain,
      userId: customer_id,
      domainState: getDomainStateBitSet({ isDomainChargeable: true }),
    };
    checkMailOrderAvailability(payload).then((resp) => {
      const response = resp.data;
      if (response.statusCode !== 200) {
        return;
      }
      const { domainPricing = {}, currency } = response;

      processDomainPricing(domainPricing);

      // TODO: for site
      dispatch(setMailDomainPricing({ ...domainPricing }));
      dispatch(setSiteDomainPricing({ ...domainPricing }));
      // dispatch(updateUser({ currency }));
    });
  };
}
