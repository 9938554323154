import React from "react";
import classNames from "classnames";
import { BILLING_CYCLE_KEYS } from "../../../../helpers/constants";
import { isMobile } from "../../../../helpers/utils";
import { usePlansContext } from "../context";
import { TPlan } from "../../../../types/plan.type";
import styles from "./style.module.scss";

type Props = {
  plan: TPlan;
  flatPriceForever?: boolean;
  className?: string;
};

const { QUARTERLY, YEARLY, TWO_YEARLY, FOUR_YEARLY } = BILLING_CYCLE_KEYS;

const PlanDiscountTag: React.FC<Props> = ({
  plan: { pricing },
  flatPriceForever,
  className,
}) => {
  const { duration } = usePlansContext();
  const isQuarterlyPlan = duration === QUARTERLY;
  const isAnnualPlan = duration === YEARLY;
  const isTwoYearlyPlan = duration === TWO_YEARLY;
  const isFourYearlyPlan = duration === FOUR_YEARLY;

  if (flatPriceForever)
    return (
      <div className={classNames(styles.discount, className)}>
        Flat price forever
      </div>
    );

  return (
    <>
      {isQuarterlyPlan && (pricing?.quarterly?.discountPercentage || 0) > 0 && (
        <div className={classNames(styles.discount, className)}>
          Save {pricing?.quarterly?.discountPercentage}% on 1st quarter
        </div>
      )}
      {isAnnualPlan && (pricing?.yearly?.discountPercentage || 0) > 0 && (
        <div className={classNames(styles.discount, className)}>
          Save {pricing?.yearly?.discountPercentage}% on 1st{" "}
          {isMobile() ? "yr" : "year"}
        </div>
      )}
      {isTwoYearlyPlan &&
        (pricing?.two_yearly?.discountPercentage || 0) > 0 && (
          <div className={classNames(styles.discount, className)}>
            Save {pricing?.two_yearly?.discountPercentage}% on 1st 2{" "}
            {isMobile() ? "yrs" : "years"}
          </div>
        )}

      {isFourYearlyPlan &&
        (pricing?.four_yearly?.discountPercentage || 0) > 0 && (
          <div className={classNames(styles.discount, className)}>
            Save {pricing?.four_yearly?.discountPercentage}% on 1st 4{" "}
            {isMobile() ? "yrs" : "years"}
          </div>
        )}
    </>
  );
};

export default PlanDiscountTag;
