import { DEFAULT_CURRENCY } from "../../constants/currency";
import { sourceHookMapping } from "../../helpers/constants";
import {
  CLEAR_SESSION,
  LOGOUT,
  RESET_USER,
  UPDATE_USER,
} from "../actions/actionsConstants";

export const planInitialState = {
  name: "", // premium or pro
  duration: "yearly", // yearly or monthly
  id: "",
  price: {},
};

const initialState = {
  alternateEmail: "",
  billingInformation: {
    billingAddress: "",
    city: "",
    companyName: "",
    country: "",
    state: "",
    yourName: "",
    zipCode: "",
  },
  domain: "",
  experiments: [],
  isBillingInfoSaved: false,
  plan: planInitialState,
  name: "",
  persona: {},
  source_hook: sourceHookMapping["purchaseFlow"],
  token: "",
  currency: DEFAULT_CURRENCY,
  haveLitePlanSupport: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_USER: {
      return {
        ...initialState,
      };
    }
    case CLEAR_SESSION: {
      return {
        ...state,
        token: "",
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default user;
