import React from "react";
import Button from "../../../Common/Button/Button";
import styles from "./style.module.scss";

type Props = {
  handleSubmit: () => void;
};

export default function SummaryFooter({ handleSubmit }: Props) {
  return (
    <Button
      type="primary"
      onClick={handleSubmit}
      className={styles.continueButton}
    >
      Continue
    </Button>
  );
}
