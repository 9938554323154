/**
 * This file will contain the mapping of campaigns and their variations as per what's defined on VWO.
 * It's not mandatory that all campaign and variation related data be added here.
 * But for any campaign that relies on client to make a decision based on the variation that VWO shows a user, it's necessary that that mapping be added here.
 */

interface Variations {
  [key: string]: string;
}

interface Campaign {
  c_id: number;
  variations: Variations;
}

interface Campaigns {
  [key: string]: Campaign;
}

interface VwoCampaignsMap {
  [key: string]: Campaigns;
}

export const vwoCampaignsMap: VwoCampaignsMap = {
  STAGING: {
    addMailbox: {
      c_id: 10,
      variations: {
        skipTeamMailbox: "1",
        showTeamMailbox: "2",
      },
    },
    chargeForCoSite: {
      c_id: 25,
      variations: {
        free: "1",
        paid: "2",
      },
    },
    additionalMailboxPurchase: {
      c_id: 29,
      variations: {
        preCheckout: "1",
        postCheckout: "2",
      },
    },
    moneyBackGuarantee: {
      c_id: 46,
      variations: {
        hide_money_back_guarantee: "1",
        show_money_back_guarantee: "2",
      },
    },
    coSite15DaysTrial: {
      c_id: 48,
      variations: {
        cs_paid: "1",
        all_lite2: "2",
        all_lite: "3",
      },
    },
  },
  PREPROD: {
    addMailbox: {
      c_id: 3,
      variations: {
        skipTeamMailbox: "1",
        showTeamMailbox: "2",
      },
    },
    chargeForCoSite: {
      c_id: 4,
      variations: {
        free: "1",
        paid: "2",
      },
    },
    additionalMailboxPurchase: {
      c_id: 5,
      variations: {
        preCheckout: "1",
        postCheckout: "2",
      },
    },
    moneyBackGuarantee: {
      c_id: 6,
      variations: {
        hide_money_back_guarantee: "1",
        show_money_back_guarantee: "2",
      },
    },
    coSite15DaysTrial: {
      c_id: 49,
      variations: {
        cs_paid: "1",
        all_lite2: "2",
        all_lite: "3",
      },
    },
  },
  PROD: {
    addMailbox: {
      c_id: 3,
      variations: {
        skipTeamMailbox: "1",
        showTeamMailbox: "2",
      },
    },
    chargeForCoSite: {
      c_id: 4,
      variations: {
        free: "1",
        paid: "2",
      },
    },
    additionalMailboxPurchase: {
      c_id: 5,
      variations: {
        preCheckout: "1",
        postCheckout: "2",
      },
    },
    moneyBackGuarantee: {
      c_id: 6,
      variations: {
        hide_money_back_guarantee: "1",
        show_money_back_guarantee: "2",
      },
    },
    coSite15DaysTrial: {
      c_id: 49,
      variations: {
        cs_paid: "1",
        all_lite2: "2",
        all_lite: "3",
      },
    },
  },
};
