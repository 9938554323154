import { userSelector } from "../store/selectors";
import { store } from "../store/store";

// return order_id if Team mailbox page shown post domain purchase
export const getOrderIdFromStore = (storeState) => {
  const storeData = storeState || store.getState();
  const user = userSelector(storeData);
  const { showTeamMailboxPostDomainPurchase, order_id } = user;

  return showTeamMailboxPostDomainPurchase ? { order_id } : {};
};
