import { useState } from "react";
import { useEntri } from "../../hooks/useEntri";
import { useNeoPropsForEntri } from "../../hooks/useNeoPropsForEntri";
import { domainSetupRedirection } from "../../services/medusa/domainSetupRedirection";
import { REDIRECTION_OPTIONS } from "../../utils/const";
import { purchaseCompletedRedirection } from "../../services/medusa/purchaseCompletedRedirection";
import { useEntriContext } from "../../store/entri/useEntriContext";
import { useNeoContext } from "../../store/neo/useNeoContext";

export const useEntriButton = () => {
  const { state: neoState } = useNeoContext();
  const { state: entriState } = useEntriContext();
  const [isLoading, setIsLoading] = useState(false);
  const entriProps = useNeoPropsForEntri();
  const { showEntri } = useEntri(entriProps);

  const onEntriButtonClicked = () => {
    if (isLoading) return false;

    // medusa events
    setIsLoading(true);
    purchaseCompletedRedirection(REDIRECTION_OPTIONS.ENTRI_SETUP, entriState, neoState);
    domainSetupRedirection(REDIRECTION_OPTIONS.ENTRI_SETUP, entriState, neoState);

    // launch entri modal
    showEntri();
    setIsLoading(false);
  };

  return {
    isLoading,
    onEntriButtonClicked
  }
}