import { useSelector } from "react-redux";
import Billing from "../shared/Billing";
import {
  isSiteDomainCustom,
  selectSiteDomainAttrs,
} from "../../../store/slices/site.slice";
import SiteOrderSummary from "../../OrderSummary/site";
import SiteBillingInformation from "../../BillingInformation/site";
import SiteBillingCard from "../../BillingCard/site";

const MailBilling = () => {
  const { isCoSiteTrial } = useSelector(selectSiteDomainAttrs);
  const isCustomDomain = useSelector(isSiteDomainCustom);

  return (
    <Billing
      OrderSummaryComponent={SiteOrderSummary}
      BillingCardComponent={SiteBillingCard}
      BillingInformationComponent={SiteBillingInformation}
      isCoSiteTrial={isCoSiteTrial}
      isCustomDomain={isCustomDomain}
    />
  );
};

export default MailBilling;
