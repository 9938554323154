import { shuffleList } from "./utils";

export const coSiteDomainPersonaQuestions = (domain) => {
  return [
    {
      question: `In which industry does ${domain} operate?`,
      placeholder: "e.g. Home & Repair, Retail, Entertainment...",
    },
    {
      question: `What is the employee count at ${domain}, including yourself?`,
      placeholder: "1",
      type: "number",
    },
    {
      question: `What is your role at ${domain}?`,
      placeholder: "e.g. Founder",
    },
    {
      question: "What made you signup for Neo?",
      options: [
        {
          value: `Email with my company name @${domain}`,
          label: `Email with my company name @${domain}`,
        },
        { value: "Zero setup email", label: "Zero setup email" },
        {
          value: `Free domain ${domain}`,
          label: `Free domain ${domain}`,
        },
        {
          value: "Simple one-page site I can launch now!",
          label: "Simple one-page site I can launch now!",
        },
        {
          value: "Read receipt email feature",
          label: "Read receipt email feature",
        },
        { value: "Others", label: "Others" },
      ],
      isMultiSelect: true,
      type: "select"
    },
    {
      question: "How did you first hear about us?",
      options: [
        // since we dont want to shuffle others option
        ...shuffleList([
          { value: "YouTube", label: "YouTube" },
          { value: "LinkedIn", label: "LinkedIn" },
          { value: "Google search", label: "Google search" },
          { value: "Instagram", label: "Instagram" },
          { value: "Facebook", label: "Facebook" },
          { value: "Reddit", label: "Reddit" },
          { value: "Review Websites", label: "Review Websites" }
        ]),
        { value: "Others", label: "Others" },
      ],
      type: "radio",
      shuffle: true
    },
    {
      question: "Do you want to import your old emails or contacts to Neo?",
      options: [
        {
          value: "Yes, import emails",
          label: "Yes, import emails",
        },
        { value: "Yes, import contacts", label: "Yes, import contacts" },
        {
          value: "Yes, import both emails and contacts",
          label: "Yes, import both emails and contacts",
        },
        {
          value: "No, don’t want to import",
          label: "No, don’t want to import",
        },
      ],
      isMultiSelect: false,
      type: "select"
    },
    {
      question:
        "What is the primary email app you use to check your work email today?",
      options: [
        {
          value: "Microsoft Outlook",
          label: "Microsoft Outlook",
        },
        { value: "Mac Mail", label: "Mac Mail" },
        {
          value: "Gmail website",
          label: "Gmail website",
        },
        {
          value: "Gmail mobile app",
          label: "Gmail mobile app",
        },
        {
          value: "iPhone Mail",
          label: "iPhone Mail",
        },
        {
          value: "Thunderbird",
          label: "Thunderbird",
        },
        { value: "Others", label: "Others" },
      ],
      isMultiSelect: true,
      type: "select"
    },
  ];
};

export const customDomainPersonaQuestions = (domain) => {
  return [
    {
      question: `In which industry does ${domain} operate?`,
      placeholder: "e.g. Home & Repair, Retail, Entertainment...",
    },
    {
      question: `What is the employee count at ${domain}, including yourself?`,
      placeholder: "1",
      type: "number",
    },
    {
      question: `What is your role at ${domain}?`,
      placeholder: "e.g. Founder",
    },
  ];
};
