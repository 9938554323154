/**
 * CaptchaVerification component that integrates with Cloudflare Turnstile for CAPTCHA verification.
 * Refer to Readme.md for more information on this component.
 *
 * @component
 * @param {Object} props - Component props
 * @param {function(string | null): void} props.onTokenChange - Callback function to handle token change
 * @param {string | boolean} [props.initialError=false] - Initial error state
 * @param {React.Ref<CaptchaVerificationRef>} ref - Ref to control the captcha component
 *
 * @typedef {Object} CaptchaVerificationRef
 * @property {function(): void} reset - Function to reset the captcha
 *
 * @returns {JSX.Element} The CaptchaVerification component
 *
 */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import type { TurnstileInstance } from "@marsidev/react-turnstile";
import { Turnstile } from "@marsidev/react-turnstile";
import classNames from "classnames";
import inputStyles from "../Common/Input/Input.module.scss";
import { logCustomerEvent } from "../../telemetry/medusaEventsFunctions";
import { ANALYTICS_EVENTS } from "../../helpers/constants";
import styles from "./CaptchaVerification.module.scss";

interface CaptchaVerificationProps {
  onTokenChange: (token: string | null) => void;
  error: string;
  setError: (error: string) => void;
}

export interface CaptchaVerificationRef {
  reset: () => void;
}

const { TRAFFIC_CHECK_REQUIRED, TRAFFIC_VERIFICATION_FAILED } =
  ANALYTICS_EVENTS;

const CaptchaVerification = forwardRef<
  CaptchaVerificationRef,
  CaptchaVerificationProps
>(({ onTokenChange, error = "", setError }, ref) => {
  const [token, setToken] = useState<string | null>(null);
  const [showTurnstile, setShowTurnstile] = useState<boolean>(false);
  const turnstileRef = useRef<TurnstileInstance>(null);

  const resetTurnstile = (shouldResetError: boolean = false) => {
    setToken(null);
    if (turnstileRef.current) {
      turnstileRef.current.reset();
    }
    if (shouldResetError) {
      setError("");
    }
  };

  useImperativeHandle(ref, () => ({
    reset: () => resetTurnstile(),
    getResponsePromise: (timeout?: number, retry?: number) => {
      return turnstileRef.current
        ? turnstileRef.current.getResponsePromise(timeout, retry)
        : null;
    },
  }));

  const handleSuccess = (newToken: string) => {
    setToken(newToken);
    setError("");
    onTokenChange(newToken);
  };

  const handleCaptchaError = () => {
    //triggered when the token generation fails
    logCustomerEvent(TRAFFIC_VERIFICATION_FAILED);
    setError("Captcha verification failed, please try again.");
  };

  useEffect(() => {
    onTokenChange(token);
  }, [token, onTokenChange]);

  const handleWidgetIsInteractive = () => {
    logCustomerEvent(TRAFFIC_CHECK_REQUIRED); // Log when CAPTCHA widget is interactive
  };

  useEffect(() => {
    if (error) {
      setShowTurnstile(true);
    }
  }, [error]);

  return (
    <div
      className={classNames(styles.captchaVerificationWrapper, {
        [styles.hidden]: !showTurnstile,
      })}
    >
      <Turnstile
        ref={turnstileRef}
        siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY || ""}
        onSuccess={handleSuccess}
        onError={handleCaptchaError}
        options={{
          theme: "light",
          size: "flexible",
        }}
        onExpire={() => resetTurnstile(true)}
        onBeforeInteractive={handleWidgetIsInteractive}
      />
      {error && (
        <div className={inputStyles.error}>
          {error || "Please verify that you are not a robot"}
        </div>
      )}
    </div>
  );
});

export default CaptchaVerification;
