import React from "react";
import Select, { FormatOptionLabelMeta } from "react-select";
import inputStyles from "../../../Common/Input/Input.module.scss";
import Price from "../../../Common/Price";
import { getRoundedDecimal, isMobile } from "../../../../helpers/utils";
import { TBillingCycle } from "../../../../types/billing-cycle";
import { BILLING_CYCLE_DISPLAY_LABELS } from "../../../../helpers/constants";
import { TObject } from "../../../../types/common";
import styles from "./style.module.scss";

type BillingCycleOption = {
  value: TBillingCycle;
  label?: React.ReactNode;
  discountPercentage?: number;
  pricePerMonth: number;
};

type BillingCycleDropdownProps = {
  options: BillingCycleOption[];
  defaultOption: BillingCycleOption;
  onChange: (selectedCycle: BillingCycleOption) => void;
  priceOf: string;
  getOptionLabel?: (option: BillingCycleOption) => React.ReactNode;
  getSelectedOptionLabel?: (option: BillingCycleOption) => string;
};

const BillingCycleDropdown: React.FC<BillingCycleDropdownProps> = ({
  options,
  defaultOption,
  onChange,
  priceOf,
  getOptionLabel = (option) => option.label ?? BILLING_CYCLE_DISPLAY_LABELS[option.value],
  getSelectedOptionLabel = (option) => BILLING_CYCLE_DISPLAY_LABELS[option.value],
}) => {
  const formatOptionLabel = (
    option: BillingCycleOption,
    { context }: FormatOptionLabelMeta<BillingCycleOption>
  ) => {
    if (context === "menu") {
      return (
        <div className={styles.selectMenuContent}>
          <div className={styles.selectMenuContentLabel}>
            <strong>{getOptionLabel(option)}</strong>
          </div>
          <div>
            <div className={styles.pricePerMonth}>
              <span>
                <Price
                  value={getRoundedDecimal(option.pricePerMonth, 2, "string")}
                />
              </span>
              {priceOf}
            </div>
            {option.discountPercentage ? (
              <small className={"colorGreen"}>
                Save {option.discountPercentage}%
              </small>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <>
        {getOptionLabel(option)}
        {option.discountPercentage ? (
          <span className={"colorGreen"}>
            {" "}
            (Save {option.discountPercentage}%)
          </span>
        ) : null}
      </>
    );
  };

  return (
    <Select
      styles={selectBoxStyling as any}
      className={inputStyles.select}
      options={options}
      defaultValue={defaultOption}
      onChange={(selectedOption) =>
        onChange(selectedOption as BillingCycleOption)
      }
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={getSelectedOptionLabel}
      isSearchable={false}
      components={{
        IndicatorsContainer: () => (
          <div className={inputStyles.selectCaret}></div>
        ),
      }}
      classNames={{
        option: ({ isSelected }) => (isSelected ? styles.selectedOption : ""),
      }}
    />
  );
};

const selectBoxStyling = {
  control: () => ({
    padding: "5px 10px",
    border: "1px solid #999",
    background: "#fff",
    borderRadius: "5px",
    outline: 0,
    cursor: "pointer",
    display: "flex",
    "&:focus": {
      outline: 0,
    },
    height: "48px",
    width: isMobile() ? "100%" : "320px",
    fontWeight: "normal",
    textAlign: "left",
  }),
  valueContainer: (provided: TObject) => ({
    ...provided,
    padding: 0,
  }),
  menu: (provided: TObject) => ({
    ...provided,
    minWidth: 200,
    right: 0,
    fontWeight: "normal",
    textAlign: "left",
  }),
  menuList: (provided: TObject) => ({
    ...provided,
    padding: "0",
    cursor: "pointer",
  }),
  input: (provided: TObject) => ({
    ...provided,
    caretColor: "transparent",
  }),
  indicatorSeparator: (provided: TObject) => ({
    ...provided,
    display: "none",
  }),
  option: (provided: TObject) => ({
    ...provided,
    color: "#333",
    backgroundColor: "transparent",
    ":focus, :hover, :active, :visited": { backgroundColor: "transparent" },
    padding: "0",
    margin: "0",
    width: "auto",
    borderBottom: "1px solid #dedede",
    "&:last-of-type": {
      borderBottom: "0",
    },
  }),
};

export default BillingCycleDropdown;
