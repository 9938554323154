import { useState, useEffect, useCallback } from "react";
import useHasHorizontalScroll from "./useHasHorizontalScroll";

type Props = {
  threshold?: number;
  once?: boolean;
};

const useHorizontalOverflowIndicator = ({
  threshold = 48,
  once = false,
}: Props = {}) => {
  const [showIndicator, setShowIndicator] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { hasHorizontalScroll, horizontalScrollableContainerRef } =
    useHasHorizontalScroll({ disabled });

  useEffect(() => {
    if (
      !hasHorizontalScroll ||
      !horizontalScrollableContainerRef.current ||
      disabled
    ) {
      setShowIndicator(false);
      return;
    }
    const checkOverflow = () => {
      if (!horizontalScrollableContainerRef.current) {
        return;
      }
      const { scrollWidth, clientWidth, scrollLeft } =
        horizontalScrollableContainerRef.current;
      const hasOverflow =
        scrollWidth > clientWidth &&
        scrollWidth - clientWidth - scrollLeft > threshold;
      setShowIndicator(hasOverflow);
      if (once && !hasOverflow) {
        setDisabled(true);
      }
    };
    checkOverflow();

    if (horizontalScrollableContainerRef.current) {
      horizontalScrollableContainerRef.current.addEventListener(
        "scroll",
        checkOverflow
      );
    }

    return () => {
      if (horizontalScrollableContainerRef.current) {
        horizontalScrollableContainerRef.current.removeEventListener(
          "scroll",
          checkOverflow
        );
      }
    };
  }, [hasHorizontalScroll, disabled]);

  const scrollToEnd = useCallback(() => {
    if (hasHorizontalScroll && horizontalScrollableContainerRef.current) {
      horizontalScrollableContainerRef.current.scrollTo({
        left: horizontalScrollableContainerRef.current.scrollWidth,
        behavior: "smooth",
      });
    }
  }, [hasHorizontalScroll]);

  return { horizontalScrollableContainerRef, showIndicator, scrollToEnd };
};

export default useHorizontalOverflowIndicator;
