export const PRODUCT_TYPES = {
  MAIL: "mail",
  SITE: "site",
} as const;

export const ORDER_TYPES = {
  MAIL_SUITE: "MailSuite",
  SITE: "Site",
  NEO_DOMAIN: "NeoDomain",
} as const;
