import React, { useState } from "react";
import Header from "../../Header";
import { HEADER_TYPES } from "../../../helpers/constants";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import LayoutTemplate from "../LayoutTemplate";
import { isValidAlphanumericWithSpecialChars } from "../../../helpers/utils";
import sharedStyles from "../shared/styles.module.scss";

const MAX_LENGTH = 55;

interface Props {
  businessName: string;
  isLoading: boolean;
  continueButtonText: React.ReactNode;
  onContinue: (businessName: string) => void;
  captchaComponent?: React.ReactNode;
  disabled?: boolean;
}

const BusinessName: React.FC<Props> = ({
  businessName: initialBusinessName,
  isLoading,
  continueButtonText,
  onContinue,
  captchaComponent,
  disabled,
}) => {
  const [businessName, setBusinessName] = useState<string>(
    initialBusinessName || ""
  );
  const [error, setError] = useState<string>("");

  const handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBusinessName(e.target.value);
    if (error) {
      setError("");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const trimmedBusinessName = businessName.trim();

    // Validation logic before calling onContinue
    if (trimmedBusinessName === "") {
      setError("Enter your business name");
      return;
    }

    if (trimmedBusinessName.length > MAX_LENGTH) {
      setError(`Text too long, maximum ${MAX_LENGTH} characters allowed`);
      return;
    }

    if (!isValidAlphanumericWithSpecialChars(trimmedBusinessName)) {
      setError("Only alphabetic, numeric, and special characters are allowed");
      return;
    }

    // Clear error if validation passes
    setError("");

    // Call onContinue if validation passes
    onContinue(trimmedBusinessName);
  };

  return (
    <LayoutTemplate
      leftContent={
        <div className="max-width-600">
          <Header type={HEADER_TYPES.YOUR_BUSINESS_NAME} />
          <div className={sharedStyles.businessSubtitle}>
            This is your site title. You can update it later.
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              name={"businessName"}
              placeholder="E.g. Acme Designs"
              type={"text"}
              onChange={handleBusinessNameChange}
              autofocus={true}
              hasError={!!error}
              errorMessage={error}
              autoCapitalize={"none"}
              value={businessName}
            />
            {captchaComponent && <div>{captchaComponent}</div>}
            <Button
              type={"primary"}
              width={"auto"}
              isProcessing={isLoading}
              disabled={disabled}
              onClick={() => handleSubmit}
            >
              {continueButtonText}
            </Button>
          </form>
        </div>
      }
    />
  );
};

export default BusinessName;
