import { EMAIL_VALIDITY_ERROR_CODES } from "../constants/email.constants";
import { checkEmailAvailability } from "../services/apiFunctions";
import { INPUT_ERROR_MESSAGE } from "./constants";
import { getDomainFromEmail } from "./domainHelpers";
import { isEmailValid } from "./utils";

export const getUserNameFromEmail = (email) => {
  return email.split("@")?.[0];
};

export const emailAvailabilityStatus = async (email) => {
  const username = getUserNameFromEmail(email);
  let res;
  try {
     res = await checkEmailAvailability(
      { emails: [email], domainName: getDomainFromEmail(email) },
      {},
    ); 
  } catch(e) {
    console.error(e);
  }
  
  if(!res) return {
    isAvailable: false,
    errorMsg: EMAIL_VALIDITY_ERROR_CODES.NETWORK_ERROR({ username })
  }
  
  const { availabilityStatus: { [email]: { isAvailable, reason } } = {} } = res.data;
  if (isAvailable) return { isAvailable, errorMsg: "" };
  if (EMAIL_VALIDITY_ERROR_CODES[reason]) {
    return {
      isAvailable,
      errorMsg: EMAIL_VALIDITY_ERROR_CODES[reason]({ username }),
    };
  }
  return {
    isAvailable,
    errorMsg: EMAIL_VALIDITY_ERROR_CODES.DEFAULT({ username }),
  };
};

export const getCountOfTotalMailboxAdded = (mailboxOrderList) => {
  // count of mailboxes added(including admin mailbox), where +1 is accounting for admin mailbox
  return mailboxOrderList?.length + 1;
};

/**
 * function to check is custom input email is already added as admin
 * OR already exist in mailbox order list
 */
const isEmailAlreadyAdded = (inputEmail, adminMailbox, mailboxOrderList) => {
  let isAlreadyAdded = false;

  if (inputEmail === adminMailbox.email) {
    isAlreadyAdded = true;
  } else {
    isAlreadyAdded = mailboxOrderList?.some(
      (item) => inputEmail === item.email
    );
  }

  return isAlreadyAdded;
};

/**
 * validate custom input email for team and generic mailboxes page
 */
export const validateCustomEmail = async (
  inputEmail,
  customUsername,
  adminMailbox,
  mailboxOrderList
) => {
  let error = false,
    errorMessage;

  if (customUsername === "") {
    error = true;
    errorMessage = INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_USERNAME;
  } else if (!isEmailValid(inputEmail)) {
    error = true;
    errorMessage = `"${inputEmail}" - is not a valid email`;
  } else if (isEmailAlreadyAdded(inputEmail, adminMailbox, mailboxOrderList)) {
    error = true;
    errorMessage = INPUT_ERROR_MESSAGE.MAILBOX_ALREADY_ADDED_WITH_THIS_USERNAME;
  } else {
    const { isAvailable, errorMsg } = await emailAvailabilityStatus(inputEmail);
    if (!isAvailable) {
      error = true;
      errorMessage = errorMsg;
    }
  }

  return { isError: error, errorMessage };
};
