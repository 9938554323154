import { BILLING_CYCLE_KEYS } from "../../helpers/constants";
import { getComputedValue } from "../../helpers/utils";
import { TBillingCycle } from "../../types/billing-cycle";
import { TPlan } from "../../types/plan.type";

const { MONTHLY } =
  BILLING_CYCLE_KEYS;

export const getPlanDataForMedusa = (prefix: string, duration: TBillingCycle, plan?: TPlan) => {
  if (!plan) return {};
  return {
    [`${prefix}_plan`]: plan.displayName,
    [`${prefix}_price_monthly`]: getComputedValue(plan.pricing[MONTHLY]?.price),
    [`${prefix}_first_billing_discount`]: plan.pricing[duration]?.discountPercentage || 0,
  };
};
