import { RootState } from "../../types/store.type";

export const selectUserDetails = (state: RootState) => {
  const {
    user: {
      account_id,
      alternateEmail,
      billingInformation,
      controlPanelAutoLoginToken,
      customer_id,
      domain,
      experiments,
      firstOrder,
      hasActiveOrders,
      isBillingInfoSaved,
      name,
      order_id,
      payment_amount,
      plan,
      source_hook,
      token,
      tokenExpiry,
      webmailAutoLoginToken,
      customMailboxInput,
      showTeamMailboxPostDomainPurchase,
      entriResponse,
      persona,
      showMoneyBackGuarantee,
      promo_discount_amount,
      currency,
      haveLitePlanSupport,
      payment_for,
      total_mail_price,
      total_site_price,
      total_neo_domain_price,
      billing_cycle_updated,
      bundleId,
    },
  } = state;
  return {
    account_id,
    alternateEmail,
    billingInformation,
    controlPanelAutoLoginToken,
    customer_id,
    domain,
    experiments,
    firstOrder,
    hasActiveOrders,
    isBillingInfoSaved,
    name,
    order_id,
    payment_amount,
    plan,
    source_hook,
    token,
    tokenExpiry,
    webmailAutoLoginToken,
    customMailboxInput,
    showTeamMailboxPostDomainPurchase,
    entriResponse,
    persona,
    showMoneyBackGuarantee,
    promo_discount_amount,
    currency,
    haveLitePlanSupport,
    payment_for,
    total_mail_price,
    total_site_price,
    total_neo_domain_price,
    billing_cycle_updated,
    bundleId,
  };
};
