import { sanitizeURL } from "./helpers";

class HTTP {
  // end point URL
  baseURL;
  // headers which needs to be present in network call
  headers;

  constructor(baseURL = '', headers = {}) {
    this.baseURL = sanitizeURL(baseURL);
    this.headers = headers || {
      'Content-Type': 'application/json'
    };
  }

  async errorHandler(resp) {
    let data = null;

    try {
      data = await resp.json();
    } catch (error) {
      try {
        data = await resp.text();
      } catch (e) {
        data = {};
      }
    }

    if (resp.ok) {
      return Promise.resolve(data);
    }

    return Promise.reject(data);
  }

  async exec(route, payload) {
    const resp = await fetch(`${this.baseURL}${route || ''}`, {
      headers: this.headers,
      ...payload
    });

    return this.errorHandler(resp);
  }

  async get(route, headers = {}, reqOptions = {}) {
    return await this.exec(route, {
      ...reqOptions,
      method: 'GET',
      headers: { ...this.headers, ...headers }
    });
  }

  async post(
    route,
    body,
    headers = {},
    reqOptions = {}
  ) {
    return await this.exec(route, {
      ...reqOptions,
      method: 'POST',
      headers: { ...this.headers, ...headers },
      body: JSON.stringify(body)
    });
  }
}

export default HTTP;
