import { useRef, useState } from "react";
import ENBridge from "../../bridge";

import { useEntri } from "../../hooks/useEntri";
import { useNeoPropsForEntri } from "../../hooks/useNeoPropsForEntri";
import { useNeoSite } from "../../hooks/useNeoSite";
import { verifyDkimKeyProxy } from "../../services/bll/dnsBannerConfig/proxy";
import { entriSetupAborted } from "../../services/medusa/entriSetupAborted";
import { entriSetupCompleted } from "../../services/medusa/entriSetupCompleted";
import { entriSetupCompleteRedirection } from "../../services/medusa/entriSetupCompleteRedirection";
import { entriSetupDocumentationClicked } from "../../services/medusa/entriSetupDocumentationClicked";
import { entriSetupInitiated } from "../../services/medusa/entriSetupInitiated";
import { useEntriContext } from "../../store/entri/useEntriContext";
import { useNeoContext } from "../../store/neo/useNeoContext";
import {
  SCREEN_TYPE_MAP,
  SETUP_COMPLETE_REDIRECTION_OPTIONS,
} from "../../utils/const";
import { entri } from "../../utils/entri";

export const useFlowManager = () => {
  const hasEventAttached = useRef(null);
  const [finishingSetup, setFinishingSetup] = useState(false);
  const entriProps = useNeoPropsForEntri();
  const { state } = useNeoContext();
  const { state: entriState } = useEntriContext();
  const {
    onEntriSetupComplete,
    onEntriClose,
    onEntriShow,
    onEntriManualSetupDocumentationClick,
  } = useEntri(entriProps);
  const { isSiteSetupMode } = ENBridge.handlers;
  const { openNeoSiteLink } = useNeoSite();
  const [screenType, setScreenType] = useState(SCREEN_TYPE_MAP.SETUP_DNS);
  const [hasInterruption, setHasInterruption] = useState(false);
  const { openSiteSetupKBArticle } = ENBridge.handlers;
  const openCustomMobileSetup = () => {
    isSiteSetupMode()
      ? openSiteSetupKBArticle()
      : setScreenType(SCREEN_TYPE_MAP.CUSTOM_MOBILE_SETUP);
  };
  const closeCustomMobileSetup = () => setScreenType(SCREEN_TYPE_MAP.SETUP_DNS);

  // listen for entri events
  const attachEntriEvents = () => {
    // setup complete
    onEntriSetupComplete(async (detail) => {
      try {
        setFinishingSetup(true);
        if (entri.isAutomaticSetupComplete(detail)) {
          await entriSetupCompleted(entriState, state);
          await verifyDkimKeyProxy(state);
        } else {
          await entriSetupAborted(detail, entriState, state);
        }
        setFinishingSetup(false);
        if (isSiteSetupMode()) {
          openNeoSiteLink();
          entriSetupCompleteRedirection(
            SETUP_COMPLETE_REDIRECTION_OPTIONS.SITE_BUILDER,
            entriState,
            state
          );
        }
        setScreenType(SCREEN_TYPE_MAP.SETUP_COMPLETE);
      } catch {
        setFinishingSetup(false);
      }
    });

    // setup interrupted
    onEntriClose((entriCloseEventPayload) => {
      setHasInterruption(true);
      entriSetupAborted(entriCloseEventPayload, entriState, state);
    });

    // doc clicked
    onEntriManualSetupDocumentationClick(() =>
      entriSetupDocumentationClicked(entriState, state)
    );

    // on entri show
    onEntriShow(() => {
      entriSetupInitiated(entriState, state);
    });

    hasEventAttached.current = true;
  };

  // we could have use useEffect but for some reason entri.showEvent (from useSetupDns.js)
  // get fired before events get attached
  // hence we are going with this approach
  if (!hasEventAttached.current) {
    attachEntriEvents();
  }

  return {
    SCREEN_TYPE_MAP,
    screenType,
    hasInterruption,
    finishingSetup,
    // navigation object which can be consumed by child for navigation purpose
    // child need not to be aware of what exactly happening during navigation
    navigation: {
      openCustomMobileSetup,
      closeCustomMobileSetup,
    },
  };
};
