export const ERROR_CODES = {
  BAD_REQUEST: {
    title: "Bad Request",
    desc: "The request could not be understood by the server due to malformed syntax.",
  },
};

export const ERROR_KEYS = {
  CAPTCHA_VERIFICATION_FAILED: "CAPTCHA_VERIFICATION_FAILED",
  CLOUDFARE_TOKEN_REQUIRED: "CLOUDFARE_TOKEN_REQUIRED",
};
