import { Navigate } from "react-router-dom";
import { useProduct } from "../../hooks/useProduct";
import useIsLoggedIn from "../../hooks/useIsLoggedIn";

const RouteProtection = ({ children }) => {
  const productHandler = useProduct();
  const isLoggedIn = useIsLoggedIn();
  const { domain, getStartedPath, fallbackRouteForUnauthenticatedUser } =
    productHandler;

  if (!isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: fallbackRouteForUnauthenticatedUser,
          search: window.location.search,
        }}
        replace
      />
    );
  }

  if (!domain && window.location.pathname !== getStartedPath) {
    return (
      <Navigate
        to={{
          pathname: getStartedPath,
        }}
        replace
      />
    );
  }

  return children;
};

export default RouteProtection;
