import React from "react";
import Price from "../Common/Price";
import { getRoundedDecimal } from "../../helpers/utils";
import { DomainState } from "../../store/slices/domain.slice";
import styles from "./styles.module.scss";

interface Props {
  isDomainChargeable: DomainState["attrs"]["isDomainChargeable"];
  domainPricing: DomainState["pricing"];
  pricingFor: string;
}

const DomainPricing: React.FC<Props> = ({
  isDomainChargeable,
  domainPricing,
  pricingFor,
}) => {
  const { monthly, yearly } = domainPricing;

  return isDomainChargeable ? (
    <div className={styles.chargesWrapper}>
      <div className={styles.charge}>
        <strong>First year free</strong> with yearly {pricingFor} plans
        <small>
          Renews at{" "}
          <strong>
            <Price value={yearly?.renewalCharge} />
            /mo
          </strong>{" "}
          (<Price value={yearly?.renewalChargePerYear} />
          /yr)
        </small>
      </div>
      <div className={styles.charge}>
        <strong>First month free</strong> with monthly {pricingFor} plan
        <small>
          Renews at{" "}
          <strong>
            <Price
              value={getRoundedDecimal(monthly?.renewalCharge, 2, "string")}
            />
            /mo
          </strong>
        </small>
      </div>
    </div>
  ) : null;
};

export default DomainPricing;
