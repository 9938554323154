import { useCallback, useEffect, useState } from "react";
import { isMobileDimension } from "../3rdPartyIntegrations/Entri/utils/helpers";
import { isMobile } from "../helpers/utils";

const isMobileDevice = () => isMobileDimension() || isMobile();

export const useMobile = () => {
    const [isMobileView, setShowMobileView] = useState(isMobileDevice());
    const _setShowMobileView = useCallback((status) => {
        if (status !== isMobileView) {
            setShowMobileView(status);
        }
    }, [isMobileView]);

    // on resize adjust slider
    useEffect(() => {
        const onResize = function() {
            _setShowMobileView(isMobileDevice());
        };

        window.addEventListener('resize', onResize);

        return () => window.removeEventListener("resize", onResize);
    }, [_setShowMobileView]);

    return {
        isMobileView
    }
}