import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface TextAreaProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  maxLength?: number;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  errorMessage?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder = "",
  rows = 4,
  maxLength,
  className,
  disabled = false,
  style,
  errorMessage,
}) => {
  return (
    <div
      className={classNames(styles.textAreaWrapper, className)}
      style={style}
    >
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
        className={styles.textArea}
      />
      {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}
    </div>
  );
};

export default TextArea;
