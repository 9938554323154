import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ANALYTICS_EVENTS,
  OFFERING,
  PAGE_PATHS,
} from "../../helpers/constants";
import { onVWOLoaded } from "../../helpers/pageLoadedHelper";
import { userSelector } from "../../store/selectors";
import Header from "../Header";
import ReviewQuote from "../ReviewQuote";
import GetStartedV1 from "./GetStartedV1";
import GetStartedV2 from "./GetStartedV2";
import { TDomainSelectionOptions, TSelectChangePayload } from "./Common/types";
import { TLogEventProps } from "../../types/global";
import { DomainState } from "../../store/slices/domain.slice";

const { GET_STARTED_VIEWED, HAS_DOMAIN_CHECKED } =
  ANALYTICS_EVENTS;
const SOURCE = "Does your business have domain?";

interface Props {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  domainSelectionOptions: TDomainSelectionOptions;
  offering: DomainState["offering"];
  navigateToHaveDomain: () => void;
  navigateToGetDomain: () => void;
  logEvent: ({ eventName, data, flag }: TLogEventProps) => void;
  onSelectChange: (payload: TSelectChangePayload) => void;
  showTestimonial?: boolean;
}

const GetStarted: React.FC<Props> = (props) => {
  const {
    title,
    subTitle,
    domainSelectionOptions,
    offering,
    navigateToHaveDomain,
    navigateToGetDomain,
    logEvent,
    onSelectChange,
    showTestimonial
  } = props;

  const { source_hook } = useSelector(userSelector);
  const [isVariant, setIsVariant] = useState<boolean>(false);

  useEffect(() => {
    setIsVariant(window.location.href.includes(PAGE_PATHS.GET_STARTED_V2));
  }, [window.location.href]);

  useEffect(() => {
    onVWOLoaded().then(() => {
      logEvent({
        eventName: GET_STARTED_VIEWED,
        data: { source_hook },
      });
    });
  }, [source_hook]);

  const handleDomainTypeSelection = (type: string) => {
    if (type === OFFERING.CUSTOM_DOMAIN) {
      onSelectChange({
        offering: OFFERING.CUSTOM_DOMAIN,
      });
      if (isVariant) {
        logEvent({
          eventName: HAS_DOMAIN_CHECKED,
          data: {
            source_hook: SOURCE,
          },
          flag: false,
        });
        navigateToHaveDomain();
      }
    } else {
      onSelectChange({
        offering: OFFERING.COSITE,
      });
      if (isVariant) {
        logEvent({
          eventName: HAS_DOMAIN_CHECKED,
          data: {
            source_hook: SOURCE,
          },
          flag: false,
        });
        navigateToGetDomain();
      }
    }
  };

  const handleGetStartedCta = () => {
    logEvent({
      eventName: HAS_DOMAIN_CHECKED,
      data: {
        source_hook: SOURCE,
      },
      flag: false,
    });
    if (offering === OFFERING.CUSTOM_DOMAIN) {
      navigateToHaveDomain();
    } else {
      navigateToGetDomain();
    }
  };

  return (
    <>
      <Header>{title}</Header>
      <div>
        <span>{subTitle}</span>
        {!isVariant ? (
          <GetStartedV2
            domainSelectionOptions={domainSelectionOptions}
            handleDomainTypeSelection={handleDomainTypeSelection}
            handleGetStartedCta={handleGetStartedCta}
          />
        ) : (
          <GetStartedV1
            domainSelectionOptions={domainSelectionOptions}
            handleDomainTypeSelection={handleDomainTypeSelection}
          />
        )}
        {showTestimonial ? <ReviewQuote /> : null}
      </div>
    </>
  );
};

export default GetStarted;
