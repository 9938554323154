import { getRootDomain } from "./domainHelpers";

// This function is used to decide where to set cookies in localhost or .neo.space domain

export const getDomainToSetCookies = (): string => {
  const hostname = window.location.hostname;
  if (hostname.includes("localhost")) {
    return "localhost";
  } else {
    return getRootDomain(hostname); // Assuming getRootDomain returns a string
  }
};

export const setCookie = (
  cname: string,
  cvalue: string | number,
  exdays: number = 365,
  restOfString: string = ""
): void => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};domain=${getDomainToSetCookies()};${expires};path=/;${restOfString}`;
};

export const getCookie = (cname: string): string | null => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let c of ca) {
    c = c.trim();
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const eraseCookie = (cname: string): void => {
  if (cname) {
    document.cookie = `${cname}=;path=/;domain=${getDomainToSetCookies()};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};
