import React from "react";
import classNames from "classnames";
import PlanItem from "../PlanHeaderItem";
import { usePlansContext } from "../context";
import planStyles from "./style.module.scss";

interface PlansHeaderProps {
  className?: string;
}

const PlansHeader = React.forwardRef<HTMLDivElement, PlansHeaderProps>(
  (props, ref) => {
    const { className } = props;

    const {
      visiblePlans: plansToShow,
      components: { EmptyPlanItem },
    } = usePlansContext();

    const totalPlans = plansToShow.length;

    return (
      <div className={classNames(planStyles.plansHeader, className, 'plansHeader')} ref={ref}>
        <div className={planStyles.headerItem}>
          <EmptyPlanItem />
        </div>
        {plansToShow.map((plan, index) => {
          return (
            <div
              className={planStyles.headerItem}
              key={index}
              data-plan-entropy={totalPlans - index}
            >
              <PlanItem plan={plan} />
            </div>
          );
        })}
      </div>
    );
  }
);

export default PlansHeader;
