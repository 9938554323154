import React from "react";
// @ts-ignore
import Modal from "react-modal";
import styles from "./style.module.scss";
import Button from "../../Common/Button/Button";
import { ReactComponent as CloseIcon } from "../../../assets/cross.svg";

interface ModalTemplateProps {
  isOpen: boolean;
  onClose: () => void;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  modalContentClassName?: string;
}

const ModalTemplate: React.FC<ModalTemplateProps> = ({
  isOpen,
  onClose,
  leftContent,
  rightContent,
  modalContentClassName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Template Modal"
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <div className={`${styles.modalContent} ${modalContentClassName}`}>
        <Button className={styles.closeButton} onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </Button>

        <div className={styles.leftContainer}>{leftContent}</div>
        <div className={styles.rightContainer}>{rightContent}</div>
      </div>
    </Modal>
  );
};

export default ModalTemplate;
