import React from "react";
import { TPlan } from "../../../../types/plan.type";
import { usePlansContext } from "../context";
import styles from "./style.module.scss";

type Props = {
  plan: TPlan;
};

const PlanTag: React.FC<Props> = ({ plan }) => {
  const { getPlanTag } = usePlansContext();
  if(!getPlanTag) return null;
  return <div className={styles.planTag}>{getPlanTag(plan)}</div>;
};

export default PlanTag;
