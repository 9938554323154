import { LOGOUT, UPDATE_CONFIG } from "../actions/actionsConstants";

const initialState = { env: "PROD" };

const config = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return initialState;  

    default:
      return state;
  }
};

export default config;
