import React from "react";
import { Tooltip as ReactTooltip, TooltipRefProps} from "react-tooltip";

type GenericTooltipProps = {
  children: React.ReactElement;
  content: React.ReactNode;
  className?: string;
  id: string;
  place?: TooltipRefProps["place"];
};

const GenericTooltip = ({
  children,
  id,
  content,
  place = "bottom",
  ...rest
}: GenericTooltipProps) => {
  // Clone the child element and add the necessary attributes
  const childWithTooltip = React.cloneElement(children, {
    ...children.props,
    "data-tooltip-id": id,
  });

  return (
    <>
      {childWithTooltip}
      <ReactTooltip
        id={id}
        key={id}
        place={place}
        className={"tooltip"}
        border={'1px solid #b4b4b4'}
      >
        {content}
      </ReactTooltip>
    </>
  );
};

export default GenericTooltip;
