import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TProductTypes } from "../../types/products.type";
import { TDomainPricing } from "../../types/domain.type";

interface DomainAttrs {
  isDomainChargeable: boolean;
  isCoSiteTrial: boolean;
}

export interface DomainState {
  name: string;
  offering: string;
  customDomain: string;
  coSiteDomain: string;
  attrs: DomainAttrs;
  pricing: TDomainPricing;
  purchasedDomain: string;
}

const initialDomainState: DomainState = {
  name: "",
  offering: "",
  customDomain: "",
  coSiteDomain: "",
  purchasedDomain: "",
  attrs: {
    isDomainChargeable: true,
    isCoSiteTrial: false,
  },
  pricing: {
    monthly: {},
    yearly: {},
    two_yearly: {},
    four_yearly: {},
    quarterly: {},
  },
};

export const createDomainSlice = (scope: TProductTypes) => {
  return createSlice({
    name: `${scope}/domain`,
    initialState: initialDomainState,
    reducers: {
      setDomain(
        state: DomainState,
        action: PayloadAction<Partial<DomainState>>
      ) {
        return { ...state, ...action.payload };
      },
      setDomainPricing(state: DomainState, action: PayloadAction<Partial<TDomainPricing>>) {
        state.pricing = {...state.pricing, ...action.payload};
      },
      setDomainAttrs(state: DomainState, action: PayloadAction<Partial<DomainAttrs>>) {
        state.attrs = {...state.attrs,  ...action.payload};
      },
      resetDomainState() {
        return initialDomainState;
      },
    },
  });
};

export type DomainReducer = ReturnType<typeof createDomainSlice>;
