export const SITE_PLAN_FEATURE_MAP = {
  CUSTOM_DOMAIN: "site_domain",
  NEO_DOMAIN_PAID: "neo_domain_paid",
  STORAGE: "site_storage",
  AI_SITE: "site_ai_site",
  AI_TEXT: "site_ai_text",
  TEMPLATES: "site_templates",
  FONTS: "site_fonts",
  COLORS: "site_colors",
  STOCK_IMAGES: "site_stock_images",
  GALLERY: "site_gallery",
  PRODUCTS: "site_products",
  SERVICES: "site_services",
  VIDEO: "site_video",
  TESTIMONIALS: "site_testimonials",
  ANALYTICS: "site_analytics",
  CONTACT_FORM: "site_contact_form",
  WHATSAPP: "site_whatsapp",
  CONTACT_INFO: "site_contact_info",
  REMOVE_NEO: "site_remove_neo",
  SUPPORT: "site_support",
  SITE_MAP: "site_sitemap",
};

export const SITE_FEATURE_GROUP_TEXT_MAP = {
  site_features: "Site customizations",
  site_contact_and_data: "Visitors",
  site_ai: "AI powered",
  site_more: "Others",
} as const;

export const siteFeaturesTextMap: Record<
  string,
  { text: string; tooltip?: string }
> = {
  [SITE_PLAN_FEATURE_MAP.STORAGE]: {
    text: "Website storage",
    tooltip: "Ample storage for all your site content and files.",
  },
  [SITE_PLAN_FEATURE_MAP.CUSTOM_DOMAIN]: {
    text: "Custom domain site",
    tooltip: "Publish your site on a custom domain",
  },
  [SITE_PLAN_FEATURE_MAP.NEO_DOMAIN_PAID]: {
    text: "premium domain",
    tooltip: "Elevate your presence with a premium site address.",
  },
  [SITE_PLAN_FEATURE_MAP.AI_SITE]: {
    text: "AI website generation",
    tooltip: "Get ready-to-launch websites in seconds.",
  },
  [SITE_PLAN_FEATURE_MAP.AI_TEXT]: {
    text: "AI copy assist",
    tooltip: "Enhance website content with AI-powered writing tools.",
  },
  [SITE_PLAN_FEATURE_MAP.TEMPLATES]: {
    text: "Templates",
    tooltip: "Wide selection of templates tailored to any look.",
  },
  [SITE_PLAN_FEATURE_MAP.FONTS]: {
    text: "Font themes",
    tooltip: "Personalize your site with a font to match your brand.",
  },
  [SITE_PLAN_FEATURE_MAP.COLORS]: {
    text: "Color themes",
    tooltip: "Pick a palette that screams you, not default",
  },
  [SITE_PLAN_FEATURE_MAP.STOCK_IMAGES]: {
    text: "Stock images",
    tooltip: "High-quality stock images to beautify your site.",
  },
  [SITE_PLAN_FEATURE_MAP.GALLERY]: {
    text: "Image gallery",
    tooltip: "Showcase your portfolio to display your range.",
  },
  [SITE_PLAN_FEATURE_MAP.PRODUCTS]: {
    text: "List your products",
    tooltip: "Efficiently display your product offerings.",
  },
  [SITE_PLAN_FEATURE_MAP.SERVICES]: {
    text: "List your services",
    tooltip: "List your services to enhance customer engagement.",
  },
  [SITE_PLAN_FEATURE_MAP.VIDEO]: {
    text: "YouTube video",
    tooltip: "Embed YouTube videos easily to engage visitors.",
  },
  [SITE_PLAN_FEATURE_MAP.TESTIMONIALS]: {
    text: "Testimonial",
    tooltip: "Boost credibility with customer testimonials on your site.",
  },
  [SITE_PLAN_FEATURE_MAP.ANALYTICS]: {
    text: "Visitor analytics",
    tooltip: "Track visitor behavior with detailed analytics tools.",
  },
  [SITE_PLAN_FEATURE_MAP.CONTACT_FORM]: {
    text: "Contact Forms",
    tooltip: "Gather visitor inquiries and get them in your Inbox",
  },
  [SITE_PLAN_FEATURE_MAP.WHATSAPP]: {
    text: "WhatsApp",
    tooltip: "Direct, fast customer communication.",
  },
  [SITE_PLAN_FEATURE_MAP.CONTACT_INFO]: {
    text: "Business contact info",
    tooltip: "Display contact info clearly to help customers reach you easily.",
  },
  [SITE_PLAN_FEATURE_MAP.SUPPORT]: {
    text: "Support",
    tooltip: "Dedicated help at every step.",
  },
  [SITE_PLAN_FEATURE_MAP.REMOVE_NEO]: {
    text: "Remove Neo Branding",
    tooltip: "Remove Neo branding for a cleaner look.",
  },
  [SITE_PLAN_FEATURE_MAP.SITE_MAP]: {
    text: "Site map",
    tooltip: "Enhance site navigation and SEO.",
  },
};
