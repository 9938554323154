import {
  BILLING_CYCLE_DURATIONS,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../helpers/constants";
import { userSelector } from "../../store/selectors";
import {
  isMailDomainCustom,
  selectMailDomainAttrs,
  selectMailPlan,
  selectMailPlanDetails,
} from "../../store/slices/mail.slice";
import { store } from "../../store/store";
import { RootState } from "../../types/store.type";
import { getPlanDataForMedusa } from "../shared/pricing.medusa.helper";

export const getMailPlanDataFromStore = (storeState?: RootState) => {
  const storeData = storeState || store.getState();
  const user = userSelector(storeData);
  const { payment_amount, promo_discount_amount } = user;
  const { duration } = selectMailPlan(storeData);
  const mailPlan = selectMailPlanDetails(storeData);
  const { isCoSiteTrial } = selectMailDomainAttrs(storeData);
  const isUserCustomDomain = isMailDomainCustom(storeData);

  return {
    billing_cycle: BILLING_CYCLE_DURATIONS[duration] || duration,
    free_trial_days: isUserCustomDomain
      ? TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN
      : isCoSiteTrial
      ? TRIAL_DURATIONS_IN_DAYS.CO_SITE
      : 0,
    payment_amount,
    payment_for: "New Order",
    promo_discount_amount,
    ...getPlanDataForMedusa("mail", duration, mailPlan),
  };
};
