import { useSelector } from "react-redux";
import useTimer from "../../hooks/useTimer";
import styles from "./style.module.scss";
import { userSelector } from "../../store/selectors";
import { TPlan } from "../../types/plan.type";
import { useAvailableBillingCycles } from "../../hooks/mail/useAvailableBillingCycles";
import { BILLING_CYCLE_SELECTION_LABELS } from "../../helpers/constants";
import { TBillingCycle } from "../../types/billing-cycle";
import React, { useEffect, useMemo } from "react";
import { getMilliSeconds } from "../../3rdPartyIntegrations/Entri/utils/helpers";
import { formatWithZeroPrefix } from "../../utils";
import {
  getItemFromLocalStorage,
  setItemFromLocalStorage,
} from "../../helpers/local-storage.helper";

const DEFAULT_TIME_SPAN = 2 * 24 * 60 * 60 * 1000; // 2 days

type Props = {
  offerBillingCycle: TBillingCycle;
  duration: TBillingCycle;
  cycles: {
    duration: TBillingCycle;
    discountPercentage?: number;
  }[];
};

type IntervalConfig = {
  value: string;
  label: string;
};

const useOfferDiscount = (offerBillingCycle: TBillingCycle, duration: TBillingCycle, cycles: Props['cycles']) => {

  const isOfferBillingCycle = duration === offerBillingCycle;
  if (!isOfferBillingCycle) return null;

  return (
    cycles.find(({ duration }) => duration === offerBillingCycle)
      ?.discountPercentage || null
  );
};

const IntervalDisplay = ({
  intervalConfig,
  sibling,
}: {
  intervalConfig: IntervalConfig[];
  sibling?: React.ReactNode;
}) => (
  <div className={styles.intervals}>
    {intervalConfig.map(({ value, label }, index) => (
      <React.Fragment key={label}>
        <div className={styles.interval}>
          <span className={styles.intervalValue}>{value}</span>
          <span className={styles.intervalLabel}>{label}</span>
        </div>
        {index < intervalConfig.length - 1 && (
          <div className={styles.intervalSeparator}>: </div>
        )}
      </React.Fragment>
    ))}
    {sibling}
  </div>
);

const getPersistedActiveTime = (): number => {
  const persistedTimer = getItemFromLocalStorage("offerTimer");
  if (!persistedTimer) return DEFAULT_TIME_SPAN;
  if (!Number.isInteger(persistedTimer)) return DEFAULT_TIME_SPAN;
  return Number.parseInt(persistedTimer) || DEFAULT_TIME_SPAN;
};

const usePersistedTimer = (timer: number) => {
  const timerRef = React.useRef<number>(0);
  timerRef.current = timer;

  const persistLastActiveTime = React.useCallback(() => {
    setItemFromLocalStorage("offerTimer", timerRef.current);
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", persistLastActiveTime);
    return () => {
      persistLastActiveTime();
      window.removeEventListener("beforeunload", persistLastActiveTime);
    };
  }, [persistLastActiveTime]);

  return timerRef;
};

export function LimitedTimeOfferBanner({ offerBillingCycle, duration, cycles }: Props) {
  const offerDiscount = useOfferDiscount(offerBillingCycle, duration, cycles);
  const persistedActiveTime = useMemo(() => getPersistedActiveTime(), []);
  const { hours, minutes, seconds, isTimeUp } = useTimer(persistedActiveTime);
  usePersistedTimer(getMilliSeconds(hours, minutes, seconds));

  if (!offerDiscount || isTimeUp) return null;

  const intervalConfig: IntervalConfig[] = [
    { value: formatWithZeroPrefix(hours), label: hours > 1 ? "hrs" : "hr" },
    {
      value: formatWithZeroPrefix(minutes),
      label: minutes > 1 ? "mins" : "min",
    },
    {
      value: formatWithZeroPrefix(seconds),
      label: seconds > 1 ? "secs" : "sec",
    },
  ];

  return (
    <div className={styles.limitedTimeOffer}>
      <div className={styles.container}>
        <IntervalDisplay
          intervalConfig={intervalConfig}
          sibling={<div className={styles.leftTitle}>Left!</div>}
        />
        <div className={styles.title}>
          <span className={styles.optionalText}>Limited time offer! </span>
          <b>
            {offerDiscount}% off on all{" "}
            {BILLING_CYCLE_SELECTION_LABELS[offerBillingCycle]} plans.
          </b>
        </div>
      </div>
    </div>
  );
}
