import { useEffect } from "react";
import { FLOW_TYPE, REDIRECTION_OPTIONS } from "../../utils/const";
import { useNeoPropsForEntri } from "../../hooks/useNeoPropsForEntri";
import { useEntri } from "../../hooks/useEntri";
import { useEntriContext } from "../../store/entri/useEntriContext";
import { useNeoContext } from "../../store/neo/useNeoContext";
import { domainSetupRedirection } from "../../services/medusa/domainSetupRedirection";
import { purchaseCompletedRedirection } from "../../services/medusa/purchaseCompletedRedirection";
import ENBridge from "../../bridge";

export const useSetupDns = () => {
  const { state: entriState } = useEntriContext();
  const { state: neoState } = useNeoContext();
  const entriProps = useNeoPropsForEntri();
  const { showEntri } = useEntri(entriProps);
  const { provider, entriToken } = entriState;
  const { flowType, showTeamMailboxPostDomainPurchase } = neoState;
  const isPurchaseFlowType = flowType === FLOW_TYPE.PURCHASE;
  const isSiteSetupMode = ENBridge.handlers.isSiteSetupMode();
  const onCpClickMedusaEventsToDispatch = async () => {
    return Promise.all([
      isPurchaseFlowType
        ? purchaseCompletedRedirection(REDIRECTION_OPTIONS.ENTRI_SETUP, entriState, neoState)
        : Promise.resolve(),
      domainSetupRedirection(REDIRECTION_OPTIONS.ENTRI_SETUP, entriState, neoState)
    ]);
  }

  useEffect(() => {
    // auto launch entri modal
    // if user is coming via auto login flow
    // and entri token is fetched
    if (!isPurchaseFlowType && entriToken) {
      domainSetupRedirection(REDIRECTION_OPTIONS.ENTRI_SETUP, entriState, neoState);
      showEntri();
    }
  }, [isPurchaseFlowType, entriToken]);

  return {
    provider,
    isSiteSetupMode,
    isPurchaseFlowType,
    onCpClickMedusaEventsToDispatch,
    showTeamMailboxPostDomainPurchase
  };
}