import React from "react";
import { useSelector } from "react-redux";
import SuccessTick from "../../assets/successTick.gif";
import { ANALYTICS_EVENTS, WINDOW_NAME } from "../../helpers/constants";
import { showSuccessToast } from "../../helpers/toastHelper";
import {
  getCopyToClipboard,
  getMailClientUrl,
  getWebmailUrl,
  isMobile,
  redirectToNeoAdminPanel,
} from "../../helpers/utils";
import { userSelector } from "../../store/selectors";
import { logPurchaseCompletedEvent } from "../../telemetry/medusaEventsFunctions";
import Button from "../Common/Button/Button";
import MobileStoreIcons from "../MobileStoreIcons/MobileStoreIcons";
import styles from "./AllDone.module.scss";
import {
  isMailDomainCustom,
  selectMailAdditionalMailbox,
  selectMailAdminMailbox,
} from "../../store/slices/mail.slice";

const DOMAIN_VERIFICATION_PATH = "domain-verification";
const WEBMAIL_URL = getWebmailUrl();
const WEBMAIL_URL_WITHOUT_PROTOCOL = WEBMAIL_URL.split("/")?.[2];

const { PURCHASE_COMPLETED_REDIRECTION } = ANALYTICS_EVENTS;

const REDIRECTION_KEYS = {
  WEBMAIL: "Webmail",
  ADMIN_PANEL: "Neo Admin Panel",
};
const { WEBMAIL, ADMIN_PANEL } = REDIRECTION_KEYS;
const { FLOCK_ADMIN, FLOCK_MAIL } = WINDOW_NAME;

function CoSiteDomain() {
  const user = useSelector(userSelector);
  const {
    webmailAutoLoginToken,
    controlPanelAutoLoginToken,
    billingInformation: { country },
    alternateEmail,
  } = user;
  // TODO: for site
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);
  // TODO: for site
  const adminMailbox = useSelector(selectMailAdminMailbox);

  const { email } = adminMailbox;
  // TODO: for site
  const customDomain = useSelector(isMailDomainCustom);

  const handleRedirection = (redirectionKey) => {
    /**
     Safari on iOS blocks any window.open() call that is made inside an async fn
     So we call window.open() before and set the URL once the promise resolves
     **/
    const target = redirectionKey === WEBMAIL ? FLOCK_MAIL : FLOCK_ADMIN;
    const windowRef = window.open("", target);
    logPurchaseCompletedEvent(PURCHASE_COMPLETED_REDIRECTION, {
      country,
      redirection_option_selected: redirectionKey,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(`${PURCHASE_COMPLETED_REDIRECTION} Event failed.`);
        }
      })
      .finally(() => {
        switch (redirectionKey) {
          case WEBMAIL:
            windowRef.location = getMailClientUrl(email, webmailAutoLoginToken);
            console.log("navigated to webmail");
            break;

          case ADMIN_PANEL:
            const queryObject = {
              section: customDomain ? DOMAIN_VERIFICATION_PATH : "",
            };
            windowRef.location = redirectToNeoAdminPanel(
              controlPanelAutoLoginToken,
              alternateEmail,
              queryObject
            );
            console.log("navigated to Admin Panel");
            break;

          default:
            break;
        }
      });
  };

  const showCopiedToast = () => {
    showSuccessToast("Copied successfully!", { toastId: "copied" });
  };

  const copyWebmailUrl = () => {
    getCopyToClipboard(WEBMAIL_URL);
    showCopiedToast();
  };

  return (
    <>
      {isMobile() ? (
        <div className={styles.coSiteDomainWrapperMobile}>
          <div className={styles.congrats}>Congratulations!</div>
          <div className={styles.coSiteSetup}>
            <p>Your mailbox is now ready for you!</p>
            <p>{email}</p>
          </div>
          <MobileStoreIcons />
          <p className={styles.strong}>Access emails on desktop/laptop on</p>
          <div className={styles.neoSpaceUrl}>
            <p>{WEBMAIL_URL_WITHOUT_PROTOCOL}</p>
            <div onClick={copyWebmailUrl}>Copy</div>
          </div>
        </div>
      ) : (
        <div className={styles.coSiteDomainWrapperDesktop}>
          <img src={SuccessTick} alt="SuccessIcon" />
          <div className={styles.congrats}>Congratulations!</div>
          <div className={styles.coSiteSetup}>
            <p>{email}</p>
            <p>Your mailbox is now ready for you!</p>
          </div>
          <div className={styles.buttonsWrapper}>
            <Button
              type={"primary"}
              onClick={() => handleRedirection(WEBMAIL)}
              width={"430px"}
            >
              Access webmail
            </Button>
            <Button
              type={"primary"}
              onClick={() => handleRedirection(ADMIN_PANEL)}
              className={styles.addMailBoxBtn}
              width={"430px"}
            >
              {additionalMailboxes.length > 0
                ? "Share login info with your team members"
                : "Add mailbox for other team members"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default CoSiteDomain;
