import ENBridge from "../bridge";
import HTTP from "./http";

// http instance for lambda calls
let _lambda = null;
export const lambda = () => {
  if (!_lambda) {
    const lambdaEndPoint = ENBridge.handlers.getLambdaEndPoint();
    _lambda = new HTTP(lambdaEndPoint);
  }

  return _lambda;
}
