import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  CardNumberElementProps,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import ENBridge from "../../3rdPartyIntegrations/Entri/bridge";
import {
  ANALYTICS_EVENTS,
  PAYMENT_ERROR_IGNORE,
} from "../../helpers/constants";
import { logPromoFailedErrorFromErrorResponse } from "../../helpers/promo.helper";
import { showErrorToast } from "../../helpers/toastHelper";
import { getSavedCardDetails, initAddCard } from "../../services/apiFunctions";
import { userSelector } from "../../store/selectors";
import { logBillingPageEvent } from "../../telemetry/medusaEventsFunctions";
import Button from "../Common/Button/Button";
import OrderProcessingModal from "../OrderModal/OrderProcessingModal";
import WhyTrialTooltip from "../WhyTrialTooltip/WhyTrialTooltip";
import {
  CARD_BACKGROUND_POSITIONS,
  COMMON_CARD_ELEMENT_OPTIONS,
} from "./CardStyleConstant";
import cardStyles from "./StripeCardPayment.module.scss";
import { TLogEventProps } from "../../types/global";

const NEO_SIGNUP_FLOW = "Neo signup flow";
const PLEASE_ENTER_VALID_CARD = "Please enter valid card details";
const {
  CUSTOMER_PAYMENT_COMPLETED,
  CUSTOMER_PAYMENT_FAILED,
  CUSTOMER_PAYMENT_CARD_SUBMITTED,
  CUSTOMER_PAYMENT_CARD_VERIFIED,
  CUSTOMER_PAYMENT_CARD_VERIFICATION_FAILED,
} = ANALYTICS_EVENTS;
const {
  handlers: { checkDomain },
  const: { setupType: entriSetupType },
} = ENBridge;

interface StripeCardPaymentProps {
  cardRef: React.RefObject<HTMLElement>;
  shouldStartPayment?: () => Promise<void>;
  initPaymentAPI: (payload: any) => Promise<any>;
  initPaymentPayload: any;
  completePurchaseAPI: (payload: any) => Promise<any>;
  completePurchasePayload: any;
  onPaymentSuccess: (data: any) => void;
  buttonText?: React.ReactNode;
  secondaryButtonText?: string | false;
  handleSecondaryBtn?: () => void;
  fromCheckoutModal?: boolean;
  isTrial: boolean;
  canCheckEntriFlow?: boolean;
  domain: string;
  onApiFailure: (_: {
    payload: Record<string, any> | null;
    response: any;
    source: string;
  }) => void;
  onFailure: (reason: string) => void;
  loadingSubTitle?: React.ReactNode;
  logEvent: (_: TLogEventProps) => void;
  cancelOrPauseNote?: React.ReactNode;
}

const StripeCardPayment: React.FC<StripeCardPaymentProps> = (props) => {
  const {
    shouldStartPayment = false,
    initPaymentAPI,
    initPaymentPayload,
    completePurchaseAPI,
    completePurchasePayload,
    onPaymentSuccess,
    buttonText,
    secondaryButtonText = false,
    handleSecondaryBtn = () => {},
    fromCheckoutModal = false,
    domain,
    isTrial,
    canCheckEntriFlow = false,
    onApiFailure,
    onFailure,
    loadingSubTitle,
    logEvent,
    cancelOrPauseNote,
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  const {
    token,
    alternateEmail,
    billingInformation: { country },
  } = useSelector(userSelector);
  // TODO: for site
  const [showSavedCard, setShowSavedCard] = useState(false);
  const [savedCardDetails, setSavedCardDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { ccType = "unknown", lastFourDigits } = savedCardDetails || {};
  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const [cardValidationError, setCardValidationError] = useState<
    string | false
  >(false);

  useEffect(() => {
    setLoading(true);
    getSavedCardDetails({ token })
      .then((res) => {
        const response = res.data;
        if (response.statusCode === 400) {
          setShowSavedCard(false);
        } else if (response.statusCode === 200) {
          setShowSavedCard(true);
          setSavedCardDetails(response);
        } else {
          console.error("Error fetching saved cards", response);
          showErrorToast("Error fetching saved cards");
        }
      })
      .catch((error) => {
        console.error("Error fetching saved cards", error);
        showErrorToast("Error fetching saved cards");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // TODO: fix this
  // useEffect(() => {
  //   if (cardRef && cardRef.current) {
  //     cardRef.current.style.setProperty(
  //       "--height",
  //       `${cardRef.current.scrollHeight}px`
  //     );
  //   }
  // }, [showSavedCard, cardValidationError]);

  const checkAutomaticDnsSetup = async () => {
    try {
      if (!canCheckEntriFlow) return Promise.resolve(null);
      return await checkDomain(domain);
    } catch (e) {
      return Promise.resolve({ setupType: entriSetupType.MANUAL });
    }
  };

  const hasValidationErrors = () => {
    // TODO: remove any type casting
    const cardNumberElement = elements?.getElement(CardNumberElement) as any;
    const cardCvcElement = elements?.getElement(CardCvcElement) as any;
    const cardExpiryElement = elements?.getElement(CardExpiryElement) as any;

    const cardNumberInvalid =
      cardNumberElement?._empty || cardNumberElement?._invalid;
    const cardCvcInvalid = cardCvcElement?._empty || cardCvcElement?._invalid;
    const cardExpiryInvalid =
      cardExpiryElement?._empty || cardExpiryElement?._invalid;

    if (cardNumberInvalid || cardCvcInvalid || cardExpiryInvalid) {
      setCardValidationError(PLEASE_ENTER_VALID_CARD);
      setShowButtonSpinner(false);
      return true;
    }

    return cardValidationError;
  };

  const handleErrorProcessPayment = (completePurchaseResponse: any) => {
    onFailure(completePurchaseResponse.desc);
    setShowButtonSpinner(false);
    setCardValidationError(completePurchaseResponse.desc);
    console.error(
      completePurchaseResponse.desc,
      "complete purchase, process payment error"
    );
    onApiFailure({
      payload: null,
      response: completePurchaseResponse,
      source: "completePurchaseAPI",
    });
  };

  const callCompletePurchase = (
    initPurchaseResponse: any,
    paymentData: any
  ) => {
    Promise.all([
      completePurchaseAPI({
        ...completePurchasePayload,
        trackingId: initPurchaseResponse.trackingId,
      }),
      checkAutomaticDnsSetup(),
    ])
      .then(([response, entriResponse]) => {
        const completePurchaseResponse = response.data;
        const { statusCode } = completePurchaseResponse;
        if (fromCheckoutModal && response?.status === 200) {
          onPaymentSuccess({ paymentData });
        } else if (statusCode === 200) {
          onPaymentSuccess({
            purchaseResponse: completePurchaseResponse,
            entriResponse,
            paymentData,
          });
        } else {
          handleErrorProcessPayment(completePurchaseResponse);
        }
      })
      .catch((error) => {
        console.error(error);
        showErrorToast("Error processing payment, please try again");
        setShowButtonSpinner(false);
        onApiFailure({
          payload: {
            ...completePurchasePayload,
            trackingId: initPurchaseResponse.trackingId,
          },
          response: error.toString(),
          source: "completePurchaseAPI",
        });
      });
  };

  const stripeConfirmCardPayment = (
    initPurchaseResponse: any,
    payment_method: any,
    paymentCardType?: string
  ) => {
    return stripe
      ?.confirmCardPayment(initPurchaseResponse.stripeSecret, {
        payment_method: payment_method || savedCardDetails?.paymentMethodId,
      })
      .then((result) => {
        if (result?.error) {
          const {
            error: { message, decline_code, payment_intent },
          } = result;
          const errorMessage = `${message} Decline Code: ${decline_code}`;
          logEvent({
            eventName: CUSTOMER_PAYMENT_FAILED,
            data: {
              country,
              error: errorMessage,
              payment_card_type: paymentCardType,
              payment_transaction_id: payment_intent?.id,
            },
          });
          setShowButtonSpinner(false);
          setCardValidationError(
            result?.error?.message || result?.error?.code || false
          );
          console.error(result.error, "confirm card payment error");
          if (
            result?.error?.code &&
            !PAYMENT_ERROR_IGNORE.includes(result?.error?.code)
          ) {
            onApiFailure({
              payload: {
                payment_method:
                  payment_method || savedCardDetails?.paymentMethodId,
              },
              response: result,
              source: "stripe confirmCardPayment",
            });
          }
        } else {
          const { paymentIntent } = result;
          logEvent({
            eventName: CUSTOMER_PAYMENT_COMPLETED,
            data: {
              country,
              payment_card_type: paymentCardType,
              payment_transaction_id: paymentIntent.id,
              // ...(fromCheckoutModal && {
              //   payment_for: "Additional mailboxes",
              // }),
            },
          });
          callCompletePurchase(initPurchaseResponse, {
            paymentCardType,
            transactionId: paymentIntent.id,
          });
        }
      });
  };

  const handleConfirmPayment = (
    initPurchaseResponse: any,
    payment_method: any,
    paymentCardType?: string
  ) => {
    if (isTrial) {
      return callCompletePurchase(initPurchaseResponse, {
        paymentCardType,
      });
    }

    return stripeConfirmCardPayment(
      initPurchaseResponse,
      payment_method,
      paymentCardType
    );
  };

  const initPayment = (payment_method?: any, paymentCardType?: string) => {
    initPaymentAPI({
      ...initPaymentPayload,
    })
      .then((response) => {
        const initPurchaseResponse = response.data;
        if (response.status === 200) {
          handleConfirmPayment(
            initPurchaseResponse,
            payment_method,
            paymentCardType
          );
        } else {
          setShowButtonSpinner(false);
          setCardValidationError(initPurchaseResponse.desc);
          if (initPaymentPayload?.promoCodeId)
            logPromoFailedErrorFromErrorResponse(
              initPurchaseResponse,
              initPaymentPayload?.promoCodeId,
              {
                country,
                error: initPurchaseResponse.desc,
                payment_card_type: paymentCardType,
              }
            );
          console.error(initPurchaseResponse.desc, "initiate purchase error");
          if (!PAYMENT_ERROR_IGNORE.includes(initPurchaseResponse.code)) {
            onApiFailure({
              payload: initPaymentPayload,
              response: initPurchaseResponse,
              source: "initPaymentAPI",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        showErrorToast("Error initiating payment, please try again");
        setShowButtonSpinner(false);
        onApiFailure({
          payload: initPaymentPayload,
          response: error.toString(),
          source: "initPaymentAPI",
        });
      });
  };

  const validateCardAndInitiatePayment = () => {
    if (!showSavedCard) {
      if (hasValidationErrors()) return false;

      const cardNumberElement = elements?.getElement(CardNumberElement);

      if (!stripe || !cardNumberElement) {
        const errorMessage =
          "We're currently experiencing issues with our payment service. Please try again later.";

        setCardValidationError(errorMessage);
        onApiFailure({
          payload: null,
          response: errorMessage,
          source: "Stripe or cardNumberElement not available",
        });

        return; // Prevent further execution if stripe or cardNumberElement isn't ready
      }
      setShowButtonSpinner(true);

      logEvent({
        eventName: CUSTOMER_PAYMENT_CARD_SUBMITTED,
        data: {
          country,
          source_hook: NEO_SIGNUP_FLOW,
        },
      });

      stripe
        .createToken(cardNumberElement)
        .then((result) => {
          if (result.error) {
            setShowButtonSpinner(false);
            setCardValidationError(
              result.error.message ||
                "An unexpected error occurred while processing your payment. please try again"
            );
            return;
          }

          const {
            token: { card },
          } = result;

          initAddCard({
            token,
          }).then((resp) => {
            const response = resp.data;
            if (response.statusCode === 200) {
              stripe
                .confirmCardSetup(response.stripeSecret, {
                  payment_method: {
                    card: elements?.getElement(CardNumberElement) as any,
                    billing_details: { email: alternateEmail },
                  },
                })
                .then((result) => {
                  if (result.error) {
                    const { message, decline_code } = result.error;
                    const errorMessage = `${message} Decline Code : ${decline_code}`;
                    logEvent({
                      eventName: CUSTOMER_PAYMENT_CARD_VERIFICATION_FAILED,
                      data: {
                        country,
                        error: errorMessage,
                        payment_card_type: card?.funding,
                        source_hook: NEO_SIGNUP_FLOW,
                      },
                    });
                    setShowButtonSpinner(false);
                    setCardValidationError(
                      result.error.message || result.error.code || false
                    );
                    console.error(result.error, "confirm card setup error");
                    if (
                      result?.error?.code &&
                      !PAYMENT_ERROR_IGNORE.includes(result.error.code)
                    ) {
                      onApiFailure({
                        payload: {
                          payment_method: {
                            billing_details: { email: alternateEmail },
                          },
                        },
                        response: result,
                        source: "stripe confirmCardSetup",
                      });
                    }
                  } else {
                    initPayment(
                      result.setupIntent.payment_method,
                      card?.funding
                    );
                    logEvent({
                      eventName: CUSTOMER_PAYMENT_CARD_VERIFIED,
                      data: {
                        payment_card_type: card?.funding,
                        country,
                        source_hook: NEO_SIGNUP_FLOW,
                      },
                    });
                  }
                });
            } else {
              setShowButtonSpinner(false);
              setCardValidationError(response.desc);
              console.error(response.desc, "init add card error");
              onApiFailure({ payload: null, response, source: "initAddCard" });
            }
          });
        })
        .catch((error) => {
          console.error(error);
          setShowButtonSpinner(false);
          setCardValidationError(
            "An unexpected error occurred while processing your payment. please try again"
          );
        });
    } else {
      setShowButtonSpinner(true);
      initPayment();
    }
  };

  const startProcess = () => {
    if (showButtonSpinner) {
      return false;
    }

    if (shouldStartPayment) {
      shouldStartPayment().then(() => validateCardAndInitiatePayment());
    } else {
      validateCardAndInitiatePayment();
    }
  };

  const handleUseDifferentCard = () => {
    setCardValidationError(false);
    setShowButtonSpinner(false);
    setShowSavedCard(false);
  };

  const handleUseSavedCard = () => {
    setCardValidationError(false);
    setShowButtonSpinner(false);
    setShowSavedCard(true);
  };

  return (
    <>
      {loading ? (
        <div className={classNames("spinner", cardStyles.spinnerSize)} />
      ) : (
        <>
          {showSavedCard ? (
            <div className={cardStyles.savedCardSection}>
              <div className={cardStyles.savedCardWrapper} data-no={ccType}>
                <div
                  className={cardStyles.logo}
                  style={{
                    backgroundPosition:
                      CARD_BACKGROUND_POSITIONS[
                        ccType as keyof typeof CARD_BACKGROUND_POSITIONS
                      ] || CARD_BACKGROUND_POSITIONS["fallback"],
                  }}
                />
                <p>Card ending with {lastFourDigits}</p>
              </div>
              <div
                className={cardStyles.updateCard}
                onClick={handleUseDifferentCard}
              >
                Use a different card
              </div>
            </div>
          ) : (
            <>
              {isTrial && <WhyTrialTooltip tooltipLocation={"cardDetails"} />}
              <div className={cardStyles.elementsWrapper}>
                <CardNumberElement
                  options={
                    {
                      ...COMMON_CARD_ELEMENT_OPTIONS,
                      placeholder: "Card number",
                    } as CardNumberElementProps["options"]
                  }
                  onChange={() => setCardValidationError(false)}
                  className={classNames(cardStyles.StripeElement, {
                    [cardStyles.hasError]: cardValidationError,
                  })}
                />
                <div className={cardStyles.elementsSection2}>
                  <CardExpiryElement
                    options={
                      {
                        ...COMMON_CARD_ELEMENT_OPTIONS,
                        placeholder: "Expiry date (MM/YY)",
                      } as CardNumberElementProps["options"]
                    }
                    onChange={() => setCardValidationError(false)}
                    className={classNames(cardStyles.StripeElement, {
                      [cardStyles.hasError]: cardValidationError,
                    })}
                  />
                  <CardCvcElement
                    options={
                      {
                        ...COMMON_CARD_ELEMENT_OPTIONS,
                        placeholder: "CVC",
                      } as CardNumberElementProps["options"]
                    }
                    onChange={() => setCardValidationError(false)}
                    className={classNames(cardStyles.StripeElement, {
                      [cardStyles.hasError]: cardValidationError,
                    })}
                  />
                </div>
              </div>
              {savedCardDetails && (
                <div
                  onClick={handleUseSavedCard}
                  className={cardStyles.useSavedCard}
                >
                  Use saved card ({lastFourDigits})
                </div>
              )}
            </>
          )}
          {cardValidationError && (
            <div className={cardStyles.cardErrorInfo}>
              {cardValidationError}
            </div>
          )}
          <div
            className={classNames(cardStyles.buttonWrapper, {
              [cardStyles.hasSecondaryBtn]: secondaryButtonText,
            })}
          >
            {secondaryButtonText && (
              <div onClick={() => handleSecondaryBtn()}>
                {secondaryButtonText}
              </div>
            )}
            <Button
              type={"primary"}
              isProcessing={showButtonSpinner}
              onClick={() => startProcess()}
            >
              {buttonText || "Pay now"}
            </Button>
          </div>
          {cancelOrPauseNote && <div>{cancelOrPauseNote}</div>}
        </>
      )}
      {showButtonSpinner && (
        <OrderProcessingModal loadingSubtitle={loadingSubTitle} />
      )}
    </>
  );
};

export default StripeCardPayment;
