// SetCJCookie.js
import { useEffect } from "react";
import { CJ_COOKIE_NAME } from "../../helpers/constants";
import { getConfig, getUrlParams } from "../../helpers/utils";
import { setCookie } from "../../helpers/cookie.helper";

const CJ_QUERY_PARAM = "cjevent";

const SetCJCookie = () => {
  const _setCJCookie = async () => {
    const cjParam = getUrlParams()[CJ_QUERY_PARAM] || "";

    const { env } = getConfig();

    const baseUrl =
      env === "STAGING"
        ? "https://lambda-staging.neo.space"
        : "https://lambda.neo.space";

    if (cjParam !== "") {
      const url = `${baseUrl}/?entity=neo&env=${env}&functionName=setCJCookie`;
      const body = {
        cjEvent: cjParam,
        origin: window.location.origin,
      };

      fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          const expiryInDays = 396;
          setCookie(
            CJ_COOKIE_NAME,
            cjParam,
            expiryInDays,
            "samesite=none; secure"
          );
        })
        .catch((error) => console.log("Error:", error));
    }
  };

  // useEffect hook to execute code on component mount
  useEffect(() => {
    _setCJCookie();
  }, []);

  return null;
};

export default SetCJCookie;
