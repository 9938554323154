import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import Slider from "react-slick";
import DOMPurify, { sanitize } from "dompurify";
import MagicSliderDots from "react-magic-slider-dots";
import Image from "../../../Image/Image";
import { ANALYTICS_EVENTS } from "../../../../helpers/constants";
import { useMobile } from "../../../../hooks/useMobile";
import { TGroupedFeature, TPlan } from "../../../../types/plan.type";
import { TLogEventProps } from "../../../../types/global";
import s from "./style.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-magic-slider-dots/dist/magic-dots.css";

interface FeatureCardProps {
  feature: TGroupedFeature;
  visiblePlans: TPlan[];
}

const FeatureCard: React.FC<FeatureCardProps> = ({ feature, visiblePlans }) => {
  const { type, metaInfo: featureMetaInfo } = feature;

  return (
    <div key={type} className={s.modalContent}>
      {featureMetaInfo.illustrationUrl ? (
        <div className={s.illustration}>
          <Image
            src={featureMetaInfo.illustrationUrl}
            alt={featureMetaInfo.heading || type}
          />
        </div>
      ) : null}
      <div
        className={classNames([
          s.copyContent,
          !featureMetaInfo.illustrationUrl && s.centerAlignedCopy,
        ])}
      >
        <span>
          {visiblePlans.map((plan, index) => {
            const featureStatus = !!feature.plans[index];
            return (
              <span key={plan.id}>
                {plan.name}
                <i className={featureStatus ? s.checked : s.unchecked} />
              </span>
            );
          })}
        </span>
        <h3
          dangerouslySetInnerHTML={{
            __html: sanitize(feature.title || featureMetaInfo.heading || ""),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(featureMetaInfo.description || ""),
          }}
        />
        {featureMetaInfo.highlights?.length ? (
          <ul>
            {featureMetaInfo.highlights.map((f) => (
              <li
                key={f}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(f) }}
              />
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

interface SliderArrowProps {
  onClick?: () => void;
  invert?: boolean;
}

const SliderArrow: React.FC<SliderArrowProps> = ({ onClick, invert }) => {
  return (
    <div
      className={classNames([s.sliderArrow, invert ? s.sliderArrowInvert : ""])}
      onClick={onClick}
    />
  );
};

interface FeatureCardSliderProps {
  activeFeature: string;
  allFeatures: TGroupedFeature[];
  isFeatureCardActive: boolean;
  hideFeatureCard: () => void;
  visiblePlans: TPlan[];
  logEvent: (e: TLogEventProps) => void;
}

const FeatureCardSlider: React.FC<FeatureCardSliderProps> = ({
  activeFeature,
  allFeatures,
  isFeatureCardActive,
  hideFeatureCard,
  visiblePlans,
  logEvent,
}) => {
  const $sliderRef = useRef<Slider>(null);
  const { isMobileView } = useMobile();

  const filteredFeatures = allFeatures.filter((feature) => !!feature?.metaInfo);
  const initialActiveSlide = filteredFeatures.findIndex(
    (f) => f.type === activeFeature
  );

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialActiveSlide,
    ...(isMobileView
      ? {
          dots: true,
          arrows: false,
          appendDots: (dots: React.ReactNode) => (
            <div className={s.mobileSliderActions}>
              <span
                className={s.mobilePrev}
                onClick={() => $sliderRef.current?.slickPrev()}
              >
                Prev
              </span>
              <MagicSliderDots
                dotContainerClassName={classNames([
                  "magic-dots slick-dots",
                  s.dotContainer,
                ])}
                numDotsToShow={3}
                dots={dots}
                dotWidth={30}
              />
              <span
                className={s.mobileNext}
                onClick={() => $sliderRef.current?.slickNext()}
              >
                Next
              </span>
            </div>
          ),
        }
      : {
          dots: false,
          arrows: true,
          nextArrow: <SliderArrow />,
          prevArrow: <SliderArrow invert />,
        }),
  };

  useEffect(() => {
    const onEscPress = (e: KeyboardEvent) => {
      if (e.keyCode === 27) _hideFeatureCard();
    };

    document.addEventListener("keydown", onEscPress);
    return () => document.removeEventListener("keydown", onEscPress);
  }, []);

  const sendMedusaEvt = (nextSlide?: number) => {
    logEvent({
      eventName: ANALYTICS_EVENTS.FEATURE_CARD_SEEN,
      data: {
        feature: nextSlide ? filteredFeatures[nextSlide].type : activeFeature,
        page_name: "Sign up flow",
        source_hook: nextSlide ? "Carousel slide change" : "Question icon",
      },
    });
  };

  const _hideFeatureCard = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    hideFeatureCard();
  };

  if (!isFeatureCardActive) return null;

  return (
    <div className="feature-card">
      <div className={s.modalWrapper} onClick={_hideFeatureCard}>
        <div className={s.modalContainer} onClick={(e) => e?.stopPropagation()}>
          <span className={s.crossButton} onClick={_hideFeatureCard} />
          <Slider
            className="feat-slider-container"
            ref={$sliderRef}
            onInit={() => sendMedusaEvt()}
            afterChange={sendMedusaEvt}
            {...sliderSettings}
          >
            {filteredFeatures.map((feature) => (
              <FeatureCard
                key={feature.type}
                feature={feature}
                visiblePlans={visiblePlans}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default FeatureCardSlider;
