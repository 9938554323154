import { useLogo } from "./useLogo"

import logoWithNameImg from "../../assets/images/logo-name.svg";
import s from './Logo.module.scss';

const NeoLogo = () => {
  const { logoUrl, onLogoClick } = useLogo();

  return (
    <a className={s.logo} href={logoUrl} onClick={onLogoClick}>
      <img src={logoWithNameImg} alt="neo-logo"/>
    </a>
  )
}

export default NeoLogo;
