import { TBillingCycle } from "../../types/billing-cycle";
import { TDomainPricing } from "../../types/domain.type";
import { TPlan } from "../../types/plan.type";
import { BILLING_CYCLE_KEYS, NUMBER_OF_MONTHS } from "../constants";
import { calculateDomainCharges } from "../domainPricingHelper";

const { MONTHLY, TWO_YEARLY, FOUR_YEARLY } = BILLING_CYCLE_KEYS;

interface ChargeInput {
  plan: TPlan;
  countOfMailboxes: number;
  isCustomDomain: boolean;
  isCoSiteTrial: boolean;
  domainPricing?: TDomainPricing;
  billingCycle: TBillingCycle;
  mailPlan: TPlan;
}

export const calculateCharge = ({
  plan,
  countOfMailboxes,
  isCustomDomain,
  isCoSiteTrial,
  domainPricing,
  billingCycle,
  mailPlan,
}: ChargeInput) => {
  const numberOfMonths = NUMBER_OF_MONTHS[billingCycle];

  const perSiteCharge =
    plan.pricing[billingCycle]?.firstBillingCyclePrice ??
    plan.pricing[billingCycle]?.price ??
    0;

  const siteCharge = perSiteCharge * numberOfMonths;

  const perMailBoxCharge =
    mailPlan.pricing?.[billingCycle]?.firstBillingCyclePrice ??
    mailPlan.pricing?.[billingCycle]?.price ??
    0;

  console.log("perMailBoxCharge", perMailBoxCharge, mailPlan);

  const mailboxCharges = perMailBoxCharge * numberOfMonths * countOfMailboxes;

  const totalDomainCharges = !isCustomDomain
    ? billingCycle === TWO_YEARLY
      ? Number(domainPricing?.yearly?.renewalChargePerYear)
      : billingCycle === FOUR_YEARLY
      ? Number(domainPricing?.yearly?.renewalChargePerYear) * 3
      : 0
    : 0;

  const totalChargeableAmount =
    siteCharge + mailboxCharges + Number(totalDomainCharges);

  const payableNow =
    isCustomDomain || isCoSiteTrial ? 0 : totalChargeableAmount;

  const calculateAmountSaved = () => {
    if (isCustomDomain || isCoSiteTrial) return 0;
    let savedAmount = 0;
    if (billingCycle !== MONTHLY) {
      const pricing = plan.pricing[billingCycle];
      if (!pricing) return 0;
      const { firstBillingCyclePrice, price } = pricing;
      savedAmount =
        Math.max((price - firstBillingCyclePrice) * numberOfMonths, 0) || 0;
    }
    return savedAmount;
  };

  const amountSaved = calculateAmountSaved();

  return {
    totalChargeableAmount,
    payableNow,
    amountSaved,
    perMailBoxCharge,
    mailboxCharges,
    siteCharge,
    perSiteCharge,
    domainCharges: calculateDomainCharges(billingCycle, domainPricing),
  };
};
