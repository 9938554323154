import { PRODUCT_TYPES } from "../constants/products.constants";
import { TProductTypes } from "../types/products.type";

/**
 * Determines the product type (e.g., 'site', 'mail') based on the current URL.
 * @param pathName - The current pathName as a string
 * @returns The product type ('site' or 'mail').
 */
export function getProductFromUrlPath(pathName: string): TProductTypes {
  // If the URL starts with '/site', it's a site product
  if (pathName.startsWith("/site")) {
    return PRODUCT_TYPES.SITE;
  }

  // Otherwise, assume it's 'mail' if no specific path is matched
  return PRODUCT_TYPES.MAIL;
}
