export const initialValues = {
  cpToken: null,
  dnsRecords: [], // these are entri compatible records
  rawDNSRecords: [], // these are what we get in api response
  flowType: null, // values mentioned under `FLOW_TYPE`
  userType: null, // values mentioned under `USER_TYPE`
  domain: null,
  customerId: null,
  accountId: null,
  orderId: null,
  customerEmail: null,
  name: null,
  email: null,
  billingInformation: null,
  partnerAlphaNumId: null,
  partnerId: null,
  neoOffering: null,
  plan: null,
  tokenExpiry: null,
  controlPanelAutoLoginToken: null,
  webmailAutoLoginToken: null,
  hasActiveOrders: null,
  showTeamMailboxPostDomainPurchase: null,
  cpTokenValidating: true, // since on load we going to fetch cpToken hence keeping true default
  dnsRecordsFetching: false,
  soid: null,
  bid: null,
};
