import { useCallback } from "react";

function useMergedRef<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return useCallback(
    (value: T) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(value);
        } else if (ref && typeof ref === "object") {
          (ref as React.MutableRefObject<T | null>).current = value;
        }
      });
    },
    []
  );
}

export default useMergedRef;
