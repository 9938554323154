import React from "react";
import { useSelector } from "react-redux";
import { DEFAULT_CURRENCY } from "../../../constants/currency";
import { userSelector } from "../../../store/selectors";
import Currency from "../Currency";

type Props = {
  currency?: string;
  value: number | string;
};

export default function Price({ currency: propCurrency, value }: Props) {
  const { currency: userCurrency } = useSelector(userSelector) || {};
  const currency = propCurrency || userCurrency || DEFAULT_CURRENCY;
  return (
    <>
      <Currency currency={currency}/>
      {value}
    </>
  );
}
