import React from "react";
import { Tooltip } from "antd";

interface CustomTooltipProps {
  title: React.ReactNode;
  children: React.ReactNode;
  overlayClassName?: string;
}

const AntdTooltip: React.FC<CustomTooltipProps> = ({
  title,
  children,
  overlayClassName,
}) => {
  return (
    <Tooltip
      title={title}
      overlayClassName={overlayClassName}
    >
      {children}
    </Tooltip>
  );
};

export default AntdTooltip;
