export const PLAN_TYPES = {
  LITE: "Lite",
  STARTER: "Starter",
  STANDARD: "Standard",
  MAX: "Max",
  DOMAIN: "Domain",
};

export const PLAN_DISPLAY_NAMES = {
  LITE: "lite",
  STARTER: "pro",
  STANDARD: "premium",
  MAX: "ultra",
  DOMAIN: "domain",
};

export const MAIL_PLAN_FEATURE_MAP = {
  STORAGE: "storage",
  WEB_MAIL: "web_mail",
  MOBILE_APPS: "mobile_apps",
  CALENDAR: "calendar",
  CONTACT_GROUPS: "contact_groups",
  READ_RECEIPTS: "read_receipts",
  EMAIL_TEMPLATES: "email_templates",
  UNDO_SEND: "undo_send",
  SCHEDULE_SEND: "schedule_send",
  EMAIL_REMINDERS: "email_reminders",
  BLOCK_SENDERS: "block_senders",
  PRIORITY_INBOX: "priority_inbox",
  CUSTOM_HTML: "custom_html",
  TURBO_SEARCH: "turbo_search",
  MULTI_DEVICE_SUPPORT: "multi_device_support",
  SEND_AS: "send_as",
  MULTIPLE_EXTERNAL_FORWARDER: "multiple_external_forwarder",
  ADVANCE_ANTIVIRUS: "advance_antivirus",
  ADVANCED_ANTI_SPAM: "advanced_anti_spam",
  TWO_FACTOR_AUTH: "two_factor_auth",
  UNLIMITED_MAIL_FILTERS: "unlimited_mail_filters",
  VIDEO_CALLING: "video_calling",
  NEO_COMPANY_BUSINESS_DOMAIN: "neo_company_business_domain",
  NEO_ONE_PAGE_SITE: "neo_one_page_site",
  MAIL_SEND: "mail_send",
  ALLOW_SENDERS: "allow_senders",
  RULES_FORWARDING: "rules_forwarding",
  TITAN_AI: "titan_ai",
  SIGNATURE_BUILDER: "signature_builder",
  EMAIL_MARKETING: "email_marketing",
  ADVANCED_TRACKING: "advanced_tracking",
  EMAIL_LABELS: "email_labels",

  // Neo Features
  NEO_DOMAIN: "neo_domain",
  CUSTOM_DOMAIN: "custom_domain",
  NEO_SITE: "neo_site",
  APPOINTMENT_BOOKING: "appointment_booking",
};

export const PLAN_TYPES_TO_BE_FILTERED = [PLAN_TYPES.DOMAIN];
