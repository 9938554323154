import { useEffect, useRef } from "react";
import { logger } from "../utils/helpers";
import { entri } from "../utils/entri";
import ENBridge from "../bridge";

/**
 * hook to handle entri actions and its state
 * { applicationId, token, dnsRecords } is required under config
 */
export const useEntri = ({ config = {} }) => {
  const initialEvents = {
    close: [],
    show: [],
    complete: [],
    manualSetupDocumentationClick: []
  };
  const hasEventAttached = useRef(false);
  const isEntriModalOpenRef = useRef(false);
  const entriEvents = useRef(initialEvents);
  const _onEntriManualSetupDocumentationClick = (e) => {
    entriEvents.current.manualSetupDocumentationClick.forEach(dce => dce(e));
  };
  const _onEntriClose = (e) => {
    isEntriModalOpenRef.current = false;
    const { detail } = e;

    // dispatch `onEntriSetupComplete` event
    if (detail.success) return entriEvents.current.complete.forEach(ce => ce(detail));

    // dispatch `onEntriClose` event
    entriEvents.current.close.forEach(ce => ce(detail));
  };

  const _onEntriShow = (e) => {
    const { detail } = e;

    // dispatch `onEntriClose` event
    entriEvents.current.show.forEach(ce => ce(detail));
  };

  useEffect(() => {
    // remove attached events on unmount
    return () => {
      window.removeEventListener('onEntriClose', _onEntriClose);
      window.removeEventListener('onEntriShow', _onEntriShow);
      window.removeEventListener(
        'onEntriManualSetupDocumentationClick',
        _onEntriManualSetupDocumentationClick
      );
    }
  }, []);

  const attachEvents = () => {
    // attached entri close event
    window.addEventListener('onEntriClose', _onEntriClose, false);

    // attached entri show event
    window.addEventListener('onEntriShow', _onEntriShow, false);

    // attached manual setup doc clicked event
    window.addEventListener(
      'onEntriManualSetupDocumentationClick',
      _onEntriManualSetupDocumentationClick,
      false
    );

    hasEventAttached.current = true;
  };

  // we could have use useEffect but then order of event attach and dispatch is getting impacted
  // spl for `onEntriShow` which we trigger on app load in auto login flow
  // event get fired before attached
  if (!hasEventAttached.current) {
    attachEvents();
  }

  /**
   * function to get the final version of entri config
   * @link https://developers.entri.com/docs/api-reference#entrishowentriconfig
   * @param { object } extendedConfig
   * @returns { object }
   */
  const getConfig = (extendedConfig = {}) => {
    const copy = ENBridge.handlers.getEntriModalCopy();
    const finalConfigToPass = {
      // mainly contain token, app, dnsRecords
      ...config,
      // if we want to extent the config
      ...extendedConfig,
      // default props
      supportForSubdomains: false,
      whiteLabel: {
        hideConfetti: true,
        hideEntriLogo: true,
        logoBackgroundColor: '#fff',
        removeShareLogin: true,
        theme: {
          fg: '#fff',
          bg: '#0066ff'
        },
        customCopy: {
          initialScreen: {
            title: { en:copy.initialScreen.title },
            subTitle: { en:copy.initialScreen.subTitle },
            easy: {
              title: { en: "Easy" }, // this required
              description: { en: copy.initialScreen.easy.description }
            }
          },
          manuallyScreen: {
            stepByStepGuide: { en:copy.manuallyScreen.stepByStepGuide }
          }
        }
      },
    };

    logger('showEntri config', config.prefilledDomain, finalConfigToPass);
    return finalConfigToPass;
  }

  // custom event fired when `showEntri` function is called
  // custom event is needed bcz events are common among all `useEntri` instances
  // e.g we trigger showEntri from EntriButton.js or SetupDns.js
  // and we listen the same event in useFlowManager.js to take some common actions
  const dispatchEntriShowEvent = (detail) => {
    window.dispatchEvent(new CustomEvent('onEntriShow', { detail }));
  }

  // handler for showEntri
  // making not more than entri modal is shown (entri doesn't have any such checks)
  const showEntri = config => {
    if (isEntriModalOpenRef.current) return true;

    entri.showEntri(getConfig(config));
    isEntriModalOpenRef.current = true;
    dispatchEntriShowEvent({ id: Date.now() }); // id might be useful
  };

  // handler for checkDomain
  const checkDomain = (domain, config) => entri.checkDomain(domain, getConfig(config));

  // event list handler `onEntriClose` event
  const onEntriClose = cb => {
    entriEvents.current.close.push(cb);
  };

  // event list handler custom `showEntri` event
  const onEntriShow = cb => {
    entriEvents.current.show.push(cb);
  };

  // event list handler for setup complete event
  // this is a custom event (entri doesn't have any such event)
  // on setup completion entri return {success: true} under onEntriClose event
  // and when this condition matches we fire this custom `onEntriSetupComplete` event
  const onEntriSetupComplete = cb => {
    entriEvents.current.complete.push(cb);
  };

  // event list handler for setup doc click event
  const onEntriManualSetupDocumentationClick = cb => {
    entriEvents.current.manualSetupDocumentationClick.push(cb);
  };

  return {
    showEntri,
    checkDomain,
    onEntriClose,
    onEntriShow,
    onEntriSetupComplete,
    onEntriManualSetupDocumentationClick
  }
}