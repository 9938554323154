import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "../../Common/Button/Button";
import GenericTooltip from "../../Common/Tooltip/GenericTooltip";
import { userSelector } from "../../../store/selectors";
import { userActions } from "../../../store/actions";
import { TBillingCycle } from "../../../types/billing-cycle";
import { BILLING_CYCLE_KEYS } from "../../../helpers/constants";
import styles from "./style.module.scss";

export const LitePlanNotAvailableButton = () => {
  const user = useSelector(userSelector);
  const { plan } = user;

  const { updateUser } = bindActionCreators(userActions, useDispatch());
  const updateDuration = (duration: TBillingCycle) => {
    updateUser({
      ...user,
      plan: {
        ...plan,
        duration,
      },
    });
  };

  return (
    <>
      <GenericTooltip
        id="lite-plan-not-available"
        content={
          <>
            Lite plan is available only on{" "}
            <span
              onClick={() => updateDuration(BILLING_CYCLE_KEYS.YEARLY)}
              className={styles.highlight}
            >
              {" "}
              yearly{" "}
            </span>
            and{" "}
            <span
              onClick={() => updateDuration(BILLING_CYCLE_KEYS.TWO_YEARLY)}
              className={styles.highlight}
            >
              {" "}
              two yearly
            </span>{" "}
            billing cycles.
          </>
        }
      >
        <Button className={`${styles.disabledBtn}`}>
          <span>Yearly </span>
          <span>subscriptions only</span>
        </Button>
      </GenericTooltip>
    </>
  );
};
