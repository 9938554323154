import { FC } from "react";
import ENBridge from "../../bridge";
import { useNeoSite } from "../../hooks/useNeoSite";

type IProps = {
    label: React.ReactNode,
    onClick: () => void
}

const SiteButton: FC<IProps> = ({ label, onClick }) => {
    const { Button } = ENBridge.sharedComponents;
    const { openNeoSiteLink } = useNeoSite(onClick);

    return (
        <Button type="primary" onClick={openNeoSiteLink}>{label}</Button>
    )
}

export default SiteButton;
