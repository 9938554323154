import React from "react";
import styles from "../styles.module.scss";

const WhyCoSiteDomain = () => {
  return (
    <>
      <div className={styles.borderLine} />
      <div className={styles.justifyWhyCoSiteContent}>
        <div className={styles.title}>Why co.site domain?</div>
        <p>
          <strong>Instantly use</strong> your email.
        </p>
        <p>
          <strong>Zero setup</strong> unlike other domains.
        </p>
        <p>
          Includes <strong>AI-powered site builder</strong>.
        </p>
      </div>
    </>
  );
};

export default WhyCoSiteDomain;
