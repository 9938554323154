import ENBridge from "../bridge";
import HTTP from "./http";

// http instance for bll calls
let _bll = null;
export const bll = () => {
  if (!_bll) {
    const { getEnvProps } = ENBridge.handlers;
    const { BLL_BASE } = getEnvProps();
    _bll = new HTTP(BLL_BASE);
  }

  return _bll;
};