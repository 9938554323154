import { DEFAULT_CURRENCY } from "../constants/currency";
const DEFAULT_LOCALE = "en-US";

export class CurrencyFormat {
  constructor(currency = DEFAULT_CURRENCY, locale = DEFAULT_LOCALE, options = {}) {
    this.currency = currency;
    this.currencySymbol = "";
    this.formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
      trailingZeroDisplay: "stripIfInteger",
      ...options,
    });
  }

  format(amount) {
    return this.formatter.format(amount);
  }

  getSymbol() {
    if(!this.currencySymbol) {
      this.currencySymbol = this.formatter.formatToParts(0).find((part) => part.type === "currency")?.value || "";
    }
    return this.currencySymbol;
  }
}

// Singleton class to manage currency formatting. It caches the currency formatters for each currency. Supports locale change.
class CurrencyFormatter {
  constructor() {
    this.formatterCache = {};
    this.locale = DEFAULT_LOCALE;
  }

  getFormatter = (currency = DEFAULT_CURRENCY) => {
    if (!this.formatterCache[currency]) {
      this.formatterCache[currency] = new CurrencyFormat(currency, this.locale);
    }
    return this.formatterCache[currency];
  };

  format = (amount, currency = DEFAULT_CURRENCY, options) => {
    if (!options) {
      const currencyFormatter = this.getFormatter(currency);
      return currencyFormatter.format(amount);
    }
    const currencyFormatter = new CurrencyFormat(currency, this.locale, options);
    return currencyFormatter.format(amount);
  };

  getCurrencySymbol = (currency = DEFAULT_CURRENCY) => {
    const currencyFormatter = this.getFormatter(currency);
    return currencyFormatter.getSymbol();
  };

  updateLocale = (locale) => {
    this.locale = locale;
    this.formatterCache = {};
  }
}

export const currencyFormatter = new CurrencyFormatter();
