import { TBillingCycle } from "../types/billing-cycle";
import { TPlan } from "../types/plan.type";
import { BILLING_CYCLE_KEYS, NUMBER_OF_MONTHS } from "./constants";
const { QUARTERLY, MONTHLY, YEARLY, TWO_YEARLY, FOUR_YEARLY } =
  BILLING_CYCLE_KEYS;

export const getDiscountPercentageForBillingCycle = (
  plans: TPlan[],
  billingCycle: TBillingCycle
): number => {
  const plan = [...plans].reverse().find((p: TPlan) => p.pricing[billingCycle]);
  if (!plan) {
    return 0;
  }
  return plan.pricing[billingCycle]?.discountPercentage || 0;
};

export const getNextDiscountedBillingCycle = (
  supportedBillingCycles: TBillingCycle[],
  currentBillingCycle: TBillingCycle
): TBillingCycle | null => {
  const _supportedBillingCyclesInSortedOrder = [...supportedBillingCycles].sort(
    (a, b) => NUMBER_OF_MONTHS[a] - NUMBER_OF_MONTHS[b]
  );
  const currentIndex =
    _supportedBillingCyclesInSortedOrder.indexOf(currentBillingCycle);
  if (currentIndex === -1) {
    return null;
  }
  return _supportedBillingCyclesInSortedOrder[currentIndex + 1] || null;
};

export const getFreeDomainPeriod = (billingCycle: TBillingCycle): string => {
  switch (billingCycle) {
    case MONTHLY:
      return "month";
    case QUARTERLY:
      return "quarter";
    case TWO_YEARLY:
    case FOUR_YEARLY:
    case YEARLY:
      return "year";
    default:
      return "";
  }
};

export const haveToPayForDomainAfter = (
  billingCycle: TBillingCycle
): string => {
  switch (billingCycle) {
    case MONTHLY:
      return "first month";
    case QUARTERLY:
      return "first quarter";
    case YEARLY:
      return "first year";
    case TWO_YEARLY:
      return "second year";
    case FOUR_YEARLY:
      return "fourth year";
    default:
      return "";
  }
};

export const getNumberOfMonths = (duration: string): number => {
  return NUMBER_OF_MONTHS[duration as keyof typeof NUMBER_OF_MONTHS];
};

export const sortBillingCycles = (billingCycles: TBillingCycle[]) => {
  if (!billingCycles) {
    return billingCycles;
  }
  return billingCycles.sort((a, b) => {
    const a1 = NUMBER_OF_MONTHS[a] || Number.MAX_SAFE_INTEGER;
    const a2 = NUMBER_OF_MONTHS[b] || Number.MAX_SAFE_INTEGER;
    return a1 - a2;
  });
};
