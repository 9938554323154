import { TBillingCycle } from "../types/billing-cycle";
import { TDomainPricing } from "../types/domain.type";
import { getNumberOfMonths } from "./billing-cycle.helper";
import { BILLING_CYCLE_KEYS, NUMBER_OF_MONTHS } from "./constants";
import { getRoundedDecimal } from "./utils";

const { MONTHLY, QUARTERLY, YEARLY, TWO_YEARLY, FOUR_YEARLY } =
  BILLING_CYCLE_KEYS;

export const processDomainPricing = (domainPricing: any) => {
  if (domainPricing.yearly) {
    domainPricing.yearly.renewalCharge = getRoundedDecimal(
      domainPricing.yearly.renewalCharge,
      2,
      "string"
    );
    domainPricing.yearly.renewalChargePerYear = getRoundedDecimal(
      domainPricing.yearly.renewalCharge * 12,
      2,
      "string"
    );
  }
  return domainPricing;
};

export const getDomainCharges = (
  duration: TBillingCycle,
  { monthly, yearly }: TDomainPricing
) => {
  if (duration === MONTHLY) {
    return {
      renewalCharge: getRoundedDecimal(monthly?.renewalCharge, 2, "string"),
      period: "mo",
    };
  } else if (duration === QUARTERLY) {
    return {
      renewalCharge: getRoundedDecimal(monthly?.renewalCharge, 2, "string"),
      period: "mo",
      additionalCharge: getRoundedDecimal(
        monthly?.renewalCharge * NUMBER_OF_MONTHS[QUARTERLY],
        2,
        "string"
      ),
      additionalPeriod: "quarter",
    };
  } else if (duration === YEARLY) {
    return {
      renewalCharge: yearly?.renewalCharge,
      period: "mo",
      additionalCharge: yearly?.renewalChargePerYear,
      additionalPeriod: "year",
    };
  } else if (duration === TWO_YEARLY) {
    return {
      renewalCharge: yearly?.renewalCharge,
      period: "mo",
      additionalCharge: getRoundedDecimal(
        yearly?.renewalChargePerYear * 2,
        2,
        "string"
      ),
      additionalPeriod: "two years",
    };
  } else {
    return {
      renewalCharge: yearly?.renewalCharge,
      period: "mo",
      additionalCharge: getRoundedDecimal(
        yearly?.renewalChargePerYear * 4,
        2,
        "string"
      ),
      additionalPeriod: "four years",
    };
  }
};

export const calculateDomainCharges = (
  duration: TBillingCycle,
  domainPricing?: TDomainPricing
): number => {
  if (!domainPricing) return 0;
  const noOfMonths = getNumberOfMonths(duration);
  const isDomainFreeOfCharge = noOfMonths <= NUMBER_OF_MONTHS.yearly;
  const { yearly } = domainPricing;
  return isDomainFreeOfCharge
    ? 0
    : yearly.renewalCharge *
        NUMBER_OF_MONTHS.yearly *
        ((noOfMonths - NUMBER_OF_MONTHS.yearly) / 12);
};
