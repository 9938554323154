import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  ANALYTICS_EVENTS,
  PAGE_PATHS,
  WINDOW_NAME,
} from "../../helpers/constants";
import { showErrorToast } from "../../helpers/toastHelper";
import { isMobile, redirectToNeoAdminPanel } from "../../helpers/utils";
import { appSelector, userSelector } from "../../store/selectors";
import { logPurchaseCompletedEvent } from "../../telemetry/medusaEventsFunctions";
import Button from "../Common/Button/Button";
import styles from "./AllDone.module.scss";
import {
  isMailDomainCustom,
  selectMailAdminMailbox,
} from "../../store/slices/mail.slice";

const DOMAIN_VERIFICATION_PATH = "domain-verification";
const ZENDESK_LOAD_ERROR =
  "Something went wrong while loading zendesk, Please try again after sometime and do refresh the page";

const { PURCHASE_COMPLETED_REDIRECTION } = ANALYTICS_EVENTS;

const REDIRECTION_KEYS = {
  NEED_HELP: "Need help",
  SETUP_MYSELF: "Setup myself",
};
const { NEED_HELP, SETUP_MYSELF } = REDIRECTION_KEYS;

const MEDUSA_EVENT_VALUE = {
  MOBILE: {
    ADMIN_PANEL_INFO: "Admin Panel Info",
  },
  DESKTOP: {
    SUPPORT_CHAT: "Support Chat",
    NEO_ADMIN_PANEL: "Neo Admin Panel",
  },
};

const {
  MOBILE: { ADMIN_PANEL_INFO },
  DESKTOP: { SUPPORT_CHAT, NEO_ADMIN_PANEL },
} = MEDUSA_EVENT_VALUE;

const { FLOCK_ADMIN } = WINDOW_NAME;

function CustomDomain() {
  const user = useSelector(userSelector);
  const {
    controlPanelAutoLoginToken,
    alternateEmail,
    showTeamMailboxPostDomainPurchase,
  } = user;
  // TODO: for site
  const adminMailbox = useSelector(selectMailAdminMailbox);

  const { email } = adminMailbox;

  // TODO: for site
  const customDomain = useSelector(isMailDomainCustom);
  const { search_params } = useSelector(appSelector);
  const navigateTo = useNavigate();

  const logPurchaseCompleteRedirectionEvent = (redirectionOption) => {
    return logPurchaseCompletedEvent(PURCHASE_COMPLETED_REDIRECTION, {
      redirection_option_selected: redirectionOption,
      auto_dns_setup_supported: false,
    });
  };

  const handleRedirection = (redirectionKey) => {
    switch (redirectionKey) {
      case NEED_HELP:
        if (window.zE) {
          window.zE("messenger", "open");
          console.info("Widget opened!");
        } else {
          showErrorToast(ZENDESK_LOAD_ERROR, {
            autoClose: 5000,
          });
          console.error("Zendesk loading error");
        }
        logPurchaseCompleteRedirectionEvent(SUPPORT_CHAT);
        break;

      case SETUP_MYSELF:
        if (isMobile()) {
          logPurchaseCompleteRedirectionEvent(ADMIN_PANEL_INFO);
          navigateTo({
            pathname: PAGE_PATHS.ADMIN_LOGIN_INFO,
            search: search_params,
          });
        } else {
          /**
           Safari on iOS blocks any window.open() call that is made inside an async fn
           So we call window.open() before and set the URL once the promise resolves
           **/
          const target = FLOCK_ADMIN;
          const windowRef = window.open("", target);
          logPurchaseCompleteRedirectionEvent(NEO_ADMIN_PANEL)
            .then((res) => {
              if (res.status !== 200) {
                console.error(
                  `${PURCHASE_COMPLETED_REDIRECTION} Event failed.`
                );
              }
            })
            .finally(() => {
              const queryObject = {
                section: customDomain ? DOMAIN_VERIFICATION_PATH : "",
              };
              windowRef.location = redirectToNeoAdminPanel(
                controlPanelAutoLoginToken,
                alternateEmail,
                queryObject
              );
              console.info("navigated to Admin Panel");
            });
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className={styles.customDomainWrapper}>
      {!showTeamMailboxPostDomainPurchase && (
        <>
          <div className={styles.thanksTitle}>
            Your order purchase is successful!
          </div>
          <hr className={styles.horizontalLine} />
        </>
      )}
      <div className={styles.setupEmail}>Now let's setup your email</div>
      <p className={styles.setupAnswer}>
        You'll need to add some{" "}
        <span className={styles.dnsRecord}>DNS records</span> on your domain
        provider's website to send and receive emails on your new{" "}
        <span className={styles.mailboxEmail}>{email}</span> email address
      </p>
      <div className={styles.buttonsWrapper}>
        <div>
          <Button
            type={"primary"}
            onClick={() => handleRedirection(NEED_HELP)}
            width={"100%"}
            className={styles.customButton}
          >
            Help me set it up
          </Button>
          <p className={styles.buttonInfo}>
            Our support team can chat or get on a call with you and get
            everything sorted out.
          </p>
        </div>
        <div>
          <Button
            type={"primary"}
            onClick={() => handleRedirection(SETUP_MYSELF)}
            className={classNames(styles.setupEmailBtn, styles.customButton)}
            width={"100%"}
          >
            I'll setup myself
          </Button>
          <p className={styles.buttonInfo}>
            We’ll provide instructions to add <strong>DNS records</strong>
          </p>
        </div>
      </div>
      <div className={styles.needHelpContainer}>
        <button
          className={styles.needHelpButton}
          onClick={() => {
            handleRedirection(NEED_HELP);
          }}
        >
          <div className={styles.greenDot}></div>
          Need help?
        </button>
      </div>
    </div>
  );
}

export default CustomDomain;
