import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appSelector } from "../../../store/selectors";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import GetStarted from "../index";
import { TDomainSelectionOptions, TSelectChangePayload } from "../Common/types";
import {
  logDomainEvent,
  logGetStartedViewedEvent,
} from "../../../telemetry/medusaEventsFunctions";
import { TLogEventProps } from "../../../types/global";
import {
  selectSiteDomain,
  setSiteDomain,
} from "../../../store/slices/site.slice";
import {
  getSiteBuilderEventData,
  getVisitorProfileForMedusa,
} from "../../../telemetry/site/siteMedusaData";

const { GET_STARTED_VIEWED, HAS_DOMAIN_CHECKED } = ANALYTICS_EVENTS;

const SiteGetStarted: React.FC = () => {
  const { search_params } = useSelector(appSelector);
  const { offering } = useSelector(selectSiteDomain);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const title = (
    <>
      To set up site, your business needs a domain.
    </>
  );
  const subTitle = (
    <>
      Like <strong>joesbusiness.com</strong> - a domain is essential for
      creating a personalised site.
    </>
  );

  const domainSelectionOptions: TDomainSelectionOptions = {
    haveDomain: "I already own a domain",
    needDomain: "I need a new domain name",
  };

  const navigateToHaveDomain = (): void => {
    navigateTo({
      pathname: PAGE_PATHS.SITE_HAVE_DOMAIN,
      search: search_params,
    });
  };

  const navigateToGetDomain = (): void => {
    navigateTo({
      pathname: PAGE_PATHS.SITE_GET_DOMAIN,
      search: search_params,
    });
  };

  const logEvent = ({ eventName, data, flag }: TLogEventProps) => {
    switch (eventName) {
      case GET_STARTED_VIEWED:
        logGetStartedViewedEvent(eventName, {
          ...data,
          ...getSiteBuilderEventData(),
          ...getVisitorProfileForMedusa(),
        });
        break;

      case HAS_DOMAIN_CHECKED:
        logDomainEvent(
          eventName,
          {
            ...data,
            ...getSiteBuilderEventData(),
            ...getVisitorProfileForMedusa(),
          },
          flag
        );
        break;

      default:
        console.log("Invalid event name");
    }
  };

  const onSelectChange = (payload: TSelectChangePayload) => {
    const { offering } = payload;
    dispatch(setSiteDomain({ offering }));
  };

  return (
    <GetStarted
      title={title}
      subTitle={subTitle}
      domainSelectionOptions={domainSelectionOptions}
      offering={offering}
      navigateToHaveDomain={navigateToHaveDomain}
      navigateToGetDomain={navigateToGetDomain}
      logEvent={logEvent}
      onSelectChange={onSelectChange}
    />
  );
};

export default SiteGetStarted;
