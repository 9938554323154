import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import Button from "../../../Common/Button/Button";
import { usePlansContext } from "../context";
import { TPlan } from "../../../../types/plan.type";
import styles from './style.module.scss';

// Define the props type for the PlanButton
interface PlanButtonProps {
  plan: TPlan;
  label?: ReactNode;
  subLabel?: ReactNode;
  className?: string;
}

const PlanButton: FC<PlanButtonProps> = ({
  plan,
  label,
  subLabel,
  className,
}) => {
  const { onSelectPlan, trialConfig } = usePlansContext();
  console.log('plan in PlanButton:', plan);
  const displayName = plan.name;

  const _label = label || `Get ${displayName}`;
  const _subLabel =
    subLabel ||
    (trialConfig.isTrial ? `${trialConfig.trialDurationInDays} days FREE` : "");
  return (
    <Button
      type="primary"
      onClick={() => onSelectPlan(plan)}
      className={classNames(styles.planBtn, className)}
    >
      <>
        {_label}
        {_subLabel && <div>{_subLabel}</div>}
      </>
    </Button>
  );
};

export default PlanButton;
