import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import BillingCard from "../shared/BillingCard";
import {
  sendToFlock,
  sendToFlockApiFailure,
} from "../../../helpers/webhookHelper";
import {
  completePurchase,
  initiatePurchase,
  logEntityChange,
} from "../../../services/apiFunctions";
import { userSelector } from "../../../store/selectors";
import { getCountOfTotalMailboxAdded } from "../../../helpers/emailHelpers";
import usePromo from "../../../hooks/mail/usePromo";
import { userActions } from "../../../store/actions";
import { useRedirection } from "../../../hooks/useRedirection";
import { getDomainStateBitSet } from "../../../helpers/bitSetHelper";
import {
  getCJCookieValue,
  getUtmParamsFromCookie,
  isMobile,
} from "../../../helpers/utils";
import {
  logAdditionalMailboxesCreated,
  logBillingPageEvent,
} from "../../../telemetry/medusaEventsFunctions";
import {
  ANALYTICS_EVENTS,
  BILLING_CYCLE_KEYS,
  GA_EVENTS,
  NEO_OFFERING_EVENT_VALUE,
  OFFERING,
} from "../../../helpers/constants";
import ENBridge from "../../../3rdPartyIntegrations/Entri/bridge";
import { resetPromoState } from "../../../helpers/promo.helper";
import MailboxPurchaseFailed from "../../OrderModal/MailboxPurchaseFailed";
import {
  isMailDomainCustom,
  selectMailAdditionalMailbox,
  selectMailAdminMailbox,
  selectMailDomain,
  selectMailDomainAttrs,
  selectMailPlan,
  selectMailPlanDetails,
  selectMailPurchaseDomain,
  setMailDomain,
} from "../../../store/slices/mail.slice";
import { TPlan } from "../../../types/plan.type";
import { ORDER_TYPES } from "../../../constants/products.constants";
import { TLogEventProps } from "../../../types/global";
import { selectSiteFreePlan } from "../../../store/slices/site.slice";
import { selectNeoDomainFreePlan } from "../../../store/slices/neo-domain-plans";
import { logGoogleAnalyticsEvent } from "../../../helpers/gaEventHelper";

const {
  CUSTOMER_BUNDLE_CREATED,
  PAYMENT_COMPLETED,
  NEO_MAILBOX_CREATION_FAILED,
  ADDITIONAL_MAILBOXES_CREATED,
} = ANALYTICS_EVENTS;

const { USER_CUSTOM_DOMAIN, CO_DOT_SITE } = NEO_OFFERING_EVENT_VALUE;

const {
  handlers: { logEntriDomainCheckedViaPurchase },
} = ENBridge;

interface Props {
  isVisible: boolean;
  onContinue: () => void;
}

// TODO: Optimize this component to avoid re-rendering
export default function MailBillingCard({ ...props }: Props) {
  const {
    token,
    billingInformation: { country, companyName },
  } = useSelector(userSelector);
  const dispatch = useDispatch();
  const { offering } = useSelector(selectMailDomain);
  const { duration } = useSelector(selectMailPlan);
  const plan = useSelector(selectMailPlanDetails) as TPlan;
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);
  const adminMailbox = useSelector(selectMailAdminMailbox);
  const domain = useSelector(selectMailPurchaseDomain);
  const isCustomDomain = useSelector(isMailDomainCustom);
  const freeSitePlan = useSelector(selectSiteFreePlan);
  const freeDomainPlan = useSelector(selectNeoDomainFreePlan);
  const { email, password } = adminMailbox;
  const { isDomainChargeable, isCoSiteTrial } = useSelector(
    selectMailDomainAttrs
  );
  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);
  const { promoCodeDetails } = usePromo();
  const promoDetails = promoCodeDetails?.id
    ? { promoCodeId: promoCodeDetails.id }
    : {};

  const { updateUser } = bindActionCreators(userActions, dispatch);
  const { handleCheckoutRedirection } = useRedirection();
  const [countOfMailboxPurchaseFailed, setCountOfMailboxPurchaseFailed] =
    useState<number>(0);

  const domainState = isCustomDomain
    ? {}
    : {
        domainState: getDomainStateBitSet({
          isDomainChargeable,
        }),
      };

  const onApiFailure = ({
    payload,
    response,
    source,
  }: {
    payload: Record<string, any> | null;
    response: any;
    source: string;
  }) => {
    // TODO: fix this
    sendToFlockApiFailure(
      isCoSiteTrial || isCustomDomain,
      payload,
      response,
      source
    );
  };

  const handleEntityChangeOnServer = (
    token: string,
    {
      bundleId,
      mailOrderId,
      siteOrderId,
    }: { bundleId: number; mailOrderId: number; siteOrderId: number }
  ) => {
    const payload1 = {
      type: "flock_order",
      id: mailOrderId.toString(),
      attrs: {
        neo_offering:
          offering === OFFERING.COSITE ? CO_DOT_SITE : USER_CUSTOM_DOMAIN,
        purchase_source: "Neo website",
        signup_device: isMobile() ? "mobile" : "desktop",
      },
    };

    const payload2 = {
      type: "site_order",
      id: siteOrderId.toString(),
      attrs: {
        neo_offering:
          offering === OFFERING.COSITE ? CO_DOT_SITE : USER_CUSTOM_DOMAIN,
        purchase_source: "Neo website",
        signup_device: isMobile() ? "mobile" : "desktop",
      },
    };

    const payload3 = {
      type: "bundle",
      id: bundleId.toString(),
      attrs: {
        ...getUtmParamsFromCookie(),
        company_name: companyName,
        signup_device: isMobile() ? "mobile" : "desktop",
        product_source: "Mail",
        neo_offering:
          offering === OFFERING.COSITE ? CO_DOT_SITE : USER_CUSTOM_DOMAIN,
      },
    };

    return Promise.all([
      logEntityChange(payload1, {}, { "X-Session-Token": token }),
      logEntityChange(payload2, {}, { "X-Session-Token": token }),
      logEntityChange(payload3, {}, { "X-Session-Token": token }),
    ]);
  };

  const onPaymentSuccess = ({
    purchaseResponse,
    entriResponse,
    paymentData,
  }: any) => {
    const {
      firstAccountId,
      bundleId,
      orderDetails,
      webmailAutoLoginToken,
      accountCreationFailures,
    } = purchaseResponse;

    const mailOrder = orderDetails?.find(
      (item: any) => item.type === ORDER_TYPES.MAIL_SUITE
    );

    const siteOrder = orderDetails?.find(
      (item: any) => item.type === ORDER_TYPES.SITE
    );

    const domainOrder = orderDetails?.find(
      (item: any) => item.type === ORDER_TYPES.NEO_DOMAIN
    );

    updateUser({
      webmailAutoLoginToken: webmailAutoLoginToken,
      order_id: mailOrder?.id,
      account_id: firstAccountId,
      bundleId: bundleId,
      accountCreationFailures: accountCreationFailures,
    });

    dispatch(setMailDomain({ purchasedDomain: domain }));
    // updateOrderUserPropertiesOnAmplitude(domainId);

    const cjCookieValue = getCJCookieValue();

    logBillingPageEvent(CUSTOMER_BUNDLE_CREATED, {
      ...(cjCookieValue ? { cjevent: cjCookieValue } : {}),
      country,
      bundle_id: bundleId,
      account_id: firstAccountId,
      order_count: 1,
      mail_order_id: mailOrder?.id,
      ...(domainOrder ? { neo_domain_order_id: domainOrder.id } : {}),
    });

    //TODO: verify this
    // if (!showTeamMailboxPostDomainPurchase) {
    logAdditionalMailboxesCreated(ADDITIONAL_MAILBOXES_CREATED);
    // }

    handleEntityChangeOnServer(webmailAutoLoginToken, {
      bundleId: bundleId,
      mailOrderId: mailOrder?.id,
      siteOrderId: siteOrder?.id,
    })
      .then(([res1, res2, res3]) => {
        if (res1.status !== 200) {
          console.error(
            `There was an error sending the first entity change request. Error Code: ${res1.status}.`
          );
        }
        if (res2.status !== 200) {
          console.error(
            `There was an error sending the second entity change request. Error Code: ${res2.status}.`
          );
        }
        if (res3.status !== 200) {
          console.error(
            `There was an error sending the third entity change request. Error Code: ${res3.status}.`
          );
        }
      })
      .finally(async () => {
        const commonEventData = {
          country,
          order_id: mailOrder?.id,
          user_type: "mailOrder",
          // linkToEntity: true,
        };
        // logBillingPageEvent(ORDER_CREATED, {
        //   ...commonEventData,
        //   account_id: firstAccountId,
        // });
        logBillingPageEvent(PAYMENT_COMPLETED, {
          ...commonEventData,
          ...(cjCookieValue ? { cjevent: cjCookieValue } : {}),
          payment_for: "Mail",
          payment_card_type: paymentData.paymentCardType,
          payment_transaction_id: paymentData.transactionId,
        });
        logGoogleAnalyticsEvent(GA_EVENTS.PV_PERSONA);
        sendToFlock(isCustomDomain);
        if (entriResponse) {
          logEntriDomainCheckedViaPurchase(entriResponse);
          updateUser({ entriResponse });
        }
        if (accountCreationFailures.length === 0) {
          handleCheckoutRedirection(entriResponse);
        } else {
          setCountOfMailboxPurchaseFailed(accountCreationFailures.length);
          logBillingPageEvent(NEO_MAILBOX_CREATION_FAILED, {
            ...commonEventData,
            payment_card_type: paymentData.paymentCardType,
            payment_transaction_id: paymentData.transactionId,
            mailbox_failed_count: accountCreationFailures.length,
          });
        }
        resetPromoState();
      });
  };

  const onFailure = (reason: string) => {
    // TODO: fix this
    sendToFlock(isCoSiteTrial || isCustomDomain, reason);
  };

  const logEvent = ({ eventName, data = {} }: TLogEventProps) => {
    logBillingPageEvent(eventName, data);
  };

  return (
    <>
      <BillingCard
        {...props}
        isTrial={isCoSiteTrial || isCustomDomain}
        domain={domain}
        onPaymentSuccess={onPaymentSuccess}
        initPaymentAPI={initiatePurchase}
        completePurchaseAPI={completePurchase}
        onApiFailure={onApiFailure}
        onFailure={onFailure}
        logEvent={logEvent}
        canCheckEntriFlow={isCustomDomain}
        completePurchasePayload={{
          token,
          firstEmailAccount: { email, password },
          startupEmails: additionalMailboxes.map((item: any) => item.email),
          ...(isMobile() && {
            source: "mobile_order",
          }),
        }}
        initPaymentPayload={{
          token,
          billingCycle: duration,
          domainToBeCreated: domain,
          orderItems: {
            [ORDER_TYPES.MAIL_SUITE]: {
              planType: plan.type,
              noOfAccounts: countOfMailboxAdded,
              ...promoDetails,
            },
            [ORDER_TYPES.SITE]: {
              planType: freeSitePlan.type || "site_free_new",
            },
            ...(!isCustomDomain && {
              [ORDER_TYPES.NEO_DOMAIN]: { planType: freeDomainPlan.type },
            }),
          },
        }}
        loadingSubTitle="Completing payment and creating your mailboxes..."
      />
      {!!countOfMailboxPurchaseFailed && (
        <MailboxPurchaseFailed
          countOfMailboxPurchaseFailed={countOfMailboxPurchaseFailed}
          countOfMailboxAdded={countOfMailboxAdded}
        />
      )}
      {/* {orderPurchaseSuccess && <OrderSuccessful />} */}
    </>
  );
}
