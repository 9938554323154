import { useNeoContext } from "../../store/neo/useNeoContext";

export const useCustomMobileSetup = ({ navigation }) => {
  const { state } = useNeoContext();
  const {
    controlPanelAutoLoginToken,
    customerEmail,
  } = state;

  const onBackClicked = () => navigation.closeCustomMobileSetup();

  return {
    controlPanelAutoLoginToken,
    customerEmail,
    onBackClicked,
  }
}