import ENBridge from "../../bridge";
import { ENTRI_SETUP_TYPE } from "../../utils/const";
import { sendMedusaEvent } from "../../utils/medusa";

export const entriDomainChecked = (entriState, neoState) => {
  if (!entriState) return null;

  const { setupType, provider } = entriState;
  const { getDnsSetupFor } = ENBridge.handlers;

  const eventName = `${getDnsSetupFor().toLowerCase()}_entri_domain_checked`;

  return sendMedusaEvent(eventName, neoState, {
    domain_supported: setupType === ENTRI_SETUP_TYPE.AUTOMATIC,
    domain_provider: provider,
  });
};
