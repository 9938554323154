import ENBridge from "../../../bridge";
import { initStateFromPurchaseFlow, setCPTokenValidating } from "../../../store/neo/actions";
import { errorLogger } from "../../../utils/error";

export const hydrateStateViaPurchaseFlow = (dispatch) => {
  dispatch(setCPTokenValidating(false))
  const purchaseFlowProps = ENBridge.handlers.getPurchaseFlowProps();

  if (!!purchaseFlowProps) {
    dispatch(initStateFromPurchaseFlow(purchaseFlowProps));
    return Promise.resolve();
  }

  return errorLogger.INSUFFICIENT_DETAILS();
}