export const COMMON_CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#333",
      fontFamily: '"Poppins", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#0006",
      },
      ":focus": {
        borderColor: "red",
      },
    },
    invalid: {
      color: "#f00",
      iconColor: "#f00",
    },
  },
};

export const CARD_BACKGROUND_POSITIONS = {
  mastercard: "0 -5px",
  fallback: "0 42px",
  amex: "0 88px",
  visa: "0 135px",
  discover: "0 182px",
  diners: "0 228px",
};
