import classNames from "classnames";
import React, { useState } from "react";
import Price from "../../../Common/Price";
import { getRoundedDecimal } from "../../../../helpers/utils";
import {
  ANALYTICS_EVENTS,
  NUMBER_OF_MONTHS,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../../../helpers/constants";
import SummaryItem from "../../shared/SummaryItem";
import { TBillingCycle } from "../../../../types/billing-cycle";
import InputNumber, { InputNumberProps } from "../../../Common/InputNumber";
import Button from "../../../Common/Button/Button";
import { TAdminMailbox } from "../../../../types/mailbox.type";
import { showErrorToast } from "../../../../helpers/toastHelper";
import MailboxRemoveConfirmation from "../MailboxRemoveConfirmation.tsx";
import styles from "./style.module.scss";
import { logSiteBillingPageEvent } from "../../../../telemetry/site/medusaEventsFunctions";

interface MailPlanSummaryProps {
  totalPayable: number;
  perMailboxCharge: number;
  duration: TBillingCycle;
  isCustomDomain: boolean;
  isCoSiteTrial: boolean;
  planDisplayName: string;
  noOfAdditionalMailboxes: number;
  adminMailbox: TAdminMailbox;
  onChangeOfNoOfAdditionalMailboxes: (value: number) => void;
  removePlan: () => void;
}

const MailPlanSummary: React.FC<MailPlanSummaryProps> = ({
  totalPayable,
  perMailboxCharge,
  isCoSiteTrial,
  isCustomDomain,
  adminMailbox,
  planDisplayName,
  onChangeOfNoOfAdditionalMailboxes,
  removePlan,
  duration,
  noOfAdditionalMailboxes,
}) => {
  const [isRemoveConfirmationVisible, setIsRemoveConfirmationVisible] =
    useState(false);
  const totalMailboxes = noOfAdditionalMailboxes + 1; // +1 for admin mailbox  

  const trialDays = isCustomDomain
    ? TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN
    : isCoSiteTrial
    ? TRIAL_DURATIONS_IN_DAYS.CO_SITE
    : 0;
  const noOfMonths =
    NUMBER_OF_MONTHS[duration as keyof typeof NUMBER_OF_MONTHS];

  const showRemoveConfirmation = () => {
    setIsRemoveConfirmationVisible(true);
    logSiteBillingPageEvent(ANALYTICS_EVENTS.MAILBOX_PLAN_REMOVE_CLICKED);
  };

  const handleConfirm = () => {
    removePlan();
    setIsRemoveConfirmationVisible(false);
    logSiteBillingPageEvent(ANALYTICS_EVENTS.MAILBOX_PLAN_REMOVED);
  };

  const handleCancel = () => {
    setIsRemoveConfirmationVisible(false);
  };

  const handleChange: InputNumberProps["onChange"] = (value) => {
    if (Number(value) <= 0) {
      showRemoveConfirmation();
      return;
    }
    onChangeOfNoOfAdditionalMailboxes(Math.max(0, Number(value) - 1));
  };

  const onLimitReached: InputNumberProps["onLimitReached"] = (type) => {
    if (type === "max") {
      showErrorToast("You can only add up to 10 mailboxes during free trial");
    }
  };

  return (
    <SummaryItem className={classNames(styles.mailPlanSummary)} columns={2}>
      <div>
        <div>
          <strong>
            Business Mail{" "}
            <span className={styles.planName}>({planDisplayName} plan)</span>
          </strong>{" "}
        </div>
        <div className={styles.mailPlanInputWrapper}>
          <InputNumber
            value={noOfAdditionalMailboxes + 1}
            className={styles.mailPlanInput}
            max={10}
            controls={false}
            useCustomControls
            onChange={handleChange}
            onLimitReached={onLimitReached}
          />
          <Button
            onClick={showRemoveConfirmation}
            className={styles.mailPlanRemoveButton}
          >
            Remove
          </Button>
        </div>
      </div>
      <div>
        <strong>
          <Price value={0} />
        </strong>
        <div className="colorGreen">Free for {trialDays} days</div>
        <div>
          <Price value={getRoundedDecimal(totalPayable, 2, "string")} /> (
          <Price value={getRoundedDecimal(perMailboxCharge, 2, "string")} /> x{" "}
          {noOfMonths} {noOfMonths > 1 ? "months" : "month"} x{" "}
          {totalMailboxes}{" "}
          {totalMailboxes > 1 ? "mailboxes" : "mailbox"}) after trial
        </div>
      </div>
      {/* Mailbox removal confirmation Modal */}
      <MailboxRemoveConfirmation
        isOpen={isRemoveConfirmationVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        adminMailbox={adminMailbox}
        noOfAdditionalMailboxes={noOfAdditionalMailboxes}
      />
    </SummaryItem>
  );
};

export default React.memo(MailPlanSummary);
