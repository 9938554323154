
import { USER_TYPE } from "../../utils/const";
import { cj } from "../../utils/helpers";
import { useUserProfile } from "./useUserProfile";
import s from "./UserProfile.module.scss";

function UserProfile() {
  const {
    name,
    cpToken,
    userType,

    profileRef,
    showDropdown,
    icons: {
      MAIL_ICON,
      LOGOUT_ICON,
      SETTING_ICON
    },

    setShowDropdown,
    openCP,
    openWebmail,
    logout,
  } = useUserProfile();

  // purchase flow login/user profile is only for customers
  // where as in entri admin and customer both can access this flow
  // hence if the user type is mailbox admin then hide the user profile
  if (!cpToken || userType === USER_TYPE.ADMIN) return null;

  return (
    <div ref={profileRef} className={s.profileWrapper}>
      <div
        className={s.userProfile}
        onClick={() => {
          setShowDropdown((showDropdown) => !showDropdown);
        }}
      >
        <div className={s.userIcon}>{name && name[0]}</div>
        <div className={s.usernameWrapper}>
          <span className={s.username}>{name}</span>
          <div className={cj([s.dropdownIcon, showDropdown ? s.open : ''])}/>
        </div>
      </div>
      {showDropdown && (
        <div className={s.dropdownList}>
          <div
            className={s.dropdownItem}
            onClick={openWebmail}
          >
            <img alt={"Mail"} src={MAIL_ICON} />
            Access webmail
          </div>
          <div
            className={s.dropdownItem}
            onClick={openCP}
          >
            <img alt={"Settings"} src={SETTING_ICON} />
            Access Neo Admin Panel
          </div>
          <hr className={s.borderLine} />
          <div
            className={s.dropdownItem}
            onClick={logout}
          >
            <img alt={"Logout"} src={LOGOUT_ICON} />
            Logout
          </div>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
