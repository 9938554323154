import ENBridge from "../../bridge";
import { cj } from "../../utils/helpers";
import { useEntriButton } from "./useEntriButton";

const EntriButton = ({ disabled, btnLabel = "Set up now" }) => {
  const { Button } = ENBridge.sharedComponents;
  const { isLoading, onEntriButtonClicked } = useEntriButton();
  const isButtonDisabled = isLoading || disabled;

  return <Button
    disabled={isButtonDisabled}
    className={cj([ isButtonDisabled ? 'loading' : '' ])}
    type="primary" onClick={onEntriButtonClicked}
  >{btnLabel}</Button>
}

export default EntriButton;
