import {
  createAsyncThunk,
  createSlice,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { userSelector } from "../selectors";
import { getPromoDetails } from "../../services/apiFunctions";
import { STATUS_CODES } from "../../constants/api.constants";
import { parsePromoErrorResponse } from "../../helpers/promo.helper";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { PromoCodeErrorDetails, PromoState } from "../../types/promo.type";
import { RootState } from "../../types/store.type";

const initialState: PromoState = {};

export const promoSlice = createSlice({
  name: "promo",
  initialState,
  reducers: {
    updatePromo: (state, action: PayloadAction<PromoState>) => {
      return action.payload;
    },
    resetPromo: () => ({ ...initialState }),
  },
  extraReducers(builder: ActionReducerMapBuilder<PromoState>) {
    builder.addCase(fetchPromoDetails.fulfilled, (state, action) => {
      state.promoCodeDetails = action.payload;
    });
    builder.addCase(fetchPromoDetails.rejected, (state, action) => {
      Object.assign(state, initialState);
      const payload = action.payload as PromoCodeErrorDetails;
      if (action.payload) state.promoCodeErrorDetails = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { updatePromo, resetPromo } = promoSlice.actions;

export default promoSlice.reducer;

export const fetchPromoDetails = createAsyncThunk(
  "fetch/promo",
  async (promoCodeId: string, { getState, rejectWithValue }) => {
    const { token } = userSelector(getState() as RootState);
    if (!token) {
      throw new Error("Unauthorized and trying to fetch promo details.");
    }
    const response = await getPromoDetails({ token, promoCodeId });
    if (response?.status === STATUS_CODES.OK) {
      return response?.data?.promoCode as PromoState["promoCodeDetails"];
    }
    const promoCodeErrorDetails = parsePromoErrorResponse(
      response,
      promoCodeId
    );
    if (promoCodeErrorDetails) {
      return rejectWithValue(promoCodeErrorDetails);
    }
    throw new Error("Failed to fetch promo code.");
  }
);
