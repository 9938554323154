import { bll } from "../../../utils/bll"

import { errorLogger } from "../../../utils/error";

// service to validate admin panel token (coming from CP)
export const validateAdminPanelToken = async (cpToken) => {
  try {
    const response = await bll().get('/internal/domain/validate-admin-panel-token', {
      'Authorization': cpToken,
      'X-Auth': 'controlPanel'
    });

    return response;
  } catch(e) {
    return errorLogger.FAILED_TO_VALIDATE_ADMIN_PANEL_TOKEN(e);
  }
}

export const validateWebmailToken = async (webmailToken) => {
  try {
    const response = await bll().post('/panel/authenticate/webmail', {
      webmailToken,
      controlPanelTokenExpiryInMinutes: 15,
    });

    return response;
  } catch(e) {
    return errorLogger.FAILED_TO_VALIDATE_WEBMAIL_TOKEN(e);
  }
}

// service to validate SSO token (coming from email templates)
export const validateSSOToken = async (jwt, partnerId) => {
  try {
    const response = await bll().post('/panel/authenticate/jwt', {
      jwt,
      partnerId,
    });

    return response;
  } catch(e) {
    return errorLogger.FAILED_TO_VALIDATE_SSO_TOKEN(e);
  }
}