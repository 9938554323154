export const getItemFromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  try {
    return value ? JSON.parse(value) : null;
  } catch (_) {
    return value;
  }
};

export const setItemFromLocalStorage = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
  return value;
};

export const deleteItemFromLocalStorage = (key: string) => localStorage.removeItem(key);
