import { useState } from "react";

export const useFeatureCard = () => {
    const [activeFeature, setActiveFeature] = useState('');
    const showFeatureCard = (record) => setActiveFeature(record);
    const hideFeatureCard = () => setActiveFeature('');

    return {
        activeFeature,
        showFeatureCard,
        hideFeatureCard
    };
}