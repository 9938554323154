import ENBridge from "../bridge";
import { FLOW_TYPE } from "./const";
import { errorLogger } from "./error";
import { getSupportedQueryParams } from "./helpers";
import HTTP from "./http";

// http instance for medusa calls
let _medusa = null;
export const medusa = () => {
  if (!_medusa) {
    _medusa = new HTTP("", {
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  }

  return _medusa;
};

export const MEDUSA_EVENT_TYPE = {
  FLOCK_ORDER: "flock_order",
  TITAN_CUSTOMER: "titan_customer",
};

const logPurchaseFlowMedusaEvent = (eventName, attrs) => {
  return ENBridge.handlers.logPurchaseFlowMedusaEvent(eventName, {
    ...attrs,
    source_hook: "New order purchase flow",
  });
};

const logPurchaseFlowCustomerSignedOutEvent = (attrs) => {
  return ENBridge.handlers.logPurchaseFlowCustomerSignedOutEvent(attrs);
};

const logAutoLoginFlowMedusaEvent = (
  eventName,
  neoState,
  attrs = {},
  type = MEDUSA_EVENT_TYPE.FLOCK_ORDER
) => {
  const { getDeviceInfo, getMedusaEndPoint } = ENBridge.handlers;
  const { sourceHook } = getSupportedQueryParams();

  const payload = {
    product: "website",
    eventTs: Date.now(),
    eventName,
    type,
    entityId: (neoState.orderId || "").toString() || "dummy_entity_id",
    attrs: {
      customer_id: neoState.customerId,
      order_id: neoState.orderId,
      account_id: neoState.accountId,
      domain_name: neoState.domain,
      partner_id: neoState.partnerId,
      source_hook: sourceHook,
      user_type: "Order",
      brand: "Neo",
      ...getDeviceInfo(),
      ...attrs,
    },
  };

  return medusa().post(getMedusaEndPoint(), payload);
};

export const eventSpecificHandlers = {
  customer_account_signed_out: (neoState, eventAttrs) => {
    const { sourceHook } = getSupportedQueryParams();
    const isPurchaseFlow = neoState.flowType === FLOW_TYPE.PURCHASE;

    return logPurchaseFlowCustomerSignedOutEvent({
      domain_name: neoState.domain,
      customer_email: neoState.customerEmail,
      customer_id: neoState.customerId,
      partner_id: neoState.partnerId,
      order_id: neoState.orderId,
      source_hook: isPurchaseFlow ? "New order purchase flow" : sourceHook,
      ...eventAttrs,
    });
  },
};

export const sendMedusaEvent = (eventName, neoState, eventAttrs = {}) => {
  try {
    if (eventSpecificHandlers[eventName]) {
      return eventSpecificHandlers[eventName](neoState, eventAttrs);
    }

    if (neoState.flowType === FLOW_TYPE.PURCHASE) {
      return logPurchaseFlowMedusaEvent(eventName, eventAttrs);
    }

    return logAutoLoginFlowMedusaEvent(eventName, neoState, eventAttrs);
  } catch (e) {
    // `true` to explicitly resolve the promise
    return errorLogger.FAILED_TO_SEND_MEDUSA(e, true);
  }
};
