import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveSiteVisitorData } from "../../services/visitor/visitor.service";
import { RootState } from "../../types/store.type";
import { userSelector } from "../selectors";
import { startSiteGeneration } from "../../helpers/site/site-offering.helper";
import { createBitMask } from "../../utils/bitmask.util";
import { getUrlParams } from "../../helpers/utils";
import { transferSiteVisitorData } from "./../../services/visitor/visitor.service";

interface VisitorDataState {
  visitorEmail: string;
  site: {
    businessProfile: {
      businessName: string;
      businessDescription: string;
    };
  };
}

const initialState: VisitorDataState = {
  visitorEmail: "",
  site: {
    businessProfile: {
      businessName: "",
      businessDescription: "",
    },
  },
};

const visitorDataSlice = createSlice({
  name: "visitor",
  initialState,
  reducers: {
    saveVisitorEmailAction: (state, action: PayloadAction<string>) => {
      state.visitorEmail = action.payload;
    },
    saveBusinessNameAction: (state, action: PayloadAction<string>) => {
      state.site.businessProfile.businessName = action.payload;
    },
    saveBusinessDescriptionAction: (state, action: PayloadAction<string>) => {
      state.site.businessProfile.businessDescription = action.payload;
    },
    resetVisitorDataAction: () => {
      return initialState;
    },
  },
});

// Export actions for use in components
export const {
  saveVisitorEmailAction,
  saveBusinessNameAction,
  saveBusinessDescriptionAction,
  resetVisitorDataAction,
} = visitorDataSlice.actions;

// Selectors
// export const visitorEmailSelector = (state: { visitor: VisitorDataState }) =>
//   state.visitor.visitorEmail;
export const businessNameSelector = (state: { visitor: VisitorDataState }) =>
  state.visitor.site.businessProfile.businessName;
export const businessIdeaSelector = (state: { visitor: VisitorDataState }) =>
  state.visitor.site.businessProfile.businessDescription;

// Export the reducer to be added to the store
export default visitorDataSlice.reducer;

export const saveSiteVisitorDataAction = createAsyncThunk(
  "visitor/saveSiteVisitorData",
  async ({ visitorEmail }: { visitorEmail: string }, { getState }) => {
    try {
      const bn = businessNameSelector(getState() as RootState);
      const bd = businessIdeaSelector(getState() as RootState);
      return await saveSiteVisitorData({
        ve: visitorEmail,
        d: { bn, bd },
      });
    } catch (error) {
      return error;
    }
  }
);

export const startVisitorSiteGenerationAction = createAsyncThunk(
  "visitor/startSiteGeneration",
  async ({ visitorEmail }: { visitorEmail: string }, { getState }) => {
    const bn = businessNameSelector(getState() as RootState);
    const bd = businessIdeaSelector(getState() as RootState);
    const user = userSelector(getState() as RootState);
    const customerId = user?.customer_id;
    const userType = user?.token ? "Customer" : "Visitor";
    const userInfo = {
      email: visitorEmail,
      customerId,
      userType,
    };
    return startSiteGeneration({ name: bn, description: bd }, userInfo);
  }
);

export const transferSiteVisitorDataAction = createAsyncThunk(
  "visitor/transferSiteVisitorData",
  async (payload: Record<string, any>, { getState }) => {
    try {
      const { token } = userSelector(getState() as RootState);
      if (!token) {
        throw new Error("User is not logged in");
      }
      await transferSiteVisitorData({ token, ...payload });
      return;
    } catch (error) {
      return error;
    }
  }
);

export const transferSiteVisitorDataPostAuthAction = createAsyncThunk(
  "visitor/transferSiteVisitorDataPostAuth",
  async (
    { visitorEmail }: { visitorEmail: string },
    { getState }
  ) => {
    try {
      const storeState = getState() as RootState;
      const bn = businessNameSelector(storeState);
      const bd = businessIdeaSelector(storeState);
      if(!bn || !bd) {
        return;
      }
      const payload = {
        ve: visitorEmail,
        d: {
          bn,
          bd,
          s: createBitMask(["hasUsedAiFlow"])({
            hasUsedAiFlow: !!getUrlParams().hasUsedAiFlow,
          }),
          d: {
            template_key: getUrlParams().templateKey,
            industry_key: getUrlParams().industryKey,
            // sck: getUrlParams().siteCategory,
          },
        },
      };
      return await saveSiteVisitorData(payload);
    } catch (error) {
      return error;
    }
  }
);
