import classNames from "classnames";
import React from "react";
import Price from "../../../Common/Price";
import EmailRow from "../../../EmailRow/EmailRow";
import { getCountOfTotalMailboxAdded } from "../../../../helpers/emailHelpers";
import { TPlan } from "../../../../types/plan.type";
import { getRoundedDecimal } from "../../../../helpers/utils";
import { TRIAL_DURATIONS_IN_DAYS } from "../../../../helpers/constants";
import MailBoxPriceBreakup from "../.././shared/MailboxPriceBreakup";
import SummaryItem from "../../shared/SummaryItem";
import styles from "./style.module.scss";
import { TBillingCycle } from "../../../../types/billing-cycle";

interface MailboxesSummaryProps {
  totalPayable: number;
  perMailboxCharge: number;
  isCoSiteTrial?: boolean;
  isCustomDomain?: boolean;
  plan: TPlan;
  additionalMailboxes: any[];
  adminMailbox: any;
  domain: string;
  handleDeleteMailbox: (mailboxToDelete: string, sourceHook?: string) => void;
  duration: TBillingCycle;
}

const sourceHook = "Order summary page";

const MailboxesSummary: React.FC<MailboxesSummaryProps> = ({
  totalPayable,
  perMailboxCharge,
  isCoSiteTrial,
  isCustomDomain,
  additionalMailboxes,
  adminMailbox,
  plan,
  handleDeleteMailbox,
  duration
}) => {
  const { name } = plan;
  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);

  const handleDelete = (mailboxToDelete: string) => {
    handleDeleteMailbox(mailboxToDelete, sourceHook);
  };

  const trialDays = isCustomDomain
    ? TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN
    : isCoSiteTrial
    ? TRIAL_DURATIONS_IN_DAYS.CO_SITE
    : 0;

  return (
    <SummaryItem className={classNames(styles.mailboxesRow)} columns={2}>
      <div>
        <div>
          <strong>Email: {name}</strong>{" "}
          {trialDays ? `(${trialDays} days free)` : null}
        </div>
        <div className={styles.mailboxAddedTitle}>Mailboxes you added</div>
        <div
          className={classNames(styles.adminMailbox, {
            [styles.addBorder]: additionalMailboxes.length > 0,
          })}
        >
          {adminMailbox.email} (Admin)
        </div>
        <div className={styles.emailListWrapper}>
          <EmailRow
            emailList={additionalMailboxes}
            handleDeleteMailbox={handleDelete}
            isOrderSummaryPage={true}
            collapsible={true}
            handleAddGenericMailbox={() => {}}
          />
        </div>
      </div>
      <div>
        <div>
          {isCustomDomain || isCoSiteTrial ? (
            <>
              <del>
                <Price value={getRoundedDecimal(totalPayable, 2, "string")} />
              </del>{" "}
              <strong>
                <Price value={0} />
              </strong>
            </>
          ) : (
            <strong>
              <Price value={getRoundedDecimal(totalPayable, 2, "string")} />
            </strong>
          )}
        </div>
        <MailBoxPriceBreakup
          duration={duration}
          countOfMailboxAdded={countOfMailboxAdded}
          perMailBoxPrice={perMailboxCharge}
        />
      </div>
    </SummaryItem>
  );
};

export default React.memo(MailboxesSummary);
