import React from "react";
import { useSelector } from "react-redux";
import Button from "../Common/Button/Button";
import Popup from "../Common/Popup/Popup";
import { useRedirection } from "../../hooks/useRedirection";
import styles from "./Modal.module.scss";
import { isMailDomainCustom } from "../../store/slices/mail.slice";

const MailboxPurchaseFailed = ({
  countOfMailboxPurchaseFailed,
  countOfMailboxAdded,
}) => {
  const { handleCheckoutRedirection } = useRedirection();
  // TODO: for site
  const isCustomDomain = useSelector(isMailDomainCustom);

  return (
    <Popup>
      <div className={styles.center}>
        <p className={styles.processingOrder}>You’re all set!</p>
        <p className={styles.subTitle}>
          {`${countOfMailboxPurchaseFailed} out of ${countOfMailboxAdded} mailboxes couldn’t be created.`}
        </p>
        {isCustomDomain ? (
          <p>
            Don't worry, you can add them later from the{" "}
            <strong>Neo Admin Panel</strong> anytime.
          </p>
        ) : (
          <p>
            Don't worry.. since you've already been charged, you can add them
            later from the <strong>Neo Admin Panel</strong>.
          </p>
        )}
        <Button
          type={"primary"}
          className={styles.okPopupBtn}
          onClick={() => handleCheckoutRedirection()}
        >
          Ok, got it
        </Button>
      </div>
    </Popup>
  );
};

export default MailboxPurchaseFailed;
