import { toast } from "react-toastify";
const DEFAULT_SUCCESS_MESSAGE = "Success!";
const DEFAULT_ERROR_MESSAGE = "Something went wrong.";
const DEFAULT_CLASSNAME = "toast-position";
export const showSuccessToast = (
  message = DEFAULT_SUCCESS_MESSAGE,
  options = { className: DEFAULT_CLASSNAME }
) => {
  return toast(message, {
    ...options,
    icon: "✅",
  });
};

export const showErrorToast = (
  message = DEFAULT_ERROR_MESSAGE,
  options = { className: DEFAULT_CLASSNAME }
) => {
  return toast(message, {
    ...options,
    icon: "⚠️",
  });
};
