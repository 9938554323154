// VWOIntegration.js
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { setAmplitudeUserProperty } from "../../helpers/amplitudeHelper";
import { ANALYTICS_EVENTS } from "../../helpers/constants";
import {
  getActiveVWOCampaignIDs,
  getFilteredExperiments,
  getStitchedCampaignIDAndVariation,
  triggerVWOLoaded,
  triggerVWOLoadFailed,
} from "../../helpers/vwoHelper";
import { userActions } from "../../store/actions";
import { store } from "../../store/store";
import {
  ANALYTICS_ATTRIBUTES,
  logMedusaEvent,
} from "../../telemetry/medusaHelper";
import { getVWOSmartCode } from "./vwoSmartCodeHelper";

const VWOIntegration = () => {
  const { updateUser } = bindActionCreators(userActions, useDispatch());
  const storeState = store.getState();
  const {
    user: { experiments },
  } = storeState;

  useEffect(() => {
    const script = document.createElement("script");
    const link = document.createElement("link");

    link.rel = "preconnect";
    link.href = "https://dev.visualwebsiteoptimizer.com";
    script.id = "vwoCode";
    script.type = "text/javascript";

    // Async loading of VWO script
    script.innerHTML = getVWOSmartCode();

    document.head.appendChild(script);
    document.head.appendChild(link);

    setVWOListener();
  }, []);

  const setVWOActiveExpInAmplitude = async () => {
    const active_experiments = await getActiveVWOCampaignIDs();
    setAmplitudeUserProperty(
      ANALYTICS_ATTRIBUTES.ACTIVE_EXPERIMENTS,
      active_experiments
    );
  };

  // This function will update experiments data to store and user property experiment data in amplitude
  const handleExperimentDataUpdate = (experiments) => {
    updateUser({
      experiments: experiments,
    });
    // identify call of experiments
    setAmplitudeUserProperty("experiments", experiments);
  };

  const updateUserVariationData = (campaignID, variationName) => {
    const exp = getStitchedCampaignIDAndVariation(campaignID, variationName);
    if (!experiments.includes(exp)) {
      experiments.push(exp);
    }
    handleExperimentDataUpdate(experiments);
  };

  const setVWOListener = () => {
    const MAX_RETRIES = 20; // Maximum retry limit
    let retryCount = 0; // Initialize a retry count
    const VWO_LOAD_TIMEOUT = 30000; // 30 seconds
    console.log("Setting VWO listener...");

    // Wait for VWO to initialize
    const intervalId = setInterval(async () => {
      if (window._vwo_code) {
        clearInterval(intervalId);
        // Presence of _vwo_code on the window object means that the SmartCode has loaded successfully,
        // but it does not mean that VWO is fully initialized.
        // We get that confirmation from the onVWOLoaded callback.
        // In case the user is on incognito mode or has an ad-blocker, the onVWOLoaded callback will not be triggered,
        // and to handle that we have set a loading timeout for VWO.

        window.VWO = window.VWO || [];

        // Set a timeout for onVWOLoaded
        const timeoutId = setTimeout(() => {
          console.error(
            "VWO failed to load within the timeout period. This is likely due to an Adblocker running or the user is running the app in incognito mode of Firefox / Safari."
          );
          triggerVWOLoadFailed();
          logVWOLoadedStatusMedusa(
            false,
            "Timeout - AdBlocker or Incognito mode"
          );
        }, VWO_LOAD_TIMEOUT);

        window.VWO.push([
          "onVWOLoaded",
          async () => {
            console.log("VWO is fully initialized and ready");
            clearTimeout(timeoutId);
            logVWOLoadedStatusMedusa(true);
            triggerVWOLoaded();
            await setVWOActiveExpInAmplitude();
            await cleanInactiveExpData();

            window.VWO.push([
              "onEventReceive",
              "vA",
              function (data) {
                var experimentId = data[1];
                var variationId = data[2];
                // var abTestName = window._vwo_exp[experimentId].name;
                var variationName =
                  window._vwo_exp[experimentId].comb_n[variationId];
                if (
                  typeof window._vwo_exp[experimentId].comb_n[variationId] !==
                    "undefined" &&
                  ["VISUAL_AB", "VISUAL", "SPLIT_URL", "SURVEY"].indexOf(
                    window._vwo_exp[experimentId].type
                  ) > -1
                ) {
                  // console.log(
                  //   "TestId : TestName-->" + experimentId + ":" + abTestName
                  // );
                  // console.log(
                  //   "VarId  : VarName-->" + variationId + ":" + variationName
                  // );
                  updateUserVariationData(experimentId, variationName);
                }
              },
            ]);
          },
        ]);
      } else if (retryCount >= MAX_RETRIES) {
        // Stop trying after reaching the max retries
        clearInterval(intervalId);
        console.error("VWO failed to initialize within the retry limit.");
        triggerVWOLoadFailed();
        logVWOLoadedStatusMedusa(false, "SmartCode loading failed");
      } else {
        retryCount++;
      }
    }, 500);
  };

  const cleanInactiveExpData = async () => {
    const activeVWOCampaignIDs = await getActiveVWOCampaignIDs();

    if (activeVWOCampaignIDs.length === 0) {
      handleExperimentDataUpdate([]);
    } else {
      const newExpData = getFilteredExperiments(
        activeVWOCampaignIDs,
        experiments
      );
      if (newExpData !== null) {
        handleExperimentDataUpdate(newExpData);
      }
    }
  };

  const logVWOLoadedStatusMedusa = (loaded, reason = null) => {
    logMedusaEvent(ANALYTICS_EVENTS.VWO_LOADED, [], {
      loaded,
      page_name: window.location.pathname,
      ...(!loaded && {
        reason,
      }),
    });
  };

  return null;
};

export default VWOIntegration;
