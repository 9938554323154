import {
  initStateFromAutoLoginFlow,
  setCPTokenValidating,
} from "../../../store/neo/actions";
import { errorLogger } from "../../../utils/error";
import {
  getMilliSeconds,
  getSupportedQueryParams,
} from "../../../utils/helpers";
import {
  validateAdminPanelToken,
  validateSSOToken,
  validateWebmailToken,
} from "./service";

export const validateAdminPanelTokenProxy = async (dispatch) => {
  try {
    const queryParams = getSupportedQueryParams();
    const {
      token,
      partnerId,
      jwt,
      domain: domainFromUrl,
      // incase of domain admin we expect domainId and accoundId to be passed in URL
      // as `validate-admin-panel-token` always return first domain Id and its account Id
      domainId: domainIdFromUrl,
      accountId: accountIdFromUrl,
      soid,
      bid,
    } = queryParams;
    const loginViaSso = !token && jwt;
    // this is extra step we have to do when user try to login via email
    // in email we can add cp/admin token, instead we keep SSO token (JWT)
    const ssoResponse = loginViaSso
      ? await validateSSOToken(jwt, partnerId)
      : null;
    const cpToken = token || ssoResponse?.token;
    const response = await validateAdminPanelToken(cpToken);
    const domain = domainFromUrl || response.domainName;
    const domainId = domainIdFromUrl || response.domainId;
    const accountId = accountIdFromUrl || response.accountId;
    if (!domain) return errorLogger.DOMAIN_MISSING();

    dispatch(
      initStateFromAutoLoginFlow({
        ...response,
        domainId,
        accountId,
        domain,
        cpToken,
        tokenExpiry: Date.now() + getMilliSeconds(3), // 3hrs
        soid,
        bid,
      })
    );
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const validateWebmailTokenProxy = async (dispatch) => {
  try {
    const { domain, domainId, token, soid, bid } = getSupportedQueryParams();
    if (!token || !domain || !domainId)
      return errorLogger.WEBMAIL_TOKEN_MISSING_INFO();

    const response = await validateWebmailToken(token);

    dispatch(
      initStateFromAutoLoginFlow({
        cpToken: response.token,
        name: response.actor?.name,
        email: response.actor?.email,
        accountId: response.actor?.id,
        domainId: domainId,
        domain,
        userId: response.bllCustomerId,
        userEmail: response.userEmail,
        partnerId: response.partnerId,
        tokenExpiry: new Date(response.expiryEpochTimestamp).getTime(),
        soid,
        bid,
      })
    );

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const validateTokenProxy = async (dispatch) => {
  try {
    dispatch(setCPTokenValidating(true));
    const { tokenType } = getSupportedQueryParams();

    if (tokenType === "webmail") {
      return await validateWebmailTokenProxy(dispatch);
    }

    return await validateAdminPanelTokenProxy(dispatch);
  } catch (e) {
    return Promise.reject(e);
  } finally {
    dispatch(setCPTokenValidating(false));
  }
};
