import React from "react";
import { DEFAULT_CURRENCY } from "../../../constants/currency";
import { currencyFormatter } from "../../../helpers/currency";
import styles from "./Currency.module.scss";

type Props = {
  className?: string;
  currency?: string;
}

export default function Currency({ currency = DEFAULT_CURRENCY, className }: Props) {
  return (
    <span className={`${styles.Currency} ${className}`}>
      {currencyFormatter.getCurrencySymbol(currency)}
    </span>
  );
}
