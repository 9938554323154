import {
  setEntriCheckDomainPayload,
  setErrorFetchingEntriDetails,
  setGettingEntriDetails
} from "../../../store/entri/actions";
import { ENTRI_SETUP_TYPE } from "../../../utils/const";
import { entri } from "../../../utils/entri";
import { entriDomainChecked } from "../../medusa/entriDomainChecked";
import { getEntriCheckDomain, getEntriToken } from "./service";

export const getEntriDetailsProxy = async (dispatch, applicationId, state) => {
  try {
    dispatch(setGettingEntriDetails(true));
    dispatch(setErrorFetchingEntriDetails(null));

    const tokenResponse = await getEntriToken();
    if (!tokenResponse?.auth_token) throw new Error('entri_token_missing');

    const hasEntryCheckEventAlreadySent = entri.hasCachedResponse();
    const setupResponse = await getEntriCheckDomain(tokenResponse.auth_token, state.domain, applicationId);
    if (!setupResponse || setupResponse.setupType !== ENTRI_SETUP_TYPE.AUTOMATIC) {
      throw new Error('entri_manual_flow');
    }

    const combinedResp = {
      entriToken: tokenResponse.auth_token,
      ...(setupResponse || {})
    };

    // in case of purchase flow we make call to checkDomain twice
    // one during order completion and other when entri app loaded
    // in that case it is expected to send event only once
    if (!hasEntryCheckEventAlreadySent) {
      entriDomainChecked(combinedResp, state);
    }

    dispatch(setEntriCheckDomainPayload(combinedResp));
    dispatch(setGettingEntriDetails(false));

    return combinedResp;
  } catch(e) {
    dispatch(setGettingEntriDetails(false));
    dispatch(setErrorFetchingEntriDetails(e));
    return Promise.reject(e);
  }
}