import React from "react";
import classNames from "classnames";
import {
  getFormattedTrialEndDate,
  getRoundedDecimal,
  isMobile,
} from "../../../../helpers/utils";
import Price from "../../../Common/Price";
import SummaryItem from "../SummaryItem";
import styles from "./style.module.scss";

type Props = {
  finalAmount: number;
  discountAmount?: number;
  finalAmountWithoutDiscount?: number;
  trialDuration: number;
};

const PostTrialAmountSummary = ({
  finalAmount,
  discountAmount,
  finalAmountWithoutDiscount,
  trialDuration,
}: Props) => {
  const amountPayableText = discountAmount
    ? `Discounted amount payable after ${trialDuration} days trial`
    : `Amount payable after ${trialDuration} days trial`;

  return (
    <SummaryItem
      className={classNames(styles.amountPayable)}
      columns={isMobile() ? 1 : 2}
    >
      <div>
        <strong>{amountPayableText}</strong>
        <div className={styles.cancellationNote}>
          Cancel anytime before {getFormattedTrialEndDate(trialDuration)}. We’ll send you a reminder before we charge your card.
        </div>
      </div>
      <div>
        <strong>
          <Price value={getRoundedDecimal(finalAmount, 2, "string")} />
        </strong>
        {discountAmount && finalAmountWithoutDiscount ? (
          <div>
            <Price
              value={getRoundedDecimal(finalAmountWithoutDiscount, 2, "string")}
            />{" "}
            - <Price value={getRoundedDecimal(discountAmount, 2, "string")} />
          </div>
        ) : null}
      </div>
    </SummaryItem>
  );
};

export default React.memo(PostTrialAmountSummary);
