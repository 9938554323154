import {
  EXIT_INTENT_TYPES,
  EXIT_POPUP_MAX_AGE,
  PITCH_TYPES,
} from "../constants/exitIntent.constants";
import { Values } from "../types/common";
import { COOKIE_KEYS } from "./constants";
import { eraseCookie, getCookie, setCookie } from "./cookie.helper";

export const getVisitorEmailFromCookie = (): string | null => {
  return getCookie(COOKIE_KEYS.VISITOR_EMAIL) || null;
};

export const setVisitorEmailInCookie = (email: string) => {
  setCookie(COOKIE_KEYS.VISITOR_EMAIL, email, 7);
};

export const clearVisitorEmailInCookie = () => {
  eraseCookie(COOKIE_KEYS.VISITOR_EMAIL);
};

export const clearVisitorEmailFromCookieIfSameAs = (email: string) => {
  if (getVisitorEmailFromCookie() === email) {
    clearVisitorEmailInCookie();
  }
}

export const getLastExitIntentShownAtFromCookie = (): number => {
  return parseInt(getCookie(COOKIE_KEYS.LAST_EXIT_INTENT_SHOWN_AT) || "0");
};

export const setLastExitIntentShownAtFromCookie = (time: number) => {
  setCookie(COOKIE_KEYS.LAST_EXIT_INTENT_SHOWN_AT, time.toString());
};

export const isExitIntentDisabled = (
  visitorEmail: string | null,
  lastExitIntentTime: EpochTimeStamp
) => {
  return !!(
    visitorEmail ||
    (lastExitIntentTime && Date.now() - lastExitIntentTime < EXIT_POPUP_MAX_AGE)
  );
};

export const createMedusaPayload = ({
  exitIntent,
  pitch,
  pageName,
}: {
  exitIntent: Values<typeof EXIT_INTENT_TYPES>;
  pitch: Values<typeof PITCH_TYPES>;
  pageName: string;
}) => {
  return {
    exit_type: exitIntent,
    pitch: pitch === PITCH_TYPES.DOMAIN ? "Free domain" : "10% discount",
    page_name: pageName,
    discount_percent: pitch === PITCH_TYPES.DOMAIN ? 0 : 10,
    discount: pitch === PITCH_TYPES.DOMAIN ? "no" : "yes",
  };
};
