import React from "react";
import { ReactComponent as RightIcon } from "../../../../assets/tick.svg";
import styles from "./styles.module.scss";

const benefitsData = [
  "Your email is your identity",
  // "Look professional",
  "Build customer trust",
  "Grow your brand with every email you send",
];

const MailBenefits: React.FC = () => {
  return (
    <div className={styles.emailBenefitsContainer}>
      <div className={styles.benefitsTitle}>Why get Professional Email?</div>
      {benefitsData.map((benefit, index) => (
        <div className={styles.benefitItem} key={`${benefit}-${index}`}>
          <RightIcon className={styles.rightIcon} />
          <span>{benefit}</span>
        </div>
      ))}
    </div>
  );
};

export default MailBenefits;
