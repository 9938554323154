import ENBridge from "../bridge";
import { useNeoContext } from "../store/neo/useNeoContext";
import { USER_TYPE } from "../utils/const";
import { getUrl } from "../utils/url";

type TUseNeoSite = (onSiteOpenCb?: () => void) => {
  openNeoSiteLink: () => void;
};

export const useNeoSite: TUseNeoSite = (onSiteOpenCb) => {
  const { getNeoSiteUrl } = ENBridge.handlers;
  const { state: neoState } = useNeoContext();
  const {
    domain,
    orderId,
    accountId,
    customerId,
    cpToken,
    userType,
    soid,
    bid,
  } = neoState;

  const openNeoSiteLink = () => {
    onSiteOpenCb?.();
    const isCustomerAccount = userType === USER_TYPE.CUSTOMER;
    const siteEditorUrl = `${getNeoSiteUrl()}edit/`;

    const queryParams = {
      domain,
      ...(soid && bid
        ? { soid, bid } // If both `soid` and `bid` are available, include them
        : { domainId: orderId }), // Otherwise, fallback to `domainId`
      ct: cpToken,
      isCustomerAccount,
      source: "entri",
      action: "publish",
      ...(isCustomerAccount
        ? { customer_id: customerId } // If the user is a customer, the 'accountId' value represents the 'customerId'. In this case, the key is set as 'customer_id'
        : { account_id: accountId }), // If the user is an admin, the 'accountId' value is the actual 'accountId'. Here, the key is set as 'account_id'
    };

    window.open(
      getUrl(siteEditorUrl, queryParams),
      "FlockSite" // window.name - open existing neo site window if it's present
    );
  };

  return {
    openNeoSiteLink,
  };
};
