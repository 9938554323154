import React from "react";
import classNames from "classnames";
import Price from "../../../Common/Price";
import SummaryItem from "../SummaryItem";
import { getRoundedDecimal } from "../../../../helpers/utils";
import styles from "./style.module.scss";

interface AmountPayableProps {
  payableNow: number;
  amountSaved?: number;
}

const AmountPayable: React.FC<AmountPayableProps> = ({
  payableNow,
  amountSaved,
}) => {
  return (
    <SummaryItem className={classNames(styles.totalAmount)} columns={2}>
      <strong>Amount payable now</strong>
      <strong className={styles.finalAmountPayable}>
        <Price
          value={
            payableNow ? getRoundedDecimal(payableNow, 2, "string") : payableNow
          }
        />
        {amountSaved ? (
          <small>
            You saved{" "}
            <Price value={getRoundedDecimal(amountSaved, 2, "string")} />!
          </small>
        ) : null}
      </strong>
    </SummaryItem>
  );
};

export default React.memo(AmountPayable);
