import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "../../helpers/utils";
import { userActions } from "../../store/actions";
import { userSelector } from "../../store/selectors";
import Button from "../Common/Button/Button";
import Input from "../Common/Input/Input";
import PreventInputPrefillByBrowser from "../Common/PreventInputPrefillByBrowser/PreventInputPrefillByBrowser";
import styles from "./CustomInput.module.scss";
import { selectMailPurchaseDomain } from "../../store/slices/mail.slice";

const GENERIC_MAILBOX_PAGE = "Generic mailbox page";
const TEAM_MAILBOX_PAGE = "Team member mailbox page";
const CustomInput = ({
  handleAddCustomMailbox,
  source,
  inputPlaceholder,
  domain,
}) => {
  const { customMailboxInput } = useSelector(userSelector);
  const { updateUser } = bindActionCreators(userActions, useDispatch());

  useEffect(() => {
    updateUser({
      customMailboxInput: {
        username: "",
        error: false,
      },
    });
  }, []);

  const handleInputChange = (e) => {
    const { value } = e.target;
    updateUser({
      customMailboxInput: {
        username: value.toLowerCase().replaceAll(" ", ""),
        error: false,
      },
    });
  };

  const handleEnterKeyPress = (event) => {
    if (event.code === "Enter") {
      handleAddCustomMailbox();
    }
  };

  return (
    <>
      <div className={styles.customInput}>
        <PreventInputPrefillByBrowser />
        <Input
          label={
            source === GENERIC_MAILBOX_PAGE
              ? "Or Enter email address"
              : "Enter team member's email address"
          }
          name={"username"}
          placeholder={inputPlaceholder || "Custom email"}
          type={"text"}
          onChange={(e) => handleInputChange(e)}
          hasError={!!customMailboxInput?.error}
          errorMessage={customMailboxInput?.error}
          domain={domain}
          className={styles.inputAddMailBox}
          autoCapitalize={"none"}
          value={customMailboxInput?.username}
          onKeyPress={(event) => handleEnterKeyPress(event)}
        />

        {/* Show this button only for mobile +Add */}
        {customMailboxInput?.username && (
          <Button
            type={"secondary"}
            onClick={handleAddCustomMailbox}
            className={styles.addButton}
          >
            Add
          </Button>
        )}

        {/* Show this button only for desktop on generic mailbox page */}
        {customMailboxInput?.username && source === GENERIC_MAILBOX_PAGE && (
          <Button
            type={"secondary"}
            onClick={handleAddCustomMailbox}
            className={styles.addButtonDesktopGenericPage}
          >
            Add
          </Button>
        )}
      </div>

      {/* show this button only for desktop in team mailbox page +Add this mailbox */}
      {customMailboxInput?.username && source === TEAM_MAILBOX_PAGE && (
        <button
          type={"secondary"}
          onClick={handleAddCustomMailbox}
          className={styles.addMailboxButton}
        >
          +Add this mailbox
        </button>
      )}
    </>
  );
};

export default CustomInput;
