import Price from "../../../Common/Price";
import { isMobile } from "../../../../helpers/utils";
import SummaryItem from "../SummaryItem";

type Props = {
  isCustomDomain: boolean;
  domain: string;
};

const OnePageSiteSummary = ({ isCustomDomain, domain }: Props) => {
  return (
    <SummaryItem columns={isMobile() ? 1 : 2}>
      <div>
        <strong>One page site</strong>
        {!isCustomDomain && <div>{domain}</div>}
      </div>
      <div>
        <strong>
          <Price value={0} />
        </strong>
        <div className={"colorGreen"}>
          {isCustomDomain ? "Free with email" : "Free beta"}
        </div>
      </div>
    </SummaryItem>
  );
};

export default OnePageSiteSummary;
