import React, { useState } from "react";
import classNames from "classnames";
import { OFFERING } from "../../helpers/constants";
import Button from "../Common/Button/Button";
import styles from "./styles.module.scss";
import {
  TDomainSelectionOptions,
  THandleDomainTypeSelection,
} from "./Common/types";

interface Props {
  domainSelectionOptions: TDomainSelectionOptions;
  handleDomainTypeSelection: THandleDomainTypeSelection;
  handleGetStartedCta: () => void;
}

const CONTINUE_WITH_MY_DOMAIN = "Continue with my domain";
const GET_A_NEW_DOMAIN = "Get a new domain";

const GetStartedV2: React.FC<Props> = ({
  domainSelectionOptions,
  handleDomainTypeSelection,
  handleGetStartedCta,
}) => {
  const [currentSelection, setCurrentSelection] = useState<string | null>(null);

  return (
    <>
      <div className={classNames(styles.buttonsWrapper, styles.variantWrapper)}>
        <div
          className={classNames(styles.button, styles.variant, {
            [styles.isSelected]:
              currentSelection === domainSelectionOptions.haveDomain,
          })}
          onClick={() => {
            handleDomainTypeSelection(OFFERING.CUSTOM_DOMAIN);
            setCurrentSelection(domainSelectionOptions.haveDomain);
          }}
        >
          <div className={styles.circle}>
            <span className={styles.tick} />
          </div>
          {domainSelectionOptions.haveDomain}
        </div>
        <div
          className={classNames(styles.button, styles.variant, {
            [styles.isSelected]:
              currentSelection === domainSelectionOptions.needDomain,
          })}
          onClick={() => {
            handleDomainTypeSelection(OFFERING.COSITE);
            setCurrentSelection(domainSelectionOptions.needDomain);
          }}
        >
          <div className={styles.circle}>
            <span className={styles.tick} />
          </div>
          {domainSelectionOptions.needDomain}
        </div>
      </div>
      {currentSelection && (
        <Button
          type={"primary"}
          onClick={handleGetStartedCta}
          className={styles.getStartedCta}
        >
          {currentSelection === domainSelectionOptions.haveDomain
            ? CONTINUE_WITH_MY_DOMAIN
            : GET_A_NEW_DOMAIN}
        </Button>
      )}
    </>
  );
};

export default GetStartedV2;
