import { useReducer } from "react"
import { NeoContext } from "./context"
import { neoReducer } from "./reducer";
import { initialValues } from "./initialValues";

export const NeoContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(neoReducer, initialValues);
  const value = { state, dispatch };

  return <NeoContext.Provider value={value}>{children}</NeoContext.Provider>
}