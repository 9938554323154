import { useSelector } from "react-redux";
import Billing from "../shared/Billing";
import MailBillingInformation from "../../BillingInformation/mail";
import MailBillingCard from "../../BillingCard/mail";
import MailOrderSummary from "../../OrderSummary/mail";
import {
  isMailDomainCustom,
  selectMailDomainAttrs,
} from "../../../store/slices/mail.slice";

const MailBilling = () => {
  const { isCoSiteTrial } = useSelector(selectMailDomainAttrs);
  const isCustomDomain = useSelector(isMailDomainCustom);

  return (
    <Billing
      OrderSummaryComponent={MailOrderSummary}
      BillingCardComponent={MailBillingCard}
      BillingInformationComponent={MailBillingInformation}
      isCoSiteTrial={isCoSiteTrial}
      isCustomDomain={isCustomDomain}
    />
  );
};

export default MailBilling;
