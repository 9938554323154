import { TBillingCycle } from "../../types/billing-cycle";
import { TDomainPricing } from "../../types/domain.type";
import { Discount } from "../../types/global";
import { TPlan } from "../../types/plan.type";
import { BILLING_CYCLE_KEYS, NUMBER_OF_MONTHS } from "../constants";
import { getDiscount } from "../discount.helper";
import { calculateDomainCharges } from "../domainPricingHelper";

const { MONTHLY, TWO_YEARLY, FOUR_YEARLY } = BILLING_CYCLE_KEYS;

interface ChargeInput {
  plan: TPlan;
  countOfMailboxes: number;
  emailDiscount?: Discount;
  isCustomDomain: boolean;
  isCoSiteTrial: boolean;
  domainPricing?: TDomainPricing;
  billingCycle: TBillingCycle;
}

export const calculateCharge = ({
  plan,
  countOfMailboxes,
  isCustomDomain,
  isCoSiteTrial,
  domainPricing,
  billingCycle,
  emailDiscount,
}: ChargeInput) => {
  const numberOfMonths = NUMBER_OF_MONTHS[billingCycle];

  const mailboxChargeBeforeDiscount =
    (plan.pricing?.[billingCycle]?.firstBillingCyclePrice ??
      plan.pricing?.[billingCycle]?.price ??
      0) *
    numberOfMonths *
    countOfMailboxes;

  const mailboxDiscount = emailDiscount
    ? getDiscount(emailDiscount, mailboxChargeBeforeDiscount)
    : 0;

  const finalMailboxCharges = mailboxChargeBeforeDiscount - mailboxDiscount;

  const totalDomainCharges = !isCustomDomain
    ? billingCycle === TWO_YEARLY
      ? Number(domainPricing?.yearly?.renewalChargePerYear)
      : billingCycle === FOUR_YEARLY
      ? Number(domainPricing?.yearly?.renewalChargePerYear) * 3
      : 0
    : 0;

  const totalChargeableAmount =
    finalMailboxCharges + Number(totalDomainCharges);

  const totalChargeableAmountBeforeDiscount =
    mailboxChargeBeforeDiscount + Number(totalDomainCharges);

  const payableNow =
    isCustomDomain || isCoSiteTrial ? 0 : totalChargeableAmount;

  const calculateAmountSaved = () => {
    if (isCustomDomain || isCoSiteTrial) return 0;
    let savedAmount = 0;
    if (billingCycle !== MONTHLY) {
      const pricing = plan.pricing[billingCycle];
      if (!pricing) return 0;
      const { firstBillingCyclePrice, price } = pricing;
      savedAmount =
        Math.max(
          (price - firstBillingCyclePrice) * countOfMailboxes * numberOfMonths,
          0
        ) || 0;
    }
    return savedAmount + mailboxDiscount;
  };

  const amountSaved = calculateAmountSaved();

  return {
    totalChargeableAmount,
    totalChargeableAmountBeforeDiscount,
    mailboxDiscount,
    payableNow,
    amountSaved,
    mailboxChargeBeforeDiscount,
    perMailBoxCharge:
      plan.pricing[billingCycle]?.firstBillingCyclePrice ??
      plan.pricing[billingCycle]?.price ??
      0,
    domainCharges: calculateDomainCharges(billingCycle, domainPricing),
  };
};
