import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  leftContent: React.ReactNode;
  rightContent?: React.ReactNode;
}

const LayoutTemplate: React.FC<Props> = ({
  leftContent,
  rightContent,
}) => {
  return (
    <div className={classNames(styles.layoutContainer)}>
      <div className={styles.leftContent}>{leftContent}</div>
      {rightContent && <div className={styles.rightContent}>{rightContent}</div>}
    </div>
  );
};

export default LayoutTemplate;