import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useProduct } from "../useProduct";
import {
  resetMailDomainState,
  selectMailDomain,
} from "../../store/slices/mail.slice";
import { PAGE_PATHS } from "../../helpers/constants";
import { ENTRI_FLOW_ROUTES } from "../../3rdPartyIntegrations/Entri/utils/const";
import { PRODUCT_TYPES } from "../../constants/products.constants";

const useResetMailDomain = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const productHandler = useProduct();

  const { customDomain, coSiteDomain, purchasedDomain } = useSelector(selectMailDomain);

  const excludedRoutes = [
    PAGE_PATHS.PERSONA,
    PAGE_PATHS.ALL_DONE,
    ENTRI_FLOW_ROUTES.ROOT,
  ];

  useEffect(() => {
    // Only reset mail domain if not on excluded routes and if conditions are met
    const shouldResetDomain =
      !excludedRoutes.includes(location.pathname) && // Ensure we're not on excluded routes
      productHandler.productType === PRODUCT_TYPES.MAIL && // Check product type is MAIL
      purchasedDomain && // Ensure purchased domain exists
      (purchasedDomain === customDomain || purchasedDomain === coSiteDomain); // Check if purchased domain matches custom or coSite domain

    if (shouldResetDomain) {
      dispatch(resetMailDomainState()); // Reset mail domain state
    }
  }, []);
};

export default useResetMailDomain;
