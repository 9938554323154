export const deepClone = <T>(obj: T): T => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error("Error while deep cloning object", e);
    return obj;
  }
};

export const formatWithZeroPrefix = (num: number): string => {
  if (num < 10) return `0${num}`;
  return num.toString();
}
