import React, { FC } from "react";
import { TGroupedFeatures } from "../../../../types/plan.type";
import { usePlansContext } from "../context";
import styles from "./style.module.scss";

interface FeatureGroupProps {
  featureGroup: TGroupedFeatures[0];
}

const FeatureGroup: FC<FeatureGroupProps> = ({ featureGroup }) => {
  const {
    components: { FeatureRow },
    featureGroupTextMap,
  } = usePlansContext();
  return (
    <div>
      {featureGroup.categoryName && (
        <div className={styles.sectionHeader}>
          {featureGroupTextMap[featureGroup.categoryName]}
        </div>
      )}
      {featureGroup.features.map((feature) => (
        <FeatureRow key={feature.type} feature={feature} />
      ))}
    </div>
  );
};

export default FeatureGroup;
