import React from "react";
import { Popover } from "antd";

interface Props {
  content: React.ReactNode;
  trigger?: "click" | "hover" | "focus" | ("click" | "hover" | "focus")[];
  placement?: "top" | "left" | "right" | "bottom";
  overlayStyle?: React.CSSProperties;
  children: React.ReactNode;
  open: boolean;
  onOpenChange: (visible: boolean) => void;
}

const CustomPopover: React.FC<Props> = ({
  content,
  trigger = "click",
  placement = "bottom",
  overlayStyle,
  children,
  open,
  onOpenChange,
}) => {
  return (
    <Popover
      content={content}
      trigger={trigger}
      placement={placement}
      overlayStyle={overlayStyle}
      open={open}
      onOpenChange={onOpenChange}
    >
      {children}
    </Popover>
  );
};

export default CustomPopover;
