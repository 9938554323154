import { sendMedusaEvent } from "../../utils/medusa";

export const customerAccountSignedOut = (neoState) => {
  if (!neoState) return null;

  // event is only applicable to customer user type
  return sendMedusaEvent("customer_account_signed_out", neoState, {
    first_name: neoState.name,
    last_name: neoState.name,
    // linkToEntity: false,
    isPurchaseFlowCustomerSignedOutEvent: true,
  });
};
