import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../../../store/actions";
import {
  ANALYTICS_EVENTS,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../../helpers/constants";
import { TBillingCycle } from "../../../types/billing-cycle";
import { getNextDiscountedBillingCycle } from "../../../helpers/billing-cycle.helper";
import SummaryHeader from "../shared/SummaryHeader";
import BillingCycleDropdown from "../shared/BillingCycleDropdown";
import {
  isSiteDomainCustom,
  resetSiteMailboxState,
  selectSiteAdminMailbox,
  selectSiteDomainAttrs,
  selectSiteDomainPricing,
  selectSiteNoOfAdditionalMailboxes,
  selectSitePlan,
  selectSitePlanDetails,
  selectSitePurchaseDomain,
  setSiteNoOfAdditionalMailboxes,
  setSitePlan,
} from "../../../store/slices/site.slice";
import DomainSummary from "../shared/DomainSummary";
import PostTrialAmountSummary from "../shared/PostTrialAmountSummary";
import AmountPayableSummary from "../shared/AmountPayableSummary";
import SummaryFooter from "../shared/SummaryFooter";
import { calculateCharge } from "../../../helpers/site/calculate-charge.helper";
import { TPlan } from "../../../types/plan.type";
import { getTotalNoOfMailboxesAdded } from "../../../helpers/mailbox.helper";
import { selectMailPlanToUpSell } from "../../../store/slices/mail.slice";
import { logSiteBillingPageEvent } from "../../../telemetry/site/medusaEventsFunctions";
import {
  getSiteBuilderEventData,
  getVisitorProfileForMedusa,
} from "../../../telemetry/site/siteMedusaData";
import styles from "./style.module.scss";
import SiteSummary from "./SiteSummary";
import MailPlanSummary from "./MailPlanSummary";

type Props = {
  isVisible: boolean;
  onContinue: () => void;
};

export default function MailOrderSummary({ onContinue }: Props) {
  const dispatch = useDispatch();
  const domainPricing = useSelector(selectSiteDomainPricing);
  const isCustomDomain = useSelector(isSiteDomainCustom);
  const { duration, supportedBillingCycles } = useSelector(selectSitePlan);
  const plan = useSelector(selectSitePlanDetails) as TPlan;
  const { isCoSiteTrial } = useSelector(selectSiteDomainAttrs);
  const domain = useSelector(selectSitePurchaseDomain);
  const noOfAdditionalMailboxes = useSelector(
    selectSiteNoOfAdditionalMailboxes
  );
  const [billingCycleUpdated, setBillingCycleUpdated] = useState(false);
  const { updateUser } = bindActionCreators(userActions, useDispatch());
  const adminMailbox = useSelector(selectSiteAdminMailbox);
  const totalNoOfMailboxesAdded = getTotalNoOfMailboxesAdded(
    adminMailbox,
    noOfAdditionalMailboxes
  );
  const mailPlan = useSelector(selectMailPlanToUpSell);

  const {
    totalChargeableAmount,
    amountSaved,
    payableNow,
    perMailBoxCharge,
    mailboxCharges,
    siteCharge,
    perSiteCharge,
    domainCharges,
  } = useMemo(() => {
    return calculateCharge({
      plan,
      countOfMailboxes: totalNoOfMailboxesAdded,
      isCustomDomain,
      isCoSiteTrial,
      domainPricing,
      billingCycle: duration,
      mailPlan,
    });
  }, [
    plan,
    isCustomDomain,
    isCoSiteTrial,
    domainPricing,
    duration,
    totalNoOfMailboxesAdded,
    mailPlan,
  ]);

  const handleSubmit = () => {
    // TODO: hack to update directly in user store
    updateUser({
      payment_amount: payableNow,
      payment_for: "Site",
      total_site_price: +(siteCharge.toFixed(2)),
      total_mail_price: totalNoOfMailboxesAdded ? +(mailboxCharges.toFixed(2)) : 0,
      total_neo_domain_price: +(domainCharges.toFixed(2)),
      billing_cycle_updated: billingCycleUpdated,
    });
    onContinue();
    logSiteBillingPageEvent(ANALYTICS_EVENTS.ORDER_SUMMARY_REVIEWED, {
      billing_cycle_updated: billingCycleUpdated,
      ...getSiteBuilderEventData(),
      ...getVisitorProfileForMedusa(),
    });
  };

  const handleSelectChange = (newDuration: TBillingCycle) => {
    setBillingCycleUpdated(true);
    dispatch(setSitePlan({ duration: newDuration }));
  };

  const getNextSavingBillingCycle = () => {
    return getNextDiscountedBillingCycle(supportedBillingCycles, duration);
  };

  const onChangeOfNoOfAdditionalMailboxes = (value: number) => {
    dispatch(setSiteNoOfAdditionalMailboxes(value));
  };

  const removeMailPlan = () => {
    dispatch(resetSiteMailboxState());
  };

  const options = supportedBillingCycles.map((cycle: TBillingCycle) => ({
    value: cycle,
    pricePerMonth: plan.pricing[cycle]?.firstBillingCyclePrice || 0,
    discountPercentage: plan.pricing[cycle]?.discountPercentage || 0,
  }));

  const defaultOption =
    options.find((option: any) => option.value === duration) || options[0];

  return (
    <div>
      <SummaryHeader
        getNextSavingBillingCycle={getNextSavingBillingCycle}
        BillingCycleDropdown={() => (
          <BillingCycleDropdown
            options={options}
            defaultOption={defaultOption}
            onChange={(e) => e && handleSelectChange(e.value)}
            priceOf="/mo"
          />
        )}
      />
      <SiteSummary
        plan={plan}
        duration={duration}
        isCoSiteTrial={isCoSiteTrial}
        isCustomDomain={isCustomDomain}
        totalPayable={siteCharge}
        perSiteCharge={perSiteCharge}
      />
      {!isCustomDomain && (
        <DomainSummary
          duration={duration}
          domain={domain}
          domainPricing={domainPricing}
          isCoSiteTrial={isCoSiteTrial}
          domainCharges={domainCharges}
        />
      )}
      {totalNoOfMailboxesAdded ? (
        <MailPlanSummary
          adminMailbox={adminMailbox}
          noOfAdditionalMailboxes={noOfAdditionalMailboxes}
          totalPayable={mailboxCharges}
          isCoSiteTrial={isCoSiteTrial}
          isCustomDomain={isCustomDomain}
          onChangeOfNoOfAdditionalMailboxes={onChangeOfNoOfAdditionalMailboxes}
          removePlan={removeMailPlan}
          perMailboxCharge={perMailBoxCharge}
          planDisplayName={mailPlan.name}
          duration={duration}
        />
      ) : null}
      {isCustomDomain || isCoSiteTrial ? (
        <PostTrialAmountSummary
          finalAmount={totalChargeableAmount}
          finalAmountWithoutDiscount={totalChargeableAmount}
          trialDuration={
            isCustomDomain
              ? TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN
              : TRIAL_DURATIONS_IN_DAYS.CO_SITE
          }
        />
      ) : null}
      <AmountPayableSummary payableNow={payableNow} amountSaved={amountSaved} />
      <SummaryFooter handleSubmit={handleSubmit} />
    </div>
  );
}
