import { useFlowManager } from "./useFlowManager";

import SetupComplete from "../../pages/SetupComplete";
import SetupDns from "../../pages/SetupDns";
import CustomMobileSetup from "../../pages/CustomMobileSetup";
import Loader from "../../components/Loader/Loader";

/**
 * component act as Navigator
 * simple switch case decides which screen to load.
 * via navigation object we expose method for navigation
 */
const FlowManager = () => {
  const {
    SCREEN_TYPE_MAP,
    screenType,
    hasInterruption,
    finishingSetup,
    navigation,
  } = useFlowManager();

  switch (screenType) {
    case SCREEN_TYPE_MAP.CUSTOM_MOBILE_SETUP:
      return <CustomMobileSetup navigation={navigation} />;

    case SCREEN_TYPE_MAP.SETUP_COMPLETE:
      return <SetupComplete navigation={navigation} />;

    default:
      return <>
        { finishingSetup ? <Loader /> : null}
        <SetupDns hasInterruption={hasInterruption} navigation={navigation} finishingSetup={finishingSetup} />
      </>
  }
}

export default FlowManager;
