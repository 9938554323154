import { getConfig } from "./utils";
import { vwoCampaignsMap } from "./vwoCampaignsMap";
import { hasUserBeenBucketed, isUserOnTargetVariant } from "./vwoHelper";

// Helper function
const determineFeatureFlag = async (
  campaignKey: string,
  variantKey: string,
  defaultBehavior = false
) => {
  const { env } = getConfig();
  const { c_id, variations } = vwoCampaignsMap[env][campaignKey];

  const isUserBucketed = await hasUserBeenBucketed(c_id);

  if (isUserBucketed) {
    return (
      isUserOnTargetVariant(c_id, variations[variantKey]) || defaultBehavior
    );
  } else {
    return defaultBehavior;
  }
};

export const isDomainChargeable = async () => {
  return await determineFeatureFlag("chargeForCoSite", "paid", false);
};

/**
 * Function to determine the team mailbox to be shown post or pre domain order purchase
 * based on the VWO response
 * @returns {boolean}
 */
export const showTeamMailboxPostDomainPurchase = async () => {
  return await determineFeatureFlag(
    "additionalMailboxPurchase",
    "postCheckout",
    false
  );
};

export const shouldShowMoneyBackGuarantee = async () => {
  return await determineFeatureFlag(
    "moneyBackGuarantee",
    "show_money_back_guarantee",
    false
  );
};

export const isCoSite15DaysTrial = async () => {

  // the cs_trial_2 key is to be user used until the Lite Plan,
  // which will be a part of this experiment campaign is released in the coming weeks.
  // So to have proper data on VWO, we have made an early split of 33% each for cs_paid, cs_trial and cs_trial_2.
  // cs_trial and cs_trial_2 will essentially be the same - folks who are on the 15 days trial.
  // Once the Lite plan is released, the cs_trial_2 key will be removed from this function,
  // added to its own function and will be call 'all_lite' to decide if the user is on the lite plan or not.

  const isAllLitePlan2 = await determineFeatureFlag(
    "coSite15DaysTrial",
    "all_lite2",
    false
  );
  const isAllLitePlan = await determineFeatureFlag(
    "coSite15DaysTrial",
    "all_lite",
    false
  );

  return {
    isCSTrial: false,
    isAllLitePlan: isAllLitePlan || isAllLitePlan2,
  };
};

