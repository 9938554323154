import { UPDATE_CONFIG } from "./actionsConstants";

export const updateConfig = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CONFIG,
      payload: user,
    });
  };
};
