import { logMedusaAnalytics } from "../services/apiFunctions";
import { TMedusaPayload } from "../types/medusa";
import { ANALYTICS_EVENTS, NEO_PARTNER_ID_NUMBER } from "./constants";
import { getConfig } from "./utils";

const { env } = getConfig();
const partnerId =
  NEO_PARTNER_ID_NUMBER[env as keyof typeof NEO_PARTNER_ID_NUMBER];

export const triggerEmailTo = (
  email: string,
  templateName: string,
  extraParams: TMedusaPayload = {}
) => {
  const _extraParams = {
    partner_id: partnerId,
    ...(extraParams || {}),
  };
  logMedusaAnalytics({
    eventName: ANALYTICS_EVENTS.TRIGGER_EMAIL,
    product: 'email',
    type: "titan_customer",
    eventTs: Date.now(),
    entityId: "dummy_entity_id",
    attrs: {
      enyo_receiver_email: email,
      enyo_template_name: templateName,
      enyo_template_params: _extraParams,
    },
  });
};
