import { PRODUCT_TYPES } from "../constants/products.constants";
import { getProductFromUrlPath } from "../helpers/products.helper";
import { RootState } from "../types/store.type";
import { getMailboxesDataForMedusa } from "./mail/mailboxesAttributesMedusa";
import { getSiteMailboxesDataForMedusa } from "./site/mailboxesAttributesMedusa";

export const getMailboxesDataFromStore = (storeState?: RootState) => {
  //TODO: fix this
  const productType = getProductFromUrlPath(window.location.pathname);
  if (productType === PRODUCT_TYPES.SITE) {
    return getSiteMailboxesDataForMedusa(storeState);
  }
  return getMailboxesDataForMedusa(storeState);
};
