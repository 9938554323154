// class to handle storage related operations
// it takes care of storage key collision/conflict by appending the prefix
// or else it is possible then neo-signup might use same key which entri is using
// class also support events for specific key change
class CustomStorage {
  prefix = '[entri-neo]';
  cbList = {};
  memCache = {};

  listen(key, cb) {
    this.cbList[key] = this.cbList[key] || [];
    this.cbList[key].push(cb);
  }

  remove(key, cbToBeRemoved) {
    this.cbList[key] = this.cbList[key].filter(cb => cb === cbToBeRemoved);
  }

  dispatchChange(key) {
    (this.cbList[key] || []).forEach(cb => cb());
  }

  setItem(key, val, storageType = 'local') {
    const sKey = `${this.prefix}-${key}`;
    const sVal =
      typeof val === 'object' ? JSON.stringify(val) : (val || '').toString();
    this.memCache[key] = sVal;
    if (storageType === 'local') {
      localStorage.setItem(sKey, sVal);
    } else if (storageType === 'session') {
      sessionStorage.setItem(sKey, sVal);
    }
    this.dispatchChange(key);
  }

  removeItem(key) {
    delete this.memCache[key];
    localStorage.removeItem(`${this.prefix}-${key}`);
    sessionStorage.removeItem(`${this.prefix}-${key}`);
    this.dispatchChange(key);
  }

  getItem(key) {
    return (
      this.memCache[key] ||
      sessionStorage.getItem(`${this.prefix}-${key}`) ||
      localStorage.getItem(`${this.prefix}-${key}`) ||
      ''
    );
  }
}

export const customStorage = new CustomStorage();
