import classNames from "classnames";
import React, { useEffect } from "react";
import ReactModal from "react-modal";
import popupStyles from "./Popup.module.scss";

type Props = {
  children: React.ReactNode;
  isOpen?: boolean;
  overlayTransparent?: boolean;
  onClose?: () => void;
};

function Popup({
  children,
  isOpen = true,
  overlayTransparent,
  onClose,
}: Props) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      className={popupStyles.modal}
      overlayClassName={classNames(popupStyles.overlay, {
        [popupStyles.overlayTransparent]: overlayTransparent,
      })}
    >
      {onClose && <button onClick={onClose} className={"closeIcon"} />}
      {children}
    </ReactModal>
  );
}

export default Popup;
