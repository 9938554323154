import { useEffect, useRef } from "react";
import useHasHorizontalScroll from "./useHasHorizontalScroll";

const createPlaceholderElement = () => {
  const placeholder = document.createElement("div");
  placeholder.style.height = "0px";
  placeholder.style.margin = "0px";
  placeholder.style.padding = "0px";
  return placeholder;
};

const updateStickyElementStyles = ({
  stickyElement,
  placeholder,
  horizontalScrollableContainer,
}: {
  stickyElement: HTMLElement;
  placeholder: HTMLElement;
  horizontalScrollableContainer: HTMLElement;
}) => {
  const containerRect = horizontalScrollableContainer.getBoundingClientRect();
  const stickyElementRect = stickyElement.getBoundingClientRect();

  const isSticky =
    containerRect.top <= 0 && containerRect.bottom >= stickyElementRect.height;
  
  if (isSticky) {
    placeholder.style.height = `${stickyElementRect.height}px`;
    placeholder.style.margin = `${stickyElement.style.margin}`;
    if (stickyElement.style.position === "fixed") return;
    stickyElement.style.position = "fixed";
    stickyElement.style.top = "0";
    stickyElement.style.left = `-${horizontalScrollableContainer.scrollLeft}px`;
    stickyElement.style.zIndex = "2";
    placeholder.style.height = `${stickyElementRect.height}px`;
    return;
  }
  stickyElement.style.position = "sticky";
  stickyElement.style.top = "";
  stickyElement.style.left = "";
  stickyElement.style.width = "";
  stickyElement.style.zIndex = "";
  placeholder.style.height = "0px";
  placeholder.style.margin = "0px";
};

const useStickyElement = <T extends HTMLElement, U extends HTMLElement>() => {
  const verticalScrollableContainerRef = useRef<T>(null);
  const stickyElementRef = useRef<U>(null);
  const { horizontalScrollableContainerRef, hasHorizontalScroll } =
    useHasHorizontalScroll<U>();

  useEffect(() => {
    const verticalScrollableContainer =
      verticalScrollableContainerRef.current ||
      document.scrollingElement ||
      document.documentElement;
    const listenToDocumentScroll = !verticalScrollableContainerRef.current;
    const stickyElement = stickyElementRef.current;
    const horizontalScrollableContainer =
      horizontalScrollableContainerRef.current;

    if (
      !horizontalScrollableContainer ||
      !verticalScrollableContainer ||
      !stickyElement ||
      !hasHorizontalScroll
    )
      return;

    const placeholder = createPlaceholderElement();
    stickyElement.parentNode?.insertBefore(placeholder, stickyElement);

    const handleScroll = () => {
      updateStickyElementStyles({
        stickyElement,
        placeholder,
        horizontalScrollableContainer,
      });
    };

    const handleHorizontalScroll = () => {
      if (stickyElement.style.position === "fixed") {
        stickyElement.style.left = `${-horizontalScrollableContainer.scrollLeft}px`;
      }
    };

    const addEventListeners = () => {
      if (listenToDocumentScroll) {
        document.addEventListener("scroll", handleScroll);
      } else {
        verticalScrollableContainer.addEventListener("scroll", handleScroll);
      }
      horizontalScrollableContainer.addEventListener(
        "scroll",
        handleHorizontalScroll
      );
    };

    const removeEventListeners = () => {
      if (listenToDocumentScroll) {
        document.removeEventListener("scroll", handleScroll);
      } else {
        verticalScrollableContainer.removeEventListener("scroll", handleScroll);
      }
      horizontalScrollableContainer.removeEventListener(
        "scroll",
        handleHorizontalScroll
      );
    };

    addEventListeners();
    return () => {
      removeEventListeners();
      placeholder.remove();
    };
  }, [
    hasHorizontalScroll,
    verticalScrollableContainerRef.current,
    stickyElementRef.current,
  ]);

  return {
    verticalScrollableContainerRef,
    stickyElementRef,
    horizontalScrollableContainerRef,
  };
};

export default useStickyElement;
