import { LOGOUT, UPDATE_ERRORS } from "../actions/actionsConstants";

const initialState = {
  emailCheckFailCount: {},
  passwordCheckFailCount: {},
};

const errors = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ERRORS:
      return {
        ...state,
        ...action.payload,
      };

    case LOGOUT:
      return initialState;  

    default:
      return {
        // since we added a new `passwordCheckFailCount` key, we're returning a merge of both states
        // for migration purposes to ensure that new keys are available for future and the app does not crash for
        // returning users
        ...initialState,
        ...state,
      };
  }
};

export default errors;
