import { SUPPORTED_QUERY_PARAMS } from "./const";
import { customStorage } from "./customStorage";

/**
 * function to parse DNS records in format which entri supports
 * @param {[object]} records
 * @returns [{object}]
 * @link https://developers.entri.com/docs/install#4-create-the-configuration-object
 */
export const parseDnsRecords = (records, dnsRecordsToParse) => {
  const parser = {
    mx: (r) => ({
      type: "MX",
      host: "@",
      value: r,
      priority: 20,
      ttl: 3600,
    }),
    spf: (r) => ({
      type: "TXT",
      host: "@",
      value: r,
      ttl: 3600,
    }),
    dkim: (r) => ({
      type: "TXT",
      host: `${r.selector}._domainkey`,
      value: r.publicKey,
      ttl: 3600,
    }),
    domainOwnershipTxt: (r) => ({
      type: "TXT",
      host: "@",
      value: r,
      ttl: 14400,
    }),
    a: (r) => ({
      type: "A",
      host: "@",
      value: r,
      ttl: 3600,
    }),
    cname: (r) => ({
      type: "CNAME",
      host: "www",
      value: r,
      ttl: 3600,
    })
  }

  return Object.keys(records)
    .filter(k => dnsRecordsToParse.indexOf(k) !== -1)
    .reduce((pv, cv) =>
      [...pv, ...(parser[cv] ? records[cv] : []).map(r => parser[cv](r))], []);
}

export const parseLookupParam = (lookups) => (lookups || ["mx", "spf", "ns", "a", "cname"])
  .reduce((acc, e, i) => `${acc}${i ? '&' : ''}lookups=${e}`, '');

/**
 * helper to return all supported query params
 * @returns { [key]: value }
 */
export const getSupportedQueryParams = (() => {
  const params = new URLSearchParams(window.location.search);
  const supportedParamsMap = {};

  for(var pKey of params.keys()) {
    const pVal = params.get(pKey);
    if (SUPPORTED_QUERY_PARAMS[pKey]) {
      supportedParamsMap[pKey] = pVal;
    }
  }

  return () => supportedParamsMap;
})();

// return truthy if its mobile (screen with mobile dimension)
export const isMobileDimension = () => window.innerWidth <= 640;

/**
 * function to decide whether it purchase flow or auto login
 * if we have token in URL then we consider it as auto login flow
 * else purchase flow
 * @returns boolean
 */
export const isFlowInitViaAutoLogin = () => {
  const allParams = getSupportedQueryParams();
  // token - comes from cp
  // jwt, partnerId - comes from mail
  return allParams?.token || (allParams?.jwt && allParams?.partnerId);
}

// function to return time in millisec
export const getMilliSeconds = (h = 0, m = 0, s = 0)  =>
  ((h * 60 * 60 + m * 60 + s) * 1000);

// helper to take care of JSX classname
export const cj = classes => classes.join(" ");

/**
 * Function to remove '/' at the end of url
 * @param url string
 * @returns string
 */
export const sanitizeURL = (url) => {
  return url.replace(/\/+$/g, '');
};

/**
 * basic logger module to print and log the msg
 * also you can find all logs under `LOG_KEY`
 * each log entri has `now` which can be used later for log rotation
 * @param  {...any} props
 */
export const logger = (...props) => {
  try {
    const LOG_KEY = 'entri-logs';
    console.log(...props);
    const logs = JSON.parse(customStorage.getItem(LOG_KEY) || '[]');
    logs.push({ ...props, now: Date.now() });
    const maxNoOfLogsToKeep = 200;
    const start = logs.length > maxNoOfLogsToKeep
      ? logs.length - maxNoOfLogsToKeep
      : 0;
    const end = logs.length;
    customStorage.setItem(LOG_KEY, logs.slice(start, end));
  } catch (e) {
    console.error('error from logger', e);
  }
}