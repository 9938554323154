import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAdditionalMailbox, TAdminMailbox } from "../../types/mailbox.type";

export interface MailboxState {
  adminMailbox: TAdminMailbox;
  additionalMailboxes: TAdditionalMailbox[];
  noOfAdditionalMailboxes: number;
}

const initialMailboxState: MailboxState = {
  adminMailbox: {},
  additionalMailboxes: [],
  noOfAdditionalMailboxes: 0,
};

// Factory to create mailbox reducer
export const createMailboxSlice = (scope: "mail" | "site") => {
  return createSlice({
    name: `${scope}/mailbox`,
    initialState: initialMailboxState,
    reducers: {
      setMailbox(
        state: MailboxState,
        action: PayloadAction<Partial<MailboxState>>
      ) {
        return { ...state, ...action.payload };
      },
      setNoOfAdditionalMailboxes(state: MailboxState, action: PayloadAction<number>) {
        state.noOfAdditionalMailboxes = action.payload;
      },
      setAdminMailbox(
        state: MailboxState,
        action: PayloadAction<TAdminMailbox>
      ) {
        state.adminMailbox = action.payload;
      },
      setAdditionalMailboxes(
        state: MailboxState,
        action: PayloadAction<TAdditionalMailbox[]>
      ) {
        state.additionalMailboxes = action.payload;
      },
      resetMailboxState(state) {
        return initialMailboxState;
      },
    },
  });
};

export type MailboxReducer = ReturnType<typeof createMailboxSlice>;
