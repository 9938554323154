import classNames from "classnames";
import CPButton, { CP_BUTTON_TYPE } from "../../components/CPButton";
import WebmailButton from "../../components/WebmailButton";

import dnsSetupCompleteIcon from '../../assets/images/dns-setup-complete-icon.svg';
import ENBridge from "../../bridge";
import SiteSetupComplete from "../../assets/images/site-setup-complete.png";
import SiteButton from "../../components/SiteButton";
import s from './SetupComplete.module.scss';
import { useSetupComplete } from "./useSetupComplete";

const SetupComplete = () => {
  const {
    email,
    domain,
    cpToken,
    accountId,
    webmailUrl,
    isCustomer,
    isPurchaseFlow,
    isSiteSetupMode,
    onLinkClick,
    copyWebmailUrl,
    onCpClickMedusaEventsToDispatch,
    onWebmailClickMedusaEventsToDispatch,
    sendSiteRedirectEvent
  } = useSetupComplete();
  const { MobileStoreIconsRenderer } = ENBridge.sharedComponents;

  if (isSiteSetupMode) {
    return (
      <div className={classNames([s['dns-setup-complete'], s['setup-mode-site']])}>
        <h1>{`${domain} is now connected to Site`}<i /></h1>
        <div>
          <h3>Next step: Publish your Site</h3>
          <p>Head over to Neo Site Builder and click <b>Publish</b></p>
          <img src={SiteSetupComplete} alt="illustration-img" />
          <SiteButton onClick={sendSiteRedirectEvent} label="Go to Neo Site Builder" />
        </div>
      </div>
    )
  }

  return (
    <div className={s['dns-setup-complete']}>
      <img src={dnsSetupCompleteIcon} alt="dns-setup-complete-icon" />
      <div>
        <h1>Congratulations!</h1>
        {
          isCustomer && isPurchaseFlow
            ?
              <>
                <span>{email}</span>
                <p>Your mailbox is now ready for you!</p>
              </>
            :
              <p>
                Your <span>@{domain}</span> mailbox is now ready for you!
              </p>
        }
        <div className={s.actions}>
          <WebmailButton
            text="Access webmail"
            onClick={onWebmailClickMedusaEventsToDispatch}
          />
          <CPButton
            type={CP_BUTTON_TYPE.BUTTON}
            text="Go to Neo Admin Panel"
            queryParamsToBePassed={{ isFromNeo: true }}
            onClick={onCpClickMedusaEventsToDispatch}
          />
        </div>
        <div className={s['mobile-instructions']}>
          <MobileStoreIconsRenderer
            email={email}
            account_id={accountId}
            token={cpToken}
            onLinkClick={onLinkClick}
          />
          <div>
            <p>Access emails on desktop/laptop on</p>
            <div className={s['copy-url']}>
              <p>{webmailUrl}</p>
              <div onClick={copyWebmailUrl}>Copy</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupComplete;