import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import SearchIcon from "../../assets/search.svg";
import { getDomainStateBitSet } from "../../helpers/bitSetHelper";
import {
  ANALYTICS_EVENTS,
  DOMAIN_ERROR_CODE,
  DOMAIN_TYPE,
  GA_EVENTS,
  NEO_OFFERING_EVENT_VALUE,
  OFFERING,
} from "../../helpers/constants";
import { processDomainPricing } from "../../helpers/domainPricingHelper";
import { logGoogleAnalyticsEvent } from "../../helpers/gaEventHelper";
import {
  domainNameLength,
  getCoSiteDomainWithTld,
  getCoSiteTld,
  isDomainValid,
  removeProtocolFromDomain,
} from "../../helpers/utils";
import { checkMailOrderAvailability } from "../../services/apiFunctions";
import { userSelector } from "../../store/selectors";
import {
  TDomainUnavailableReasons,
  domainUnavailableReasons,
} from "../../constants/domain.constant";
import Button from "../Common/Button/Button";
import Input from "../Common/Input/Input";
import Price from "../Common/Price";
import Header from "../Header";
import PromoError from "../Promo/PromoError";
import ReviewQuote from "../ReviewQuote";
import DomainPricing from "./DomainPricing";
import styles from "./styles.module.scss";
import { TLogEventProps } from "../../types/global";
import { TDomainOwnershipDetails, TDomainPricing } from "./Common/types";
import { DomainState } from "../../store/slices/domain.slice";
import { bindActionCreators } from "redux";
import { userActions } from "../../store/actions";

const {
  CUSTOMER_ACCOUNT_LINKED,
  DOMAIN_AVAILABILITY_CHECKED,
  DOMAIN_SELECTED,
} = ANALYTICS_EVENTS;

const UNAVAILABLE = "Unavailable";
const GET_FREE_DOMAIN = "Get free domain";
const GET_DOMAIN = "Get domain";
const DOMAIN_LENGTH_LESS_THAN_FIVE_ERROR_MSG =
  "Domain names less than 5 characters are not available currently. For enquiries, write to hello@neo.space";

interface Props {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  businessName?: string;
  onGetDomain: () => void;
  coSiteDomain: DomainState["coSiteDomain"];
  domainPricing: DomainState["pricing"];
  isDomainChargeable: DomainState["attrs"]["isDomainChargeable"];
  WhyCoSiteDomain?: React.ReactNode;
  domainPricingFor: string;
  logEvent: (props: TLogEventProps) => void;
  initializeOffering: () => void;
  updateDomainPricing: (props: TDomainPricing) => void;
  onGetFreeDomain: (props: TDomainOwnershipDetails) => void;
}

const GetDomain: React.FC<Props> = (props) => {
  const {
    title,
    subTitle,
    onGetDomain,
    coSiteDomain,
    domainPricing,
    isDomainChargeable,
    WhyCoSiteDomain,
    businessName,
    domainPricingFor,
    logEvent,
    initializeOffering,
    updateDomainPricing,
    onGetFreeDomain,
  } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { customer_id, token } = user;
  const { updateUser } = bindActionCreators(userActions, dispatch);
  const coSiteTld = getCoSiteTld();
  const [inputValue, setInputValue] = useState<string>(
    () => coSiteDomain || businessName || ""
  );
  const [error, setError] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [isAvailable, setIsAvailable] = useState<boolean>(true);
  const [reason, setReason] = useState<string | null>(null);
  const [preview, setPreview] = useState<boolean>(false);
  const [previousInputValue, setPreviousInputValue] = useState<string>("");

  const { CO_DOT_SITE } = NEO_OFFERING_EVENT_VALUE;
  useEffect(() => {
    initializeOffering();
    if (inputValue) {
      handleSearch();
    }

    /**
     * We have paused charge co.site vwo campaign that's why are commenting below lines
     * so that we don't make unnecessary call to vwo for this campaign data
     * By default marking as domain is chargeable
     */
    // onVWOLoaded().then(async () => {
    //   const shouldChargeForDomain = await shouldDomainBeCharged();
    // TODO: for site
    //   dispatch(setMailDomainAttrs({ isDomainChargeable: shouldChargeForDomain }));
    // });
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueWithoutSpace = value.replaceAll(" ", "").toLowerCase();
    setInputValue(valueWithoutSpace);
    setError("");
  };

  const handleSearch = () => {
    if (inputValue) {
      const sanitizedDomainValue =
        removeProtocolFromDomain(inputValue).toLowerCase();
      if (
        isDomainValid(sanitizedDomainValue) ||
        isDomainValid(`${sanitizedDomainValue}.${coSiteTld}`)
      ) {
        const COSITE_DOMAIN = getCoSiteDomainWithTld(
          sanitizedDomainValue,
          coSiteTld
        );
        if (domainNameLength(sanitizedDomainValue) < 5) {
          setError(DOMAIN_LENGTH_LESS_THAN_FIVE_ERROR_MSG);
          setPreview(false);
          logEvent({
            eventName: DOMAIN_AVAILABILITY_CHECKED,
            data: {
              domain_name: COSITE_DOMAIN,
              domain_availability: "Unavailable",
              error: "Short domain name",
              domain_ownership: DOMAIN_TYPE.DONT_HAVE_DOMAIN,
              domain_name_length: domainNameLength(COSITE_DOMAIN),
              source_hook: location?.state?.sourceHook || "",
            },
          });
        } else {
          setPreview(true);
          setPreviousInputValue(sanitizedDomainValue);
          checkAvailability(COSITE_DOMAIN);
        }
      } else {
        setError("Domain name is not valid");
        setPreview(false);
      }
    } else {
      setError("Please enter domain name");
      setPreview(false);
    }
  };

  const checkAvailability = (domainName: string) => {
    setProcessing(true);

    let payload = {
      domainName,
      userId: customer_id,
      domainState: getDomainStateBitSet({ isDomainChargeable: true }),
    };

    checkMailOrderAvailability(payload)
      .then((resp) => {
        const response = resp.data;
        if (response.statusCode !== 200) {
          const { code } = response;
          const { INVALID_DOMAIN, SHORT_DOMAIN_NAME } = DOMAIN_ERROR_CODE;
          switch (code) {
            case INVALID_DOMAIN:
              setIsAvailable(false);
              setError(response.description);
              return;

            case SHORT_DOMAIN_NAME:
              setIsAvailable(false);
              setError(DOMAIN_LENGTH_LESS_THAN_FIVE_ERROR_MSG);
              return;

            default:
              break;
          }
          console.error(
            "Error fetching domain availability information",
            response
          );
          return;
        }
        const {
          isDomainAvailable,
          unAvailabilityReason = "",
          domainPricing = {},
          currency,
        } = response;

        processDomainPricing(domainPricing);
        updateUser({
          currency,
        });
        updateDomainPricing({
          domainPricing,
        });
        if (isDomainAvailable) {
          setIsAvailable(true);
        } else {
          setIsAvailable(false);
          setReason(unAvailabilityReason);
        }

        logGoogleAnalyticsEvent(GA_EVENTS.DOMAIN_AVAILABILITY_CHECKED);
        logEvent({
          eventName: DOMAIN_AVAILABILITY_CHECKED,
          data: {
            domain_name: domainName,
            domain_availability: isDomainAvailable
              ? "Available"
              : domainUnavailableReasons[
                  unAvailabilityReason as keyof TDomainUnavailableReasons
                ]?.event_availability_message,
            error: isDomainAvailable
              ? undefined
              : domainUnavailableReasons[
                  unAvailabilityReason as keyof TDomainUnavailableReasons
                ]?.event_error_message || unAvailabilityReason,
            domain_ownership: DOMAIN_TYPE.DONT_HAVE_DOMAIN,
            domain_name_length: domainNameLength(domainName),
            source_hook: location.state?.sourceHook || "",
          },
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleGetFreeDomain = () => {
    const coSiteDomain = getCoSiteDomainWithTld(previousInputValue, coSiteTld);
    onGetFreeDomain({
      coSiteDomain: coSiteDomain,
      offering: OFFERING.COSITE,
    });
    logEvent({
      eventName: DOMAIN_SELECTED,
      data: {
        domain_name: coSiteDomain,
        neo_offering: CO_DOT_SITE,
        source_hook: "Domain availability check page",
      },
    });

    if (!!token) {
      logEvent({
        eventName: CUSTOMER_ACCOUNT_LINKED,
        data: {
          user_type: "Customer",
          customer_account_linked_via: "Already signed in",
          dummy_entity_Id: true,
        },
        flag: true,
      });
    }
    onGetDomain();
  };

  const getNeoTldRegistrationNoteText = () => {
    return (
      <p className={styles.partnerInfo}>
        Neo currently does not offer domain registration for the other
        extensions (like .com, .net, etc.). Buy a paid domain from a registrar,
        or get a free .co.site domain from Neo.
      </p>
    );
  };

  const handleUserKeypress = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        handleSearch();
      }
    },
    [inputValue, handleSearch]
  );

  useEffect(() => {
    document.addEventListener("keypress", handleUserKeypress);
    return () => {
      document.removeEventListener("keypress", handleUserKeypress);
    };
  }, [handleUserKeypress]);

  return (
    <>
      <PromoError className={styles.promoError} />
      <Header>{title}</Header>
      <div className={styles.purchaseDomainWrapper}>
        {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
        <div className={styles.inputWrapper}>
          <Input
            name={"yourName"}
            placeholder="Type domain you want, like acme.co.site"
            type={"text"}
            onChange={(e) => handleInputChange(e)}
            defaultValue={inputValue}
            hasError={Boolean(error)}
            errorMessage={error}
            autoCapitalize={"none"}
            className={styles.coSiteDomainInput}
          />
          <div className={styles.searchIcon} onClick={() => handleSearch()}>
            <img src={SearchIcon} alt={"search icon"} />
            <div>Search</div>
          </div>
        </div>
        {preview ? (
          <div className={styles.boxWrapper}>
            <div>
              <div className={styles.coSiteDomainName}>
                {previousInputValue?.split(".")[0]}
                <span>.{coSiteTld}</span>
              </div>
              {isAvailable ? (
                <>
                  {!isDomainChargeable && <strong>FREE with Email</strong>}
                  {!processing && (
                    <>
                      <p className={styles.amountPayable}>
                        <Price value={0} />
                      </p>

                      <DomainPricing
                        isDomainChargeable={isDomainChargeable}
                        domainPricing={domainPricing}
                        pricingFor={domainPricingFor}
                      />
                    </>
                  )}
                </>
              ) : (
                !processing && (
                  <p className={styles.error}>
                    {domainUnavailableReasons[
                      reason as keyof TDomainUnavailableReasons
                    ]?.error_message || reason}
                  </p>
                )
              )}

              <Button
                type={"primary"}
                onClick={handleGetFreeDomain}
                isProcessing={processing}
                className={classNames({
                  [styles.disabled]: !isAvailable,
                })}
              >
                {processing || isAvailable
                  ? isDomainChargeable
                    ? GET_DOMAIN
                    : GET_FREE_DOMAIN
                  : UNAVAILABLE}
              </Button>
              {!isAvailable && (
                <p className={styles.searchOtherDomain}>
                  Search a different domain name instead
                </p>
              )}
              {WhyCoSiteDomain && <div>{WhyCoSiteDomain}</div>}
            </div>
            <div className={styles.verticalBorder} />
            {isDomainValid(previousInputValue) ? (
              <div>
                <p className={styles.customDomain}>{previousInputValue}</p>
                {getNeoTldRegistrationNoteText()}
              </div>
            ) : (
              <div className={styles.customDomainExt}>
                <p>Looking for a different domain extension?</p>
                {getNeoTldRegistrationNoteText()}
              </div>
            )}
          </div>
        ) : (
          <ReviewQuote />
        )}
      </div>
    </>
  );
};

export default GetDomain;
