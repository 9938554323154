import React, { useState } from "react";
import Button from "../Common/Button/Button";
import { useRedirection } from "../../hooks/useRedirection";
import styles from "./MailboxCheckoutModal.module.scss";
import { useBulkMailboxPurchase } from "./useBulkMailboxPurchase";

const CustomDomainPayment = ({
  setCountOfMailboxPurchaseFailed,
  setMailboxPurchaseSuccess,
}) => {
  const { handleCheckoutRedirection } = useRedirection();
  const { handleBulkMailboxPurchase } = useBulkMailboxPurchase({
    setCountOfMailboxPurchaseFailed,
    setMailboxPurchaseSuccess,
  });

  const [loading, setLoading] = useState(false);
  const onPaymentSuccess = () => {
    /**
     * skipping payment as this is custom domain
     * create email in bulk
     */
    setLoading(true);
    handleBulkMailboxPurchase();
  };

  return (
    <div className={styles.customDomainBtnWrapper}>
      <div
        className={styles.secondaryBtn}
        onClick={() => handleCheckoutRedirection()}
      >
        Add later
      </div>
      <Button
        type={"primary"}
        isProcessing={loading}
        onClick={() => onPaymentSuccess()}
      >
        Continue
      </Button>
    </div>
  );
};

export default CustomDomainPayment;
