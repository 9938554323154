import React from "react";
import styles from "./style.module.scss";

export default function Footer({ className }: { className?: string }) {
  return (
    <div className={`${styles.footer} ${className}`}>
      Trusted by <b>30,000 </b> + small businesses
    </div>
  );
}
