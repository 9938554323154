import React from "react";
import { Tooltip as ReactTooltip, TooltipRefProps } from "react-tooltip";
import TooltipIcon from "../../../assets/tooltip-icon.svg";
import tooltipStyles from "./Tooltip.module.scss";
import classNames from "classnames";

const Tooltip = ({
  id,
  tooltipText,
  place = "bottom",
  tooltipIconClassName,
}: {
  id: string;
  tooltipText: React.ReactNode;
  place?: TooltipRefProps["place"];
  tooltipIconClassName?: string;
}) => {
  return (
    <>
      <img
        src={TooltipIcon}
        alt="info"
        data-tooltip-id={id}
        className={classNames(tooltipStyles.tooltipIcon, tooltipIconClassName)}
      />
      <ReactTooltip
        id={id}
        key={id}
        className={"tooltip"}
        positionStrategy="fixed"
        place={place}
        classNameArrow={"tooltipArrow"}
        border={"1px solid #b4b4b4"}
      >
        {tooltipText}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
