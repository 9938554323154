import { useMemo } from "react";
import { getDiscountPercentageForBillingCycle } from "../../helpers/billing-cycle.helper";
import { BILLING_CYCLE_SELECTION_LABELS } from "../../helpers/constants";
import { getSupportedBillingCycleForAllPlans } from "../../helpers/site/billing-cycle.helper";
import useUserVisiblePlans from "./useUserVisiblePlans";

export function useAvailableBillingCycles() {
  const userPlans = useUserVisiblePlans();
  const cycles = useMemo(() => {
    const supportedBillingCycles = getSupportedBillingCycleForAllPlans();
    return supportedBillingCycles.map((duration) => {
      return {
        duration,
        label: BILLING_CYCLE_SELECTION_LABELS[duration],
        discountPercentage: getDiscountPercentageForBillingCycle(
          userPlans,
          duration
        ),
      };
    });
  }, [userPlans]);
  return {
    cycles,
  };
}
