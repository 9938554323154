import { useState } from "react";
import ENBridge from "../../bridge";
import { useNeoContext } from "../../store/neo/useNeoContext";

export const useWebmailButton = ({ onClick }) => {
  const { state } = useNeoContext();
  const [isLoading, setIsLoading] = useState(false);
  const { webmailAutoLoginToken, email } = state;
  const { redirectToWebmail } = ENBridge.handlers;

  // action redirect to webmail
  const onWebmailButtonClick = async () => {
    // avoid multi click
    if (isLoading) return false;

    // send medusa events
    setIsLoading(true);
    onClick && await onClick();
    setIsLoading(false);

    redirectToWebmail(email, webmailAutoLoginToken);
  }

  return {
    onWebmailButtonClick
  }
}