import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { appSelector } from "../../../store/selectors";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import SignIn from "../index";
import { logCustomerEvent } from "../../../telemetry/medusaEventsFunctions";
import LoginNudge from "./LoginNudge";
import { selectMailPurchaseDomain } from "../../../store/slices/mail.slice";

const {
  CUSTOMER_ACCOUNT_SIGNED_IN,
  CUSTOMER_ACCOUNT_SIGNED_IN_FAILED,
  CUSTOMER_ACCOUNT_LINKED,
  CUSTOMER_ACCOUNT_FORGOT_PASSWORD_CLICKED,
} = ANALYTICS_EVENTS;

const MailSignIn = () => {
  const navigateTo = useNavigate();
  const { search_params } = useSelector(appSelector);
  const domain = useSelector(selectMailPurchaseDomain);

  const navigateToSignup = () => {
    navigateTo(
      {
        pathname: PAGE_PATHS.SIGN_UP,
        search: search_params,
      },
      { replace: true }
    );
  };

  const navigateAwayFromSignIn = () => {
    if (!!domain) {
      navigateTo(
        {
          pathname: PAGE_PATHS.ADD_MAILBOX,
          search: search_params,
        },
        { replace: true }
      );
    } else {
      navigateTo(
        {
          pathname: PAGE_PATHS.GET_STARTED,
          search: search_params,
        },
        { replace: true }
      );
    }
  };

  const navigateAfterLogin = () => {
    if (!domain) {
      navigateTo(
        {
          pathname: PAGE_PATHS.GET_STARTED,
          search: search_params,
        },
        { replace: true }
      );
    } else {
      navigateTo(
        {
          pathname: PAGE_PATHS.ADD_MAILBOX,
          search: search_params,
        },
        { replace: true }
      );
    }
  };

  const logEvent = ({ eventName, data, flag }) => {
    switch (eventName) {
      case CUSTOMER_ACCOUNT_SIGNED_IN_FAILED:
      case CUSTOMER_ACCOUNT_LINKED:
      case CUSTOMER_ACCOUNT_SIGNED_IN:
      case CUSTOMER_ACCOUNT_FORGOT_PASSWORD_CLICKED:
        logCustomerEvent(eventName, data, flag);
        break;
      default:
        console.log("Invalid event name");
    }
  };

  return (
    <SignIn
      LoginNudge={<LoginNudge />}
      navigateToSignup={navigateToSignup}
      navigateAwayFromSignIn={navigateAwayFromSignIn}
      navigateAfterLogin={navigateAfterLogin}
      logEvent={logEvent}
    />
  );
};

export default MailSignIn;
