import React from "react";
import Popup from "../Common/Popup/Popup";
import styles from "./Modal.module.scss";

type Props = {
  loadingSubtitle?: React.ReactNode;
};

const OrderProcessingModal = ({ loadingSubtitle }: Props) => {
  return (
    <Popup isOpen={true} overlayTransparent={false}>
      <div className={styles.center}>
        <div className={"spinner"} />
        <p className={styles.processingOrder}>Processing order</p>
        <p className={styles.subTitle}>
          {loadingSubtitle ? loadingSubtitle : "Completing payment..."}
        </p>
        <p className={styles.doNotClose}>
          Please do not close this window or click back button
        </p>
      </div>
    </Popup>
  );
};

export default OrderProcessingModal;
