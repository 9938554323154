import * as amplitude from "@amplitude/analytics-browser";

export const setAmplitudeUserProperty = (property, value) => {
  const identifyObj = new amplitude.Identify();
  identifyObj.set(property, value);
  amplitude.identify(identifyObj);
};

export const setAmplitudeUserProperties = (payload = {}) => {
  const identifyObj = new amplitude.Identify();

  Object.entries(payload).forEach(([property, value]) => {
    identifyObj.set(property, value);
  });

  console.log(identifyObj);
  amplitude.identify(identifyObj);
};

export const updateAmplitudeOrderUser = (orderId, payload = {}) => {
  if (!orderId) {
    console.error(
      "No order ID found. Unable to update Order User on amplitude."
    );
    return;
  }

  amplitude.setUserId(`0000O${orderId}`);
  setAmplitudeUserProperties(payload);
};

export const resetAmplitudeUserId = () => {
  amplitude.setUserId(null);
};
