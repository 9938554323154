import { createSlice, combineReducers, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../types/store.type";
import { OFFERING } from "../../helpers/constants";
import { LOGOUT } from "../actions/actionsConstants";
import { createDomainSlice } from "./domain.slice";
import { createPlanSlice } from "./plan.slice";
import { createMailboxSlice } from "./mailbox.slice";
import { createPlansSlice } from "./plans.slice";
import { TPlan } from "../../types/plan.type";
import { isFreePlan } from "../../helpers/plans.helper";

const siteDomainSlice = createDomainSlice("site");
const sitePlanSlice = createPlanSlice("site");
const siteMailboxSlice = createMailboxSlice("site");
const sitePlansSlice = createPlansSlice("site");

const combinedSiteReducer = combineReducers({
  domain: siteDomainSlice.reducer,
  plan: sitePlanSlice.reducer,
  mailboxToPurchase: siteMailboxSlice.reducer,
  plans: sitePlansSlice.reducer,
});

// Define the initial state for the site slice
const initialSiteState = combinedSiteReducer(undefined, { type: "" });

const siteSlice = createSlice({
  name: "site",
  initialState: initialSiteState,
  reducers: {
    // Additional site-specific reducers if needed
  },
  extraReducers: (builder) => {
    // Using extraReducers to handle the logout action, as it is defined in another slice
    builder.addCase(LOGOUT, () => {
      // Reset mail state to initial state on logout
      return initialSiteState;
    });

    builder.addDefaultCase(combinedSiteReducer); // Combine the sub-slice reducers
  },
});

export const {
  setDomain: setSiteDomain,
  setDomainPricing: setSiteDomainPricing,
  setDomainAttrs: setSiteDomainAttrs,
  resetDomainState: resetSiteDomainState,
} = siteDomainSlice.actions;
export const {
  setPlan: setSitePlan,
  setPlanDuration: setSitePlanDuration,
  setPlanId: setSitePlanId,
  resetPlan: resetSitePlan,
} = sitePlanSlice.actions;
export const { setPlans: setSitePlans } = sitePlansSlice.actions;
export const {
  setMailbox: setSiteMailbox,
  setNoOfAdditionalMailboxes: setSiteNoOfAdditionalMailboxes,
  setAdminMailbox: setSiteAdminMailbox,
  resetMailboxState: resetSiteMailboxState,
} = siteMailboxSlice.actions;

export default siteSlice.reducer;

export const selectSiteDomain = (state: RootState) => state.site.domain;
export const selectSiteDomainPricing = (state: RootState) =>
  state.site.domain.pricing;
export const selectSiteDomainAttrs = (state: RootState) =>
  state.site.domain.attrs;
export const selectSitePlan = (state: RootState) => state.site.plan;
export const selectSitePlans = (state: RootState) => state.site.plans;
export const selectSitePlanDetails = createSelector(
  selectSitePlans,
  selectSitePlan,
  (plans, { id }) => {
    return plans.find((plan) => plan.id === id);
  }
);
export const selectSitePlanDuration = (state: RootState) =>
  state.site.plan.duration;
export const selectSiteNoOfAdditionalMailboxes = (state: RootState) =>
  state.site.mailboxToPurchase.noOfAdditionalMailboxes;
export const selectSiteAdminMailbox = (state: RootState) =>
  state.site.mailboxToPurchase.adminMailbox;
export const selectSitePurchaseDomain = (state: RootState) => {
  const domain = state.site.domain;
  return domain.offering === OFFERING.COSITE
    ? domain.coSiteDomain
    : domain.customDomain;
};
export const isSiteDomainCustom = (state: RootState): boolean => {
  const { offering } = selectSiteDomain(state);
  return offering === OFFERING.CUSTOM_DOMAIN;
};

export const selectSiteFreePlan = createSelector(
  selectSitePlans,
  (plans: TPlan[]) => {
    return plans.find(isFreePlan) || plans[0];
  }
);
