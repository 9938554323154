import "core-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "regenerator-runtime/runtime";
import { getConfig } from "./helpers/utils";
import App from "./App";
import {
  initAmplitude,
  initApiServices,
  initHotjar,
  initSentry,
} from "./helpers/initialisers";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";

// Sentry
const { env } = getConfig();
if (env === "PROD") {
  // Initialize Sentry
  initSentry();

  // Initialize Hotjar
  initHotjar();
}

// Initialize Amplitude
initAmplitude();
// Initialize all API Services
initApiServices();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
