import React from "react";
import styles from "./styles.module.scss";

const AdminMailboxInfo: React.FC = () => {
  return (
    <div className={styles.adminMailboxInfo}>
      <div className={styles.infoIcon} />
      <span>
        This is the default <strong>admin</strong> email address. You can update
        or assign more admins later from the Admin Panel.
      </span>
    </div>
  );
};

export default AdminMailboxInfo;
