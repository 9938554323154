import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import Modal from "react-modal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getFormattedTrialEndDate, isMobile } from "../../helpers/utils";
import Button from "../Common/Button/Button";
import { TRIAL_DURATIONS_IN_DAYS } from "../../helpers/constants";
import styles from "./WhyTrialTooltip.module.scss";
import { isMailDomainCustom } from "../../store/slices/mail.slice";

interface TimelineItem {
  day: string;
  iconClass: string;
  text: string;
}

const timelineItems: TimelineItem[] = [
  {
    day: "Day 0",
    iconClass: "unlocked",
    text: "Access to all features in your Neo plan",
  },
  {
    day: "Day 10",
    iconClass: "unreadEmail",
    text: "Reminder about trial expiry",
  },
  {
    day: "Day 15",
    iconClass: "creditCard",
    text: "Your trial will end and you’ll be charged",
  },
];

interface TooltipContentProps {
  trialDuration: number;
  tooltipLocation: "billingInfo" | "cardDetails";
}

const TooltipContent: React.FC<TooltipContentProps> = ({
  trialDuration,
  tooltipLocation,
}) => (
  <div className={styles.infoContent}>
    <div className={styles.tooltipHeading}>
      {tooltipLocation === "cardDetails" &&
        `Don't worry, we won't charge you before the free trial ends.`}
      {tooltipLocation === "billingInfo" &&
        `This ensures uninterrupted service post trial. No charges during trial.`}
    </div>

    <div className={styles.timeline}>
      {timelineItems.map((item, index) => (
        <div key={index} className={styles.timelineItem}>
          <div
            className={classNames(
              styles.timelineItemIcon,
              styles[item.iconClass]
            )}
          />
          <div className={styles.timelineItemText}>
            <h2>{item.day}</h2>
            <p>{item.text}</p>
          </div>
        </div>
      ))}
    </div>

    <div>
      You can cancel anytime before {getFormattedTrialEndDate(trialDuration)} to
      avoid being charged.
    </div>
  </div>
);

interface TriggerProps {
  tooltipLocation: "billingInfo" | "cardDetails";
}

const Trigger: React.FC<TriggerProps> = ({ tooltipLocation }) => (
  <div
    data-tooltip-id={`whyTrial_${tooltipLocation}`}
    className={styles.whyTrial}
  >
    Why do we need this for free trial?
  </div>
);

interface WhyTrialTooltipProps {
  tooltipLocation: "billingInfo" | "cardDetails";
  placement?: "top" | "bottom" | "left" | "right";
}

const WhyTrialTooltip: React.FC<WhyTrialTooltipProps> = ({
  tooltipLocation,
  placement = "top",
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);

  // TODO: for site
  const isCustomDomain = useSelector(isMailDomainCustom);
  const trialDuration = isCustomDomain
    ? TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN
    : TRIAL_DURATIONS_IN_DAYS.CO_SITE;

  const handleModalOpen = () => setShowInfoModal(true);
  const handleModalClose = () => setShowInfoModal(false);

  return isMobile() ? (
    <>
      <div onClick={handleModalOpen}>
        <Trigger tooltipLocation={tooltipLocation} />
      </div>
      <Modal
        isOpen={showInfoModal}
        contentLabel="Why Trial Info Modal"
        onRequestClose={handleModalClose}
        className={styles.infoModal}
        overlayClassName={styles.infoModalOverlay}
      >
        <TooltipContent
          trialDuration={trialDuration}
          tooltipLocation={tooltipLocation}
        />
        <div className={styles.buttonWrapper}>
          <Button type="primary" onClick={handleModalClose}>
            Got it
          </Button>
        </div>
      </Modal>
    </>
  ) : (
    <>
      <Trigger tooltipLocation={tooltipLocation} />
      <ReactTooltip
        id={`whyTrial_${tooltipLocation}`}
        className={classNames("tooltip", styles.whyTrialTooltip)}
        place="bottom"
        border={"1px solid #b4b4b4"}
        positionStrategy="fixed"
        openOnClick
      >
        <TooltipContent
          trialDuration={trialDuration}
          tooltipLocation={tooltipLocation}
        />
      </ReactTooltip>
    </>
  );
};

export default WhyTrialTooltip;
