import { STATE_ACTIONS } from "../../utils/const";

export const entriReducer = (state, action) => {
  switch (action.type) {

    case STATE_ACTIONS.SET_ENTRI_CHECK_DOMAIN_PAYLOAD:
      return {
        ...state,
        entriToken: action.payload.entriToken,
        provider: action.payload.provider,
        setupType: action.payload.setupType
      }

    case STATE_ACTIONS.SET_GETTING_ENTRI_DETAILS:
      return {
        ...state,
        gettingEntriDetails: action.payload
      }

    case STATE_ACTIONS.SET_ERROR_FETCHING_ENTRI_DETAILS:
      return {
        ...state,
        errorFetchingEntriDetails: action.payload
      }

    default:
      return state;
  }
}