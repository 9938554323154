import ENBridge from "../../bridge"

export const useLogo = () => {
  const { redirectToNeoHome, getNeoHomeUrl } = ENBridge.handlers;
  const logoUrl = getNeoHomeUrl();

  // redirect to neo home
  const onLogoClick = (e) => {
    e.preventDefault();
    redirectToNeoHome();
  };

  return {
    logoUrl,
    onLogoClick
  }
}