import classNames from "classnames";
import React from "react";
import { TBillingCycle } from "../../../../types/billing-cycle";
import {
  BILLING_CYCLE_PERIOD_UNITS,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../../../helpers/constants";
import { getRoundedDecimal, isMobile } from "../../../../helpers/utils";
import Price from "../../../Common/Price";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import DomainPriceBreakup from "../DomainPriceBreakup";
import SummaryItem from "../SummaryItem";
import styles from "./style.module.scss";

interface DomainSummaryProps {
  duration: TBillingCycle;
  domain: string;
  isCoSiteTrial: boolean;
  domainPricing: any;
  domainCharges: number;
}

const DomainSummary: React.FC<DomainSummaryProps> = ({
  duration,
  domain,
  isCoSiteTrial,
  domainPricing,
  domainCharges,
}) => {

  const period = domainCharges ? "year" : BILLING_CYCLE_PERIOD_UNITS[duration];

  return (
    <SummaryItem columns={isMobile() ? 1 : 2}>
      <div>
        <strong>Domain</strong>
        <div>{domain}</div>
      </div>
      <div>
        <>
          <strong>
            <Price value={domainCharges ? getRoundedDecimal(domainCharges, 2, 'string') : domainCharges} />
          </strong>
          <div className={classNames("colorGreen", styles.domainFreeInfo)}>
            Free for 1st {period}
            {isCoSiteTrial && (
              <Tooltip
                id={"trial_period"}
                tooltipText={`Your 1st ${period} free will start after the ${TRIAL_DURATIONS_IN_DAYS.CO_SITE} days trial`}
              />
            )}
          </div>
          <DomainPriceBreakup
            duration={duration}
            domainPricing={domainPricing}
          />
        </>
      </div>
    </SummaryItem>
  );
};

export default DomainSummary;
