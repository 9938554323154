import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  ANALYTICS_EVENTS,
  EVENT_MAILBOX_TYPE,
  HEADER_TYPES,
  MAILBOX_ORDER_LIMIT_ERROR_MSG,
  MAILBOX_PAGE_SOURCE_HOOK,
  MAXIMUM_MAILBOX_LIMIT_COUNT,
  PAGE_PATHS,
  SOURCE_OF_MAILBOX_ADDITION,
} from "../../helpers/constants";
import {
  getCountOfTotalMailboxAdded,
  validateCustomEmail,
} from "../../helpers/emailHelpers";
import { onVWOLoaded } from "../../helpers/pageLoadedHelper";
import { showErrorToast } from "../../helpers/toastHelper";
import { countOfEachTypeMailbox } from "../../helpers/utils";
import { userActions } from "../../store/actions";
import { appSelector, userSelector } from "../../store/selectors";
import { logMailboxEvent } from "../../telemetry/medusaEventsFunctions";
import Button from "../Common/Button/Button";
import CustomInput from "../CustomInput/CustomInput";
import EmailRow from "../EmailRow/EmailRow";
import Header from "../Header";
import MailboxCheckoutModal from "../MailboxCheckoutModal";
import styles from "./AddTeamMailbox.module.scss";
import {
  isMailDomainCustom,
  selectMailAdditionalMailbox,
  selectMailAdminMailbox,
  selectMailPurchaseDomain,
  setMailAdditionalMailboxes,
} from "../../store/slices/mail.slice";

const SOURCE_HOOK = MAILBOX_PAGE_SOURCE_HOOK.TEAM_MAILBOX_PAGE;
const { TEAM_MAILBOX } = SOURCE_OF_MAILBOX_ADDITION;

const {
  MAILBOX_ADDED,
  MAILBOX_ADD_FAILED,
  MAILBOX_DELETED,
  TEAM_MAILBOX_ADD_VIEWED,
  TEAM_MAILBOX_ADD_CONTINUED,
} = ANALYTICS_EVENTS;

const AddTeamMailbox = () => {
  const { customMailboxInput, showTeamMailboxPostDomainPurchase } =
    useSelector(userSelector);
  const domain = useSelector(selectMailPurchaseDomain);
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);
  const adminMailbox = useSelector(selectMailAdminMailbox);
  const isCustomDomain = useSelector(isMailDomainCustom);
  const { search_params } = useSelector(appSelector);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { updateUser } = bindActionCreators(userActions, useDispatch());
  const [showCheckoutPopup, setShowCheckoutPopup] = useState(false);

  useEffect(() => {
    onVWOLoaded().then(() => {
      logMailboxEvent(TEAM_MAILBOX_ADD_VIEWED);
    });
  }, []);

  const { teamMailboxCount } = countOfEachTypeMailbox(additionalMailboxes);

  const [teamMailboxStateCount, setTeamMailboxStateCount] =
    useState(teamMailboxCount);

  useEffect(() => {
    // when team page mailbox count change, update in the local component state
    setTeamMailboxStateCount(teamMailboxCount);
  }, [teamMailboxCount]);

  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);

  const handleAdd = ({ email, username }) => {
    // add email to the mailbox order list (store)
    dispatch(
      setMailAdditionalMailboxes([
        {
          email,
          source: TEAM_MAILBOX,
          username,
        },
        ...additionalMailboxes,
      ])
    );
  };

  const handleDeleteMailbox = (deleteMailboxObj, source_hook) => {
    const updatedMailboxList = additionalMailboxes.filter(
      (item) => item !== deleteMailboxObj
    );

    dispatch(setMailAdditionalMailboxes([...updatedMailboxList]));

    logMailboxEvent(MAILBOX_DELETED, {
      email: deleteMailboxObj?.email,
      mailbox_type: EVENT_MAILBOX_TYPE.CUSTOM,
      source_hook: source_hook || SOURCE_HOOK,
    });
  };

  const handleContinue = async () => {
    const isError =
      customMailboxInput?.username && (await handleAddCustomMailbox());

    if (isError) {
      return;
    }

    if (teamMailboxStateCount === 0 && !customMailboxInput?.username) {
      // continue button is disabled
      return;
    }

    logMailboxEvent(TEAM_MAILBOX_ADD_CONTINUED, {
      mailboxes_added: teamMailboxStateCount,
      cta_clicked: "Continue",
    });

    const countOfMailboxAdded =
      getCountOfTotalMailboxAdded(additionalMailboxes);
    const { teamMailboxCount } = countOfEachTypeMailbox(additionalMailboxes);

    if (
      countOfMailboxAdded === MAXIMUM_MAILBOX_LIMIT_COUNT &&
      teamMailboxCount === 9
    ) {
      /**
       * if total number of mailboxes added on add-team-mailboxes page is 9 which means the mailbox limit count is reached
       * then don't redirect to add-generic-mailbox page
       */
      if (showTeamMailboxPostDomainPurchase) {
        setShowCheckoutPopup(true);
      } else {
        if (isCustomDomain) {
          navigateTo({
            pathname: PAGE_PATHS.PLANS,
            search: search_params,
          });
        } else {
          navigateTo({
            pathname: PAGE_PATHS.PREVIEW_SITE,
            search: search_params,
          });
        }
      }
    } else {
      navigateTo({
        pathname: PAGE_PATHS.ADD_GENERIC_MAILBOX,
        search: search_params,
      });
    }
  };

  // custom input mailbox
  const INPUT_EMAIL = `${customMailboxInput?.username}@${domain}`;

  const commonMedusaEventData = {
    email: INPUT_EMAIL,
    mailbox_type: EVENT_MAILBOX_TYPE.CUSTOM,
    source_hook: SOURCE_HOOK,
  };

  const { CUSTOM_DOMAIN_ERROR, COSITE_DOMAIN_ERROR } =
    MAILBOX_ORDER_LIMIT_ERROR_MSG;

  const handleAddCustomMailbox = async () => {
    const { isError, errorMessage } = await validateCustomEmail(
      INPUT_EMAIL,
      customMailboxInput?.username,
      adminMailbox,
      additionalMailboxes
    );

    // if input validation error return
    if (isError) {
      updateUser({
        customMailboxInput: {
          ...customMailboxInput,
          error: errorMessage,
        },
      });

      // log mailbox_add_failed medusa event
      logMailboxEvent(MAILBOX_ADD_FAILED, {
        ...commonMedusaEventData,
        error: errorMessage,
      });

      // returns true if error
      return true;
    }

    // count of mailboxes added should not exceed 10 (including admin mailbox)
    if (countOfMailboxAdded === MAXIMUM_MAILBOX_LIMIT_COUNT) {
      showErrorToast(
        isCustomDomain ? CUSTOM_DOMAIN_ERROR : COSITE_DOMAIN_ERROR
      );

      // log mailbox_add_failed medusa event
      logMailboxEvent(MAILBOX_ADD_FAILED, {
        ...commonMedusaEventData,
        error: `${MAXIMUM_MAILBOX_LIMIT_COUNT} mailbox limit exceeded`,
      });

      // returns true if error
      return true;
    }

    // log mailbox_added medusa event
    logMailboxEvent(MAILBOX_ADDED, {
      ...commonMedusaEventData,
    });

    handleAdd({
      email: INPUT_EMAIL,
      username: customMailboxInput?.username,
    });

    // make custom input to initial state once email added to the list
    updateUser({
      customMailboxInput: {
        username: "",
        error: false,
      },
    });

    return false;
  };

  const handleSkip = () => {
    logMailboxEvent(TEAM_MAILBOX_ADD_CONTINUED, {
      mailboxes_added: teamMailboxStateCount,
      cta_clicked: "Skip",
    });

    navigateTo({
      pathname: PAGE_PATHS.ADD_GENERIC_MAILBOX,
      search: search_params,
    });
  };

  // This function return list of mailboxes added in this page(add-team-mailboxes)
  const teamMailboxList = () => {
    return additionalMailboxes.filter((item) => {
      return item.source === TEAM_MAILBOX;
    });
  };

  const closeCheckoutModal = () => {
    setShowCheckoutPopup(false);
  };

  return (
    <>
      <Header type={HEADER_TYPES.ADD_TEAM_MAILBOX} />
      <div className={styles.containerMaxWidth}>
        <div className={"subTitle"}>
          <p>Add your team members who need their own mailboxes</p>
        </div>
        <CustomInput
          handleAddCustomMailbox={handleAddCustomMailbox}
          source={SOURCE_HOOK}
          inputPlaceholder={"e.g. john"}
          domain={domain}
        />
        <EmailRow
          emailList={teamMailboxList()}
          handleDeleteMailbox={handleDeleteMailbox}
        />
        <div className={styles.buttonWrapper}>
          <Button
            type={"primary"}
            onClick={handleContinue}
            className={classNames({
              [styles.disabled]:
                teamMailboxStateCount === 0 && !customMailboxInput?.username,
            })}
            width={"fit-content"}
          >
            I’ve added everyone, continue
          </Button>
          {teamMailboxStateCount === 0 && (
            <Button
              type={"secondary"}
              onClick={handleSkip}
              width={"fit-content"}
            >
              It's just me, skip this
            </Button>
          )}
        </div>
      </div>
      {showCheckoutPopup && (
        <MailboxCheckoutModal
          closeCheckoutModal={closeCheckoutModal}
          handleDeleteMailbox={handleDeleteMailbox}
        />
      )}
    </>
  );
};

export default AddTeamMailbox;
