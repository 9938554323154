import styles from "./style.module.scss";
import { ReactComponent as OverflowIndicatorIcon  } from '../../assets/right-arrow.svg';
import classNames from "classnames";

type Props = {
  showIndicator: boolean;
  onClick: () => void;
  showOnlyOnMobile?: boolean;
};

export default function OverflowIndicator({
  showIndicator,
  onClick,
  showOnlyOnMobile,
}: Props) {
  return (
    <div
      className={classNames(styles.overflowIndicator, {
        [styles.showIndicator]: showIndicator,
        [styles.showOnlyOnMobile]: showOnlyOnMobile,
      })}
      onClick={onClick}
    >
      <OverflowIndicatorIcon />
    </div>
  );
}
