import { logger } from "./helpers";

/**
 * handle error
 * @param {object} contains errorLogger[key](<object>)
 * @param {string} key error key to print
 * @returns function
 * - forcePass {boolean} to explicitly return Promise.resolve
 */
export const handleError = (thingToReturn, key) => {
  return (e, forcePass) => {
    logger(key, (e && e.message) || e);
    return forcePass
      ? Promise.resolve(thingToReturn)
      : Promise.reject(thingToReturn);
  }
}

export const errorLogger = {
  ENTRI_FAILED_TO_GET_TOKEN: handleError(
    {
      type: 'ENTRI_FAILED_TO_GET_TOKEN',
      msg: 'Failed to fetch entri token'
    }, 'ENTRI_FAILED_TO_GET_TOKEN'
  ),
  ENTRI_DOMAIN_CHECK_FAILED: handleError(
    {
      type: 'ENTRI_DOMAIN_CHECK_FAILED',
      msg: 'Failed to fetch domain check response'
    }, 'ENTRI_DOMAIN_CHECK_FAILED'
  ),
  FAILED_TO_FETCH_DNS_INFO: handleError(
    {
      type: 'FAILED_TO_FETCH_DNS_INFO',
      msg: 'Failed to fetch DNS info'
    }, 'FAILED_TO_FETCH_DNS_INFO'
  ),
  FAILED_TO_GET_DKIM_KEY: handleError(
    {
      type: 'FAILED_TO_GET_DKIM_KEY',
      msg: 'Failed to get dkim key'
    }, 'FAILED_TO_GET_DKIM_KEY'
  ),
  FAILED_TO_VERIFY_DKIM_KEY: handleError(
    {
      type: 'FAILED_TO_VERIFY_DKIM_KEY',
      msg: 'Failed to get dkim key'
    }, 'FAILED_TO_VERIFY_DKIM_KEY'
  ),
  FAILED_TO_VALIDATE_ADMIN_PANEL_TOKEN: handleError(
    {
      type: 'FAILED_TO_VALIDATE_ADMIN_PANEL_TOKEN',
      msg: 'Failed to validate admin token'
    }, 'FAILED_TO_VALIDATE_ADMIN_PANEL_TOKEN'
  ),
  FAILED_TO_VALIDATE_WEBMAIL_TOKEN: handleError(
    {
      type: 'FAILED_TO_VALIDATE_WEBMAIL_TOKEN',
      msg: 'Failed to validate webmail token'
    }, 'FAILED_TO_VALIDATE_WEBMAIL_TOKEN'
  ),
  WEBMAIL_TOKEN_MISSING_INFO: handleError(
    {
      type: 'WEBMAIL_TOKEN_MISSING_INFO',
      msg: 'Failed to validate webmail token'
    }, 'WEBMAIL_TOKEN_MISSING_INFO'
  ),
  FAILED_TO_VALIDATE_SSO_TOKEN: handleError(
    {
      type: 'FAILED_TO_VALIDATE_SSO_TOKEN',
      msg: 'Failed to validate token provided in email'
    }, 'FAILED_TO_VALIDATE_SSO_TOKEN'
  ),
  INSUFFICIENT_DETAILS: handleError(
    {
      type: 'INSUFFICIENT_DETAILS',
      msg: 'Purchase flow required details are missing'
    }, 'INSUFFICIENT_DETAILS'
  ),
  DOMAIN_MISSING: handleError(
    {
      type: 'DOMAIN_MISSING',
      msg: 'Domain is missing'
    }, 'DOMAIN_MISSING'
  ),
  FAILED_TO_SEND_MEDUSA: handleError(
    {
      type: 'FAILED_TO_SEND_MEDUSA',
      msg: 'Domain is missing'
    }, 'FAILED_TO_SEND_MEDUSA'
  )
}
