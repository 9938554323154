import React from "react";
import { getRoundedDecimal } from "../../../../helpers/utils";
import Price from "../../../Common/Price";
import Currency from "../../../Common/Currency";
import styles from "./style.module.scss";

interface PlanPriceProps {
  discountedPrice?: number;
  originalPrice?: number;
  isDiscountedPrice: boolean;
  currency: string;
}

const PlanPrice: React.FC<PlanPriceProps> = ({
  discountedPrice,
  originalPrice,
  isDiscountedPrice,
  currency,
}) => (
  <div className={styles.price}>
    <div>
      <sup>
        <Currency currency={currency} />
      </sup>
      {getRoundedDecimal(discountedPrice, 2, "string")}
    </div>
    {isDiscountedPrice && (
      <div className={styles.struckPrice}>
        <Price value={getRoundedDecimal(originalPrice, 2, "string")} />
      </div>
    )}
  </div>
);

export default PlanPrice;
