import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  ANALYTICS_EVENTS,
  EMAIL_SUGGESTION_LIST,
  EMAIL_SUGGESTION_USERNAME_LIST,
  EVENT_MAILBOX_TYPE,
  HEADER_TYPES,
  MAILBOX_ORDER_LIMIT_ERROR_MSG,
  MAILBOX_PAGE_SOURCE_HOOK,
  MAXIMUM_MAILBOX_LIMIT_COUNT,
  PAGE_PATHS,
  SOURCE_OF_MAILBOX_ADDITION,
} from "../../helpers/constants";
import {
  getCountOfTotalMailboxAdded,
  validateCustomEmail,
} from "../../helpers/emailHelpers";
import { onVWOLoaded } from "../../helpers/pageLoadedHelper";
import { showErrorToast } from "../../helpers/toastHelper";
import { countOfEachTypeMailbox } from "../../helpers/utils";
import { useRedirection } from "../../hooks/useRedirection";
import { userActions } from "../../store/actions";
import { appSelector, userSelector } from "../../store/selectors";
import { logMailboxEvent } from "../../telemetry/medusaEventsFunctions";
import Button from "../Common/Button/Button";
import CustomInput from "../CustomInput/CustomInput";
import EmailRow from "../EmailRow/EmailRow";
import Header from "../Header";
import MailboxCheckoutModal from "../MailboxCheckoutModal";
import styles from "./AddGenericMailbox.module.scss";
import {
  isMailDomainCustom,
  selectMailAdditionalMailbox,
  selectMailAdminMailbox,
  selectMailPurchaseDomain,
  setMailAdditionalMailboxes,
} from "../../store/slices/mail.slice";

const SOURCE_HOOK = MAILBOX_PAGE_SOURCE_HOOK.GENERIC_MAILBOX_PAGE;

const { TEAM_MAILBOX, SUGGESTION_MAILBOX, CUSTOM_MAILBOX } =
  SOURCE_OF_MAILBOX_ADDITION;
const { CUSTOM_DOMAIN_ERROR, COSITE_DOMAIN_ERROR } =
  MAILBOX_ORDER_LIMIT_ERROR_MSG;

const {
  MAILBOX_ADDED,
  MAILBOX_DELETED,
  GENERIC_MAILBOX_ADD_VIEWED,
  GENERIC_MAILBOX_ADD_CONTINUED,
  MAILBOX_ADD_FAILED,
} = ANALYTICS_EVENTS;

const AddGenericMailbox = () => {
  const { customMailboxInput, showTeamMailboxPostDomainPurchase } =
    useSelector(userSelector);
  const domain = useSelector(selectMailPurchaseDomain);
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);
  const adminMailbox = useSelector(selectMailAdminMailbox);

  const dispatch = useDispatch();
  const { handleCheckoutRedirection } = useRedirection();
  const isCustomDomain = useSelector(isMailDomainCustom);
  const { search_params } = useSelector(appSelector);
  const navigateTo = useNavigate();
  const { updateUser } = bindActionCreators(userActions, useDispatch());

  const [list, setList] = useState(
    EMAIL_SUGGESTION_LIST.filter(
      (item) => item.username !== adminMailbox.username
    )
  );
  const [showCheckoutPopup, setShowCheckoutPopup] = useState(false);

  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);

  // populate initial state from store
  useEffect(() => {
    let updatedList = list;

    additionalMailboxes?.forEach((item) => {
      const { username, source } = item;

      /**
       * if source is suggestion or team mailbox
       * OR either
       * if source is custom mailbox and given email is already in suggestion list
       * then below block will get executed
       */
      if (
        source === SUGGESTION_MAILBOX ||
        source === TEAM_MAILBOX ||
        (source === CUSTOM_MAILBOX &&
          EMAIL_SUGGESTION_USERNAME_LIST.includes(username))
      ) {
        // Update the component state if given email is found in the list and mark that as added(checked)
        const newState = updatedList.map((listItem) => {
          if (listItem.username === username) {
            return { ...listItem, isAdded: true, source: source };
          }
          return listItem;
        });
        updatedList = newState;
      } else {
        /**
         * if source is custom mailbox and the email is not included in suggestion list
         * then this block will get executed and add the given email to the suggestion list
         * (update the component state by adding a new object to it)
         */
        updatedList = [
          ...updatedList,
          {
            username: username,
            isSuggestion: false,
            isAdded: true,
            source: CUSTOM_MAILBOX,
          },
        ];
      }
    });

    setList(updatedList);
    onVWOLoaded().then(() => {
      logMailboxEvent(GENERIC_MAILBOX_ADD_VIEWED);
    });
  }, []);

  const handleAdd = ({ email, username, isCustomMailbox }) => {
    // if isCustomMailbox value is true that means the given email is added as custom mailbox

    let selectedEmailObject;
    let isAlreadyAddedInList = false;

    // This will return the updated new state of email list
    const newState = list.map((listItem) => {
      if (listItem.username === username) {
        isAlreadyAddedInList = true;
        selectedEmailObject = listItem;
        return {
          ...listItem,
          isAdded: true,
          source: isCustomMailbox ? CUSTOM_MAILBOX : SUGGESTION_MAILBOX,
        };
      }
      return listItem;
    });

    let updatedList, newListItemObject;

    if (isAlreadyAddedInList) {
      // if email is already added in list, which means this is a suggestion mailbox
      const { username } = selectedEmailObject;
      updatedList = newState;
      newListItemObject = {
        email: `${username}@${domain}`,
        username: username,
        source: isCustomMailbox ? CUSTOM_MAILBOX : SUGGESTION_MAILBOX,
      };
    } else {
      // if email is not already added in list, which means this is a custom mailbox
      updatedList = [
        ...newState,
        {
          username,
          isSuggestion: false,
          isAdded: true,
          source: CUSTOM_MAILBOX,
        },
      ];
      newListItemObject = {
        email,
        username,
        source: CUSTOM_MAILBOX,
      };
    }

    // update local component list state
    setList(updatedList);

    // update store
    dispatch(
      setMailAdditionalMailboxes([newListItemObject, ...additionalMailboxes])
    );
  };

  // custom input mailbox
  const INPUT_EMAIL = `${customMailboxInput?.username}@${domain}`;

  const commonMedusaEventData = {
    email: INPUT_EMAIL,
    mailbox_type: EVENT_MAILBOX_TYPE.CUSTOM,
    source_hook: SOURCE_HOOK,
  };

  const handleAddCustomMailbox = async () => {
    const { isError, errorMessage } = await validateCustomEmail(
      INPUT_EMAIL,
      customMailboxInput?.username,
      adminMailbox,
      additionalMailboxes
    );

    // if input validation error return
    if (isError) {
      updateUser({
        customMailboxInput: {
          ...customMailboxInput,
          error: errorMessage,
        },
      });

      // log mailbox_add_failed medusa event
      logMailboxEvent(MAILBOX_ADD_FAILED, {
        ...commonMedusaEventData,
        error: errorMessage,
      });

      // returns true if error
      return true;
    }

    // count of mailboxes added should not exceed 10 (including admin mailbox)
    if (countOfMailboxAdded === MAXIMUM_MAILBOX_LIMIT_COUNT) {
      showErrorToast(
        isCustomDomain ? CUSTOM_DOMAIN_ERROR : COSITE_DOMAIN_ERROR
      );

      // log mailbox_add_failed medusa event
      logMailboxEvent(MAILBOX_ADD_FAILED, {
        ...commonMedusaEventData,
        error: `${MAXIMUM_MAILBOX_LIMIT_COUNT} mailbox limit exceeded`,
      });

      // returns true if error
      return true;
    }

    // log mailbox_added medusa event
    logMailboxEvent(MAILBOX_ADDED, {
      ...commonMedusaEventData,
    });

    handleAdd({
      email: INPUT_EMAIL,
      username: customMailboxInput?.username,
      isCustomMailbox: true,
    });

    // make custom input to initial state once email added to the list
    updateUser({
      customMailboxInput: {
        username: "",
        error: false,
      },
    });
  };

  const handleAddGenericMailbox = (emailObj) => {
    const { username } = emailObj;
    const email = `${username}@${domain}`;

    // count of mailboxes added should not exceed 10 (including admin mailbox)
    if (countOfMailboxAdded === MAXIMUM_MAILBOX_LIMIT_COUNT) {
      showErrorToast(
        isCustomDomain ? CUSTOM_DOMAIN_ERROR : COSITE_DOMAIN_ERROR
      );
      // log mailbox_add_failed medusa event
      logMailboxEvent(MAILBOX_ADD_FAILED, {
        email,
        mailbox_type: EVENT_MAILBOX_TYPE.SYSTEM_SUGGESTED,
        source_hook: SOURCE_HOOK,
        error: `${MAXIMUM_MAILBOX_LIMIT_COUNT} mailbox limit exceeded`,
      });
      return;
    }

    // log mailbox_added medusa
    logMailboxEvent(MAILBOX_ADDED, {
      email,
      mailbox_type: EVENT_MAILBOX_TYPE.SYSTEM_SUGGESTED,
      source_hook: SOURCE_HOOK,
    });

    handleAdd({ email, username });
  };

  const handleDeleteMailbox = (deleteMailboxObj, source_hook) => {
    const { username, source } = deleteMailboxObj;

    const newState = list.map((listItem) => {
      if (listItem.username === username) {
        return { ...listItem, isAdded: false };
      }
      return listItem;
    });

    // if added email is custom then remove from suggestion after deletion
    const updatedList = newState.filter((listItem) => {
      return listItem.isSuggestion === true || listItem.isAdded === true;
    });

    const updatedMailboxList = additionalMailboxes.filter(
      (item) => item.username !== username
    );

    // update local component list state
    setList(updatedList);

    // update store
    dispatch(setMailAdditionalMailboxes([...updatedMailboxList]));

    logMailboxEvent(MAILBOX_DELETED, {
      email: `${username}@${domain}`,
      mailbox_type:
        source === SUGGESTION_MAILBOX
          ? EVENT_MAILBOX_TYPE.SYSTEM_SUGGESTED
          : EVENT_MAILBOX_TYPE.CUSTOM,
      source_hook: source_hook || SOURCE_HOOK,
    });
  };

  // common handle button(CTA) function
  const handleButton = async (buttonType) => {
    const { suggestionMailboxCount, customMailboxCount } =
      countOfEachTypeMailbox(additionalMailboxes);

    logMailboxEvent(GENERIC_MAILBOX_ADD_CONTINUED, {
      mailboxes_added: suggestionMailboxCount + customMailboxCount,
      suggested_mailboxes_added: suggestionMailboxCount,
      custom_mailboxes_added: customMailboxCount,
      total_mailbox_count: countOfMailboxAdded,
      cta_clicked: buttonType,
    });

    if (showTeamMailboxPostDomainPurchase) {
      /**
       * check for count of team and generic mailbox added is more than zero then show checkout modal
       */
      if (countOfMailboxAdded > 1) {
        setShowCheckoutPopup(true);
      } else {
        handleCheckoutRedirection();
      }
      return;
    }

    navigateTo({
      pathname: PAGE_PATHS.PREVIEW_SITE,
      search: search_params,
    });
  };

  const handleContinue = () => {
    if (isSkipVisible()) {
      // continue button is disabled
      return;
    }
    handleButton("Continue");
  };

  const handleSkip = () => {
    handleButton("Skip");
  };

  // function to handle skip button visibility
  const isSkipVisible = () => {
    return list.every((item) => item.isAdded === false);
  };

  // This function return list of mailboxes to render in EmailRow component
  const genericPageMailboxList = () => {
    return list.filter((item) => {
      return item.source !== TEAM_MAILBOX;
    });
  };

  const closeCheckoutModal = () => {
    setShowCheckoutPopup(false);
  };

  return (
    <>
      <Header type={HEADER_TYPES.ADD_GENERIC_MAILBOX} />
      <div className={"max-width-600"}>
        <div className={"subTitle"}>
          <p>
            These make your business look professional and are perfect to have
            them on your website or social media
          </p>
        </div>
      </div>

      {/* Do not show this component for now
      <MobileInfoComponent />
      */}
      <div className={styles.boxWrapper}>
        <div className={styles.genericEmailListContainer}>
          <EmailRow
            emailList={genericPageMailboxList()}
            handleDeleteMailbox={handleDeleteMailbox}
            handleAddGenericMailbox={handleAddGenericMailbox}
          />
          <CustomInput
            handleAddCustomMailbox={handleAddCustomMailbox}
            source={SOURCE_HOOK}
            domain={domain}
          />
          <div className={styles.buttonWrapper}>
            <Button
              type={"primary"}
              onClick={handleContinue}
              width={"fit-content"}
              className={classNames({
                [styles.disabled]: isSkipVisible(),
              })}
            >
              Done, continue to next step
            </Button>
            {isSkipVisible() && (
              <Button
                type={"secondary"}
                onClick={handleSkip}
                width={"fit-content"}
              >
                Skip this step
              </Button>
            )}
          </div>
        </div>
        <DesktopInfoComponent />
      </div>
      {showCheckoutPopup && (
        <MailboxCheckoutModal
          closeCheckoutModal={closeCheckoutModal}
          handleDeleteMailbox={handleDeleteMailbox}
        />
      )}
    </>
  );
};

export default AddGenericMailbox;

const MobileInfoComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const infoRef = useRef(null);

  const handleInfoState = () => {
    setIsOpen((isOpen) => !isOpen);
    infoRef.current.style.setProperty("--height", `230px`);
  };
  return (
    <div
      className={classNames(styles.mobileInfo, {
        [styles.hidden]: !isOpen,
      })}
      onClick={handleInfoState}
      ref={infoRef}
    >
      <div className={styles.infoIcon} />
      <div className={styles.details}>
        <ul className={styles.unorderList}>
          <li>
            <div
              className={classNames({
                [styles.hidden]: !isOpen,
              })}
            >
              Protect your privacy when you add them to your website
            </div>
          </li>
          <li>Look professional</li>
        </ul>
        <span className={styles.infoPopularBtn}>POPULAR</span>
        <p className={styles.discountInformation}>
          <strong>60%</strong> of our customers use at least one of these on
          their website
        </p>
      </div>
      <div
        className={classNames(styles.arrow, {
          [styles.rightArrow]: isOpen,
        })}
      />
    </div>
  );
};

const DesktopInfoComponent = () => {
  return (
    <div className={styles.desktopInfo}>
      <ul className={styles.unorderList}>
        <li>Protect your privacy when you add them to your website</li>
        <li>Look professional</li>
      </ul>
      <span className={styles.infoPopularBtn}>POPULAR</span>
      <p className={styles.discountInformation}>
        <strong>60%</strong> of our customers use at least one of these on their
        website
      </p>
    </div>
  );
};
