import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CUSTOM_DOMAIN_TRIAL_DURATION_IN_DAYS } from "../../3rdPartyIntegrations/Entri/utils/const";
import { getCountOfTotalMailboxAdded } from "../../helpers/emailHelpers";
import { getRoundedDecimal } from "../../helpers/utils";
import { userSelector } from "../../store/selectors";
import Popup from "../Common/Popup/Popup";
import Price from "../Common/Price";
import EmailRow from "../EmailRow/EmailRow";
import MailboxPurchaseFailed from "../OrderModal/MailboxPurchaseFailed";
import MailboxPurchaseSuccess from "../OrderModal/MailboxPurchaseSuccess";
// import PriceBreakup from "../OrderSummary/_PriceBreakup";
import CoSiteDomainPayment from "./CoSiteDomainPayment";
import CustomDomainPayment from "./CustomDomainPayment";
import styles from "./MailboxCheckoutModal.module.scss";
import NoMailboxAddedModal from "./NoMailboxAddedModal";
import {
  isMailDomainCustom,
  selectMailAdditionalMailbox,
} from "../../store/slices/mail.slice";
import { getNumberOfMonths } from "../../helpers/billing-cycle.helper";

const source_hook = "Mailbox checkout modal";

const MailboxCheckoutModal = ({ closeCheckoutModal, handleDeleteMailbox }) => {
  const { plan } = useSelector(userSelector);
  // TODO: for site
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);

  const { duration } = plan;

  // TODO: for site
  const customDomain = useSelector(isMailDomainCustom);
  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);
  const noOfMonths = getNumberOfMonths(duration);
  const amount = plan.price[`${duration}`]?.discounted;

  const mailboxChargesExcludingAdmin = getRoundedDecimal(
    amount * noOfMonths * (countOfMailboxAdded - 1)
  );

  const [mailboxPurchaseSuccess, setMailboxPurchaseSuccess] = useState(false);
  const [countOfMailboxPurchaseFailed, setCountOfMailboxPurchaseFailed] =
    useState(0);
  const handleDelete = (mailboxToDelete) => {
    handleDeleteMailbox(mailboxToDelete, source_hook);
  };

  if (mailboxPurchaseSuccess) {
    return (
      <MailboxPurchaseSuccess
        countOfMailboxPurchaseFailed={countOfMailboxPurchaseFailed}
        countOfMailboxAdded={countOfMailboxAdded - 1} // excluding admin
      />
    );
  }

  if (!!countOfMailboxPurchaseFailed) {
    return (
      <MailboxPurchaseFailed
        countOfMailboxPurchaseFailed={countOfMailboxPurchaseFailed}
        countOfMailboxAdded={countOfMailboxAdded - 1} // excluding admin
      />
    );
  }

  return (
    <Popup>
      <div className={"closeIcon"} onClick={() => closeCheckoutModal()} />
      {customDomain ? (
        <>
          <p className={styles.title}>Mailbox Summary</p>
          <div className={styles.mailboxCheckoutWrapper}>
            {countOfMailboxAdded > 1 ? (
              <>
                <div className={styles.firstRow}>
                  <div>
                    <EmailRow
                      emailList={additionalMailboxes}
                      handleDeleteMailbox={handleDelete}
                      isOrderSummaryPage={true}
                      collapsible={true}
                      fromCheckoutModal={true}
                    />
                  </div>
                  <div className={styles.priceWrapper}>
                    <strike>
                      <Price value={mailboxChargesExcludingAdmin} />
                    </strike>{" "}
                    <strong>
                      <Price value={0} />
                    </strong>
                    {/* <PriceBreakup purchaseType={"mailboxesExcludingAdmin"} /> */}
                  </div>
                </div>
                <div className={styles.payableAfterTrial}>
                  <span>
                    {`Amount payable for ${
                      countOfMailboxAdded - 1
                    } mailboxes after ${CUSTOM_DOMAIN_TRIAL_DURATION_IN_DAYS} days trial ends`}
                  </span>
                  <strong>
                    <Price value={mailboxChargesExcludingAdmin} />
                  </strong>
                </div>
                <div className={styles.payableNow}>
                  <span>Total payable now</span>
                  <strong>
                    <Price value={0} />
                  </strong>
                </div>
                <CustomDomainPayment
                  setMailboxPurchaseSuccess={setMailboxPurchaseSuccess}
                  setCountOfMailboxPurchaseFailed={
                    setCountOfMailboxPurchaseFailed
                  }
                />
              </>
            ) : (
              <NoMailboxAddedModal />
            )}
          </div>
        </>
      ) : (
        <>
          <p className={styles.title}>Checkout</p>
          <div className={styles.mailboxCheckoutWrapper}>
            {countOfMailboxAdded > 1 ? (
              <>
                <div className={styles.firstRow}>
                  <div>
                    <EmailRow
                      emailList={additionalMailboxes}
                      handleDeleteMailbox={handleDelete}
                      isOrderSummaryPage={true}
                      collapsible={true}
                      fromCheckoutModal={true}
                    />
                  </div>
                  <div className={styles.priceWrapper}>
                    <strong>
                      <Price value={mailboxChargesExcludingAdmin} />
                    </strong>
                    {/* <PriceBreakup purchaseType={"mailboxesExcludingAdmin"} /> */}
                  </div>
                </div>
                <div className={styles.payableNow}>
                  <span>Total payable now</span>
                  <strong>
                    <Price value={mailboxChargesExcludingAdmin} />
                  </strong>
                </div>
                <div className={styles.paymentInformation}>
                  Payment information
                </div>
                <CoSiteDomainPayment
                  setMailboxPurchaseSuccess={setMailboxPurchaseSuccess}
                  setCountOfMailboxPurchaseFailed={
                    setCountOfMailboxPurchaseFailed
                  }
                />
              </>
            ) : (
              <NoMailboxAddedModal />
            )}
          </div>
        </>
      )}
    </Popup>
  );
};

export default MailboxCheckoutModal;
