import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import {
  businessIdeaSelector,
  businessNameSelector,
  saveBusinessDescriptionAction,
  saveBusinessNameAction,
  saveSiteVisitorDataAction,
  // saveVisitorEmailAction,
  startVisitorSiteGenerationAction,
  // visitorEmailSelector,
} from "../../../store/slices/visitor.slice";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import BusinessIdea from "../BusinessIdea";
import BusinessName from "../BusinessName";
// import VisitorEmail from "../VisitorEmail";
import Loader from "../../Common/Loader/Loader";
import { appSelector, userSelector } from "../../../store/selectors";
import PreloadSite from "../../Common/PreloadSite";
import { generateSiteOptionAvailable } from "../../../helpers/site/site-offering.helper";
import CaptchaVerification from "../../CaptchaVerification/CaptchaVerification";
import { ERROR_KEYS } from "../../../constants/error.constants";
import { logSiteOfferingOnboardingEvent } from "../../../telemetry/site/siteoffering";
import { AppDispatch } from "../../../store/store";
import { ReactComponent as StarIcon } from "../../../assets/stars.svg";
import { verifyCloudflareTurnstileTokenAction } from "../../../store/slices/trunstile.slice";
import styles from "./styles.module.scss";

const CONTINUE = "Continue";
const GENERATE_SITE = "Generate site";
const CAPTCHA_ERROR_LIMIT = 3;
const {
  BUSINESS_IDEA_SUBMITTED,
  BUSINESS_NAME_SUBMITTED,
  // GENERATE_SITE_CLICKED,
  VISITOR_EMAIL_SAVED,
} = ANALYTICS_EVENTS;

const SiteOffering: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [siteGenerationLoading, setSiteGenerationLoading] = useState(false);
  const businessIdea = useSelector(businessIdeaSelector);
  const businessName = useSelector(businessNameSelector);
  // const visitorEmail = useSelector(visitorEmailSelector);
  const { search_params } = useSelector(appSelector);
  const { alternateEmail } = useSelector(userSelector);

  const [cfTurnstileToken, setCfTurnstileToken] = useState<string | null>(null);
  const turnstileRef = useRef(null);
  const captchaFailedCount = useRef(0);
  const [captchaError, setCaptchaError] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    switch (location.pathname) {
      // case PAGE_PATHS.YOUR_EMAIL:
      //   if (!businessIdea) {
      //     navigate(PAGE_PATHS.YOUR_IDEA);
      //   } else if (!businessName || alternateEmail) {
      //     navigate(PAGE_PATHS.YOUR_BUSINESS_NAME);
      //   }
      //   break;
      case PAGE_PATHS.YOUR_BUSINESS_NAME:
        if (!businessIdea) {
          navigate(PAGE_PATHS.YOUR_IDEA);
        }
        break;
      default:
        break;
    }
    setLoading(false);
  }, []);

  const _startSiteGeneration = async (email: string) => {
    try {
      await dispatch(startVisitorSiteGenerationAction({ visitorEmail: email }));
    } catch (error) {
      console.error(error);
    }
  };

  const getCaptchaToken = async () => {
    try {
      return await (turnstileRef.current as any)?.getResponsePromise(10 * 1000);
    } catch (error) {
      console.error("Failed to load captcha", error);
      return null;
    }
  };

  const handleCaptchaAndSiteGeneration = async (email: string) => {
    setSiteGenerationLoading(true);

    try {
      let shouldBypassCaptcha = false;
      let captchaResponse = await getCaptchaToken();

      // Handle CAPTCHA failure and decide whether to bypass
      if (!captchaResponse) {
        captchaFailedCount.current += 1;
        setCaptchaError("Please verify that you are not a robot");
        (turnstileRef.current as any)?.reset();

        if (captchaFailedCount.current > CAPTCHA_ERROR_LIMIT) {
          console.error("Bypassing CAPTCHA after multiple failures");
          shouldBypassCaptcha = true;
        } else {
          return; // Stop further execution for CAPTCHA failures below the threshold
        }
      }

      // If not bypassing, verify CAPTCHA
      if (!shouldBypassCaptcha) {
        const response = await dispatch(
          verifyCloudflareTurnstileTokenAction(captchaResponse) as any
        ).unwrap();

        if (response.data.statusCode !== 200) {
          if (
            response.data.code === ERROR_KEYS.CAPTCHA_VERIFICATION_FAILED ||
            response.data.code === ERROR_KEYS.CLOUDFARE_TOKEN_REQUIRED
          ) {
            setCaptchaError(response.data.desc);
          } else {
            setCaptchaError("Something went wrong, please try again");
          }
          return; // Stop execution for failed CAPTCHA verification
        }
      }

      // Proceed with logging and site generation
      logSiteOfferingOnboardingEvent(VISITOR_EMAIL_SAVED, {
        business_name: businessName,
        business_description: businessIdea,
      });

      if (email) {
        await dispatch(
          saveSiteVisitorDataAction({ visitorEmail: email }) as any
        ).unwrap();
      }

      await _startSiteGeneration(email);
    } catch (error) {
      setCaptchaError("Error verifying Captcha, please try again");
      console.error("Error verifying Captcha, please try again", error);
    } finally {
      setSiteGenerationLoading(false);
      (turnstileRef.current as any)?.reset();
    }
  };

  const handleBusinessIdeaContinue = (
    businessIdea: string,
    exampleIdeaUsed: string
  ) => {
    logSiteOfferingOnboardingEvent(BUSINESS_IDEA_SUBMITTED, {
      business_description: businessIdea,
      example_idea_used: exampleIdeaUsed,
    });

    dispatch(saveBusinessDescriptionAction(businessIdea));
    navigate({
      pathname: PAGE_PATHS.YOUR_BUSINESS_NAME,
      search: search_params,
    });
  };

  const handleBusinessNameContinue = (businessName: string) => {
    logSiteOfferingOnboardingEvent(BUSINESS_NAME_SUBMITTED, {
      business_name: businessName,
      business_description: businessIdea,
    });

    dispatch(saveBusinessNameAction(businessName));
    handleCaptchaAndSiteGeneration(alternateEmail);
  };

  // const handleVisitorEmailContinue = (visitorEmail: string) => {
  //   logSiteOfferingOnboardingEvent(GENERATE_SITE_CLICKED, {
  //     business_name: businessName,
  //     business_description: businessIdea,
  //     hattrs: {
  //       visitor_email: visitorEmail
  //     }
  //   });
  //   dispatch(saveVisitorEmailAction(visitorEmail));
  //   handleCaptchaAndSiteGeneration(visitorEmail);
  // };

  const captchaComponent = (
    <CaptchaVerification
      ref={turnstileRef}
      onTokenChange={setCfTurnstileToken}
      error={captchaError}
      setError={setCaptchaError}
    />
  );

  const _renderContent = () => {
    switch (location.pathname) {
      case PAGE_PATHS.YOUR_IDEA:
        return (
          <BusinessIdea
            businessIdea={businessIdea}
            onContinue={handleBusinessIdeaContinue}
            continueButtonText={CONTINUE}
          />
        );
      case PAGE_PATHS.YOUR_BUSINESS_NAME:
        return (
          <BusinessName
            businessName={businessName}
            isLoading={siteGenerationLoading}
            onContinue={handleBusinessNameContinue}
            continueButtonText={
              <div className={styles.iconTextWrapper}>
                <StarIcon /> {GENERATE_SITE}
              </div>
            }
            captchaComponent={captchaComponent}
            disabled={siteGenerationLoading}
          />
        );
      // case PAGE_PATHS.YOUR_EMAIL:
      //   return (
      //     <VisitorEmail
      //       visitorEmail={visitorEmail}
      //       isLoading={siteGenerationLoading}
      //       onContinue={handleVisitorEmailContinue}
      //       continueButtonText={<div className={styles.iconTextWrapper}><StarIcon /> {GENERATE_SITE}</div>}
      //       captchaComponent={captchaComponent}
      //     />
      //   );
      default:
        navigate(PAGE_PATHS.SIGN_UP);
        return null;
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      {_renderContent()}
      <PreloadSite
        load={generateSiteOptionAvailable(
          PAGE_PATHS.YOUR_BUSINESS_NAME,
          alternateEmail,
          PAGE_PATHS.YOUR_EMAIL
        )}
        src={process.env.REACT_APP_SITE_URL || ""}
      />
    </>
  );
};

export default SiteOffering;
