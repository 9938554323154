import { useMobile } from "../../../../hooks/useMobile";
import purchaseCompleteImg from "../../assets/images/purchase-complete.gif";
import CPButton, { CP_BUTTON_TYPE } from "../../components/CPButton";
import EntriButton from "../../components/EntriButton";
import { cj } from "../../utils/helpers";
import s from "./SetupDns.module.scss";
import { useSetupDns } from "./useSetupDns";

const connectSiteDomainPath = { section: "connect-site-domain" };

const SetupDns = ({ hasInterruption, navigation, finishingSetup }) => {
  const {
    provider,
    isSiteSetupMode,
    isPurchaseFlowType,
    onCpClickMedusaEventsToDispatch,
    showTeamMailboxPostDomainPurchase,
  } = useSetupDns();
  const { isMobileView } = useMobile();

  if (isSiteSetupMode) {
    return (
      <div className={cj([s["setup-dns"], s["auto-login-flow"]])}>
        <div>
          <h1>Connect domain to Neo Site in a few clicks</h1>
          <p>
            To publish your Neo Site, simply sign in to your{" "}
            <span>{provider}</span> account and we'll do the rest.
          </p>
          <div className={s.actions}>
            <EntriButton btnLabel="Connect now" disabled={finishingSetup} />
            {hasInterruption ?
              isMobileView ? (
                <CPButton
                  navigation={navigation}
                  type={CP_BUTTON_TYPE.LINK}
                  text="Show instructions to connect"
                  onClick={onCpClickMedusaEventsToDispatch}
                />
              ) : (
                <CPButton
                  navigation={navigation}
                  type={CP_BUTTON_TYPE.LINK}
                  text="Set up later from Neo Admin Panel"
                  queryParamsToBePassed={connectSiteDomainPath}
                  onClick={onCpClickMedusaEventsToDispatch}
                />
              )
            : null}
          </div>
        </div>
      </div>
    );
  }

  // when user coming via purchase flow
  // show purchase success screen
  if (isPurchaseFlowType) {
    return (
      <div className={cj([s["setup-dns"], s["purchase-flow"]])}>
        {!showTeamMailboxPostDomainPurchase && (
          <div>
            <img src={purchaseCompleteImg} alt="purchase-complete-img" />
            <p>Your order purchase is successful</p>
          </div>
        )}
        <div>
          <h1>Let's setup your email in a few clicks</h1>
          <p>
            To start using your new email, simply sign in to your{" "}
            <span>{provider}</span> account and we'll do the rest.
          </p>
          <div className={s.actions}>
            <EntriButton disabled={finishingSetup} />
            {hasInterruption ? (
              <CPButton
                navigation={navigation}
                type={CP_BUTTON_TYPE.LINK}
                text="Set up later from Neo Admin Panel"
                onClick={onCpClickMedusaEventsToDispatch}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  // user coming via auto login flow
  return (
    <div className={cj([s["setup-dns"], s["auto-login-flow"]])}>
      <div>
        <h1>Setup your email in a few clicks</h1>
        <p>
          To start using your new email, simply sign in to your{" "}
          <span>{provider}</span> account and we'll do the rest.
        </p>
        <div className={s.actions}>
          <EntriButton disabled={finishingSetup} />
          {hasInterruption ? (
            <CPButton
              navigation={navigation}
              type={CP_BUTTON_TYPE.LINK}
              text="Set up later from Neo Admin Panel"
              onClick={onCpClickMedusaEventsToDispatch}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SetupDns;
