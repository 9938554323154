import React from "react";
import { logPlanPageEvent } from "../../telemetry/medusaEventsFunctions";
import { ANALYTICS_EVENTS } from "../../helpers/constants";
import styles from './NeedMoreMailbox.module.scss'

const NEED_MORE_NUMBER_OF_MAILBOXES = "Need more than 50 mailboxes?";
const { LARGE_TEAM_LINK_CLICKED } = ANALYTICS_EVENTS;

type Props = {
    className?: string;
}

export default function NeedMoreMailbox({ className }: Props = {}) {
  const handleMoreMailboxesClicked = () => {
    logPlanPageEvent(LARGE_TEAM_LINK_CLICKED, {
      source_hook: "signup_app_plans",
    });
  };
  return (
    <a
      href="https://ask.neo.space/large-team?source=signup_app_plans"
      target="_blank"
      rel="noreferrer"
      onClick={handleMoreMailboxesClicked}
      className={`${styles.needMoreMailbox} ${className}`}
    >
      {NEED_MORE_NUMBER_OF_MAILBOXES}
    </a>
  );
}
