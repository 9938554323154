import ENBridge from "../../bridge";
import { sendMedusaEvent } from "../../utils/medusa";

export const entriSetupCompleteRedirection = (
  redirectionOptionSelected,
  entriState,
  neoState
) => {
  if (!entriState.provider) return null;

  const { getDnsSetupFor, isSiteSetupMode } = ENBridge.handlers;

  const eventName = `${getDnsSetupFor().toLowerCase()}_entri_setup_complete_redirection`;

  return sendMedusaEvent(eventName, neoState, {
    domain_provider: entriState.provider,
    redirection_option_selected: redirectionOptionSelected,
    redirection_type: isSiteSetupMode() ? "Auto redirect" : "Manual by user",
  });
};
