import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Select, { SingleValueProps, StylesConfig } from "react-select";
import { ANALYTICS_EVENTS, WINDOW_NAME } from "../../../helpers/constants";
import { countryCodes } from "../../../helpers/countryCodes";
import { getResetPasswordURL } from "../../../helpers/utils";
import inputStyles from "./Input.module.scss";
import { TLogEventProps } from "../../../types/global";

const { FLOCK_ADMIN } = WINDOW_NAME;

const { CUSTOMER_ACCOUNT_FORGOT_PASSWORD_CLICKED } = ANALYTICS_EVENTS;

interface InputProps {
  label?: React.ReactNode;
  name?: string;
  wrapperClassName?: string;
  selectName?: string;
  placeholder?: string;
  type: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  autofocus?: boolean;
  className?: string;
  isMobileNumberInput?: boolean;
  onSelectChange?: (value: any) => void;
  selectDefaultValue?: any;
  hasError?: boolean;
  errorMessage?: React.ReactNode;
  isPasswordInput?: boolean;
  hasSelectError?: boolean;
  maxLength?: number;
  showForgotPasswordHook?: boolean;
  domain?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  minimumNumber?: number;
  autoCapitalize?: string;
  value?: string;
  logEvent?: (arg: TLogEventProps) => Promise<any>;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  selectName,
  placeholder,
  type,
  defaultValue,
  onChange,
  onBlur,
  autofocus,
  className,
  wrapperClassName,
  isMobileNumberInput,
  onSelectChange,
  selectDefaultValue,
  hasError,
  errorMessage,
  isPasswordInput,
  hasSelectError,
  maxLength,
  showForgotPasswordHook,
  domain,
  onKeyPress,
  minimumNumber,
  autoCapitalize,
  value,
  logEvent,
}) => {
  const [inputType, setInputType] = useState<string>(type);

  const inputRef = useRef<HTMLInputElement>(null);

  const computedClassName = `${inputStyles.input} ${className || ""} ${
    isMobileNumberInput ? inputStyles.mobile : ""
  } ${hasError ? inputStyles.hasError : ""}`;

  // Custom component to show value instead of label
  // after selection from the react-select dropdown
  const SingleValue = ({ selectProps }: SingleValueProps<any>) => {
    return (
      <div className={inputStyles.singleVal}>{selectProps.value.value}</div>
    );
  };

  // Custom component to add a styled caret to the select dropdown
  const IndicatorsContainer = () => {
    return <div className={inputStyles.selectCaret}></div>;
  };

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  const selectBoxStyling: StylesConfig = {
    control: () => ({
      padding: "10px 15px",
      border: 0,
      outline: 0,
      display: "flex",
      "&:focus": {
        outline: 0,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: 300,
    }),
    input: (provided) => ({
      ...provided,
      caretColor: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const togglePasswordVisibility = () => {
    setInputType(inputType === "text" ? "password" : "text");
  };

  // TODO: Temporary solution – refactor to remove `logEvent` prop from this common input component.
  // Event logging should ideally be decoupled from this component, as it’s specific to site and mail products.
  // For now, `logEvent` is passed as a prop due to different data requirements per product.
  const handleResetPassword = async () => {
    // Perform logging asynchronously
    try {
      if (logEvent) {
        const res = await logEvent({
          eventName: CUSTOMER_ACCOUNT_FORGOT_PASSWORD_CLICKED,
          data: {},
        });

        if (res.status !== 200) {
          console.error("Reset Password Event logging failed.");
        }
      }
    } catch (error) {
      console.error("An error occurred during event logging:", error);
    }

    // After logging completes, perform the redirection
    window.open(getResetPasswordURL(), FLOCK_ADMIN)
  };

  return (
    <div className={classNames(inputStyles["input-wrapper"], wrapperClassName)}>
      {label ? <label>{label}</label> : null}
      {isPasswordInput && showForgotPasswordHook && (
        <div className={inputStyles.forgotPwd} onClick={handleResetPassword}>
          Forgot password?
        </div>
      )}
      <div>
        {isMobileNumberInput && (
          <Select
            styles={selectBoxStyling}
            name={selectName}
            options={countryCodes}
            placeholder={"Country"}
            onChange={onSelectChange}
            menuShouldScrollIntoView
            defaultValue={selectDefaultValue}
            value={selectDefaultValue ? { value: selectDefaultValue } : null}
            className={classNames(inputStyles.input, inputStyles.select, {
              [inputStyles.hasError]: hasSelectError,
            })}
            components={{
              SingleValue,
              IndicatorsContainer,
            }}
          />
        )}
        <input
          ref={inputRef}
          type={inputType}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          autoComplete="off"
          maxLength={maxLength || undefined}
          onChange={onChange}
          onBlur={onBlur}
          className={classNames(computedClassName)}
          onKeyPress={onKeyPress}
          min={minimumNumber}
          autoCapitalize={autoCapitalize || "true"}
          value={value}
        />
        {isPasswordInput && (
          <div
            onClick={togglePasswordVisibility}
            className={inputStyles.togglePassword}
          />
        )}
        {domain && <div className={inputStyles.emailDomain}>@{domain}</div>}
      </div>
      {hasError && errorMessage && (
        <div className={inputStyles.error}>{errorMessage}</div>
      )}
    </div>
  );
};

export default Input;