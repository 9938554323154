import NeoLogo from "../../components/Logo/Logo";
import FlowManager from "../FlowManager";
import UserProfile from "../../components/UserProfile/UserProfile";
import Loader from "../../components/Loader/Loader";

import { useApp } from "./useApp";
import s from './App.module.scss';
import '../../assets/styles/styles.scss';

const App = () => {
  const {
    showAppLoader,
    isAutoLoginFlow,
    isAutomaticSetupPossible
  } = useApp();

  // show loader
  if (showAppLoader) return <Loader loadingText={isAutoLoginFlow ? 'Checking domain...' : 'Loading...'} />;

  // hook is already taking care of redirection
  // this is just to hide the content to avoid flicker
  if (isAutomaticSetupPossible) return null;

  return (
    <div>
      <header className={s.header}>
        <NeoLogo />
        <UserProfile />
      </header>
      <div className={s.wrapper}>
        <FlowManager />
      </div>
    </div>
  )
}

export default App;