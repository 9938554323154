import React from "react";
import { BILLING_CYCLE_KEYS } from "../../../../helpers/constants";
import { TBillingCycle } from "../../../../types/billing-cycle";
import Price from "../../../Common/Price";
import { getRoundedDecimal } from "../../../../helpers/utils";
import { getNumberOfMonths } from "../../../../helpers/billing-cycle.helper";

const { QUARTERLY, YEARLY, TWO_YEARLY, FOUR_YEARLY } = BILLING_CYCLE_KEYS;

interface DomainPriceBreakupProps {
  duration: TBillingCycle;
  domainPricing: any;
}

const DomainPriceBreakup: React.FC<DomainPriceBreakupProps> = ({
  duration,
  domainPricing,
}) => {
  const { monthly, yearly } = domainPricing;

  const noOfMonths = getNumberOfMonths(duration);
  return (
    <>
      {duration === QUARTERLY ? (
        <div>
          <Price
            value={getRoundedDecimal(monthly?.renewalCharge * 3, 2, "string")}
          />
          /quarter (
          <Price
            value={getRoundedDecimal(monthly?.renewalCharge, 2, "string")}
          />{" "}
          x {noOfMonths} months) after 1st quarter
        </div>
      ) : duration === YEARLY ? (
        <div>
          <Price value={getRoundedDecimal(yearly?.renewalChargePerYear, 2, 'string')} />
          /year (
          <Price value={yearly?.renewalCharge} /> x {noOfMonths} months) after
          1st year
        </div>
      ) : duration === TWO_YEARLY ? (
        <div>
          <Price
            value={getRoundedDecimal(
              yearly?.renewalChargePerYear * 2,
              2,
              "string"
            )}
          />
          /two years (
          <Price value={yearly?.renewalCharge} /> x {noOfMonths} months) after
          2nd year
        </div>
      ) : duration === FOUR_YEARLY ? (
        <div>
          <Price
            value={getRoundedDecimal(
              yearly?.renewalChargePerYear * 4,
              2,
              "string"
            )}
          />
          /four years (
          <Price value={yearly?.renewalCharge} /> x {noOfMonths} months) after
          4th year
        </div>
      ) : (
        <div>
          <Price
            value={getRoundedDecimal(monthly?.renewalCharge, 2, "string")}
          />
          /month after 1st month
        </div>
      )}
    </>
  );
};

export default React.memo(DomainPriceBreakup);
