export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_DOMAIN = "UPDATE_DOMAIN";
export const UPDATE_UTM_PARAMS = "UPDATE_UTM_PARAMS";
export const UPDATE_PAGE_VARIANT = "UPDATE_PAGE_VARIANT";
export const UPDATE_DEVICE_ID = "UPDATE_DEVICE_ID";
export const UPDATE_APP_PARAMS = "UPDATE_APP_PARAMS";
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const UPDATE_ERRORS = "UPDATE_ERRORS";
export const UPDATE_PLAN_PRICING = "UPDATE_PLAN_PRICING";
export const LOGOUT = "LOGOUT";
export const RESET_USER = "RESET_USER";
export const CLEAR_SESSION = "CLEAR_SESSION";