import { useEffect } from "react";
import classNames from "classnames";
import { TBillingCycle } from "../../../../types/billing-cycle";
import toggleStyles from "./style.module.scss";

type Props = {
  changeBillingCycle: (duration: TBillingCycle) => void;
  billingCycle: TBillingCycle;
  backupCycle: TBillingCycle;
  cycles: {
    duration: TBillingCycle;
    label: React.ReactNode;
    discountPercentage: string | number;
  }[];
};

const BillingCycleToggle: React.FC<Props> = ({
  changeBillingCycle,
  billingCycle,
  backupCycle,
  cycles,
}) => {
  // If selected billing cycle is not visible then show default one
  useEffect(() => {
    if (
      cycles.length &&
      !cycles.find(({ duration }) => billingCycle === duration)
    ) {
      const _backupCycle = cycles.find(
        ({ duration }) => duration === backupCycle
      )?.duration;
      changeBillingCycle(_backupCycle || cycles[0]?.duration);
    }
  }, [cycles, billingCycle, backupCycle, changeBillingCycle]);

  return (
    <div className={toggleStyles.billingCycleSelectionWrapper}>
      <div>
        {cycles.map((cycle) => {
          return (
            <div
              key={cycle.duration}
              className={classNames({
                [toggleStyles.active]: billingCycle === cycle.duration,
                [toggleStyles.billingDuration]: true,
              })}
              onClick={() => changeBillingCycle(cycle.duration)}
            >
              {cycle.label}
              {cycle.discountPercentage ? (
                <small>Save {cycle.discountPercentage}%</small>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BillingCycleToggle;
