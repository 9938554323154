import { useReducer } from "react"
import { EntriContext } from "./context"

import { entriReducer } from "./reducer";
import { initialValues } from "./initialValues";

export const EntriContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(entriReducer, initialValues);
  const value = { state, dispatch };

  return <EntriContext.Provider value={value}>{children}</EntriContext.Provider>
}