import React, { useEffect, useState } from "react";
import {
  ANALYTICS_EVENTS,
  EVENT_MAILBOX_TYPE,
  INPUT_ERROR_MESSAGE,
  MAILBOX_PAGE_SOURCE_HOOK,
  MINIMUM_PASSWORD_LENGTH,
} from "../../helpers/constants";
import { emailAvailabilityStatus } from "../../helpers/emailHelpers";
import { onVWOLoaded } from "../../helpers/pageLoadedHelper";
import useKeypress from "../../helpers/useKeyPressHook";
import { isEmailValid, isPasswordValid } from "../../helpers/utils";
import Button from "../Common/Button/Button";
import Input from "../Common/Input/Input";
import PreventInputPrefillByBrowser from "../Common/PreventInputPrefillByBrowser/PreventInputPrefillByBrowser";
import Header from "../Header";
import { TLogEventProps } from "../../types/global";
import { TOnOkButtonClickArgs } from "./Common/types";
import styles from "./styles.module.scss";

const SOURCE_HOOK = MAILBOX_PAGE_SOURCE_HOOK.ADMIN_MAILBOX_PAGE;
const USERNAME = "username";
const PASSWORD = "password";
const { MAILBOX_ADD_VIEWED, MAILBOX_ADDED, MAILBOX_ADD_FAILED } =
  ANALYTICS_EVENTS;

interface Props {
  domain: string;
  adminMailbox: Record<string, any>;
  additionalMailboxes?: Record<string, any>[];
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  AdminMailboxInfo?: React.ReactNode;
  okButtonProps: {
    label: string;
    sublabel?: string;
    handler: (args: TOnOkButtonClickArgs) => void;
  };
  cancelButtonProps?: {
    label: string;
    handler: () => void;
  };
  EmailBenefits?: React.ReactNode;
  logEvent: (args: TLogEventProps) => void;
  setOffering: () => void;
  footer?: React.ReactNode;
  mailboxInputLabel?: React.ReactNode;
  mailboxPasswordLabel?: React.ReactNode;
}

const AddMailbox: React.FC<Props> = (props) => {
  const {
    domain,
    additionalMailboxes = [],
    adminMailbox,
    title,
    subTitle,
    AdminMailboxInfo,
    okButtonProps,
    cancelButtonProps,
    EmailBenefits,
    logEvent,
    setOffering,
    footer,
    mailboxInputLabel,
    mailboxPasswordLabel
  } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const [mailboxCreds, setMailboxCreds] = useState<Record<string, any>>({
    username: adminMailbox?.username || "",
    password: adminMailbox?.password || "",
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  const INPUT_EMAIL = `${mailboxCreds?.username}@${domain}`;
  const COMMON_EVENT_DATA = {
    email: INPUT_EMAIL,
    mailbox_type: EVENT_MAILBOX_TYPE.CUSTOM,
    source_hook: SOURCE_HOOK,
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMailboxCreds({
      ...mailboxCreds,
      [name]:
        name === PASSWORD ? value : value.toLowerCase().replaceAll(" ", ""),
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  /**
   * function to check is entered email is already added in team or generic mailbox page
   */
  const isEmailAlreadyAdded = () => {
    return additionalMailboxes?.some(
      (item: Record<string, any>) => item.email === INPUT_EMAIL
    );
  };

  const validateInputs = async () => {
    const { username, password } = mailboxCreds;
    let formError = { username: "", password: "" };
    let error = false;

    if (username === "") {
      error = true;
      formError.username = INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_USERNAME;
    } else if (!isEmailValid(INPUT_EMAIL)) {
      error = true;
      formError.username = `"${INPUT_EMAIL}" - is not a valid email`;
    } else if (isEmailAlreadyAdded()) {
      error = true;
      formError.username =
        INPUT_ERROR_MESSAGE.MAILBOX_ALREADY_ADDED_WITH_THIS_USERNAME;
    } else {
      const { isAvailable, errorMsg } = await emailAvailabilityStatus(
        INPUT_EMAIL
      );
      if (!isAvailable) {
        error = true;
        formError.username = errorMsg;
      }
    }

    if (password === "") {
      error = true;
      formError.password = INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_PASSWORD;
    } else if (!isPasswordValid(password)) {
      error = true;
      formError.password = INPUT_ERROR_MESSAGE.PASSWORD_ERROR;
    }

    if (error) {
      setFormErrors(formError);
      // log mailbox_add_failed medusa event
      logEvent({
        eventName: MAILBOX_ADD_FAILED,
        data: {
          ...COMMON_EVENT_DATA,
          error: `${formError.username || ""}, ${formError.password || ""}`,
        },
      });
    }

    return error;
  };

  useEffect(() => {
    onVWOLoaded().then(() => {
      logEvent({ eventName: MAILBOX_ADD_VIEWED, data: {} });
    });
    setOffering();
  }, []);

  const handleSubmit = async () => {
    setIsProcessing(true);
    const isFormError = await validateInputs();

    if (isFormError) {
      setIsProcessing(false);
      return;
    }
    // log mailbox_added medusa event
    logEvent({
      eventName: MAILBOX_ADDED,
      data: {
        ...COMMON_EVENT_DATA,
      },
    });

    await okButtonProps.handler({ mailboxCreds, email: INPUT_EMAIL });
    setIsProcessing(false);
  };

  useKeypress("Enter", handleSubmit, mailboxCreds);

  return (
    <>
      <Header>{title}</Header>
      {subTitle && <div>{subTitle}</div>}
      <div className={styles.addMailboxContainer}>
        <div className={"max-width-600"}>
          <PreventInputPrefillByBrowser />
          <Input
            label={mailboxInputLabel || "Mailbox email address"}
            name={USERNAME}
            placeholder="Username"
            type="text"
            onChange={handleInputChange}
            hasError={!!formErrors.username}
            errorMessage={formErrors.username}
            domain={domain}
            className={styles.inputAddMailBox}
            autoCapitalize="none"
            value={mailboxCreds.username}
          />
          <Input
            label={mailboxPasswordLabel || "Set password for this mailbox"}
            name={PASSWORD}
            placeholder={`At least ${MINIMUM_PASSWORD_LENGTH} characters`}
            type="password"
            onChange={handleInputChange}
            hasError={!!formErrors.password}
            errorMessage={formErrors.password}
            isPasswordInput
            autoCapitalize="none"
            value={mailboxCreds.password}
          />
          {AdminMailboxInfo && <div>{AdminMailboxInfo}</div>}
          <div className={styles.submitBtnWrapper}>
            <Button
              type="primary"
              onClick={handleSubmit}
              isProcessing={isProcessing}
              className={styles.createMailboxButton}
            >
              {okButtonProps.label}
              <br />
              {okButtonProps.sublabel}
            </Button>
            {cancelButtonProps && (
              <Button type="secondary" onClick={cancelButtonProps.handler}>
                {cancelButtonProps.label}
              </Button>
            )}
          </div>
          {footer ? footer : null}
        </div>
        {EmailBenefits && <div>{EmailBenefits}</div>}
      </div>
    </>
  );
};

export default AddMailbox;
