import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TProductTypes } from "../../types/products.type";
import { TBillingCycle } from "../../types/billing-cycle";
import { BILLING_CYCLE_KEYS } from "../../helpers/constants";

export interface PlanState {
  duration: TBillingCycle;
  id: number | null;
  supportedBillingCycles: TBillingCycle[];
}

const initialPlanState: PlanState = {
  duration: BILLING_CYCLE_KEYS.YEARLY,
  id: null,
  supportedBillingCycles: [],
};

// Factory to create plan reducer
export const createPlanSlice = (scope: TProductTypes) => {
  return createSlice({
    name: `${scope}/plan`,
    initialState: initialPlanState,
    reducers: {
      setPlan(state: PlanState, action: PayloadAction<Partial<PlanState>>) {
        return { ...state, ...action.payload };
      },
      setPlanDuration(state: PlanState, action: PayloadAction<PlanState['duration']>) {
        state.duration = action.payload;
      },
      setPlanId(state: PlanState, action: PayloadAction<PlanState['id']>) {
        state.id = action.payload;
      },
      resetPlan() {
        return initialPlanState;
      }
    },
  });
};

export type PlanReducer = ReturnType<typeof createPlanSlice>;
