import { userSelector } from "../store/selectors";
import { store } from "../store/store";

export const getPersonaDataFromStore = (storeState) => {
  const storeData = storeState || store.getState();

  const user = userSelector(storeData);
  const { persona } = user;
  const { q1, q2, q3, q4, q5, q6, q7 } = persona || {};

  return {
    business_industry:
      q1 && (q1.reason ? `${q1.answer} - ${q1.reason}` : q1.answer),
    employee_count: q2 && parseInt(q2.answer),
    role_in_business:
      q3 && (q3.reason ? `${q3.answer} - ${q3.reason}` : q3.answer),
    signup_reason:
      q4 &&
      (q4.reason
        ? `${q4.answer.toString()} - ${q4.reason}`
        : q4.answer.toString()),
    signup_source:
      q5 &&
      (q5.reason
        ? `${q5.answer.toString()} - ${q5.reason}`
        : q5.answer.toString()),
    import_emails_contacts:
      q6 &&
      (q6.reason
        ? `${q6.answer.toString()} - ${q6.reason}`
        : q6.answer.toString()),
    current_email_app:
      q7 &&
      (q7.reason
        ? `${q7.answer.toString()} - ${q7.reason}`
        : q7.answer.toString()),
  };
};
