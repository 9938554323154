import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import useIsLoggedIn from "../useIsLoggedIn";
import {
  emailAccountDiscountSelector,
  promoSelector,
} from "../../store/selectors/promoSelector";
import { getPromoCodeIdFromURL } from "../../helpers/utils";
import { fetchPromoDetails } from "../../store/slices/promo.slice";
import { removePromoFromUrl } from "../../helpers/promo.helper";

type Props =
  | {
      fetchIfNotPresent?: boolean;
    }
  | undefined;

const usePromo = ({ fetchIfNotPresent }: Props = {}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useIsLoggedIn();
  const promo = useSelector(promoSelector);
  const emailAccountDiscount = useSelector(emailAccountDiscountSelector);

  useEffect(() => {
    let promoCodeId = getPromoCodeIdFromURL();
    if (fetchIfNotPresent && isLoggedIn && promoCodeId) {
      fetchPromo(promoCodeId);
    }
  }, [isLoggedIn, fetchIfNotPresent]);

  const fetchPromo = async (promoCodeId: string) => {
    setIsLoading(true);
    try {
      await dispatch(fetchPromoDetails(promoCodeId));
    } catch (error) {
      removePromoFromUrl();
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    promoCodeDetails: promo?.promoCodeDetails,
    emailAccountDiscount,
    promoCodeErrorDetails: promo?.promoCodeErrorDetails,
  };
};

export default usePromo;
