// TODO: Remove this file
//  this file is currently redundant, as this is just an extra step in using the selector.
//  These selectors can be used directly from their source files.

import { selectAppDetails } from "./appSelector";
import { selectConfigDetails } from "./configSelector";
import { selectDomainData } from "./domainSelector";
import { selectErrorCounts } from "./errorsSelector";
import { selectUserDetails } from "./userSelector";
import { selectUtmDetails } from "./utmSelector";

export const userSelector = selectUserDetails;
export const appSelector = selectAppDetails;
export const utmSelector = selectUtmDetails;
export const errorSelector = selectErrorCounts;
export const domainSelector = selectDomainData;
export const configSelector = selectConfigDetails;