import React from "react";
import { useSelector } from "react-redux";
import BillingInformation from "../shared/BillingInformation";
import {
  isSiteDomainCustom,
  selectSiteDomainAttrs,
} from "../../../store/slices/site.slice";
import { TLogEventProps } from "../../../types/global";
import {
  getSiteBuilderEventData,
  getVisitorProfileForMedusa,
} from "../../../telemetry/site/siteMedusaData";
import { logSiteBillingPageEvent } from "../../../telemetry/site/medusaEventsFunctions";

type Props = {
  isVisible: boolean;
  onContinue: () => void;
};

// TODO: Optimize this component to avoid re-rendering
export default function SiteBillingInformation({ ...props }: Props) {
  const { isCoSiteTrial } = useSelector(selectSiteDomainAttrs);
  const isCustomDomain = useSelector(isSiteDomainCustom);

  const logEvent = ({ eventName, data = {} }: TLogEventProps) => {
    logSiteBillingPageEvent(eventName, {
      ...data,
      ...getSiteBuilderEventData(),
      ...getVisitorProfileForMedusa(),
    });
  };

  return (
    <BillingInformation
      {...props}
      showWhyTrialTooltip={isCoSiteTrial || isCustomDomain}
      logEvent={logEvent}
    />
  );
}
