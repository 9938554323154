import { getCountOfTotalMailboxAdded } from "../../helpers/emailHelpers";
import { countOfEachTypeMailbox } from "../../helpers/utils";
import { selectMailAdditionalMailbox, selectMailAdminMailbox } from "../../store/slices/mail.slice";
import { store } from "../../store/store";
import { RootState } from "../../types/store.type";

export const getMailboxesDataForMedusa = (storeState?: RootState) => {
  const storeData = storeState || store.getState();
  const additionalMailboxes = selectMailAdditionalMailbox(storeData);
  const adminMailbox = selectMailAdminMailbox(storeData);

  const { teamMailboxCount, suggestionMailboxCount, customMailboxCount } =
    countOfEachTypeMailbox(additionalMailboxes);

  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);

  return {
    email: adminMailbox?.email,
    generic_mailbox_count: suggestionMailboxCount + customMailboxCount,
    name_mailbox_count: teamMailboxCount,
    total_mailbox_count: countOfMailboxAdded,
    suggested_mailbox_count: suggestionMailboxCount,
  };
};
