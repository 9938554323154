import { getActiveVWOCampaignIDs } from "../helpers/vwoHelper";
import { userSelector } from "../store/selectors";
import { store } from "../store/store";

export const getVWOExperimentsDataFromStore = async (storeState) => {
  const storeData = storeState || store.getState();

  const user = userSelector(storeData);
  const { experiments } = user;

  let active_experiments;
  try {
    active_experiments = await getActiveVWOCampaignIDs();
  } catch (error) {
    console.error("Error fetching campaign IDs:", error);
  }

  return {
    active_experiments,
    experiments,
  };
};
