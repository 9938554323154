import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { bindActionCreators } from "redux";
import ENBridge from "../3rdPartyIntegrations/Entri/bridge";
import { COOKIE_KEYS, PAGE_PATHS } from "../helpers/constants";
import { cleanUrl } from "../helpers/utils";
import { eraseCookie } from "../helpers/cookie.helper";
import { appActions } from "../store/actions";
import { userSelector } from "../store/selectors";
import { isMailDomainCustom } from "../store/slices/mail.slice";

/**
 * hook to handle redirections after domain or mailbox purchased
 * This hook handle both billing page checkout and mailbox checkout modal redirection
 */
// Keys To be cleared from search params if redirected to signup app from Landing page or NAP
const keysToClearFromSearchParams = [
  "utm_term",
  "utm_medium",
  "utm_campaign",
  "utm_source",
  "utm_content",
];
const {
  routes: { entriRoot },
  const: { setupType: entriSetupType },
} = ENBridge;
const { UTM_PARAMS } = COOKIE_KEYS;
const { PERSONA, ALL_DONE } = PAGE_PATHS;

export const useRedirection = () => {
  const { entriResponse: entriStoreData } = useSelector(userSelector);
  // TODO: for site
  const isCustomDomain = useSelector(isMailDomainCustom);
  const navigateTo = useNavigate();
  const { updateAppParams } = bindActionCreators(appActions, useDispatch());

  // Remove UTM parameters from url and redux store after domain or order created successfully
  const removeUtmParams = () => {
    // remove utm params from cookies
    eraseCookie(UTM_PARAMS);

    // remove utm params from search params
    const updatedSearchParam = cleanUrl(keysToClearFromSearchParams);

    // update app params with new search params not containing utm params
    updateAppParams({ search_params: updatedSearchParam });
    return updatedSearchParam;
  };

  const redirectToTeamMailbox = () => {
    // Remove UTM parameters from url and redux store after domain or order created successfully
    const updatedSearchParams = removeUtmParams();
    navigateTo({
      pathname: PAGE_PATHS.ADD_TEAM_MAILBOX,
      search: updatedSearchParams,
    });
  };

  const redirectTo = (PAGE_PATH) => {
    // Remove UTM parameters from url and redux store after domain or order created successfully
    const updatedSearchParams = removeUtmParams();
    navigateTo({
      pathname: PAGE_PATH,
      search: updatedSearchParams,
    });
  };

  const redirectToDnsSetupFlow = () => {
    navigateTo({
      pathname: entriRoot,
    });
  };

  const handleCheckoutRedirection = (entriResponse = null) => {
    // There are two A/B flows for redirection to dns setup
    // 1. control group where add team and generic mailbox shown pre checkout, here we get entri data from function arguments
    // 2. variation group where add team and generic shown post checkout, here we fetch entri data from store
    // Note: At the time of order purchase we are adding entri data to store

    const selectedEntriResponse = entriResponse || entriStoreData;
    if (isCustomDomain) {
      if (selectedEntriResponse?.setupType === entriSetupType.AUTOMATIC) {
        redirectToDnsSetupFlow();
      } else {
        redirectTo(ALL_DONE);
      }
    } else {
      navigateTo({
        pathname: PERSONA,
      });
    }
  };

  return { handleCheckoutRedirection, redirectToTeamMailbox };
};
