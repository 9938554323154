import classNames from "classnames";
import styles from "./style.module.scss";

const Addon = ({
  onClick,
  children,
  disabled,
}: {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <span
      className={classNames(styles.addon, { [styles.disabled]: disabled })}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default Addon;