/**
 * Get all the VWO (Visual Website Optimizer) campaign data available on the window._vwo_exp object.
 * @returns {Array} An array of campaign data objects with c_id, c_name and c_status properties.
 */
export const getAllVWOCampaignData = () => {
  try {
    const allCampaignData = [];
    if (!window._vwo_exp) {
      console.error("VWO data is not available");
      return allCampaignData; // Return empty array
    }

    for (const exp_id in window._vwo_exp) {
      if (window._vwo_exp.hasOwnProperty(exp_id)) {
        const campaign = window._vwo_exp[exp_id];
        const data = {
          c_id: getFormattedCampaignID(exp_id), // campaign ID
          c_name: campaign.name, // campaign name
          c_status: campaign.status,
        };
        allCampaignData.push(data);
      }
    }
    return allCampaignData;
  } catch (error) {
    console.error("Error while fetching VWO campaign data:", error);
    return []; // Return empty array in case of error
  }
};

/**
 * Async function to get all the IDs of the active VWO campaigns.
 * @returns {Promise<Array>} An array of active VWO campaign IDs.
 */
export const getActiveVWOCampaignIDs = async () => {
  try {
    await VWOLoaded;
    return _getActiveCampaignIDs();
  } catch (error) {
    console.error("Error in getActiveVWOCampaignIDs:", error);
    return []; // Return empty array in case of error
  }
};

/**
 * Private function to filter out the active campaign IDs.
 * @returns {Array} An array of active VWO campaign IDs.
 */
const _getActiveCampaignIDs = () => {
  try {
    const allCampaignData = getAllVWOCampaignData();

    // Check if allCampaignData is an array
    if (!Array.isArray(allCampaignData)) {
      console.error("Error: Expected allCampaignData to be an array");
      return []; // Return empty array
    }

    return allCampaignData
      .filter((test) => test.c_status === "RUNNING")
      .map((test) => test.c_id);
  } catch (error) {
    console.error("Error while fetching active VWO campaign IDs:", error);
    return []; // Return empty array in case of error
  }
};

/**
 * Creates a stitched string of campaign ID and variation name for logging in Medusa Events.
 * @param {string} campaignID - The ID of the campaign.
 * @param {string} variationName - The name of the variation.
 * @returns {string} The stitched string of campaign ID and variation name.
 */
export const getStitchedCampaignIDAndVariation = (
  campaignID,
  variationName
) => {
  return `${getFormattedCampaignID(campaignID)}_${variationName}`;
};

/**
 * Creates a formatted string of campaign ID for use in the app.
 * @param {string} campaignID - The ID of the campaign.
 * @returns {string} Campaign ID string.
 */
export const getFormattedCampaignID = (campaignID) => {
  return `VWO-Test-ID-${campaignID}`;
};
//
export const getVariationDataForCampaign = async (campaignID) => {
  await VWOLoaded;
  _getVariationDataForCampaign(campaignID);
};

/**
 * Creates a formatted string of campaign ID for use in the app.
 * @param {number} campaignID - The ID of the campaign.
 * @returns {Object} - Variation data object containing variation name and ID.
 */
const _getVariationDataForCampaign = (campaignID) => {
  try {
    // Check if _vwo_exp is available on window object
    if (!window._vwo_exp || !window._vwo_exp[campaignID]) {
      console.error(`No campaign data found for campaign ID: ${campaignID}`);
      return null;
    }

    const campaignData = window._vwo_exp[campaignID];
    const variationId = campaignData.combination_chosen;
    const variationName = campaignData.comb_n[variationId];

    return {
      variationId,
      variationName,
    };
  } catch (error) {
    console.error(
      `Error fetching campaign data for campaign ID: ${campaignID}`,
      error
    );
    return null;
  }
};

/**
 * Filters experimentsData based on activeCampaignIDs. The function returns
 * a new array consisting of elements in experimentsData which start with any of
 * the elements present in activeCampaignIDs array.
 *
 * @param {Array} activeCampaignIDs - Array of campaign IDs that are active.
 * @param {Array} experimentsData - Array of experiment data strings to be filtered.
 *
 * @returns {Array} - Returns a new array of filtered experimentsData that start with
 * any of the activeCampaignIDs. If either of the input is not provided or invalid,
 * the function logs an error and returns undefined.
 *
 * @example
 *
 * let activeCampaignIDs = ['VWO-Test-ID-7', 'VWO-Test-ID-8'];
 * let experimentsData = ['VWO-Test-ID-7_name-1', 'VWO-Test-ID-8_name-2', 'VWO-Test-ID-9_name-3'];
 *
 * getFilteredExperiments(activeCampaignIDs, experimentsData);
 * // Returns ['VWO-Test-ID-7_name-1', 'VWO-Test-ID-8_name-2']
 *
 */
export const getFilteredExperiments = (activeCampaignIDs, experimentsData) => {
  if (!activeCampaignIDs || !experimentsData) {
    console.error("getFilteredExperiments : Invalid arguments");
    return null;
  }

  return experimentsData.filter((currentExpElem) =>
    activeCampaignIDs.some((activeIDsElem) =>
      currentExpElem.startsWith(activeIDsElem)
    )
  );
};

const _getChosenVariationId = async (campaignID) => {
  await VWOLoaded;
  if (!window._vwo_exp || !window._vwo_exp[campaignID]) {
    console.error(`No campaign data found for campaign ID: ${campaignID}`);
    return null;
  }
  return window._vwo_exp[campaignID].combination_chosen;
};

/**
 * Checks to see if the user is on a particular variant 'X' of a given campaign
 * where 'X' is the variant ID
 *
 * @param {number} campaignID - ID of the campaign to be checked.
 * @param {string} targetVariationID - ID of the variant that the user needs to be on.
 *
 * @returns {Boolean} - Returns a boolean value.
 *
 */
export const isUserOnTargetVariant = async (campaignID, targetVariationID) => {
  const chosenVariationId = await _getChosenVariationId(campaignID);

  if (!chosenVariationId) {
    return null;
  }
  // skipping type checking due to inconsistencies in the way VWO handles data
  // IDs can be both number and strings
  // eslint-disable-next-line eqeqeq
  return chosenVariationId == targetVariationID;
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const MAX_CHECK_BUCKETING_RETRIES = 5;
let retryCount = 0;
export const hasUserBeenBucketed = async (campaignID) => {
  try {
    const chosenVariationId = await _getChosenVariationId(campaignID);
    if (!!chosenVariationId) {
      console.log("User bucketing complete. Chosen ID:", chosenVariationId);
      retryCount = 0;
      return chosenVariationId;
    } else if (retryCount < MAX_CHECK_BUCKETING_RETRIES) {
      retryCount++;
      await delay(250);
      return hasUserBeenBucketed(campaignID);
    } else {
      console.error(
        "Max retries reached, user might not have been bucketed yet."
      );
      retryCount = 0;
      return null;
    }
  } catch (error) {
    console.error(
      `Error in hasUserBeenBucketed for campaign ID: ${campaignID}`,
      error
    );
    retryCount = 0;
    return null;
  }
};

let VWOLoadedPromise = null;

export const VWOLoaded = (() => {
  if (!VWOLoadedPromise) {
    VWOLoadedPromise = new Promise((resolve, reject) => {
      window.addEventListener("vwoLoaded", () => resolve(), { once: true });
      window.addEventListener(
        "vwoLoadFailed",
        () => reject(new Error("VWO failed to load")),
        { once: true }
      );
    });
  }
  return VWOLoadedPromise;
})();

export const triggerVWOLoaded = () => {
  window.dispatchEvent(new CustomEvent("vwoLoaded"));
};

export const triggerVWOLoadFailed = () => {
  window.dispatchEvent(new CustomEvent("vwoLoadFailed"));
};
