import { createAsyncThunk } from "@reduxjs/toolkit";
import { autoLogin } from "../../services/apiFunctions";
import { userSelector } from "../selectors";
import { getDomainWithoutTld } from "../../helpers/domainHelpers";
import { DOMAIN_TYPE, NEO_OFFERING_EVENT_VALUE } from "../../helpers/constants";
import { CLEAR_SESSION, RESET_USER, UPDATE_USER } from "./actionsConstants";
import { RootState } from "../../types/store.type";

const { USER_CUSTOM_DOMAIN, CO_DOT_SITE } = NEO_OFFERING_EVENT_VALUE;

type AutoLoginResponse = {
  name: string;
  email: string;
  accountId: number;
  domainId: number;
  domainName: string;
  userId: number;
  userEmail: string;
  partnerId: string;
  createdAt: string;
  expiryEpochTimestamp: number;
};

export const updateUser = (user: any) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_USER,
      payload: user,
    });
  };
};

export const resetUser = () => {
  return (dispatch: any) => {
    dispatch({
      type: RESET_USER,
    });
  };
};

export const clearSession = () => {
  return (dispatch: any) => {
    dispatch({
      type: CLEAR_SESSION,
    });
  };
};

export const updateCoSiteDomain = (coSiteDomain: string) => {
  return (dispatch: any) => {
    const inputDomain = getDomainWithoutTld(coSiteDomain);
    dispatch({
      type: UPDATE_USER,
      payload: {
        coSiteDomain,
        inputDomain: inputDomain,
        domain_ownership: DOMAIN_TYPE.DONT_HAVE_DOMAIN,
        neo_offering: CO_DOT_SITE,
      },
    });
  };
};

export const updateCustomDomain = (customDomain: string) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_USER,
      payload: {
        customDomain,
        domain_ownership: DOMAIN_TYPE.ALREADY_HAVE_DOMAIN,
        neo_offering: USER_CUSTOM_DOMAIN,
      },
    });
  };
};

export const autoLoginUser = createAsyncThunk(
  "user/autoLogin",
  async (token: string, { dispatch, getState }) => {
    const user = userSelector(getState() as RootState);
    try {
      const response = await autoLogin({ token });
      if (response.status !== 200) {
        throw new Error("Failed to auto login");
      }
      const { name, expiryEpochTimestamp, domainName, userEmail, userId } =
        response.data as AutoLoginResponse;
      const hasActiveOrders = !!domainName;
      const updatedUser = {
        name: name || "",
        token,
        tokenExpiry: expiryEpochTimestamp,
        alternateEmail: userEmail,
        hasActiveOrders,
        firstOrder: !hasActiveOrders,
        billingInformation: {
          ...user.billingInformation,
          yourName: name,
        },
        customer_id: userId,
      };
      dispatch(updateUser(updatedUser));
    } catch (error) {
      dispatch(resetUser());
      throw error;
    }
  }
);
