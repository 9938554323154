import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../Header";
import { ANALYTICS_EVENTS, HEADER_TYPES } from "../../../helpers/constants";
import Button from "../../Common/Button/Button";
import TextArea from "../../Common/Textarea";
import { isValidAlphanumericWithSpecialChars } from "../../../helpers/utils";
import { logSiteOfferingOnboardingEvent } from "../../../telemetry/site/siteoffering";
import { userSelector } from "../../../store/selectors";
import LayoutTemplate from "../LayoutTemplate";
import DescriptionList from "./DescriptionList";
import sharedStyles from "../shared/styles.module.scss";

const MAX_LENGTH = 1000;

const { BUSINESS_IDEA_PAGE_VIEWED } = ANALYTICS_EVENTS;

interface Props {
  businessIdea: string;
  continueButtonText: string;
  onContinue: (businessIdea: string, exampleIdeaUsed: string) => void;
}

const BusinessIdea: React.FC<Props> = ({
  businessIdea: initialBusinessIdea,
  continueButtonText,
  onContinue,
}) => {
  const [businessIdea, setBusinessIdea] = useState(initialBusinessIdea || "");
  const [exampleIdeaUsed, setExampleIdeaUsed] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { source_hook } = useSelector(userSelector);

  useEffect(() => {
    logSiteOfferingOnboardingEvent(BUSINESS_IDEA_PAGE_VIEWED, { source_hook });
  }, []);

  const handleIdeaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBusinessIdea(event.target.value);
  };

  const handleSelectDescription = (description: string) => {
    setBusinessIdea(description);
    setExampleIdeaUsed(description);
    if (error) {
      setError("");
    }
  };

  const handleContinue = () => {
    const trimmedBusinessIdea = businessIdea.trim();

    // Validation logic before calling onContinue
    if (trimmedBusinessIdea === "") {
      setError("Enter a description for your project or business");
      return;
    }

    if (trimmedBusinessIdea.length > MAX_LENGTH) {
      setError(`Text too long, maximum ${MAX_LENGTH} characters allowed`);
      return;
    }

    if (!isValidAlphanumericWithSpecialChars(trimmedBusinessIdea)) {
      setError("Only alphabetic, numeric, and special characters are allowed");
      return;
    }

    // Clear error if all validation passes
    setError("");

    // Call onContinue if validation passes
    onContinue(trimmedBusinessIdea, exampleIdeaUsed);
  };

  // Left content for the layout
  const leftContent = (
    <div className="max-width-600">
      <Header type={HEADER_TYPES.YOUR_IDEA} />
      <div className={sharedStyles.businessSubtitle}>
        Describe your idea and let Neo design the perfect website for you.
      </div>
      <TextArea
        value={businessIdea}
        onChange={handleIdeaChange}
        placeholder="E.g. A cleaning company providing top-quality services for residential and commercial clients."
        rows={5}
        errorMessage={error}
      />
      <DescriptionList onSelectDescription={handleSelectDescription} />
      <Button type={"primary"} width={"auto"} onClick={handleContinue}>
        {continueButtonText}
      </Button>
    </div>
  );

  return <LayoutTemplate leftContent={leftContent} />;
};

export default BusinessIdea;
