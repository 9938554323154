import React, { createContext, useContext, FC, ReactNode } from "react";
import { PlansContextProps } from "../types";

const PlansContext = createContext<PlansContextProps | undefined>(undefined);

export const usePlansContext = () => {
  const context = useContext(PlansContext);
  if (!context) {
    throw new Error("usePlansContext must be used within a PlansProvider");
  }
  return context;
};

export const PlansProvider: FC<{ children: ReactNode; value: PlansContextProps }> = ({ children, value }) => (
  <PlansContext.Provider value={value}>{children}</PlansContext.Provider>
);
