import { getCoSiteTld } from "./utils";

const cleanDomain = (domain = "") => {
  return domain?.toLowerCase()?.trim();
};

export const getDomainFromEmail = (email = "") => {
  return email.split("@")?.[1];
};

export const isCoSiteDomain = (domain = "") => {
  domain = domain?.toLowerCase()?.trim();
  const coSiteTld = getCoSiteTld();
  return domain?.endsWith(coSiteTld);
};

export const isCustomDomain = (domain = "") => {
  return !isCoSiteDomain(domain);
};

export const getDomainWithoutTld = (domain = "") => {
  domain = cleanDomain(domain);
  return domain?.split(".")[0];
};

// Helper function to extract the root domain (removes subdomains)
export const getRootDomain = (hostname: string) => {
  const parts = hostname.split(".");
  return parts.length > 2 ? parts.slice(parts.length - 2).join(".") : hostname;
};
