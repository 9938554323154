import { updateLogout } from "../store/actions/logoutActions";
import { store } from "../store/store";
import { COOKIE_KEYS } from "./constants";
import { eraseCookie } from "./cookie.helper";

const { CUSTOMER_ID_COOKIE } = COOKIE_KEYS;

export const logoutUser = async () => {
    store.dispatch(updateLogout());

    eraseCookie(CUSTOMER_ID_COOKIE);
  };