import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";
import { ReactComponent as CancelIcon } from "../../assets/cancel.svg";
import { PROMO_ERROR_CODES } from "../../constants/promo.constants";
import usePromo from "../../hooks/mail/usePromo";
import { logPromoFailedEvent } from "../../telemetry/medusaEventsFunctions";
import { ANALYTICS_EVENTS } from "../../helpers/constants";
import style from "./PromoError.module.scss";

const { PROMO_CODE_EXPIRED, PROMO_CODE_USED } = PROMO_ERROR_CODES;

const config = {
  [PROMO_CODE_USED]: {
    title: "Promo code expired",
    desc: (promoCode: string) =>
      `Promo code ${promoCode} has already been used by you.`,
  },
  [PROMO_CODE_EXPIRED]: {
    title: "Promo code expired",
    desc: (promoCode: string) => `Promo code ${promoCode} has expired.`,
  },
  default: {
    title: "Invalid Promo code",
    desc: () => "The promo code seems incorrect.",
  },
};

type Props = {
  className?: string;
};

// Only publish medusa event once per session
const isMedusaEventPublished = false;

export default function PromoError({ className }: Props) {
  const { promoCodeErrorDetails } = usePromo();
  const [hideContent, setHideContent] = useState(false);

  useEffect(() => {
    if (
      isMedusaEventPublished ||
      !promoCodeErrorDetails ||
      !promoCodeErrorDetails.errorCode
    )
      return;
    logPromoFailedEvent(ANALYTICS_EVENTS.CUSTOMER_PROMO_CODE_FAILED, {
      promo_code: promoCodeErrorDetails.displayCode,
      promo_error_code: promoCodeErrorDetails.errorCode,
      promo_code_id: promoCodeErrorDetails.id,
      source_hook: window.location.pathname,
    });
  }, [promoCodeErrorDetails]);

  const handleCancelClick = () => {
    setHideContent(true);
  };

  if (!promoCodeErrorDetails || !promoCodeErrorDetails?.errorCode) return null;

  if (hideContent) {
    return null;
  }

  const { title, desc } =
    config[promoCodeErrorDetails.errorCode] || config.default;

  return (
    <div className={classNames(style.errorWrapper, className)}>
      <div>
        {" "}
        <WarningIcon className={style.icon} />
      </div>
      <div className={style.content}>
        <div className={style.title}>{title}</div>
        <div className={style.description}>
          {desc(promoCodeErrorDetails?.displayCode || "")}
        </div>
      </div>
      <CancelIcon className={style.cancel} onClick={handleCancelClick} />
    </div>
  );
}
