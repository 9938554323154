import React from 'react';

interface PreloadSiteProps {
  load: boolean;
  src: string;
}

const PreloadSite: React.FC<PreloadSiteProps> = ({ load, src }) => {
  if (!load) return null;
  
  return <iframe src={src} hidden />;
};

export default PreloadSite;
