import ENBridge from "../../../bridge";
import { setDnsRecordsAction, setDnsRecordsFetching } from "../../../store/neo/actions";
import { errorLogger } from "../../../utils/error";
import { parseDnsRecords } from "../../../utils/helpers";
import { dnsBannerConfig, getLatestUnverifiedDkimKey, verifyDkimKey } from "./service";

export const dnsBannerConfigProxy = async (dispatch, state) => {
  try {
    dispatch(setDnsRecordsFetching(true));

    const { domain, cpToken } = state;
    const dnsRecordToSet = ENBridge.handlers.getDnsRecordsToSet();
    let dnsBannerResponse = await dnsBannerConfig(cpToken, domain, dnsRecordToSet);
    const rawDNSRecords = dnsBannerResponse.expectedRecords || {};
    const cnameRecord = dnsRecordToSet.indexOf('cname') !== -1 ? {
      cname: [domain] // since BE doesn't provide cname record
    } : {};

    // dkim record not set, get the dkim key
    if (!dnsBannerResponse?.dkimVerified) {
      const dkimResponse = await getLatestUnverifiedDkimKey(cpToken, domain);
      dnsBannerResponse = {
        ...dnsBannerResponse,
        expectedRecords: {
          ...dnsBannerResponse.expectedRecords,
          dkim: [dkimResponse]
        }
      }

      // since all records are in array format hence keeping dkim in same way
      rawDNSRecords.dkim = [dkimResponse];
    }

    const { expectedRecords } = dnsBannerResponse;
    const dnsRecords = parseDnsRecords({
      ...expectedRecords,
      ...cnameRecord
    }, dnsRecordToSet)

    dispatch(setDnsRecordsAction({ dnsRecords, rawDNSRecords }));
    dispatch(setDnsRecordsFetching(false));
    return dnsBannerResponse;
  } catch(e) {
    dispatch(setDnsRecordsFetching(false));
    return Promise.reject(e);
  }
}

export const verifyDkimKeyProxy = async (state) => {
  try {
    const { rawDNSRecords, cpToken, domain: domainName } = state;
    const dkimKey = rawDNSRecords.dkim?.[0];
    if (!dkimKey || !dkimKey?.dkimId) throw new Error('dkim_key_missing');

    return verifyDkimKey(dkimKey.dkimId, cpToken, domainName);
  } catch(e) {
    return errorLogger.FAILED_TO_VERIFY_DKIM_KEY(e, true);
  }
}