import React, { useState } from "react";
import Header from "../../Header";
import { COMPONENTS_NAME, HEADER_TYPES } from "../../../helpers/constants";
import CustomAccordion from "../../Common/CustomAccordion";
import styles from "./Billing.module.scss";

const { ORDER_SUMMARY, BILLING_DETAILS, CARD_DETAILS } = COMPONENTS_NAME;

type ComponentProps = {
  onContinue: () => void;
  isVisible: boolean;
};

type BillingProps = {
  isCoSiteTrial: boolean;
  isCustomDomain: boolean;
  OrderSummaryComponent: React.ComponentType<ComponentProps>;
  BillingInformationComponent: React.ComponentType<ComponentProps>;
  BillingCardComponent: React.ComponentType<ComponentProps>;
};

const Billing: React.FC<BillingProps> = ({
  isCoSiteTrial,
  isCustomDomain,
  OrderSummaryComponent,
  BillingInformationComponent,
  BillingCardComponent,
}) => {
  const [accordionState, setAccordionState] = useState({
    [ORDER_SUMMARY]: { isVisible: true, isDisabled: false },
    [BILLING_DETAILS]: { isVisible: false, isDisabled: true },
    [CARD_DETAILS]: { isVisible: false, isDisabled: true },
  });

  const openNextAccordion = (currentComponent: string) => {
    setAccordionState((prevState) => {
      const newState = { ...prevState };

      if (currentComponent === ORDER_SUMMARY) {
        // Close current and open the next accordion
        newState[ORDER_SUMMARY].isVisible = false;
        newState[BILLING_DETAILS].isVisible = true;
        newState[BILLING_DETAILS].isDisabled = false;
      } else if (currentComponent === BILLING_DETAILS) {
        newState[BILLING_DETAILS].isVisible = false;
        newState[CARD_DETAILS].isVisible = true;
        newState[CARD_DETAILS].isDisabled = false;
      }

      return newState;
    });
  };

  const toggleAccordion = (component: string) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [component]: {
        ...prevState[component],
        isVisible: !prevState[component].isVisible,
      },
    }));
  };

  return (
    <>
      {isCustomDomain ? (
        <Header type={HEADER_TYPES.BILLING_CUSTOM_DOMAIN} />
      ) : isCoSiteTrial ? (
        <Header type={HEADER_TYPES.BILLING_CO_SITE_TRIAL} />
      ) : (
        <Header type={HEADER_TYPES.BILLING} />
      )}

      <div className={styles.accordionContainer}>
        <CustomAccordion
          title="Order Summary"
          expanded={accordionState[ORDER_SUMMARY].isVisible}
          onChange={() => toggleAccordion(ORDER_SUMMARY)}
          disabled={accordionState[ORDER_SUMMARY].isDisabled}
          className={styles.accordionItem}
        >
          <OrderSummaryComponent
            onContinue={() => openNextAccordion(ORDER_SUMMARY)}
            isVisible={accordionState[ORDER_SUMMARY].isVisible}
          />
        </CustomAccordion>

        <CustomAccordion
          title="Billing Information"
          expanded={accordionState[BILLING_DETAILS].isVisible}
          onChange={() => toggleAccordion(BILLING_DETAILS)}
          disabled={accordionState[BILLING_DETAILS].isDisabled}
          className={styles.accordionItem}
        >
          <BillingInformationComponent
            onContinue={() => openNextAccordion(BILLING_DETAILS)}
            isVisible={accordionState[BILLING_DETAILS].isVisible}
          />
        </CustomAccordion>

        <CustomAccordion
          title="Card Details"
          expanded={accordionState[CARD_DETAILS].isVisible}
          onChange={() => toggleAccordion(CARD_DETAILS)}
          disabled={accordionState[CARD_DETAILS].isDisabled}
          className={styles.accordionItem}
        >
          <BillingCardComponent
            onContinue={() => openNextAccordion(CARD_DETAILS)}
            isVisible={accordionState[CARD_DETAILS].isVisible}
          />
        </CustomAccordion>
      </div>
    </>
  );
};

export default Billing;
