import ENBridge from "../../bridge";
import { useCPButton } from "./useCPButton";

import { cj } from "../../utils/helpers";
import s from './CpButton.module.scss';

export const CP_BUTTON_TYPE = {
  BUTTON: 'button',
  LINK: 'link'
};

const CPButton = ({ type = CP_BUTTON_TYPE.BUTTON, text = '', ...restProps }) => {
  const { Button } = ENBridge.sharedComponents;
  const { isLoading, onCPButtonClick } = useCPButton(restProps);

  if (type === CP_BUTTON_TYPE.BUTTON) {
    return <Button
      disabled={isLoading}
      className={cj([ isLoading ? 'loading' : '' ])}
      type="secondary"
      onClick={onCPButtonClick}
    >{text}</Button>
  }

  return (
    <span className={cj([s.cpLink, isLoading ? 'loading' : ''])} onClick={onCPButtonClick}>
      {text}
    </span>
  )
}

export default CPButton;
