import React from "react";
import { showSuccessToast } from "../../helpers/toastHelper";
import {
  getAdminPanelUrl,
  getCopyToClipboard,
  redirectToNeoAdminPanel,
} from "../../helpers/utils";
import MobileStoreIcons from "../MobileStoreIcons/MobileStoreIcons";
import styles from "./CustomMobileSetup.module.scss";

function CustomMobileSetupRenderer({ controlPanelAutoLoginToken, alternateEmail }) {
  const ADMIN_PANEL_URL = getAdminPanelUrl();
  const ADMIN_URL_WITHOUT_PROTOCOL = ADMIN_PANEL_URL.split("/")?.[2];

  const showCopiedToast = () => {
    showSuccessToast("Copied successfully!", { toastId: "copied" });
  };

  const copyAdminPanelUrl = () => {
    getCopyToClipboard(
      redirectToNeoAdminPanel(controlPanelAutoLoginToken, alternateEmail)
    );
    showCopiedToast();
  };

  return (
    <>
      <div className={styles.congrats}>
        Login to Neo Admin Panel on Desktop to continue setup
      </div>
      <div className={styles.coSiteSetup}>
        <p>Neo Admin Panel is not optimized for mobile currently.</p>
      </div>
      <strong className={styles.strong}>Open this link on your desktop</strong>
      <div className={styles.neoSpaceUrl}>
        <p>{ADMIN_URL_WITHOUT_PROTOCOL}</p>
        <div onClick={copyAdminPanelUrl}>Copy</div>
      </div>
      <hr className={styles.horizontalLine} />
      <MobileStoreIcons />
    </>
  );
}

export default CustomMobileSetupRenderer;
