import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ANALYTICS_EVENTS,
  COOKIE_KEYS,
  HEADER_TYPES,
  INPUT_ERROR_MESSAGE,
  sourceHookMapping,
} from "../../helpers/constants";
import { isEmailValid, redirectToNeoAdminPanel } from "../../helpers/utils";
import { setCookie } from "../../helpers/cookie.helper";
import {
  customerLogin,
  generateCpAutoLoginToken,
} from "../../services/apiFunctions";
import { userActions } from "../../store/actions";
import { userSelector } from "../../store/selectors";
import Button from "../Common/Button/Button";
import Input from "../Common/Input/Input";
import Header from "../Header";
import styles from "./styles.module.scss";

const {
  CUSTOMER_ACCOUNT_SIGNED_IN,
  CUSTOMER_ACCOUNT_SIGNED_IN_FAILED,
  CUSTOMER_ACCOUNT_LINKED,
} = ANALYTICS_EVENTS;

const SignIn = ({
  LoginNudge,
  navigateToSignup,
  navigateAwayFromSignIn,
  navigateAfterLogin,
  logEvent,
  onSuccess,
}) => {
  const user = useSelector(userSelector);
  const { token, source_hook, alternateEmail } = user;

  const initialFormValues = {
    email: alternateEmail || "",
    password: "",
  };

  const formErrorValues = {
    email: false,
    password: false,
  };

  const emailErrors = {
    count: 0,
    reason: "",
  };

  const [userDetails, setUserDetails] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState(formErrorValues);
  const [processing, setProcessing] = useState(false);
  const [emailCheckFailCount, setEmailCheckFailCount] = useState(emailErrors);
  const [showError, setShowError] = useState(false);

  const { updateUser } = bindActionCreators(userActions, useDispatch());

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  // handle case where the user has active orders,
  // reaches the NAP post sign in and then clicks the browser back button
  useEffect(() => {
    if (token) {
      navigateAwayFromSignIn();
    }
  }, []);

  const handleUserKeypress = useCallback(
    (event) => {
      if (event.code === "Enter") {
        handleSubmit();
      }
    },
    [userDetails]
  );

  useEffect(() => {
    document.addEventListener("keypress", handleUserKeypress);
    return () => {
      document.removeEventListener("keypress", handleUserKeypress);
    };
  }, [handleUserKeypress]);

  const validateAllInputs = () => {
    const { email, password } = userDetails;
    let updatedFormErrors = { ...formErrors },
      error = false;

    // handle alternate email validation
    if (email === "") {
      error = true;
      updatedFormErrors.email = INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_EMAIL;
    } else if (!isEmailValid(email)) {
      error = true;
      updatedFormErrors.email = INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_EMAIL;
      setEmailCheckFailCount({
        count: emailCheckFailCount.count + 1,
        reason: "Invalid email format",
      });
    }

    // handle password validation
    if (password === "") {
      error = true;
      updatedFormErrors.password =
        INPUT_ERROR_MESSAGE.PLEASE_ENTER_VALID_PASSWORD;
    }

    setFormErrors(updatedFormErrors);

    return !error;
  };

  const handleSubmit = () => {
    setProcessing(true);
    setShowError(false);
    const isFormValid = validateAllInputs();
    const { CUSTOMER_ID_COOKIE } = COOKIE_KEYS;
    if (isFormValid) {
      // Do api call here for sign in with credentials

      const payload = {
        email: userDetails.email,
        password: userDetails.password,
      };

      customerLogin(payload).then((response) => {
        if (response.status !== 200) {
          setShowError(true);
          setProcessing(false);
          console.error("Login Error", response);
          logEvent({
            eventName: CUSTOMER_ACCOUNT_SIGNED_IN_FAILED,
            data: {
              error: response.data.desc,
              source_hook,
            },
            flag: true,
          });
          return;
        }

        const loginRes = response.data;

        const {
          actor: { attrs },
          userEmail,
          token: authToken,
          expiryEpochTimestamp,
          primaryDomain,
          bllUserId,
        } = loginRes;
        setProcessing(false);

        const hasActiveOrders = !!primaryDomain;

        updateUser({
          name: attrs.userName || "",
          token: authToken,
          tokenExpiry: expiryEpochTimestamp,
          alternateEmail: userEmail,
          hasActiveOrders,
          firstOrder: !hasActiveOrders,
          billingInformation: {
            ...user.billingInformation,
            yourName: attrs.userName,
          },
          customer_id: bllUserId,
        });
        onSuccess?.(loginRes);
        setCookie(CUSTOMER_ID_COOKIE, bllUserId);
        logEvent({
          eventName: CUSTOMER_ACCOUNT_LINKED,
          data: {
            user_type: "Customer",
            customer_account_linked_via: "Sign In",
            dummy_entity_Id: true,
          },
          flag: true,
        });
        logEvent({
          eventName: CUSTOMER_ACCOUNT_SIGNED_IN,
          data: {
            source_hook,
          },
          flag: true,
        });

        if (hasActiveOrders) {
          generateCpAutoLoginToken({ token: authToken }).then((response) => {
            const res = response.data;

            if (response.status !== 200) {
              console.error(
                "Error fetching control panel auth token",
                response
              );
              return;
            }
            updateUser({
              controlPanelAutoLoginToken: res.controlPanelAutoLoginToken,
            });
            if (
              source_hook &&
              (source_hook === sourceHookMapping.neoAdminPanel || source_hook === sourceHookMapping.neoAvatarSignIn)
            ) {
              window.location.href = redirectToNeoAdminPanel(
                res.controlPanelAutoLoginToken,
                alternateEmail
              );
            } else {
              navigateAfterLogin();
            }
          });
        } else {
          navigateAfterLogin();
        }
      });
    } else {
      setProcessing(false);
    }
  };

  return (
    <>
      <Header type={HEADER_TYPES.SIGN_IN} />
      <div className={"max-width-600"}>
        <div className={"subTitle"}>
          <span>New user?</span>
          <div className={styles.createAccount} onClick={navigateToSignup}>
            Create account
          </div>
        </div>
        <Input
          label="Email address"
          name={"email"}
          placeholder="Enter your email address"
          type={"text"}
          defaultValue={userDetails.email}
          onChange={(e) => handleInputChange(e)}
          hasError={formErrors["email"]}
          errorMessage={formErrors["email"]}
          autoCapitalize={"none"}
        />
        <Input
          label="Password"
          name={"password"}
          placeholder="Enter your password"
          type={"password"}
          defaultValue={userDetails.password}
          hasError={formErrors["password"]}
          errorMessage={formErrors["password"]}
          onChange={(e) => handleInputChange(e)}
          isPasswordInput={true}
          showForgotPasswordHook={true}
          logEvent={logEvent}
        />
        <Button
          type={"primary"}
          onClick={handleSubmit}
          isProcessing={processing}
        >
          Sign in
        </Button>
      </div>
      {showError && (
        <div className={styles.errorsWrapper}>
          <div>Incorrect customer email or password.</div>
          <div>
            Don't remember email address? Write to us at{" "}
            <a href="mailto:hello@neo.space">hello@neo.space</a> with your
            domain name.
          </div>
        </div>
      )}
      {LoginNudge && <div>{LoginNudge}</div>}
    </>
  );
};

export default SignIn;
