import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import { appSelector } from "../../../store/selectors";
import GetStarted from "../index";
import { TDomainSelectionOptions, TSelectChangePayload } from "../Common/types";
import {
  logDomainEvent,
  logGetStartedViewedEvent,
} from "../../../telemetry/medusaEventsFunctions";
import { TLogEventProps } from "../../../types/global";
import {
  selectMailDomain,
  setMailDomain,
} from "../../../store/slices/mail.slice";

const {
  EXISTING_DOMAIN_QUERY_RESPONDED,
  GET_STARTED_VIEWED,
  HAS_DOMAIN_CHECKED,
} = ANALYTICS_EVENTS;

const MailGetStarted: React.FC = () => {
  const { search_params } = useSelector(appSelector);
  const { offering } = useSelector(selectMailDomain);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const title = "Does your business already have a domain?";
  const subTitle = (
    <>
      Like <strong>joesbusiness.com</strong> - You will need a domain in order
      to set up your email.
    </>
  );

  const domainSelectionOptions: TDomainSelectionOptions = {
    haveDomain: "Yes, I have a domain I can use",
    needDomain: "No, I need a new domain",
  };

  const navigateToHaveDomain = (): void => {
    navigateTo({
      pathname: PAGE_PATHS.HAVE_DOMAIN,
      search: search_params,
    });
  };

  const navigateToGetDomain = (): void => {
    navigateTo({
      pathname: PAGE_PATHS.GET_DOMAIN,
      search: search_params,
    });
  };

  const logEvent = ({ eventName, data, flag }: TLogEventProps) => {
    switch (eventName) {
      case GET_STARTED_VIEWED:
        logGetStartedViewedEvent(eventName, data);
        break;

      case HAS_DOMAIN_CHECKED:
        // - For Mail product, fire both `existing_domain_query_responded` and the new event `has_domain_checked`.
        logDomainEvent(eventName, data, flag);
        logDomainEvent(EXISTING_DOMAIN_QUERY_RESPONDED, data, flag);
        break;

      default:
        console.log("Invalid event name");
    }
  };

  const onSelectChange = (payload: TSelectChangePayload) => {
    const { offering } = payload;
    dispatch(setMailDomain({ offering }));
  };

  return (
    <GetStarted
      title={title}
      subTitle={subTitle}
      domainSelectionOptions={domainSelectionOptions}
      offering={offering}
      navigateToHaveDomain={navigateToHaveDomain}
      navigateToGetDomain={navigateToGetDomain}
      logEvent={logEvent}
      onSelectChange={onSelectChange}
      showTestimonial
    />
  );
};

export default MailGetStarted;
