import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import Zendesk from "react-zendesk";
import { bindActionCreators } from "redux";
import {
  COOKIE_KEYS,
  CURRENT_PAGE_VIEWED,
  PAGE_PATHS,
  ZENDESK_KEY,
} from "../../helpers/constants";
import { onVWOLoaded } from "../../helpers/pageLoadedHelper";
import { showErrorToast } from "../../helpers/toastHelper";
import { generateNewDeviceId } from "../../helpers/utils";
import { eraseCookie, getCookie, setCookie } from "../../helpers/cookie.helper";
import { logoutActions, userActions } from "../../store/actions";
import { appSelector, userSelector } from "../../store/selectors";
import { logPageViewedEvent } from "../../telemetry/medusaEventsFunctions";
import { ANALYTICS_ATTRIBUTES } from "../../telemetry/medusaHelper";
import AddGenericMailbox from "../AddGenericMailbox/AddGenericMailbox";
import AddTeamMailbox from "../AddTeamMailbox/AddTeamMailbox";
import AllDone from "../AllDone/AllDone";
// import BuyDomainFromPartner from "../BuyDomainFromPartner/BuyDomainFromPartner";
import GoBack from "../Common/GoBack/GoBack";
// Mail component
import MailBilling from "../Billing/mail";
import MailSignUp from "../SignUp/Mail";
import MailSignIn from "../SignIn/Mail";
import MailGetStarted from "../GetStarted/Mail";
import MailHaveDomain from "../HaveDomain/Mail";
import MailGetDomain from "../GetDomain/Mail";
import MailAddMailbox from "../AddMailbox/Mail";
import MailPlans from "../Plans/mail";

// Site component
import SiteOffering from "../Site/SiteOfferingOnboard";
import SiteSignUp from "../SignUp/Site";
import SiteSignIn from "../SignIn/Site";
import SiteGetStarted from "../GetStarted/Site";
import SiteHaveDomain from "../HaveDomain/Site";
import SiteGetDomain from "../GetDomain/Site";
import SiteAddMailbox from "../AddMailbox/Site";
import SitePlans from "../Plans/site";
import SiteBilling from "../Billing/site";

import CustomDomainMobileSetup from "../CustomMobileSetup/CustomMobileSetup";
import Persona from "../Persona/Persona";
import PreviewSite from "../PreviewSite/PreviewSite";
import RouteProtection from "../RouteProtection/RouteProtection";
import UserProfile from "../UserProfile/UserProfile";
import { useProduct } from "../../hooks/useProduct";
import useLayoutNavigation from "./useLayoutNavigation";
import styles from "./Layout.module.scss";
import useResetMailDomain from "../../hooks/mail/useResetMailDomain";
import SiteRouteProtection from "../RouteProtection/Site/SiteRouteProtection";

const { LANDING_PAGE_NAME } = ANALYTICS_ATTRIBUTES;

const {
  SIGN_IN,
  SIGN_UP,
  ADD_MAILBOX,
  ADD_TEAM_MAILBOX,
  ADD_GENERIC_MAILBOX,
  PLANS,
  BILLING,
  ALL_DONE,
  PERSONA,
  PREVIEW_SITE,
  GET_STARTED,
  GET_STARTED_V2,
  HAVE_DOMAIN,
  GET_DOMAIN,
  ADMIN_LOGIN_INFO,

  //Site
  YOUR_IDEA,
  YOUR_BUSINESS_NAME,
  YOUR_EMAIL,
  SITE_SIGN_IN,
  SITE_SIGN_UP,
  SITE_GET_STARTED,
  SITE_GET_STARTED_V2,
  SITE_HAVE_DOMAIN,
  SITE_GET_DOMAIN,
  SITE_ADD_MAILBOX,
  SITE_BILLING,
  SITE_PLANS,
} = PAGE_PATHS;

const { DEVICE_ID, HOTJAR_ID, CUSTOMER_ID_COOKIE } = COOKIE_KEYS;

const BUFFER_TIME = 900000; // 15 minutes in epoch millis

function Layout() {
  const containerRef = useRef(null);

  const { search_params } = useSelector(appSelector);
  const {
    token,
    tokenExpiry,
    // showTeamMailboxPostDomainPurchase,
  } = useSelector(userSelector);

  const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  useLayoutNavigation();

  const location = useLocation();
  const productHandler = useProduct();

  const backBtnHiddenPages = [
    SIGN_UP,
    SIGN_IN,
    GET_STARTED,
    GET_STARTED_V2,
    ADD_MAILBOX,
    ALL_DONE,
    PERSONA,

    // site
    YOUR_IDEA,
    SITE_SIGN_UP,
    SITE_SIGN_IN,
    SITE_GET_STARTED,
  ];

  const authenticatedPages = [
    ADD_MAILBOX,
    ADD_TEAM_MAILBOX,
    ADD_GENERIC_MAILBOX,
    PLANS,
    BILLING,
    ALL_DONE,
    PREVIEW_SITE,
    PERSONA,

    // site
    SITE_ADD_MAILBOX,
    SITE_BILLING,
    SITE_PLANS,
  ];

  const { updateLogout } = bindActionCreators(logoutActions, useDispatch());
  const { clearSession } = bindActionCreators(userActions, useDispatch());
  useEffect(() => {
    async function handleTokenExpiry() {
      if (isLoggedIn && tokenExpiry) {
        const currentDateTime = Date.now();

        if (currentDateTime >= tokenExpiry - BUFFER_TIME) {
          // added a 15 mins buffer time to prevent token from expiring while user is on the flow

          const isAuthenticatedPage = authenticatedPages.includes(
            window.location.pathname
          );
          showErrorToast(
            isAuthenticatedPage
              ? "Your session has expired. You will be redirected to the sign in page. Please login again."
              : "Your session has expired.",
            {
              autoClose: 3000,
              toastId: "token-expired",
            }
          );
          /**
             * To logout user for authenticated pages we are using clearStore and
             * For unauthenticated pages we are using updateLogout action
             * The reason behind not to use updateLogout action for authenticated pages
             is we are setting the store to initial state with domain as empty and
             Route protection component will redirect the user to get-started page
             if domain is empty for fraction of second before redirecting to signin page
             */
          eraseCookie(CUSTOMER_ID_COOKIE);
          // await clearStore();
          clearSession();
          if (isAuthenticatedPage) {
            setTimeout(() => {
              productHandler.navigateToSignIn(search_params);
            }, 3000);
          } else {
            updateLogout();
          }
        }
      }
    }

    handleTokenExpiry();
  }, [isLoggedIn, tokenExpiry, location]);

  useEffect(() => {
    setIsLoggedIn(!!token);
  }, [token]);

  const logPageViewedMedusa = (path) => {
    logPageViewedEvent({
      [LANDING_PAGE_NAME]: CURRENT_PAGE_VIEWED[path],
    });
  };

  useEffect(() => {
    // if team mailbox page is shown to post domain or order purchase then don't show back button
    // if (showTeamMailboxPostDomainPurchase) {
    //   backBtnHiddenPages.push(ADD_TEAM_MAILBOX);
    // }
    setShowBackBtn(!backBtnHiddenPages.includes(location.pathname));
    window.scrollTo(0, 0);

    // log website_page_viewed event
    onVWOLoaded().then(() => {
      if (isFirstRender) {
        setIsFirstRender(false);
        setTimeout(() => logPageViewedMedusa(location.pathname), 250);
      } else {
        logPageViewedMedusa(location.pathname);
      }
    });

    // On url change check for device id and hotjar id if not exist in cookie then update
    if (!getCookie(DEVICE_ID)) {
      setCookie(DEVICE_ID, generateNewDeviceId());
    }
    setTimeout(() => {
      if (!getCookie(HOTJAR_ID) && window?.hj?.globals?.get("userId")) {
        setCookie(HOTJAR_ID, window?.hj?.globals?.get("userId"));
      }
    }, 3000);
  }, [location]);

  // const getContainerBounds = () => {
  //   return containerRef?.current?.getBoundingClientRect();
  // };

  const getProtectedRoute = (element) => {
    return <RouteProtection>{element}</RouteProtection>;
  };

  const getSiteRouteProtection = (element) => {
    return <SiteRouteProtection>{element}</SiteRouteProtection>;
  };

  /**
   * Load zendesk chat widget after containerRef div mounts
   * To pass the horizontal offset value to zendesk chat widget to get positioned correctly
   */
  const zendeskLoadPages = [ALL_DONE];
  const [loadZendesk, setLoadZendesk] = useState(false);
  const [zendeskSetting, setZendeskSetting] = useState(null);
  useEffect(() => {
    if (zendeskLoadPages.includes(location.pathname)) {
      setZendeskSetting({
        color: {
          theme: "#3872B2",
        },
        offset: {
          horizontal:
            window.innerWidth -
            containerRef?.current?.getBoundingClientRect().right +
            "px",
        },
        chat: {
          departments: {
            enabled: [],
          },
        },
      });
      setLoadZendesk(true);
    }
  }, [location]);

  const LogoComponent = productHandler?.logo;

  // This hook is called to reset the domain state if the purchased domain matches the custom or co-site domain.
  // It ensures that the mail domain data is cleared when transitioning to a new flow, allowing for a clean state.
  useResetMailDomain(); // Resets domain state for new flow

  return (
    <>
      <div className={styles.container} ref={containerRef}>
        <>
          <div className={styles.logo}>
            <LogoComponent />
            <UserProfile />
          </div>
        </>
        <div className={styles.content}>
          <main>
            {showBackBtn && <GoBack />}
            <Routes>
              {/* MAIL ROUTES */}
              <Route path={SIGN_UP} element={<MailSignUp />} />
              <Route path={SIGN_IN} element={<MailSignIn />} />
              <Route path={GET_STARTED} element={<MailGetStarted />} />
              <Route path={GET_STARTED_V2} element={<MailGetStarted />} />
              <Route path={HAVE_DOMAIN} element={<MailHaveDomain />} />
              <Route path={GET_DOMAIN} element={<MailGetDomain />} />
              <Route
                path={ADD_MAILBOX}
                element={getProtectedRoute(<MailAddMailbox />)}
              />
              <Route
                path={BILLING}
                element={getProtectedRoute(<MailBilling />)}
              />

              {/* SITE ROUTES */}
              <Route path={SITE_SIGN_UP} element={<SiteSignUp />} />
              <Route path={SITE_SIGN_IN} element={<SiteSignIn />} />
              <Route
                path={SITE_GET_STARTED}
                element={getSiteRouteProtection(<SiteGetStarted />)}
              />
              <Route
                path={SITE_GET_STARTED_V2}
                element={getSiteRouteProtection(<SiteGetStarted />)}
              />
              <Route path={SITE_HAVE_DOMAIN} element={<SiteHaveDomain />} />
              <Route path={SITE_GET_DOMAIN} element={<SiteGetDomain />} />
              <Route
                path={SITE_PLANS}
                element={getProtectedRoute(<SitePlans />)}
              />
              <Route
                path={SITE_BILLING}
                element={getProtectedRoute(<SiteBilling />)}
              />
              <Route
                path={SITE_ADD_MAILBOX}
                element={getProtectedRoute(<SiteAddMailbox />)}
              />

              <Route
                path={ADD_TEAM_MAILBOX}
                element={getProtectedRoute(<AddTeamMailbox />)}
              />
              <Route
                path={ADD_GENERIC_MAILBOX}
                element={getProtectedRoute(<AddGenericMailbox />)}
              />
              <Route path={PLANS} element={getProtectedRoute(<MailPlans />)} />

              <Route path={ALL_DONE} element={getProtectedRoute(<AllDone />)} />
              <Route path={PERSONA} element={getProtectedRoute(<Persona />)} />
              <Route
                path={PREVIEW_SITE}
                element={getProtectedRoute(<PreviewSite />)}
              />
              <Route
                path={ADMIN_LOGIN_INFO}
                element={getProtectedRoute(<CustomDomainMobileSetup />)}
              />

              {/* Site offering routes */}
              <Route path={YOUR_IDEA} element={<SiteOffering />} />
              <Route path={YOUR_BUSINESS_NAME} element={<SiteOffering />} />
              <Route path={YOUR_EMAIL} element={<SiteOffering />} />
            </Routes>
          </main>
        </div>
        {loadZendesk && (
          <Zendesk
            defer
            zendeskKey={ZENDESK_KEY}
            onLoaded={() => {
              window.zE("messenger", "close");
              console.info("Zendesk loaded");
            }}
            {...zendeskSetting}
          />
        )}
        {/*<Footer redirectToHomepage={redirectToHomepage} />*/}
        {/* <IntercomLauncher containerBounds={getContainerBounds()} /> */}
        {/* <div className={styles.version}>{getAppVersion()}</div> */}
      </div>
    </>
  );
}

export default Layout;
