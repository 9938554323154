import React, { useState } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_KEYS } from "../../helpers/constants";
import { configSelector } from "../../store/selectors";
import StripeCardPayment from "../StripeCardPayment/StripeCardPayment";

const CardElementsWrapper = (
  props: React.ComponentProps<typeof StripeCardPayment>
) => {
  const { env } = useSelector(configSelector);
  // using a state variable instead of a direct variable to fix some warnings showing up in console
  const [stripe] = useState(() =>
    loadStripe(STRIPE_KEYS[env as keyof typeof STRIPE_KEYS])
  );

  return (
    <Elements stripe={stripe}>
      <StripeCardPayment {...props} />
    </Elements>
  );
};

export default CardElementsWrapper;
