import React from "react";
import styles from "./styles.module.scss";

const ReviewQuote: React.FC = () => {
  return (
    <div className={styles.reviewWrapper}>
      <strong>“</strong>
      <p className={styles.review}>
        Neo is the best alternative to Google. It is an excellent and affordable
        email platform for my team and my startup clients.
      </p>
      <div>Maria Jordan</div>
      <div>Graphic Designer - Freelancer</div>
    </div>
  );
};

export default ReviewQuote;
