import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { appSelector, userSelector } from "../../../store/selectors";
import {
  ANALYTICS_EVENTS,
  BILLING_CYCLE_KEYS,
  HEADER_TYPES,
  PAGE_PATHS,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../../helpers/constants";
import { TPlan } from "../../../types/plan.type";
import {
  isSiteDomainCustom,
  selectSiteDomainAttrs,
  selectSiteDomainPricing,
  selectSitePlan,
  selectSitePurchaseDomain,
  setSitePlan,
} from "../../../store/slices/site.slice";
import {
  SITE_FEATURE_GROUP_TEXT_MAP,
  siteFeaturesTextMap,
} from "../../../constants/site.constant";
import { TBillingCycle } from "../../../types/billing-cycle";
import PlansTable from "../shared/plansTable";
import BillingInfo from "../shared/billingInfo";
import { TrialConfig } from "../shared/types";
import Header from "../../Header";
import commonStyles from "../commonStyle.module.scss";
import BillingCycleToggle from "../shared/BillingCycleToggle";
import { useAvailableBillingCycles } from "../../../hooks/site/useAvailableBillingCycles";
import { getSupportedBillingCycleForPlan } from "../../../helpers/site/billing-cycle.helper";
import { usePlans } from "./usePlans";
import SiteFeatureCard from "./FeatureCard";
import { logSitePlanSelectedEvent } from "../../../telemetry/site/medusaEventsFunctions";
import {
  getSiteBuilderEventData,
  getVisitorProfileForMedusa,
} from "../../../telemetry/site/siteMedusaData";

type TPlanTableComponents = React.ComponentProps<
  typeof PlansTable
>["components"];

const getPlanTag = (plan: TPlan) => {
  return null;
};

const Plans: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { currency } = user;
  const domain = useSelector(selectSitePurchaseDomain);
  const { duration: planDuration } = useSelector(selectSitePlan);
  const { isCoSiteTrial } = useSelector(selectSiteDomainAttrs);
  const domainPricing = useSelector(selectSiteDomainPricing);
  const { search_params } = useSelector(appSelector);
  const isCustomDomain = useSelector(isSiteDomainCustom);
  const { cycles } = useAvailableBillingCycles();
  const navigateTo = useNavigate();
  const { visiblePlans, allFeatures, groupedFeatures } = usePlans({
    isCustomDomain,
    domain,
    duration: planDuration,
    featuresTextMap: siteFeaturesTextMap,
    domainPricing,
    isCoSiteTrial,
  });

  const changeBillingCycle = useCallback(
    (duration: TBillingCycle) => {
      dispatch(setSitePlan({ duration }));
    },
    [dispatch]
  );

  const onSelectPlan = useCallback(
    (_plan: TPlan) => {
      const { id } = _plan;

      const supportedBillingCycles = getSupportedBillingCycleForPlan(_plan);
      dispatch(setSitePlan({ id, supportedBillingCycles }));
      navigateTo({
        pathname: PAGE_PATHS.SITE_ADD_MAILBOX,
        search: search_params,
      });
      logSitePlanSelectedEvent(ANALYTICS_EVENTS.PLAN_SELECTED, {
        ...getSiteBuilderEventData(),
        ...getVisitorProfileForMedusa(),
      });
    },
    [dispatch, navigateTo, search_params] 
  );

  const components: TPlanTableComponents = useMemo(() => {
    return {
      BillingInfo: (props: any) => (
        <BillingInfo billingFor="/month" {...props} />
      ),
      FeatureCard: SiteFeatureCard,
    };
  }, []);

  const trialConfig = useMemo<TrialConfig>(() => {
    if (isCoSiteTrial) {
      return {
        isTrial: true,
        trialDurationInDays: TRIAL_DURATIONS_IN_DAYS.CO_SITE,
      };
    }
    if (isCustomDomain) {
      return {
        isTrial: true,
        trialDurationInDays: TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN,
      };
    }
    return { isTrial: false };
  }, [isCoSiteTrial, isCustomDomain]);

  return (
    <>
      <Header type={HEADER_TYPES.SITE_PLANS} className={commonStyles.header} />
      <div>
        <div className={commonStyles.firstHeaderTileWrapper}>
          <BillingCycleToggle
            changeBillingCycle={changeBillingCycle}
            cycles={cycles}
            backupCycle={BILLING_CYCLE_KEYS.YEARLY}
            billingCycle={planDuration}
          />
        </div>
        <div className={commonStyles.plansWrapper}>
          <PlansTable
            domain={domain}
            allFeatures={allFeatures}
            visiblePlans={visiblePlans}
            featuresTextMap={siteFeaturesTextMap}
            groupedFeatures={groupedFeatures}
            currency={currency}
            duration={planDuration}
            components={components}
            trialConfig={trialConfig}
            onSelectPlan={onSelectPlan}
            featureGroupTextMap={SITE_FEATURE_GROUP_TEXT_MAP}
          />
        </div>
      </div>
    </>
  );
};

export default Plans;
