import { useEffect, useRef, useState } from "react";
import ENBridge from "../../bridge";
import { useNeoContext } from "../../store/neo/useNeoContext";
import { customerAccountSignedOut } from "../../services/medusa/customerAccountSignedOut";

export const useUserProfile = () => {
  const { state } = useNeoContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    handlers: {
      redirectToCP,
      redirectToWebmail,
      logoutUser,
    },
    images: {
      MAIL_ICON,
      LOGOUT_ICON,
      SETTING_ICON
    },
  } = ENBridge;
  const {
    name,
    cpToken,
    userType,
    domain,
    webmailAutoLoginToken,
    email,
    customerEmail,
  } = state;

  // Custom hook to detect outside click of the component
  const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [ref]);

    return ref;
  };

  // hide user profile if clicked outside
  const handleClickOutside = () => setShowDropdown(false);
  const profileRef = useOutsideClick(handleClickOutside);

  // action to open webmail
  // auto login if webmail token is present (incase of purchase flow we have this info)
  // if no token then simply redirect
  const openWebmail = () => redirectToWebmail(email, webmailAutoLoginToken);

  // action to open cp
  // auto login if cp token is present
  // if no token then simply redirect
  const openCP = () => redirectToCP(cpToken, domain);

  // action to logout user
  const logout = async () => {
    await customerAccountSignedOut(state);
    logoutUser();
  };

  return {
    name,
    cpToken,
    userType,
    customerEmail,

    profileRef,
    showDropdown,
    icons: {
      MAIL_ICON,
      LOGOUT_ICON,
      SETTING_ICON
    },

    setShowDropdown,
    handleClickOutside,
    openCP,
    openWebmail,
    logout,
  }

}