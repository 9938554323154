import { useMemo } from "react";
import { useSelector } from "react-redux";
import { TPlan } from "../../types/plan.type";
import {
  isSiteDomainCustom,
  selectSiteDomainAttrs,
  selectSitePlans,
} from "../../store/slices/site.slice";
import { isFreePlan } from "../../helpers/plans.helper";

/**
 * Returns only those plans that need to be shown to the user
 * based on whether the user needs to be shown trial plans
 *
 * @returns {Object} plans Object
 */
const useUserVisiblePlans = (): TPlan[] => {
  const plans: TPlan[] = useSelector(selectSitePlans);
  const { isCoSiteTrial } = useSelector(selectSiteDomainAttrs);
  const isCustomDomain = useSelector(isSiteDomainCustom);

  return useMemo(
    () =>
      plans.filter((plan) => {
        if (isFreePlan(plan)) return false; // Do not show free plans at all.
        const showTrialPlans = isCustomDomain || isCoSiteTrial;
        return showTrialPlans ? !plan.isPaid : plan.isPaid;
      }),
    [plans, isCustomDomain, isCoSiteTrial]
  );
};

export default useUserVisiblePlans;
