import { LOGOUT } from "./actionsConstants";

export const updateLogout = (payload = {}) => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
      payload,
    });
  };
};
