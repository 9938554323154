import { QueryParam } from "../types/global";

export const addQueryParamsInUrl = (
  url: string,
  params: Record<string, QueryParam>
) => {
  const urlObj = new URL(url);
  Object.entries(params).forEach(([paramKey, paramValue]) => {
    const paramValueStringified =
      typeof paramValue === "string" ? paramValue : JSON.stringify(paramValue);
    urlObj.searchParams.set(paramKey, paramValueStringified);
  });
  return urlObj.href;
};

export const getQueryParamsFromUrl = (url: string) => {
  const urlObj = new URL(url);
  const queryParams: Record<string, QueryParam> = {};
  urlObj.searchParams.forEach((value, key) => {
    queryParams[key] = decodeURIComponent(value);
  });
  return queryParams;
};

export function removeQueryParams(
  url: string,
  paramsToRemove: string[] = []
): string {
  const urlObject = new URL(url);
  paramsToRemove.forEach((param) => {
    urlObject.searchParams.delete(param);
  });
  return urlObject.href;
}

export function removeAllQueryParams(url: string): string {
  const urlObject = new URL(url);
  urlObject.search = "";
  return urlObject.href;
}

export const openUrlInCurrentTab = (
  url: string,
  params: Record<string, QueryParam> = {}
) => {
  const urlWithParams = addQueryParamsInUrl(url, params);
  window.location.href = urlWithParams;
};

export const getOrigin = (url: string) => {
  const urlObj = new URL(url);
  return urlObj.origin;
};

export function appendQueryParamsToSearch(
  search: string,
  paramsToAppend: Record<string, string>
): string {
  const searchParams = new URLSearchParams(search);

  Object.entries(paramsToAppend).forEach(([key, value]) => {
    searchParams.set(key, value);
  });
  return searchParams.toString();
}

export const getHostNameFromUrl = (url: string) => {
  const urlObj = new URL(url);
  return urlObj.hostname;
};

export const removeTrailingSlashes = (path: string): string => {
  return path.replace(/\/+$/, "");
};
