import { openUrlInCurrentTab } from "../../utils/urlUtils";
import { WINDOW_NAME } from "../constants";
export const getSiteBaseUrl = () => {
  return process.env.REACT_APP_SITE_URL || "http://localhost:3000/site";
};
export const openSiteAppAfterSuccessfulPayment = (
  path: string,
  queryParams: Record<string, any> = {}
) => {
  const siteAppBaseUrl = getSiteBaseUrl();
  const url = `${siteAppBaseUrl}/${path}`;
  openUrlInCurrentTab(url, {
    ...queryParams,
    source: "neoSignup",
  });
};

export const openSiteAppInNewTab = () => {
  window.open(getSiteBaseUrl(), WINDOW_NAME.FLOCK_SITE);
};
