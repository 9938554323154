import { promoSelector } from "../store/selectors/promoSelector";
import { store } from "../store/store";
import { RootState } from "../types/store.type";
import { getVerboseDiscount } from "../helpers/discount.helper";
import { getDaysLeft, parseUTCDate } from "../utils/dateUtils";
import { emailAccountDiscountSelector } from "./../store/selectors/promoSelector";

export const getPromoDataFromStore = (storeState: RootState) => {
  const storeData = storeState || store.getState();
  const { promoCodeDetails } =
    promoSelector(storeData) || {};
  const emailAccountDiscount = emailAccountDiscountSelector(storeData);

  if (!promoCodeDetails || !emailAccountDiscount) {
    return {};
  }

  return {
    promo_code: promoCodeDetails?.displayCode,
    promo_code_id: promoCodeDetails?.id,
    promo_discount: getVerboseDiscount(emailAccountDiscount),
    promo_validity_days: getDaysLeft(
      parseUTCDate(promoCodeDetails?.expiryDate)
    ),
  };
};
