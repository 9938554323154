import React from "react";
import classNames from "classnames";
import {
  InputNumberProps as InputNumberPropsAntd,
  InputNumber as InputNumberAntd,
} from "antd";
import styles from "./style.module.scss";
import Addon from "./Addon";

type Props = InputNumberPropsAntd & {
  useCustomControls?: boolean;
  onLimitReached?: (type: "min" | "max") => void;
};

export default function InputNumber({
  useCustomControls,
  onChange,
  value,
  min,
  max,
  className,
  controls,
  onLimitReached,
  ...rest
}: Props) {
  const increment = () => {
    const newValue = (Number(value) || 0) + 1;
    _onChange(newValue);
  };

  const decrement = () => {
    const newValue = (Number(value) || 0) - 1;
    _onChange(newValue);
  };

  const _onChange = (value: number) => {
    if ((!min || value >= Number(min)) && (!max || value <= Number(max))) {
      onChange?.(value);
    } else if (value < Number(min)) {
      onLimitReached?.("min");
    } else if (value > Number(max)) {
      onLimitReached?.("max");
    }
  };

  const customControlProps = useCustomControls
    ? {
        addonBefore: (
          <Addon onClick={decrement} disabled={min === value}>
            -
          </Addon>
        ),
        addonAfter: (
          <Addon onClick={increment} disabled={max === value}>
            +
          </Addon>
        ),
      }
    : {};

  return (
    <InputNumberAntd
      {...customControlProps}
      onChange={onChange}
      value={value}
      min={min}
      max={max}
      {...rest}
      controls={useCustomControls ? false : controls}
      className={classNames(styles.inputNumber, className)}
    />
  );
}

export type InputNumberProps = Props;
