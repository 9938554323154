import {
  createSlice,
  combineReducers,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { OFFERING } from "../../helpers/constants";
import { LOGOUT } from "../actions/actionsConstants";
import { getPlanFeatureProps } from "../../services/apiFunctions";
import { getMailPlanToUpSell } from "../../helpers/mail/plans.helper";
import { createDomainSlice } from "./domain.slice";
import { createPlanSlice } from "./plan.slice";
import { createMailboxSlice } from "./mailbox.slice";
import { createPlansSlice } from "./plans.slice";
import type { RootState } from "../../types/store.type";

let featureProps: any = null;

// Create the mail-specific slices for domain, plan, and mailbox
const mailDomainSlice = createDomainSlice("mail");
const mailPlanSlice = createPlanSlice("mail");
const mailMailboxSlice = createMailboxSlice("mail");
const mailPlansSlice = createPlansSlice("mail");

const combinedMailReducer = combineReducers({
  domain: mailDomainSlice.reducer,
  plan: mailPlanSlice.reducer,
  plans: mailPlansSlice.reducer,
  mailboxToPurchase: mailMailboxSlice.reducer,
});

// Define the initial state for the mail slice
const initialMailState = combinedMailReducer(undefined, { type: "" });

const mailSlice = createSlice({
  name: "mail",
  initialState: initialMailState,
  reducers: {},
  extraReducers: (builder) => {
    // Using extraReducers to handle the logout action, as it is defined in another slice
    builder.addCase(LOGOUT, () => {
      // Reset site state to initial state on logout
      return initialMailState;
    });

    // Add the default case after specific cases
    builder.addDefaultCase(combinedMailReducer); // Combine the sub-slice reducers
  },
});

export const {
  setDomain: setMailDomain,
  setDomainPricing: setMailDomainPricing,
  setDomainAttrs: setMailDomainAttrs,
  resetDomainState: resetMailDomainState,
} = mailDomainSlice.actions;
export const {
  setPlan: setMailPlan,
  setPlanDuration: setMailPlanDuration,
  setPlanId: setMailPlanId,
  resetPlan: resetMailPlan,
} = mailPlanSlice.actions;

export const { setPlans: setMailPlans } = mailPlansSlice.actions;
export const {
  setMailbox: setMailMailbox,
  setAdminMailbox: setMailAdminMailbox,
  setAdditionalMailboxes: setMailAdditionalMailboxes,
  resetMailboxState: resetMailMailboxState,
} = mailMailboxSlice.actions;

export default mailSlice.reducer;

export const selectMailDomain = (state: RootState) => state.mail.domain;
export const selectMailDomainPricing = (state: RootState) =>
  state.mail.domain.pricing;
export const selectMailDomainAttrs = (state: RootState) =>
  state.mail.domain.attrs;
export const selectMailPlan = (state: RootState) => state.mail.plan;
export const selectMailPlans = (state: RootState) => state.mail.plans;
export const selectMailPlanDetails = createSelector(
  selectMailPlans,
  selectMailPlan,
  (plans, { id }) => {
    return plans.find((plan) => plan.id === id);
  }
);
export const selectMailPlanDuration = (state: RootState) =>
  state.mail.plan.duration;
export const selectMailAdminMailbox = (state: RootState) =>
  state.mail.mailboxToPurchase.adminMailbox;
export const selectMailAdditionalMailbox = (state: RootState) =>
  state.mail.mailboxToPurchase.additionalMailboxes;
export const selectMailPurchaseDomain = (state: RootState) => {
  const domain = state.mail.domain;
  return domain.offering === OFFERING.COSITE
    ? domain.coSiteDomain
    : domain.customDomain;
};
export const isMailDomainCustom = (state: RootState): boolean => {
  const { offering } = selectMailDomain(state);
  return offering === OFFERING.CUSTOM_DOMAIN;
};

export const selectMailPlanToUpSell = createSelector(selectMailPlans, (plans) =>
  getMailPlanToUpSell(plans)
);

// only fetch once as it is static data
export const getFeaturePropsAction = createAsyncThunk<void, void>(
  "mail/getFeatureProps",
  async () => {
    if (featureProps) return featureProps;
    featureProps = await getPlanFeatureProps();
    return featureProps;
  }
);
