import React, { useMemo } from "react";
import { ReactComponent as PartyPopperEmoji } from "../../assets/emojis/partyPopper.svg";
import Button from "../Common/Button/Button";
import { domainRecommendation } from "../../helpers/domainRecommendationHelpers";
import {
  ANALYTICS_EVENTS,
  DOMAIN_TYPE,
  SOURCE_HOOKS,
} from "../../helpers/constants";
import { getCoSiteTld } from "../../helpers/utils";
import s from "./styles.module.scss";
import { TRedirectState } from "./Common/types";
import { TLogEventProps } from "../../types/global";

interface Props {
  domain: string;
  navigateToGetDomain: (state?: TRedirectState) => void;
  setCoSiteDomain: (domainForPurchase: string) => void;
  logEvent: (props: TLogEventProps) => void;
}

const DomainRecommendation: React.FC<Props> = ({
  domain,
  navigateToGetDomain,
  setCoSiteDomain,
  logEvent,
}) => {
  const recommendedDomain = useMemo(() => {
    return domainRecommendation(domain);
  }, [domain]);

  const handleContinue = () => {
    const domainName = `${recommendedDomain}.${getCoSiteTld()}`;
    logEvent({
      eventName: ANALYTICS_EVENTS.HAS_DOMAIN_CHECKED,
      data: {
        domain_ownership: DOMAIN_TYPE.DONT_HAVE_DOMAIN,
        neo_offering: "",
        source_hook: SOURCE_HOOKS.CO_SITE_UPSELL_ON_CUSTOM_DOMAIN_ERROR,
      },
    });
    setCoSiteDomain(domainName);
    navigateToGetDomain({
      sourceHook: SOURCE_HOOKS.CO_SITE_UPSELL_ON_CUSTOM_DOMAIN_ERROR,
    }); // Redirect with state
  };

  return (
    <>
      {!recommendedDomain ? null : (
        <div className={s.domainRecommendation}>
          <p className={s.title}>Don’t own a domain name?</p>
          <div className={s.container}>
            <div className={s.domainAvailable}>
              <p className={s.domain}>
                <b>{recommendedDomain}</b>.{getCoSiteTld()} is available
              </p>
              <p className={s.firstYearFree}>
                <b>1st year FREE</b> with yearly email plans{" "}
                <PartyPopperEmoji className={s.partyPopper} />
              </p>
            </div>
            <Button
              className={s.continue}
              type="primary"
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default DomainRecommendation;