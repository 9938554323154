import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { appSelector } from "../../../store/selectors";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import SignUp from "../index";
import { logCustomerEvent } from "../../../telemetry/medusaEventsFunctions";
import { selectMailPurchaseDomain } from "../../../store/slices/mail.slice";

const {
  CUSTOMER_ACCOUNT_CREATED,
  CUSTOMER_ACCOUNT_LINKED,
  CUSTOMER_PASSWORD_CHECKED_FAILED,
  CUSTOMER_EMAIL_CHECKED_FAILED,
  TRAFFIC_VERIFICATION_FAILED,
  TRAFFIC_VERIFIED,
} = ANALYTICS_EVENTS;

const MailSignUp = () => {
  const navigateTo = useNavigate();
  const { search_params } = useSelector(appSelector);
  const domain = useSelector(selectMailPurchaseDomain);

  const title = "Let’s create your customer account";
  const subTitle =
    "Your customer account is where you’ll manage your billing and mailboxes.";
  const fullNameLabel = "Your full name";
  const alternateEmailLabel = (
    <>
      Your existing email address <span>(one you already use)</span>
    </>
  );
  const setPasswordLabel = "Set password";
  const ctaText = "Create customer account";

  const navigateToSignIn = () => {
    navigateTo(
      {
        pathname: PAGE_PATHS.SIGN_IN,
        search: search_params,
      },
      { replace: true }
    );
  };

  const navigateAwayFromSignUp = () => {
    if (!!domain) {
      navigateTo(
        {
          pathname: PAGE_PATHS.ADD_MAILBOX,
          search: search_params,
        },
        { replace: true }
      );
    } else {
      navigateTo(
        {
          pathname: PAGE_PATHS.GET_STARTED,
          search: search_params,
        },
        { replace: true }
      );
    }
  };

  const onSuccess = (response) => {
    // console.log("Mail signup success", response);
  };
  const onFailure = (error) => {
    // console.log("Mail signup failed", error);
  };

  const logEvent = ({ eventName, data, flag }) => {
    switch (eventName) {
      case CUSTOMER_ACCOUNT_CREATED:
      case CUSTOMER_ACCOUNT_LINKED:
      case CUSTOMER_PASSWORD_CHECKED_FAILED:
      case CUSTOMER_EMAIL_CHECKED_FAILED:
      case TRAFFIC_VERIFICATION_FAILED:
      case TRAFFIC_VERIFIED:
        logCustomerEvent(eventName, data, flag);
        break;
      default:
        console.log("Invalid event name");
    }
  };

  return (
    <SignUp
      title={title}
      subTitle={subTitle}
      fullNameLabel={fullNameLabel}
      alternateEmailLabel={alternateEmailLabel}
      setPasswordLabel={setPasswordLabel}
      ctaText={ctaText}
      domain={domain}
      navigateToSignIn={navigateToSignIn}
      navigateAwayFromSignUp={navigateAwayFromSignUp}
      onSuccess={onSuccess}
      onFailure={onFailure}
      logEvent={logEvent}
    />
  );
};

export default MailSignUp;
