import React from "react";
import classNames from "classnames";
import styles from "./style.module.scss";

type Props = {
  className?: string;
  children: React.ReactNode;
  columns?: number;
};

const SummaryItem = ({ children, className, columns }: Props) => {
  const columnsClass = columns ? styles[`colSplit${columns}`] : "";
  return (
    <div className={classNames(styles.row, columnsClass, className)}>
      {children}
    </div>
  );
};

export default SummaryItem;
