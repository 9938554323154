import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PreviewSiteDesktopImg from "../../assets/site-preview-desktop.jpg";
import PreviewSiteMobileImg from "../../assets/site-preview-mobile.jpg";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../helpers/constants";
import { appSelector } from "../../store/selectors";
import { logSitePreviewEvent } from "../../telemetry/medusaEventsFunctions";
import {
  isMailDomainCustom,
  selectMailPurchaseDomain,
} from "../../store/slices/mail.slice";
import Button from "../Common/Button/Button";
import styles from "./PreviewSite.module.scss";

const { NEO_SITE_PREVIEWED } = ANALYTICS_EVENTS;

const PreviewSite = () => {
  const { search_params } = useSelector(appSelector);
  const navigateTo = useNavigate();
  const domain = useSelector(selectMailPurchaseDomain);

  const isCustomDomain = useSelector(isMailDomainCustom);

  const navigateToPlansPage = () => {
    logSitePreviewEvent(NEO_SITE_PREVIEWED);

    navigateTo({
      pathname: PAGE_PATHS.PLANS,
      search: search_params,
    });
  };

  const getHeadingCopy = () => (
    <div
      className={classNames(styles.siteInfo, {
        [styles.fullWidth]: isCustomDomain,
      })}
    >
      {isCustomDomain ? (
        <>
          Free <span>AI-powered site builder</span> included. Launch within
          minutes.
        </>
      ) : (
        <>
          Your domain comes with a <span>AI-powered site builder</span>. Launch
          your brand online.
        </>
      )}
    </div>
  );

  return (
    <>
      <div className={styles.previewSiteWrapper}>
        <div className={styles.headingWrapper}>
          {getHeadingCopy()}
          <div>
            <Button
              type={"primary"}
              onClick={navigateToPlansPage}
              className={styles.previewSiteContinueBtn}
            >
              Continue
            </Button>
          </div>
        </div>
        <div className={styles.sitePreviewImageWrapper}>
          <picture>
            <div className={styles.siteUrl}>{domain}</div>
            <source media="(max-width:640px)" srcSet={PreviewSiteMobileImg} />
            <img src={PreviewSiteDesktopImg} alt="Preview Site" />
          </picture>
        </div>
        <Button
          type={"primary"}
          onClick={navigateToPlansPage}
          className={styles.previewSiteContinueBtn}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default PreviewSite;
