import React from "react";
import classNames from "classnames";
import { ReactComponent as PromoIcon } from "../../assets/promo.svg";
import usePromo from "../../hooks/mail/usePromo";
import styles from "./Promo.module.scss";

type Props = {
  className?: string;
  iconClassName?: string;
  view: "desktop" | "mobile";
};

const config = {
  desktop: {
    className: styles.desktop,
    desc: "applied on checkout",
  },
  mobile: {
    className: styles.mobile,
    desc: "applied for all plans on checkout!",
  },
} as const;

function Promo({ className, iconClassName, view }: Props) {
  const { promoCodeDetails } = usePromo();
  const { className: calculatedClassName, desc } =
    config[view] || config["desktop"];

  if (!promoCodeDetails) return null;

  return (
    <div className={`${styles.wrapper} ${calculatedClassName} ${className}`}>
      <PromoIcon className={classNames(styles.icon, iconClassName)} />
      <span className={styles.details}>
        <span className={styles.code}>{promoCodeDetails.displayCode}</span>{" "}
        {desc}
      </span>
    </div>
  );
}

export default Promo;
