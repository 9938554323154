import ENBridge from "../../bridge";
import { FLOW_TYPE, SETUP_COMPLETE_REDIRECTION_OPTIONS, USER_TYPE } from "../../utils/const";
import { useNeoContext } from "../../store/neo/useNeoContext";
import { useEntriContext } from "../../store/entri/useEntriContext";
import { entriSetupCompleteRedirection } from "../../services/medusa/entriSetupCompleteRedirection";

export const useSetupComplete = () => {
  const {
    handlers: {
      getWebmailUrl,
      getCopyToClipboard,
      showSuccessToast,
    },
    const: {
      appStoreConst
    }
  } = ENBridge;
  const { state: entriState } = useEntriContext();
  const { state: neoState } = useNeoContext();
  const { email, domain, accountId, cpToken, userType, flowType } = neoState;
  const isCustomer = userType === USER_TYPE.CUSTOMER;
  const isPurchaseFlow = flowType === FLOW_TYPE.PURCHASE;
  const isSiteSetupMode = ENBridge.handlers.isSiteSetupMode();
  const webmailUrl = getWebmailUrl();
  const _entriSetupCompleteRedirection = (redirectionType) =>
    entriSetupCompleteRedirection(redirectionType, entriState, neoState)

  // fun called when user clicks on app store icons (ios, android)
  // this is applicable for mobile screens
  const onLinkClick = (storeName) => {
    switch (storeName) {
      case appStoreConst.IOS.NAME:
        return _entriSetupCompleteRedirection(SETUP_COMPLETE_REDIRECTION_OPTIONS.APPLE_APP_STORE);

      case appStoreConst.ANDROID.NAME:
        return _entriSetupCompleteRedirection(SETUP_COMPLETE_REDIRECTION_OPTIONS.GOOGLE_PLAY_STORE);

      default:
        // as this fun expect promise resp
        return Promise.resolve();
    }
  };

  const sendSiteRedirectEvent = () => {
    _entriSetupCompleteRedirection(SETUP_COMPLETE_REDIRECTION_OPTIONS.SITE_BUILDER);
  }

  // toast msg on copy webmail URL
  const showCopiedToast = () => {
    showSuccessToast("Copied successfully!", { toastId: "copied" });
  };

  // copy webmail URL and show toast msg
  const copyWebmailUrl = () => {
    getCopyToClipboard(webmailUrl);
    showCopiedToast();
  };

  // cp button click event
  const onCpClickMedusaEventsToDispatch = () =>
    _entriSetupCompleteRedirection(SETUP_COMPLETE_REDIRECTION_OPTIONS.NEO_ADMIN_PANEL);

  // webmail button click event
  const onWebmailClickMedusaEventsToDispatch = () =>
    _entriSetupCompleteRedirection(SETUP_COMPLETE_REDIRECTION_OPTIONS.WEBMAIL);

  return {
    email,
    domain,
    accountId,
    cpToken,
    webmailUrl,
    isCustomer,
    isPurchaseFlow,
    isSiteSetupMode,
    onLinkClick,
    copyWebmailUrl,
    sendSiteRedirectEvent,
    onCpClickMedusaEventsToDispatch,
    onWebmailClickMedusaEventsToDispatch
  }
}