import { TBillingCycle } from "../../types/billing-cycle";
import { TPlan } from "../../types/plan.type";
import { sortBillingCycles } from "../billing-cycle.helper";
import { BILLING_CYCLE_KEYS } from "../constants";

const { MONTHLY, YEARLY, TWO_YEARLY } = BILLING_CYCLE_KEYS;

export const getSupportedBillingCycleForAllPlans = (): TBillingCycle[] => {
  return [MONTHLY, YEARLY, TWO_YEARLY];
};

export const getSupportedBillingCycleForPlan = (
  plan: TPlan
): TBillingCycle[] => {
  return sortBillingCycles(
    Object.keys(plan.pricing).filter((billingCycle) => {
      const price =
        plan.pricing?.[billingCycle as TBillingCycle]?.firstBillingCyclePrice;
      return price && price > 0;
    }) as TBillingCycle[]
  );
};
