import React from "react";
import { ReactComponent as StorageIcon } from "../../../../assets/features/storage.svg";
import { ReactComponent as ReadReceiptIcon } from "../../../../assets/features/read-receipt.svg";
import { ReactComponent as EmailTemplateIcon } from "../../../../assets/features/email-template.svg";
import { ReactComponent as AppointmentBookingIcon } from "../../../../assets/features/appointment-booking.svg";
import { ReactComponent as AiEmailWriterIcon } from "../../../../assets/features/ai-email-writer.svg";
import { ReactComponent as SignDesignerIcon } from "../../../../assets/features/signature-designer.svg";
import { ReactComponent as CampaignModeIcon } from "../../../../assets/features/campaign-mode.svg";
import { ReactComponent as AdvanceTrackingIcon } from "../../../../assets/features/advanced-tracking.svg";
import { ReactComponent as PriorityInboxIcon } from "../../../../assets/features/priority-inbox.svg";
import { ReactComponent as AntivirusIcon } from "../../../../assets/features/antivirus.svg";
import { ReactComponent as TooltipIcon } from "../../../../assets/tooltip.svg";
import AntdTooltip from "../../../Common/AntdTooltip";
import styles from "./styles.module.scss";



// Component for rendering each feature item inside the tooltip
const FeatureItem: React.FC<{ icon: React.ReactNode; text: string }> = ({ icon, text }) => (
  <div className={styles.featureItem}>
    <div className={styles.featureIcon}>{icon}</div>
    <span className={styles.featureText}>{text}</span>
  </div>
);


const featuresTooltipContent = (
  <div className={styles.tooltipContainer}>
  <div className={styles.tooltipTitle}>
    Unlock <span className={styles.highlight}>powerful tools</span> with higher plans
  </div>
  <div className={styles.featureList}>
    <FeatureItem icon={<SignDesignerIcon />} text="Signature designer" />
    <FeatureItem icon={<CampaignModeIcon />} text="Campaign mode" />
    <FeatureItem icon={<AdvanceTrackingIcon />} text="Advanced tracking" />
    <FeatureItem icon={<PriorityInboxIcon />} text="Priority Inbox" />
    <FeatureItem icon={<AntivirusIcon />} text="Advanced Antivirus and Antispam" />
  </div>
  <div className={styles.tooltipFooter}>
    and 20+ features to grow your business. Upgrade anytime.
  </div>
</div>

);

const BenefitItem: React.FC<{
  icon: React.ReactNode;
  title: string;
  description: string;
  tooltip?: React.ReactNode;
}> = ({ icon, title, description, tooltip }) => (
  <div className={styles.benefitItem}>
    <div>{icon}</div>
    <div>
      <div className={styles.benefitHeader}>
        <div className={styles.benefitTitle}>{title}</div>
        {tooltip && (
          <AntdTooltip title={tooltip} overlayClassName={styles.customTooltip}>
            <span className={styles.tooltipIcon}>
              <TooltipIcon />
            </span>
          </AntdTooltip>
        )}
      </div>
      <div className={styles.benefitDescription}>{description}</div>
    </div>
  </div>
);


const benefitsData = [
  {
    icon: <StorageIcon />,
    title: "15 GB Storage",
    description: "For an uninterrupted service",
  },
  {
    icon: <ReadReceiptIcon />,
    title: "Read receipts",
    description: "Know when your email was opened",
  },
  {
    icon: <EmailTemplateIcon />,
    title: "Email Templates",
    description: "Save frequently sent responses",
  },
  {
    icon: <AppointmentBookingIcon />,
    title: "Appointment Booking",
    description: "Let clients book with you for your services",
    tooltip: featuresTooltipContent, 
  },
  {
    icon: <AiEmailWriterIcon />,
    title: "AI Email Writer",
    description: "Generate email content using AI",
    tooltip: featuresTooltipContent, 
  },
];

const EmailBenefits: React.FC = () => {
  return (
    <div className={styles.emailBenefitsContainer}>
      <div className={styles.trialText}>Try risk-free for 15 days</div>
      {benefitsData.map((benefit, index) => (
        <BenefitItem
          key={`${benefit.title}-${index}`}
          icon={benefit.icon}
          title={benefit.title}
          description={benefit.description}
          tooltip={benefit.tooltip}
        />
      ))}
    </div>
  );
};

export default EmailBenefits;
