import { useEffect } from "react"
import { isFlowInitViaAutoLogin } from "../../utils/helpers";
import { validateTokenProxy } from "../../services/bll/validateAdminPanelToken/proxy";
import { hydrateStateViaPurchaseFlow } from "../../services/bll/hydrateStateViaPurchaseFlow/proxy";
import { useNeoPropsForEntri } from "../../hooks/useNeoPropsForEntri";
import ENBridge from "../../bridge";
import { ENTRI_SETUP_TYPE } from "../../utils/const";
import { useEntriContext } from "../../store/entri/useEntriContext";
import { useNeoContext } from "../../store/neo/useNeoContext";

export const useApp = () => {
  const { state, dispatch } = useNeoContext();
  const { state: entriState } = useEntriContext();
  const { redirectToCP } = ENBridge.handlers;
  useNeoPropsForEntri();
  const {
    cpToken,
    flowType,
    domain,
    cpTokenValidating,
    dnsRecordsFetching
  } = state;
  const { gettingEntriDetails, setupType } = entriState;
  const isAutomaticSetupPossible = setupType !== ENTRI_SETUP_TYPE.AUTOMATIC;
  const isAutoLoginFlow = isFlowInitViaAutoLogin();

  // validate OR hydrate CL SLT token
  useEffect(() => {

    // auto login flow
    // validate CP token from URL
    // if token is invalid then redirect to CP login screen
    const autoLoginTokenValidation = async () => {
      try {
        await validateTokenProxy(dispatch);
      } catch(e) {
        redirectToCP(cpToken, domain);
      }
    }

    // purchase flow
    // in this flow we assume the data which is store by neo is valid
    // get the data from neo and hydrate/set the state
    // if data is missing then redirect to CP login screen
    const purchaseFlowStateHydrate = async () => {
      try {
        await hydrateStateViaPurchaseFlow(dispatch);
      } catch(e) {
        redirectToCP(cpToken, domain);
      }
    }

    // in both cases we get and set cpToken to NeoContext
    // do this step only if token is not set
    if (!cpToken) {

      // decide which flow to trigger
      if (isAutoLoginFlow) {
        autoLoginTokenValidation();
      } else {
        purchaseFlowStateHydrate();
      }
    }
  }, [cpToken]);

  // show app loader
  // since each component/hook it responsible to certain action/props they make
  // calls to corresponding API (its a sequential process)
  // hence make sure we showing loading as long complete data is loaded
  const showAppLoader = cpTokenValidating || dnsRecordsFetching || gettingEntriDetails;

  return {
    flowType,
    showAppLoader,
    isAutoLoginFlow,
    isAutomaticSetupPossible
  }
}
