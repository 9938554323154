import React from "react";

/**
 *
 * The following code is to prevent the browser from prefilling forms with email addresses and passwords.
 * This is achieved by adding a hidden username and password inputs at the top of the page.
 * 'display:none' is ignored by browsers and hence we have to set the 'opacity' to 0 and 'position' to 'absolute'
 * so that the elements do not take up any visible space in the DOM
 *
 **/

function PreventInputPrefillByBrowser() {
  return (
    <>
      <input
        type="text"
        name={"username"}
        style={{ opacity: 0, position: "absolute" }}
        tabIndex={-1}
      />
      <input
        type="password"
        name={"password"}
        style={{ opacity: 0, position: "absolute" }}
        tabIndex={-1}
      />
    </>
  );
}

export default PreventInputPrefillByBrowser;
