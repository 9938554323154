export const selectBoxStyling = {
  control: () => ({
    padding: "0px 10px",
    border: 0,
    outline: 0,
    display: "flex",
    borderRadius: "8px",
    background: "#ffffff",
    "&:focus": {
      outline: 0,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    marginBottom: 0,
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: 300,
  }),
  input: (provided) => ({
    ...provided,
    caretColor: "transparent",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};
