import { LOGOUT, UPDATE_APP_PARAMS } from "../actions/actionsConstants";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_APP_PARAMS:
      return {
        ...state,
        ...action.payload,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
