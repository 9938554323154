import { hasSpecialChars } from "./utils";
import { specialCharsRegex } from "./constants";

const RESTRICTED_DOMAINS = [
  "gmail",
  "outlook",
  "hotmail",
  "mail",
  "live",
  "rediffmail",
  "yahoo",
  "aol",
  "icloud",
  "protonmail",
  "zoho",
  "gmx",
  "yandex",
];

function getDomainNameWithinSpecialChars(domainName) {
  if (domainName.search(specialCharsRegex) === -1) {
    return domainName;
  }
  return domainName.slice(0, domainName.search(specialCharsRegex));
}

function getDomainNameWithAtTheRate(domainName) {
  const splitString = domainName.split("@");
  const stringBeforeAtTheRate = splitString[0];
  const stringAfterAtTheRate = splitString[1];
  const domainNameWithinSpecialChars =
    getDomainNameWithinSpecialChars(stringAfterAtTheRate);
  if (!RESTRICTED_DOMAINS.includes(domainNameWithinSpecialChars)) {
    return domainNameWithinSpecialChars;
  }
  return removeNumericaAndSpecialCharsFromString(stringBeforeAtTheRate);
}

export function domainRecommendation(domainName) {
  let recommendedDomain = domainName;
  if (!hasSpecialChars(domainName)) {
    recommendedDomain = domainName;
  } else if (!domainName.includes("@")) {
    const domain = getDomainNameWithinSpecialChars(domainName);
    recommendedDomain = domain;
  } else if (domainName.includes("@")) {
    recommendedDomain = getDomainNameWithAtTheRate(domainName);
  }
  if (RESTRICTED_DOMAINS.includes(recommendedDomain)) {
    return "";
  }
  return recommendedDomain;
}

// Remove Numeric and Special Characters from string
export function removeNumericaAndSpecialCharsFromString(string) {
  return string.replace(/[^a-zA-Z]/g, "");
}
