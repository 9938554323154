import { NEO_PARTNER_ID, NEO_PARTNER_ID_NUMBER } from "./constants";
import { getConfig } from "./utils";

const { env } = getConfig();

export const getPartnerId = () => {
  return (
    NEO_PARTNER_ID_NUMBER[env as keyof typeof NEO_PARTNER_ID] || NEO_PARTNER_ID_NUMBER.PROD
  );
};
