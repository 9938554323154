export const readFromBitMask = <T extends string>(orderedKeys: readonly T[]) => {
    type Key = (typeof orderedKeys)[number];
    type Result = Record<Key, boolean>;
  
    return (value: number) =>
      orderedKeys.reduce<Partial<Result>>(
        (acc, key, index) =>
          // eslint-disable-next-line no-bitwise
          ({ ...acc, [key]: Boolean(value & (2 ** index)) }),
        {},
      ) as Result;
  };
  
  export const createBitMask = <T extends string>(orderedKeys: readonly T[]) => {
    type Key = (typeof orderedKeys)[number];
    type Result = Record<Key, boolean>;
  
    return (flags: Result) =>
      orderedKeys.reduce(
        // eslint-disable-next-line no-bitwise
        (acc, key, index) => acc | (Number(flags[key] || 0) * 2 ** index),
        0,
      );
  };