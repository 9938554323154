import { STATE_ACTIONS } from "../../utils/const"

export const setEntriCheckDomainPayload = payload => {
  return {
    type: STATE_ACTIONS.SET_ENTRI_CHECK_DOMAIN_PAYLOAD,
    payload
  }
}

export const setGettingEntriDetails = status => {
  return {
    type: STATE_ACTIONS.SET_GETTING_ENTRI_DETAILS,
    payload: status
  }
}

export const setErrorFetchingEntriDetails = status => {
  return {
    type: STATE_ACTIONS.SET_ERROR_FETCHING_ENTRI_DETAILS,
    payload: status
  }
}
