import React, { useState } from "react";
import { ReactComponent as BulbOutlineIcon } from "../../../assets/bulb-outline.svg";
import Popup from "../../Common/Popup/Popup";
import { isMobile } from "../../../helpers/utils";
import styles from "./styles.module.scss";
import CustomPopover from "../../Common/Popover";

const descriptions = [
  {
    title: "Digital marketing...",
    fullDescription:
      "Digital marketing agency helping businesses grow online with SEO, social media, and ads",
  },
  {
    title: "Business consulting...",
    fullDescription:
      "Business consulting firm offering services in strategic planning, market entry strategies, business coaching, financial advisory, and business operations",
  },
  {
    title: "Fashion store...",
    fullDescription:
      "Fashion store offering trendy, high-quality apparel for men, women, and kids",
  },
  {
    title: "Home services...",
    fullDescription:
      "Home services company offering all-in-one home repair services, including cleaning, carpentry, plumbing, electrical work, painting, and appliance repairs",
  },
  {
    title: "Logistics...",
    fullDescription:
      "Logistics company offering cargo, parcel delivery, warehousing, and tracking for timely, reliable service",
  },
];
const USE_DESCRIPTION = "Use this description";

interface DescriptionListProps {
  onSelectDescription: (description: string) => void;
}

const DescriptionList: React.FC<DescriptionListProps> = ({
  onSelectDescription,
}) => {
  const [activeDescriptionIndex, setActiveDescriptionIndex] = useState<
    number | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState<string | null>(
    null
  );

  const handleOpenDescription = (index: number, description: string) => {
    if (isMobile()) {
      openModal(description); // Open modal if mobile
    } else {
      setActiveDescriptionIndex(
        activeDescriptionIndex === index ? null : index
      ); // Toggle visibility
    }
  };

  const openModal = (description: string) => {
    setSelectedDescription(description);
    setIsModalOpen(true);
  };

  const handleUseDescription = (description: string) => {
    onSelectDescription(description);
    setActiveDescriptionIndex(null);
    setIsModalOpen(false); // Close modal on mobile
  };

  const renderPopoverContent = (description: string) => (
    <>
      <p>{description}</p>
      <button
        className={styles.useDescriptionButton}
        onClick={() => handleUseDescription(description)}
      >
        {USE_DESCRIPTION}
      </button>
    </>
  );

  return (
    <div className={styles.businessDescriptionsWrapper}>
      <BulbOutlineIcon />
      <div className={styles.businessDescriptions}>
        {descriptions.map((desc, index) => (
          <div key={`${desc}-${index}`}>
            {!isMobile() ? (
              <CustomPopover
                content={renderPopoverContent(desc.fullDescription)}
                trigger="click"
                placement="bottom"
                overlayStyle={{ maxWidth: 300 }}
                open={activeDescriptionIndex === index} // Check if the popover is open for this index
                onOpenChange={(open) => {
                  setActiveDescriptionIndex(open ? index : null); // Update the active description index based on visibility
                }}
              >
                <button
                  onClick={() =>
                    handleOpenDescription(index, desc.fullDescription)
                  }
                  className={styles.descButton}
                  tabIndex={0}
                >
                  {desc.title}
                </button>
              </CustomPopover>
            ) : (
              <button
                onClick={() =>
                  handleOpenDescription(index, desc.fullDescription)
                }
                className={styles.descButton}
                tabIndex={0}
              >
                {desc.title}
              </button>
            )}
          </div>
        ))}

        {isMobile() && (
          <Popup
            isOpen={isModalOpen}
            overlayTransparent={false}
            onClose={() => setIsModalOpen(false)}
          >
            <BulbOutlineIcon />
            <p className={styles.descriptionInfo}>{selectedDescription}</p>
            <button
              className={styles.useDescriptionButton}
              onClick={() => handleUseDescription(selectedDescription || "")}
            >
              {USE_DESCRIPTION}
            </button>
          </Popup>
        )}
      </div>
    </div>
  );
};

export default DescriptionList;
