import { useState } from "react";
import ENBridge from "../../bridge"
import { useNeoContext } from "../../store/neo/useNeoContext";
import { useMobile } from "../../../../hooks/useMobile";

export const useCPButton = ({
  navigation,
  queryParamsToBePassed,
  onClick
}) => {
  const { isMobileView } = useMobile();
  const [isLoading, setIsLoading] = useState(false);
  const { redirectToCP } = ENBridge.handlers;
  const { state: neoState } = useNeoContext();
  const { cpToken, domain } = neoState;

  /**
   * cp button click handler
   * in mobile we redirect to custom mobile screen
   * else redirect to CP auto login within same tab
   * @returns
   */
  const onCPButtonClick = async () => {
    // avoid multi click
    if (isLoading) return false;

    if (isMobileView) {
      return navigation.openCustomMobileSetup();
    }

    // send medusa events
    setIsLoading(true);
    onClick && await onClick();
    setIsLoading(false);

    // redirection
    return redirectToCP(cpToken, domain, queryParamsToBePassed);
  }

  return {
    isLoading,
    onCPButtonClick
  }
}