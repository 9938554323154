import {
  selectMailAdditionalMailbox,
  selectMailAdminMailbox,
  selectMailPurchaseDomain,
} from "../store/slices/mail.slice";
import {
  selectSiteAdminMailbox,
  selectSiteNoOfAdditionalMailboxes,
  selectSitePlan,
  selectSitePlanDetails,
  selectSitePurchaseDomain,
} from "../store/slices/site.slice";
import { store } from "../store/store";
import { BILLING_CYCLE_DURATIONS } from "./constants";
import { getCountOfTotalMailboxAdded } from "./emailHelpers";
import { getTotalNoOfMailboxesAdded } from "./mailbox.helper";
import { getConfig, getCoSiteTld, getUtmParamsFromCookie } from "./utils";

/**
 * Staging and Preprod Flock Channel:
 * Name: Neo Requests UAT/Testing Environment
 * https://web.flock.com/channel/?chat_jid=ad053bcad13c4ba49bac1c76eda9d7ca@groups.go.to&teamId=10
 *
 * Prod Channel:
 * Name: Neo website requests
 * https://web.flock.com/channel/?chat_jid=988e8e3b0ec14001b31cc81e9de69139@groups.go.to&teamId=10
 *
 */
const WEBHOOK_ENDPOINT = {
  STAGING:
    "https://api.flock.com/hooks/sendMessage/2cf8da01-dbc8-4d87-8d54-bdc8ad61dd64",
  PREPROD:
    "https://api.flock.com/hooks/sendMessage/2cf8da01-dbc8-4d87-8d54-bdc8ad61dd64",
  PROD: "https://api.flock.com/hooks/sendMessage/ef7d592f-7c37-4bc8-8639-9320a3e2c9e8",
};

const postToFlock = (endpoint, data) => {
  return fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then(async (data) => {
      console.log("Success:", data);
    })
    .catch(async (error) => {
      console.error("Error:", error);
    });
};

export const sendToFlock = (isTrial, failureReason) => {
  const storeState = store.getState();
  const { env } = getConfig();

  const {
    user: {
      account_id,
      alternateEmail,
      name,
      plan,
      accountCreationFailures = [],
    },
  } = storeState;

  // TODO: for site
  const domain = selectMailPurchaseDomain(storeState);
  // TODO: for site
  const additionalMailboxes = selectMailAdditionalMailbox(storeState);
  const adminMailbox = selectMailAdminMailbox(storeState);
  const { email } = adminMailbox;

  const { utm_term, utm_source } = getUtmParamsFromCookie() || {};

  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);

  const planName = isTrial ? `${plan.name} Trial` : plan.name;
  const gtmTrafficSource = utm_source || "No traffic source found";
  const gtmKeyword = utm_term || "Unknown";
  const heading = isTrial
    ? "------------------ Neo E5 - Trial Account Request ------------------ "
    : `------------------ $$$$ Neo E5 - ${getCoSiteTld().toUpperCase()} Domain Account Purchase Request $$$$ ------------------ `;
  let data;

  if (!failureReason) {
    data = {
      flockml: `<flockml>
        <b><span style="color:#008000">${heading}</span></b> <br/>
        Name: ${name} <br/>
        Recovery email: ${alternateEmail || ""} <br/>
        Domain Purchased: ${domain} <br/>
        Mailbox Purchased: ${email} <br/>
        Total Mailboxes Purchased: ${countOfMailboxAdded}(including admin) <br/>
        Total Mailboxes Failed To Purchase: ${
          accountCreationFailures?.length
        } <br/>
        Neo Plan Name: ${planName} <br/>
        Billing Cycle: ${plan.duration} <br/>
        Neo order Account ID: ${account_id || ""} <br/>
        Created on: ${new Date(Date.now())} <br/>
        Traffic source: ${gtmTrafficSource} <br/>
        Keyword: ${gtmKeyword} <br/>
        URL: ${window.location.href} <br/></br>
        </flockml>`,
    };
  } else {
    // there was a purchase failure
    data = {
      flockml: `<flockml>
                  <b><span style="color:#ff0000">${heading}</span></b> <br/>
                  <span style="color:#f00"> THERE WAS AN ERROR COMPLETING PURCHASE </span> <br/>
                  <span style="color:#f00">Reason for failure: ${failureReason} </span> <br/>
                  Name: ${name} <br/> Neo Plan Name: ${planName} <br/>
                  Domain to Purchase: ${domain} <br />
                  Mailbox to Purchase: ${email} <br/>
                  Total Mailboxes to Purchase: ${countOfMailboxAdded}(including admin) <br/>
                  Billing Cycle Selected: ${plan.duration} <br/>
                  Recovery email: ${alternateEmail || ""} <br/>
                  Failure Timestamp: ${new Date(Date.now())} <br/>
                  Traffic source: ${gtmTrafficSource} <br/>
                  Keyword: ${gtmKeyword} <br/>
                  URL: ${window.location.href} <br/></br>
                </flockml>`,
    };
  }

  postToFlock(WEBHOOK_ENDPOINT[env], data);

  return null;
};

/**
 * This function is used to send a notification to a webhook in case of any API failure on the billing page.
 * It is a temporary solution for error tracking and notification.
 *
 * @param {boolean} isTrial - Indicates if the purchase for trial.
 * @param {Object | null} payload - The payload of the API request that failed.
 * @param {Object} error - The error object from the API failure.
 */
export const sendToFlockApiFailure = (
  isTrial,
  payload,
  error,
  failureSource
) => {
  const storeState = store.getState();

  const {
    user: { alternateEmail, name, plan, customer_id },
  } = storeState;

  // TODO: for site
  const domain = selectMailPurchaseDomain(storeState);

  // TODO: for site
  const additionalMailboxes = selectMailAdditionalMailbox(storeState);
  const adminMailbox = selectMailAdminMailbox(storeState);

  const { email } = adminMailbox;

  const { utm_term, utm_source } = getUtmParamsFromCookie() || {};

  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);

  const planName = isTrial ? `${plan.name} Trial` : plan.name;
  const gtmTrafficSource = utm_source || "No traffic source found";
  const gtmKeyword = utm_term || "Unknown";
  const heading = isTrial
    ? "------------------ Custom Domain Account Request ------------------ "
    : `------------------ ${getCoSiteTld().toUpperCase()} Domain Account Request ------------------ `;
  let data;

  const errorString = error
    ? `<span style="color:#f00">API Response of failure: ${JSON.stringify(
        error
      )} </span> <br/>`
    : "";
  const payloadString = payload
    ? `Payload: ${JSON.stringify(payload)} <br/></br>`
    : "";
  const failureSourceString = failureSource
    ? `Failure Source: ${failureSource} <br/></br>`
    : "";

  data = {
    flockml: `<flockml>
                <b><span style="color:#ff0000">${heading}</span></b> <br/>
                Name: ${name} <br/> Neo Plan Name: ${planName} <br/>
                Domain to Purchase: ${domain} <br />
                Mailbox to Purchase: ${email} <br/>
                Total Mailboxes to Purchase: ${countOfMailboxAdded}(including admin) <br/>
                Billing Cycle Selected: ${plan.duration} <br/>
                Recovery email: ${alternateEmail || ""} <br/>
                Customer ID: ${customer_id} <br/>
                Failure Timestamp: ${new Date(Date.now())} <br/>
                Traffic source: ${gtmTrafficSource} <br/>
                Keyword: ${gtmKeyword} <br/>
                URL: ${window.location.href} <br/></br>
                <span style="color:#f00"> ERROR </span> <br/>
                ${errorString}
                ${payloadString}
                ${failureSourceString}
              </flockml>`,
  };

  postToFlock(
    "https://api.flock.com/hooks/sendMessage/7b57be2b-2de7-4059-8565-bd51d29ccbb5",
    data
  );
};

export const sendToFlockSiteOfferingPurchase = async (bundleId) => {
  const storeState = store.getState();
  const {
    user: { alternateEmail, name },
  } = storeState;
  const domain = selectSitePurchaseDomain(storeState);
  const sitePlan = selectSitePlanDetails(storeState);
  const { duration } = selectSitePlan(storeState);
  const noOfAdditionalMailboxes = selectSiteNoOfAdditionalMailboxes(storeState);
  const adminMailbox = selectSiteAdminMailbox(storeState);
  const countOfMailboxAdded = getTotalNoOfMailboxesAdded(
    adminMailbox,
    noOfAdditionalMailboxes
  );
  const { utm_term, utm_source } = getUtmParamsFromCookie() || {};
  const gtmTrafficSource = utm_source || "No traffic source found";
  const gtmKeyword = utm_term || "Unknown";

  const heading =
    "------------------ Neo Site Offering Bundles ------------------";

  const data = {
    flockml: `<flockml>
                <b><span style="color:#008000">${heading}</span></b> <br/><br/>
                Name: ${name} <br/>
                Customer email: ${alternateEmail || ""} <br/>
                Domain: ${domain} <br/>
                Site Plan: ${sitePlan.displayName} <br/>
                Mailbox count: ${countOfMailboxAdded} <br/>
                Billing cycle: ${
                  BILLING_CYCLE_DURATIONS[duration] || duration
                } <br/>
                Bundle id: ${bundleId || "N/A"} <br/>
                Created on: ${new Date().toString()} <br/>
                Traffic source: ${gtmTrafficSource} <br/>
                Keyword: ${gtmKeyword} <br/>
                URL: ${window.location.href} <br/>
              </flockml>`,
  };

  await postToFlock(
    "https://api.flock.com/hooks/sendMessage/0fe77711-f72c-4717-bd25-87a9a68fc143",
    data
  );
};
