import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import { appSelector } from "../../../store/selectors";
import SignUp from "../index";
import { logCustomerEvent } from "../../../telemetry/medusaEventsFunctions";
import { selectSitePurchaseDomain } from "../../../store/slices/site.slice";
import {
  getSiteBuilderEventData,
  getVisitorProfileForMedusa,
} from "../../../telemetry/site/siteMedusaData";
import {
  transferSiteVisitorDataPostAuthAction,
} from "../../../store/slices/visitor.slice";

const {
  CUSTOMER_ACCOUNT_CREATED,
  CUSTOMER_ACCOUNT_LINKED,
  CUSTOMER_PASSWORD_CHECKED_FAILED,
  CUSTOMER_EMAIL_CHECKED_FAILED,
  TRAFFIC_VERIFICATION_FAILED,
  TRAFFIC_VERIFIED,
} = ANALYTICS_EVENTS;

const SiteSignUp = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { search_params } = useSelector(appSelector);
  const domain = useSelector(selectSitePurchaseDomain);
  const title = "Let’s finish signing up";
  const subTitle =
    "Sign up to create your website and customize your experience.";
  const fullNameLabel = "Your full name";
  const alternateEmailLabel = "Your email address";
  const setPasswordLabel = "Set your password";
  const ctaText = "Continue";

  const navigateToSignIn = () => {
    navigateTo(
      {
        pathname: PAGE_PATHS.SITE_SIGN_IN,
        search: search_params,
      },
      { replace: true }
    );
  };

  const navigateAwayFromSignUp = () => {
    if (!!domain) {
      navigateTo(
        {
          pathname: PAGE_PATHS.SITE_PLANS,
          search: search_params,
        },
        { replace: true }
      );
    } else {
      navigateTo(
        {
          pathname: PAGE_PATHS.SITE_GET_STARTED,
          search: search_params,
        },
        { replace: true }
      );
    }
  };

  const onSuccess = (response) => {
    // console.log("Site signup success", response);
    if (response?.userEmail)
      dispatch(
        transferSiteVisitorDataPostAuthAction({
          visitorEmail: response.userEmail,
        })
      );
  };
  const onFailure = (error) => {
    // console.log("Site signup failed", error);
  };

  const logEvent = ({ eventName, data, flag }) => {
    switch (eventName) {
      case CUSTOMER_ACCOUNT_CREATED:
      case CUSTOMER_PASSWORD_CHECKED_FAILED:
      case CUSTOMER_EMAIL_CHECKED_FAILED:
      case TRAFFIC_VERIFICATION_FAILED:
      case TRAFFIC_VERIFIED:
        logCustomerEvent(
          eventName,
          {
            ...data,
            ...getSiteBuilderEventData(),
            ...getVisitorProfileForMedusa(),
          },
          flag
        );
        break;

      case CUSTOMER_ACCOUNT_LINKED:
        logCustomerEvent(
          eventName,
          {
            ...data,
            ...getSiteBuilderEventData(),
            ...getVisitorProfileForMedusa(true),
          },
          flag
        );
        break;

      default:
        console.log("Invalid event name");
    }
  };

  return (
    <SignUp
      title={title}
      subTitle={subTitle}
      fullNameLabel={fullNameLabel}
      alternateEmailLabel={alternateEmailLabel}
      setPasswordLabel={setPasswordLabel}
      ctaText={ctaText}
      domain={domain}
      navigateToSignIn={navigateToSignIn}
      navigateAwayFromSignUp={navigateAwayFromSignUp}
      onSuccess={onSuccess}
      onFailure={onFailure}
      logEvent={logEvent}
    />
  );
};

export default SiteSignUp;
