import { STATE_ACTIONS } from "../../utils/const"

export const setDnsRecordsAction = payload => {
  return {
    type: STATE_ACTIONS.SET_DNS_RECORDS,
    payload
  }
}

export const setCPTokenValidating = payload => {
  return {
    type: STATE_ACTIONS.SET_CP_TOKEN_VALIDATING,
    payload
  }
}

export const setDnsRecordsFetching = payload => {
  return {
    type: STATE_ACTIONS.SET_DNS_RECORDS_FETCHING,
    payload
  }
}

export const initStateFromAutoLoginFlow = payload => {
  return {
    type: STATE_ACTIONS.INIT_STATE_FROM_AUTO_LOGIN_FLOW,
    payload
  }
}

export const initStateFromPurchaseFlow = payload => {
  return {
    type: STATE_ACTIONS.INIT_STATE_FROM_PURCHASE_FLOW,
    payload
  }
}
