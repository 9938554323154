import { useMemo } from "react";
import useUserVisiblePlans from "../../../hooks/site/useUserVisiblePlans";
import { MAIL_PLAN_FEATURE_MAP } from "../../../constants/mail.constant";
import { TBillingCycle } from "../../../types/billing-cycle";
import {
  TFeature,
  TFeatureTextMap,
  TGroupedFeature,
  TGroupedFeatures,
} from "../../../types/plan.type";
import { TRIAL_DURATIONS_IN_DAYS } from "../../../helpers/constants";
import { TDomainPricing } from "../../../types/domain.type";
import {
  getFreeDomainPeriod,
  haveToPayForDomainAfter,
} from "../../../helpers/billing-cycle.helper";
import { DomainCharges } from "../shared/DomainCharges";
import { getSitePlanFeaturesComparison } from "../../../helpers/site/plans.helper";
import { SITE_PLAN_FEATURE_MAP } from "../../../constants/site.constant";

type Props = {
  duration: TBillingCycle;
  isCustomDomain: boolean;
  domain: string;
  featuresTextMap: TFeatureTextMap;
  domainPricing: TDomainPricing;
  isCoSiteTrial: boolean;
};

export const usePlans = ({
  duration,
  isCustomDomain,
  domain,
  featuresTextMap,
  domainPricing,
  isCoSiteTrial,
}: Props) => {
  const visiblePlans = useUserVisiblePlans();

  const { groupedFeatures } = useMemo(() => {
    const getFeatureValueForPlan = (feature: TFeature) => {
      const { type } = feature;
      if (type !== SITE_PLAN_FEATURE_MAP.NEO_DOMAIN_PAID) {
        return null;
      }
      const freeDomainPeriod = getFreeDomainPeriod(duration);

      const val = `1st ${freeDomainPeriod} free`;

      const baseTooltip = (
        <>
          After the {haveToPayForDomainAfter(duration)} your domain will be
          priced at{" "}
          {<DomainCharges domainPricing={domainPricing} duration={duration} />}.
          This is in addition to the site plan price.
        </>
      );

      const coSiteTrialTooltip = (
        <>
          Your 1st {freeDomainPeriod} free starts after the{" "}
          {TRIAL_DURATIONS_IN_DAYS.CO_SITE} days trial.
          <br />
          <br />
          {baseTooltip}
        </>
      );

      const val_tooltip = isCoSiteTrial ? coSiteTrialTooltip : baseTooltip;

      return {
        val,
        ...(val_tooltip && { val_tooltip }),
      };
    };

    return getSitePlanFeaturesComparison(visiblePlans, {
      isCustomDomain,
      domain,
      featuresTextMap,
      getFeatureValueForPlan,
    }) as { groupedFeatures: TGroupedFeatures };
  }, [
    visiblePlans,
    isCustomDomain,
    domain,
    featuresTextMap,
    domainPricing,
    isCoSiteTrial,
    duration,
  ]);

  const allFeatures = useMemo(
    () =>
      groupedFeatures.reduce((acc, featureGroup) => {
        return [...acc, ...featureGroup.features];
      }, [] as unknown as TGroupedFeature[]),
    [groupedFeatures]
  );

  return { visiblePlans, allFeatures, groupedFeatures };
};
