import { LS_PERSIST_STORE_KEY } from "./constants";
import { getItemFromLocalStorage } from "./local-storage.helper";

export const getStoreFromLocalStorage = () => {
  try {
    const store = getItemFromLocalStorage(LS_PERSIST_STORE_KEY);
    if (!store || typeof store !== "object") return null;
    Object.keys(store).forEach((key) => {
      store[key] = JSON.parse(store[key]);
    });
    return store;
  } catch (error) {
    console.error("Error while getting store from local storage", error);
    return null;
  }
};
