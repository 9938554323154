import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  isMailDomainCustom,
  selectMailDomainAttrs,
  selectMailPlans,
} from "../../store/slices/mail.slice";
import { userSelector } from "../../store/selectors";
import { PLAN_TYPES } from "../../constants/mail.constant";
import { TPlan } from "../../types/plan.type";
import { isFreePlan } from "../../helpers/plans.helper";

/**
 * Returns only those plans that need to be shown to the user
 * based on whether the user needs to be shown trial plans
 *
 * @returns {Object} plans Object
 */
const useUserVisiblePlans = (): TPlan[] => {
  const plans: TPlan[] = useSelector(selectMailPlans);
  const { isCoSiteTrial } = useSelector(selectMailDomainAttrs);
  const isCustomDomain = useSelector(isMailDomainCustom);
  const { haveLitePlanSupport } = useSelector(userSelector);

  return useMemo(
    () =>
      plans.filter((plan) => {
        if (isFreePlan(plan)) return false; // Do not show free plans
        const planType = plan.neoPlanType;
        const showTrialPlans = isCustomDomain || isCoSiteTrial;
        if ((!haveLitePlanSupport || isCustomDomain) && planType === PLAN_TYPES.LITE) {
          return false; // Exclude lite plans if haveLitePlanSupport is false
        }
        return showTrialPlans ? !plan.isPaid : plan.isPaid;
      }),
    [plans, haveLitePlanSupport, isCustomDomain, isCoSiteTrial]
  );
};

export default useUserVisiblePlans;
