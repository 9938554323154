import App from "./containers/App/App";

import { EntriContextProvider } from "./store/entri/provider";
import { NeoContextProvider } from "./store/neo/provider";

const Entri = () => {
  return (
    <EntriContextProvider>
      <NeoContextProvider>
        <App />
      </NeoContextProvider>
    </EntriContextProvider>
  )
}

export default Entri;