import React from "react";
import { OFFERING } from "../../helpers/constants";
import styles from "./styles.module.scss";
import {
  TDomainSelectionOptions,
  THandleDomainTypeSelection,
} from "./Common/types";

interface Props {
  handleDomainTypeSelection: THandleDomainTypeSelection;
  domainSelectionOptions: TDomainSelectionOptions;
}

const GetStartedV1: React.FC<Props> = ({
  handleDomainTypeSelection,
  domainSelectionOptions,
}) => {
  return (
    <div className={styles.buttonsWrapper}>
      <div
        className={styles.button}
        onClick={() => handleDomainTypeSelection(OFFERING.CUSTOM_DOMAIN)}
      >
        {domainSelectionOptions.haveDomain}
      </div>
      <div
        className={styles.button}
        onClick={() => handleDomainTypeSelection(OFFERING.COSITE)}
      >
        {domainSelectionOptions.needDomain}
      </div>
    </div>
  );
};

export default GetStartedV1;
