// Define the type for each domain reason
interface TDomainUnavailableReason {
  error_message: string;
  event_error_message: string;
  event_availability_message: string;
}

// Define the type for the entire object
export interface TDomainUnavailableReasons {
  DOMAIN_ALREADY_EXISTS: TDomainUnavailableReason;
  INTERNAL_DOMAIN: TDomainUnavailableReason;
  SPAM_DOMAIN: TDomainUnavailableReason;
  TOP_BRAND: TDomainUnavailableReason;
  PREMIUM_KEYWORDS: TDomainUnavailableReason;
  BLOCK_REQUEST_FROM_PARTNER: TDomainUnavailableReason;
  DOMAIN_UNDER_VERIFICATION: TDomainUnavailableReason;
  DOMAIN_BELONGS_TO_CUSTOMER: TDomainUnavailableReason;
  DISALLOWED_DOMAIN: TDomainUnavailableReason;
}

export const domainUnavailableReasons: TDomainUnavailableReasons = {
  DOMAIN_ALREADY_EXISTS: {
    error_message: "This domain is already in use",
    event_error_message: "Domain already in use",
    event_availability_message: "Unavailable",
  },
  INTERNAL_DOMAIN: {
    error_message: "This domain is not available",
    event_error_message: "Blocked - Internal",
    event_availability_message: "Blocked",
  },
  SPAM_DOMAIN: {
    error_message: "This domain is not available",
    event_error_message: "Blocked - Spam",
    event_availability_message: "Blocked",
  },
  TOP_BRAND: {
    error_message: "This domain is not available",
    event_error_message: "Blocked - Brand",
    event_availability_message: "Blocked",
  },
  PREMIUM_KEYWORDS: {
    error_message: "This domain is not available",
    event_error_message: "Blocked - Premium",
    event_availability_message: "Blocked",
  },
  BLOCK_REQUEST_FROM_PARTNER: {
    error_message: "This domain is not available",
    event_error_message: "Blocked - Partner",
    event_availability_message: "Unavailable",
  },
  DOMAIN_UNDER_VERIFICATION: {
    error_message: "This domain is already in use",
    event_error_message: "Domain under verification",
    event_availability_message: "Unavailable",
  },
  DOMAIN_BELONGS_TO_CUSTOMER: {
    error_message: "This domain is already in use",
    event_error_message: "Own domain",
    event_availability_message: "Own domain",
  },
  DISALLOWED_DOMAIN: {
    error_message: "This domain does not allow setting email on it",
    event_error_message: "Shared subdomain",
    event_availability_message: "Unavailable",
  },
};
