import React from "react";
import { useSelector } from "react-redux";

import { ANALYTICS_EVENTS, APP_STORE_CONSTANTS } from "../../helpers/constants";
import { userSelector } from "../../store/selectors";
import { logPurchaseCompletedEvent } from "../../telemetry/medusaEventsFunctions";
import MobileStoreIconsRenderer from "./MobileStoreIconsRender";
import { selectMailAdminMailbox } from "../../store/slices/mail.slice";

const { PURCHASE_COMPLETED_DOWNLOAD_APP_CLICKED } = ANALYTICS_EVENTS;

const { ANDROID, IOS } = APP_STORE_CONSTANTS;

function MobileStoreIcons() {
  const user = useSelector(userSelector);
  const { account_id, token } = user;
  // TODO: for site
  const adminMailbox = useSelector(selectMailAdminMailbox);

  const email = adminMailbox?.email;
  const getPlaystoreUrl = () => {
    const playstoreParams = encodeURIComponent(
      `payload={${account_id}:{"email":"${email}","sessionToken":"${token}"}}&utm={source:"mobile_website"}`
    );
    return `${ANDROID.URL}${playstoreParams}`;
  };

  const handleStoreIconClick = (storeName) => {
    logPurchaseCompletedEvent(PURCHASE_COMPLETED_DOWNLOAD_APP_CLICKED, {
      redirection_option_selected: storeName,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(
            `${PURCHASE_COMPLETED_DOWNLOAD_APP_CLICKED} Event failed.`
          );
        }
      })
      .finally(() => {
        switch (storeName) {
          case ANDROID.NAME:
            console.log("navigated to Google Play Store", getPlaystoreUrl());
            window.location.href = getPlaystoreUrl();
            break;

          case IOS.NAME:
            console.log("navigated to iOS App Store ");
            window.location.href = IOS.URL;
            break;

          default:
            return null;
        }
      });
  };

  return (
    <MobileStoreIconsRenderer
      email={email}
      account_id={account_id}
      token={token}
      onLinkClick={handleStoreIconClick}
    />
  );
}

export default MobileStoreIcons;
