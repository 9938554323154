import { useState, useEffect } from "react";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

interface TimerResult {
  hours: number;
  minutes: number;
  seconds: number;
  isTimeUp: boolean;
}

export default function useTimer(
  timeSpan: number,
  { interval = SECOND }: { interval?: number } = {}
): TimerResult {
  const [remainingTime, setRemainingTime] = useState<number>(timeSpan);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((_remainingTime) => {
        const newRemainingTime = _remainingTime - interval;
        if (newRemainingTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return newRemainingTime;
      });
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);

  return {
    hours: Math.floor(remainingTime / HOUR),
    minutes: Math.floor((remainingTime / MINUTE) % 60),
    seconds: Math.floor((remainingTime / SECOND) % 60),
    isTimeUp: remainingTime <= 0,
  };
}
