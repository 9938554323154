import React, { useMemo } from "react";
import Price from "../../Common/Price";
import styles from "../styles.module.scss";
import { CUSTOM_DOMAIN_TRIAL_DURATION_IN_DAYS } from "../../../3rdPartyIntegrations/Entri/utils/const";
import { getCheapestRenewalPriceForDomain } from "../../../helpers/plans.helper";
import useUserVisiblePlans from "../../../hooks/mail/useUserVisiblePlans";

const EmailBenefits = () => {
  const userVisiblePlans = useUserVisiblePlans();

  const cheapestRenewalPrice = useMemo(
    () => getCheapestRenewalPriceForDomain(userVisiblePlans),
    [userVisiblePlans]
  );
  return (
    <div className={styles.emailBenefits}>
      <div>Why get name@domain email from Neo?</div>
      <p>
        <strong>⭐️ Trusted by 15k small businesses.</strong>
      </p>
      <p>
        ⭐ Packed with powerful{" "}
        <strong>email features like Read Receipts</strong> and Email Templates
      </p>
      <p>
        ⭐️ Try{" "}
        <strong>FREE for {CUSTOM_DOMAIN_TRIAL_DURATION_IN_DAYS} days</strong>.
        Cancel anytime. Renewal starts at{" "}
        <span>
          <Price value={cheapestRenewalPrice || 0} />
          /mailbox/mo
        </span>
        .
      </p>
    </div>
  );
};

export default EmailBenefits;
