import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PAGE_PATHS } from "../../helpers/constants";
import { appSelector } from "../../store/selectors";
import Button from "../Common/Button/Button";
import MailboxEmptyIcon from "../../assets/mailboxEmpty.svg";
import { useRedirection } from "../../hooks/useRedirection";
import styles from "./MailboxCheckoutModal.module.scss";

const NoMailboxAddedModal = () => {
  const { search_params } = useSelector(appSelector);
  const navigateTo = useNavigate();
  const { handleCheckoutRedirection } = useRedirection();

  const redirectToTeamMailbox = () => {
    navigateTo({
      pathname: PAGE_PATHS.ADD_TEAM_MAILBOX,
      search: search_params,
    });
  };

  return (
    <div className={styles.mailboxEmptyWrapper}>
      <img src={MailboxEmptyIcon} alt="mailbox icon" />
      <p>
        No mailboxes added. Add team or generic mailboxes for your business.
      </p>
      <Button type={"primary"} onClick={() => handleCheckoutRedirection()}>
        Continue without adding
      </Button>
      <div
        className={styles.secondaryBtn}
        onClick={() => redirectToTeamMailbox()}
      >
        Add mailboxes
      </div>
    </div>
  );
};

export default NoMailboxAddedModal;
