import { ENTRI_SETUP_TYPE, FLOW_TYPE } from "../../utils/const";
import { MEDUSA_EVENT_TYPE, sendMedusaEvent } from "../../utils/medusa";

export const purchaseCompletedRedirection = (
  redirectionOption,
  entriState,
  neoState
) => {
  if (!entriState || neoState.flowType !== FLOW_TYPE.PURCHASE) return null;

  return sendMedusaEvent(
    "purchase_completed_redirection",
    neoState,
    {
      auto_dns_setup_supported:
        entriState.setupType === ENTRI_SETUP_TYPE.AUTOMATIC,
      redirection_option_selected: redirectionOption,
      // linkToEntity: false,
    },
    MEDUSA_EVENT_TYPE.TITAN_CUSTOMER
  );
};
