import { LOGOUT, UPDATE_UTM_PARAMS } from "../actions/actionsConstants";

const initialState = {};

const utm = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_UTM_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return initialState;  

    default:
      return state;
  }
};

export default utm;
