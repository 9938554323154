// Constants for flags
const DOMAIN_FLAGS = {
  IS_DOMAIN_CHARGEABLE: 1 << 1,
  // Future flags can be added here,
};

// Utility function to set a flag
const setFlag = (state, flag) => {
  return state | flag;
};

// Utility function to create domainState
export const getDomainStateBitSet = (options) => {
  let state = 0;

  if (options.isDomainChargeable) {
    state = setFlag(state, DOMAIN_FLAGS.IS_DOMAIN_CHARGEABLE);
  }

  // Handle more flags here as needed

  return state;
};
