import React from "react";
import classNames from "classnames";
import { isMobile } from "../../../../helpers/utils";
import { TPricing } from "../../../../types/plan.type";
import { TBillingCycle } from "../../../../types/billing-cycle";
import styles from "./style.module.scss";

export const billingTextMap = {
  quarterly: "billed quarterly",
  yearly: "billed yearly",
  "two_yearly": "billed 2 yearly",
  "four_yearly": "billed 4 yearly",
};

interface BillingInfoProps {
  pricing: TPricing | undefined;
  duration: TBillingCycle;
  billingFor: React.ReactNode;
}

const BillingInfo: React.FC<BillingInfoProps> = ({
  pricing,
  duration,
  billingFor,
}) => {
  const havePricingSet = pricing !== undefined;
  const currentBillingText =
    billingTextMap[duration as keyof typeof billingTextMap];

  return (
    <small className={classNames(styles.billingInfo, "billingInfo")}>
      <span>
        {billingFor}
        {!isMobile() && havePricingSet && currentBillingText && ", "}
      </span>
      {havePricingSet && currentBillingText && <span>{currentBillingText}</span>}
    </small>
  );
};

export default BillingInfo;
