import React, { useEffect, useState } from "react";
import Popup from "../Common/Popup/Popup";
import SuccessIcon from "../../assets/successTick.gif";
import { SUCCESS_REDIRECTION_TIME } from "../../helpers/constants";
import { useRedirection } from "../../hooks/useRedirection";
import styles from "./Modal.module.scss";

const MailboxPurchaseSuccess = ({
  countOfMailboxPurchaseFailed,
  countOfMailboxAdded,
}) => {
  const { handleCheckoutRedirection } = useRedirection();

  const [counter, setCounter] = useState(SUCCESS_REDIRECTION_TIME);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <Popup>
      <div className={styles.center}>
        <img src={SuccessIcon} alt="Success" className={styles.successGif} />
        <p className={styles.processingOrder}>
          {countOfMailboxAdded - countOfMailboxPurchaseFailed} mailboxes are
          successfully added!
        </p>
        <p className={styles.subTitle}>Redirecting you in {counter}...</p>
        <p className={styles.doNotClose}>
          Don’t want to wait? Click{" "}
          <span
            className={styles.clickHere}
            onClick={() => handleCheckoutRedirection()}
          >
            here
          </span>{" "}
          to get redirected
        </p>
      </div>
    </Popup>
  );
};

export default MailboxPurchaseSuccess;
