import axios from "axios";
import { SERVICE_TYPES } from "../helpers/constants";
import { getConfig } from "../helpers/utils";
import ExponentialBackoffScheduler from "../lib/exponential-backoff-scheduler";
import {
  BASE_RETRY_DELAY_IN_SECONDS,
  MAX_RETRY_DELAY_IN_MILLISECONDS,
} from "../constants/api.constants";

const { env } = getConfig();

const isRequestRetryable = (retryCount) => {
  return retryCount > 0;
};

function getConfigObject(endpoint = SERVICE_TYPES.LAMBDA[env]) {
  return {
    baseURL: endpoint,
    headers: {
      // "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
    },
    params: {},
    validateStatus(status) {
      return status >= 200 && status < 300; // default
    },
  };
}

class ApiManager {
  _services = {};

  createApiService = ({ type = SERVICE_TYPES.LAMBDA[env] }) => {
    const service = axios.create(getConfigObject(type));

    service.interceptors.request.use(
      (config) => {
        config.params = Object.assign({}, config.params);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    service.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          console.error("You are unauthorized.");
        }
        console.error(
          "Error: server response:",
          JSON.stringify(error?.response?.data?.desc)
        );
        return error?.response;
      }
    );

    this._services[type] = service;
  };

  get(type = SERVICE_TYPES.LAMBDA[env], path, requestProps = {}, options = {}) {
    return this._request("GET", type, path, requestProps, options);
  }

  put(type = SERVICE_TYPES.LAMBDA[env], path, requestProps = {}, options = {}) {
    return this._request("PUT", type, path, requestProps, options);
  }

  post(
    type = SERVICE_TYPES.LAMBDA[env],
    path,
    requestProps = {},
    options = {}
  ) {
    return this._request("POST", type, path, requestProps, options);
  }

  delete(
    type = SERVICE_TYPES.LAMBDA[env],
    path,
    requestProps = {},
    options = {}
  ) {
    return this._request("DELETE", type, path, requestProps, options);
  }

  async _request(method = "GET", type, path, requestProps = {}, options = {}) {
    const service = this._services[type];
    if (!service) {
      console.error("No service found", this._services);
      return;
    }
    const { maxRetries = 0 } = options;

    const {
      baseURL,
      headers,
      params,
      payload,
      responseType = "json",
    } = requestProps;

    const requestParams = {
      method,
      url: path,
      params,
      data: payload,
      responseType,
    };

    if (baseURL) requestParams.baseURL = baseURL;
    if (headers) requestParams.headers = headers;

    const request = service(requestParams);

    try {
      return await request;
    } catch (err) {
      if (!isRequestRetryable(maxRetries) || err.error === "APICancellation") {
        return Promise.reject(err);
      }

      const backoffScheduler = new ExponentialBackoffScheduler({
        baseDelay: BASE_RETRY_DELAY_IN_SECONDS,
        maxDelay: MAX_RETRY_DELAY_IN_MILLISECONDS,
        jitter: true,
      });
      const delay = backoffScheduler.nextDelay();
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          return this._request(method, type, path, requestProps, {
            ...options,
            maxRetries: maxRetries - 1,
          })
            .then(resolve)
            .catch(reject);
        }, delay);
      });
    }
  }
}

export default new ApiManager();
