import React, { useEffect, useState } from "react";
import ModalTemplate from "../ModalTemplate";
import EmailDiscountImage from "../../../assets/images/exit-intent/email-discount.png";
import ExclusiveDiscountImage from "../../../assets/images/exit-intent/exclusive-discount.png";
import Image from "../../Image/Image";
import EmailSubmitter from "../../Common/EmailSubmitter";
import Footer from "../Footer";
import Button from "../../Common/Button/Button";
import { TMedusaPayload } from "../../../types/medusa";
import { ANALYTICS_EVENTS } from "../../../helpers/constants";
import styles from "./style.module.scss";

interface EmailPitchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (email: string) => void;
  onEvent: (eventName: string, payload?: TMedusaPayload) => void;
}

const EmailPitchModal: React.FC<EmailPitchModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  onEvent,
}) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) setShowSuccess(false); // reset success state on close
  }, [isOpen]);

  const _onSubmit = async (email: string) => {
    setShowSuccess(true);
    onSubmit(email);

    onEvent(ANALYTICS_EVENTS.EXIT_POPUP_EMAIL_SUBMITTED, {
      hattrs: {
        visitor_email: email,
      },
    });
    onEvent(ANALYTICS_EVENTS.EXIT_CONFIRMATION_SEEN);
  };

  const _onClose = () => {
    onClose();
    if (!showSuccess) {
      onEvent(ANALYTICS_EVENTS.EXIT_POPUP_CLOSED);
      return;
    }
    onEvent(ANALYTICS_EVENTS.EXIT_CONFIRMATION_CLOSE_CLICKED);
  };

  const renderDiscountContent = () => {
    return (
      <>
        <h1 className={styles.discountText}>
          Get <b>exclusive 10%</b> off Neo's professional email
        </h1>
        <EmailSubmitter onSubmit={_onSubmit} buttonText="Get extra 10% OFF" />
        <Footer />
      </>
    );
  };

  const renderSuccessContent = () => {
    const onContinueClick = () => {
      onEvent(ANALYTICS_EVENTS.EXIT_CONFIRMATION_CLICKED, { step: 1 });
      onClose();
    };
    return (
      <div>
        <div className={styles.successText}>
          Your <b>exclusive discount will be automatically applied</b> at
          checkout.
        </div>
        <div className={styles.successSubText}>
          We've also emailed you the promo code.
        </div>
        <Button
          type="primary"
          onClick={onContinueClick}
          className={styles.continue}
        >
          Continue
        </Button>
      </div>
    );
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={_onClose}
      leftContent={
        <Image
          src={showSuccess ? ExclusiveDiscountImage : EmailDiscountImage}
          alt="offer"
          width={400} // so that it doesn't create jumpy behaviour on fetch
        />
      }
      rightContent={
        showSuccess ? renderSuccessContent() : renderDiscountContent()
      }
    />
  );
};

export default EmailPitchModal;
