import { useState } from "react";

import FallbackImg from "../../assets/placeholder.png";

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  alt: string;
  fallbackImageSrc?: string;
}

const Image = ({ src, alt, fallbackImageSrc, ...rest }: Props) => {
  const [showFallbackImg, setShowFallbackImg] = useState(false);
  const onImgLoadError = () => setShowFallbackImg(true);

  return (
    <img
      src={showFallbackImg ? fallbackImageSrc || FallbackImg : src}
      onError={onImgLoadError}
      alt={alt}
      {...rest}
    />
  );
};

export default Image;
