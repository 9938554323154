import React, { useEffect, useState } from "react";
import ModalTemplate from "../ModalTemplate";
import FreeDomainImage from "../../../assets/images/exit-intent/free-domain.png";
import ThankYouImage from "../../../assets/images/exit-intent/thank-you.png";
import ExclusiveDiscountImage from "../../../assets/images/exit-intent/exclusive-discount.png";
import Image from "../../Image/Image";
import EmailSubmitter from "../../Common/EmailSubmitter";
import Footer from "../Footer";
import Button from "../../Common/Button/Button";
import { TMedusaPayload } from "../../../types/medusa";
import { ANALYTICS_EVENTS } from "../../../helpers/constants";
import styles from "./style.module.scss";

interface DomainPitchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (email: string) => void;
  onEvent: (eventName: string, payload?: TMedusaPayload) => void;
  onContinueClick: () => void;
}

const DomainPitchModal: React.FC<DomainPitchModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  onEvent,
  onContinueClick,
}) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [remindMeLater, setRemindMeLater] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) setShowSuccess(false);
  }, [isOpen]);

  const _onClose = () => {
    onClose();
    if (!showSuccess) {
      onEvent(ANALYTICS_EVENTS.EXIT_POPUP_CLOSED);
      return;
    }
    onEvent(ANALYTICS_EVENTS.EXIT_CONFIRMATION_CLOSE_CLICKED, {
      step: remindMeLater ? 2 : 1,
    });
  };

  const _onSubmit = (email: string) => {
    setShowSuccess(true);
    onSubmit(email);
    onEvent(ANALYTICS_EVENTS.EXIT_POPUP_REMIND_LATER_CLICKED, {
      hattrs: {
        visitor_email: email,
      },
    });
    onEvent(ANALYTICS_EVENTS.EXIT_CONFIRMATION_SEEN);
  };

  const renderFreeDomainContent = () => {
    const getFreeDomainClick = () => {
      setShowSuccess(true);
      onEvent(ANALYTICS_EVENTS.EXIT_POPUP_CLICKED, {
        click_type: "Continue",
      });
    };

    const remindMeLaterClick = () => {
      setRemindMeLater(true);
      onEvent(ANALYTICS_EVENTS.EXIT_POPUP_CLICKED, {
        click_type: "Remind me later",
      });
    };
    return (
      <>
        <h1 className={styles.freeDomainTitle}>Get a FREE domain</h1>
        <div className={styles.freeDomainSubTitle}>
          Claim your <b>free domain</b> with your professional email.
        </div>
        {remindMeLater ? (
          <EmailSubmitter onSubmit={_onSubmit} buttonText="Remind me" />
        ) : (
          <>
            <Button
              type="primary"
              className={styles.getFreeDomainButton}
              onClick={getFreeDomainClick}
            >
              Get free domain now
            </Button>
            <div className={styles.remindMeLater} onClick={remindMeLaterClick}>
              Remind me later
            </div>
          </>
        )}
        <Footer />
      </>
    );
  };

  const renderSuccessContent = () => {
    const continueClick = () => {
      onEvent(ANALYTICS_EVENTS.EXIT_CONFIRMATION_CLICKED, {
        step: remindMeLater ? 2 : 1,
      });
      onContinueClick();
    };
    return (
      <div>
        {remindMeLater ? (
          <div className={styles.youWillHearFromUs}>
            You’ll hear from us soon!
          </div>
        ) : (
          <>
            <div className={styles.congratsTitle}>
              <b>Congratulations!! </b> <br /> You are now eligible for a{" "}
              <b>free</b> domain.
            </div>
            <div className={styles.congratsSubtitle}>
              Select your domain in next step to avail the offer.{" "}
            </div>
          </>
        )}
        <Button
          type="primary"
          className={styles.continue}
          onClick={continueClick}
        >
          Continue
        </Button>
      </div>
    );
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={_onClose}
      leftContent={
        <Image
          src={
            showSuccess
              ? remindMeLater
                ? ThankYouImage
                : ExclusiveDiscountImage
              : FreeDomainImage
          }
          alt="free domain"
        />
      }
      rightContent={
        showSuccess ? renderSuccessContent() : renderFreeDomainContent()
      }
    />
  );
};

export default DomainPitchModal;
