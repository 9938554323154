import React from "react";
import { TPlan } from "../../../../types/plan.type";
import { PLAN_TYPES } from "../../../../constants/mail.constant";
import { BILLING_CYCLE_KEYS } from "../../../../helpers/constants";
import { usePlansContext } from "../context";
import styles from "./style.module.scss";

interface PlanHeaderItemProps {
  plan: TPlan;
}

const PlanHeaderItem: React.FC<PlanHeaderItemProps> = ({ plan }) => {
  const { pricing } = plan;
  const {
    duration: planDuration,
    currency,
    components: {
      Promo,
      PlanPrice,
      BillingInfo,
      PlanButton,
      PlanDiscountTag,
      PlanTag,
    },
  } = usePlansContext();

  const firstBillingCyclePrice =
    plan.neoPlanType === PLAN_TYPES.LITE &&
    planDuration === BILLING_CYCLE_KEYS.QUARTERLY
      ? pricing[BILLING_CYCLE_KEYS.YEARLY]?.firstBillingCyclePrice
      : pricing[planDuration]?.firstBillingCyclePrice;

  const isDiscountedPrice = !!pricing[planDuration]?.discountPercentage;
  const originalPrice = pricing[planDuration]?.price;

  return (
    <>
      <div className={styles.planDetails}>
      <PlanTag plan={plan} />
      <div className={styles.planName}>{plan.name}</div>
      <div>
        <PlanDiscountTag plan={plan} />
      </div>
      <PlanPrice
        discountedPrice={firstBillingCyclePrice}
        originalPrice={originalPrice}
        isDiscountedPrice={isDiscountedPrice}
        currency={currency}
      />
      <BillingInfo duration={planDuration} pricing={pricing[planDuration]} />
      <Promo
        view="desktop"
        className={styles.promoDesktop}
        iconClassName={styles.promoIcon}
      />
      </div>
      <PlanButton plan={plan} />
    </>
  );
};

export default PlanHeaderItem;
