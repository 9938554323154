/**
 * Articles for understanding exponential-backoff and jitter
 * https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
 * https://cloud.google.com/iot/docs/how-tos/exponential-backoff
 */

const BASE_TIMEOUT_IN_SECONDS: number = 2;
const MAX_TIMEOUT_IN_MILLISECONDS: number = 5 * 60 * 1000;

class BackoffScheduler {
  private _numTries: number;
  private _currentDelay: number;
  private _jitter: boolean;
  private _maxDelay: number;
  private _baseDelay: number;
  private _maxDelayReached: boolean;

  constructor({
    baseDelay,
    maxDelay,
    jitter = true,
  }: { baseDelay?: number; maxDelay?: number; jitter?: boolean } = {}) {
    this._numTries = 0;
    this._currentDelay = 0;
    this._jitter = jitter;
    this._maxDelay = maxDelay || MAX_TIMEOUT_IN_MILLISECONDS;
    this._baseDelay = baseDelay || BASE_TIMEOUT_IN_SECONDS;
    this._maxDelayReached = false;
  }

  numTries(): number {
    return this._numTries;
  }

  currentDelay(): number {
    return this._currentDelay;
  }

  reset(): void {
    this._numTries = 0;
    this._currentDelay = 0;
    this._maxDelayReached = false;
  }

  nextDelay(): number {
    const nextDelay: number = this._calculateNextDelay();
    this._numTries++;
    this._currentDelay = nextDelay;
    return nextDelay;
  }

  getNextBackoffDelay(base: number, exponent: number): number {
    const nextDelay: number = base ** exponent * 1000;
    return nextDelay;
  }

  /**
   * For generating random time in millsecond (For now 0 <= time < 1000 millisecond)
   * @returns Time in millisecond
   */
  randomTimeInMilliSec(): number {
    return Math.ceil(Math.random() * 1000);
  }

  private _calculateNextDelay(): number {
    if (this._maxDelayReached) {
      return this._maxDelay + this.randomTimeInMilliSec();
    }

    let nextDelay: number = Math.min(this.getNextBackoffDelay(this._baseDelay, this._numTries), this._maxDelay);

    if (nextDelay === this._maxDelay) {
      this._maxDelayReached = true;
    }

    if (this._jitter) {
      nextDelay += this.randomTimeInMilliSec();
    }

    return nextDelay;
  }
}

export default BackoffScheduler;
