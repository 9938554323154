import { useCallback, useEffect } from "react";
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 * @param {any} data - the action to perform on data change
 */
export default function useKeypress(key, action, data) {
  const handleUserKeypress = useCallback(
    (event) => {
      if (event.code === key) {
        action();
      }
    },
    [data]
  );

  useEffect(() => {
    document.addEventListener("keypress", handleUserKeypress);
    return () => {
      document.removeEventListener("keypress", handleUserKeypress);
    };
  }, [handleUserKeypress]);
}
