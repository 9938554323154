import { Routes, Route } from "react-router"
import { ToastContainer } from "react-toastify";

import DnsSetup from "./flow/DnsSetup"
import PurchaseFlow from "./flow/PurchaseFlow"
import ENBridge from "./3rdPartyIntegrations/Entri/bridge"

const App = () => {
  const { entriRoot } = ENBridge.routes;
  const getToastStyles = () => {
    return {
      maxWidth: "500px",
      borderRadius: "10px",
      bottom: "20%",
      width: "auto",
    };
  };

  return (
    <>
      <Routes>
        <Route path={entriRoot} element={<DnsSetup />} />
        <Route path="*" element={<PurchaseFlow />} />
      </Routes>
      <ToastContainer
        position={"bottom-center"}
        autoClose={3000}
        closeButton={false}
        hideProgressBar={true}
        draggable={false}
        limit={5}
        theme={"dark"}
        style={getToastStyles()}
        className={"toast-notification"}
      />
    </>
  )
}

export default App;
