import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import promoReducer from "../slices/promo.slice";
import visitorReducer from "../slices/visitor.slice";
import { LS_STORE_KEY } from "../../helpers/constants";
import mailReducer from "../slices/mail.slice";
import siteReducer from "../slices/site.slice";
import neoDomainPlansReducer from "../slices/neo-domain-plans";
import appReducer from "./appReducer";
import configReducer from "./configReducer";
import domainReducer from "./domainReducer";
import errorsReducer from "./errorsReducer";
import planPricingReducer from "./planPricingReducer";
import userReducer from "./userReducer";
import utmReducer from "./utmReducer";

const reducers = combineReducers({
  user: userReducer,
  utm_params: utmReducer,
  config: configReducer,
  app: appReducer,
  errorCounts: errorsReducer,
  domainAttributes: domainReducer,
  planPricing: planPricingReducer,
  promo: promoReducer,
  visitor: visitorReducer,
  mail: mailReducer,
  site: siteReducer,
  neoDomainPlans: neoDomainPlansReducer,
});

const persistedReducer = persistReducer(
  { key: LS_STORE_KEY, storage, blacklist: ["promo"] },
  reducers
);

export default persistedReducer;
