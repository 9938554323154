import React from "react";
import Logo from "../../assets/Neo_logo.svg";

interface Props {
  redirectToHomepage: () => void;
}

const NeoLogo: React.FC<Props> = ({ redirectToHomepage }) => {
  return <img src={Logo} alt="Neo Logo" onClick={redirectToHomepage} />;
};

export default NeoLogo;
